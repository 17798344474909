import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "@mui/material";
import { MdMarkEmailRead } from "react-icons/md";

const ConfirmationMessage = ({ eventDetails }) => {
  const Navigate = useNavigate();

  const handleRedirection = () => {
    Navigate("/dashboard");
  };
  return (
    <div className="w-100 d-flex justify-content-center">
      <div style={{ backgroundColor: "white", padding: 10, borderRadius: 10 }}>
        <div className="mt-4">
          <div className="w-100 d-flex justify-content-center">
            <MdMarkEmailRead size={40} color="#0ac074" />
          </div>
          <div className="w-100 d-flex justify-content-center">
            <p style={{ fontSize: 16, textAlign: "center", width: 500 }}>
              Success
              <span style={{ fontWeight: "600" }}>
                {eventDetails?.eventName}
              </span>{" "}
              Please Verify your email
            </p>
          </div>
        </div>
        <div className="mt-3 d-flex justify-content-center">
          <Link to="/login">
            <Button
              type="submit"
              variant="contained"
              // onClick={() => handleRedirection()}
            >
              Ok
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationMessage;
