import React from "react";
// import useCustomMutation from "../../../../../../Hooks/useCustomMutation";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import "bootstrap/dist/css/bootstrap.min.css";

import "../../css/Summary.scss";

import "./branding.scss";

import UploaderReceptionBanner from "./components/UploaderReceptionBanner";
import EditReceptionBanner from "./components/EditReceptionBanner";

const Receptionbanner = ({ token, eventID }) => {
  const key = ["myEventReception", eventID];

  const {
    data: getReceptionBanner,
    error: errorGetReceptionBanner,
    isLoading: isLoadingGetReceptionBanner,
  } = useCustomQuery(
    [key],
    `${process.env.REACT_APP_API}event-reception/${eventID}`,
    token
  );

  return (
    <React.Fragment>
      <div className="event-branding">
        <div className="event-branding--heading">
          <h3>Event Reception Banner</h3>
          <span className="text">
            This Banner will display on your event lobby
          </span>
        </div>
        {getReceptionBanner?.length === 0 && (
          <UploaderReceptionBanner token={token} eventID={eventID} />
        )}

        {Array.isArray(getReceptionBanner) &&
          getReceptionBanner?.length > 0 && (
            <EditReceptionBanner
              token={token}
              eventID={eventID}
              eventReception={getReceptionBanner}
            />
          )}
      </div>
    </React.Fragment>
  );
};
export default Receptionbanner;
