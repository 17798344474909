import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import usePost from "../../../../Hooks/usePost";
import { Spinner, Button, Row, Col } from "reactstrap";

import CustomSwitch from "./CustomSwicth";

function DeleteAccount({ userId, token }) {
  const { execute, pending, data, status } = usePost();
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  //   const userId = userDetails?.id;

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const Method = "DELETE",
      url = `users/${userId}`;
    const raw = null;

    execute(url, raw, Method, "Account Successfully Deleted", token);
  };

  useEffect(() => {
    if (status === "success") {
      const user = data;
      if (user) {
        localStorage.clear();
        localStorage.setItem("authShowbayUser", JSON.stringify(user));
      }
    }
  }, [status, isSwitchOn]);

  const handleSwitchChange = (event) => {
    setIsSwitchOn(!isSwitchOn);
    console.log("Switch clicked, new value: ", event.target.checked); // Add this line
  };

  // useEffect(() => {
  //   console.log("Current state of switch: ", isSwitchOn); // Add this line
  // }, [isSwitchOn]);

  return (
    <div className="w-100 d-flex justify-content-center mt-3">
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        <Row>
          <div className="d-flex justify-content-between">
            <div>
              <p>
                Once you delete your account, there is no going back. Please be
                certain.
              </p>
              <div className="d-flex" onClick={handleSwitchChange}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        sx={{ m: 1 }}
                        checked={isSwitchOn}
                        isSwitchOn={isSwitchOn}
                        onChange={handleSwitchChange}
                      />
                    }
                  />
                </FormGroup>

                <div>
                  <h5>Confirm</h5>
                  <p>I want to delete my account.</p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-end">
              <div className="">
                <button
                  className={`btn ${
                    isSwitchOn ? "btn-danger" : "btn-secondary"
                  }`}
                  type="submit"
                  disabled={!isSwitchOn}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    {!pending ? <span>Delete Account</span> : null}
                    {!pending ? null : (
                      <span>
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        Loading
                      </span>
                    )}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </Row>
      </form>
    </div>
  );
}

export default DeleteAccount;
