import React, { useState, useRef, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Spinner } from "reactstrap";
import { BiEdit } from "react-icons/bi";
import "bootstrap/dist/css/bootstrap.min.css";

import useCustomMutation from "../../../../Hooks/useCustomMutation";
import ImgSession from "../../../../assets/images/banners/sessions/bannerSession.png";
import "./css/Summary.scss";
import "./css/AddSponsor.scss";

const AddSponsor = ({ userId, token, eventID, setSlideInOpenSponsor }) => {
  const queryClient = useQueryClient();
  const key = ["mySponsors", eventID];
  const mutation = useCustomMutation(key);
  const [logoServer, setLogoServer] = useState();
  const [profile, setProfile] = useState();
  const [inputs, setInputs] = useState({});

  // edit event

  // Function to handle link clicks
  const refFileUpload = useRef("");

  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLogoServer(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    console.log("inputs.......", inputs);
    const Method = "POST",
      url = `sponsors/${eventID}`,
      isJSON = true;
    const formdata = new FormData();

    formdata.append("sponsorLogo", logoServer);
    formdata.append("sponsorName", inputs.sponsorName);
    formdata.append("website", inputs.website);
    formdata.append("sponsorTier", inputs.sponsorTier);

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Sponsor added successfully",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };
  useEffect(() => {
    if (mutation.isSuccess) {
      setInputs({});
      queryClient.invalidateQueries(["my-Sponsors", eventID]);
      queryClient.invalidateQueries(["mySponsorsInstitutional", eventID]);
      queryClient.invalidateQueries(["mySponsorsPlatinum", eventID]);
      queryClient.invalidateQueries(["mySponsorsGold", eventID]);
      queryClient.invalidateQueries(["mySponsorsSilver", eventID]);
      setSlideInOpenSponsor(false);
    }
  }, [mutation.isSuccess]);

  return (
    <React.Fragment>
      <div className="sponsor-form-container">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="sponsor-form-body"
          onSubmit={handleSubmitForm}
        >
          <div className="d-flex justify-content-center">
            <div className="section---banner---container mt-2">
              <img
                src={!logoServer ? ImgSession : profile}
                alt="session Image"
                className="w-100 h-100 section---banner---container---img"
              />
              <Button
                size="md"
                variant="separator-light"
                className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
                onClick={onThumbChangeClick}
              >
                <BiEdit
                  size={40}
                  style={{
                    color: "black",
                    filter: "drop-shadow(0px 0px 10px white)",
                  }}
                />
              </Button>
              <input
                type="file"
                ref={refFileUpload}
                className="file-upload d-none"
                accept="image/*"
                onChange={changeThumb}
              />
            </div>
          </div>
          <div
            className="sponsor-form-body__sponsor-logo"
            style={{ marginRight: "12px", marginBottom: "-16px" }}
          >
            <p
              color="ambience.6"
              font-size="1"
              className="sponsor-form-body--input-description"
            >
              Recommended format: 400x400px | JPG, SVG, PNG | Up to 1 MB
            </p>
          </div>

          <div className="sponsor-form-body__input">
            <label htmlFor="sponsorName">
              <p className="sponsor-form-body--input-heading">Sponsor name*</p>
            </label>
            <input
              type="text"
              id="sponsorName"
              placeholder=""
              className="sponsor-form-body__input--field"
              name="sponsorName"
              aria-label="sponsorName"
              onChange={handleChange}
            />
          </div>
          <div className="sponsor-form-body__input">
            <label htmlFor="website">
              <p className="sponsor-form-body--input-heading">Website URL</p>
            </label>
            <input
              type="text"
              id="website"
              placeholder="https://www.yourwebsite.com"
              className="sponsor-form-body__input--field"
              name="website"
              aria-label="website"
              onChange={handleChange}
            />
          </div>
          <div className="sponsor-form-body__input">
            <label htmlFor="sponsorTier">
              <p className="sponsor-form-body--input-heading">Sponsor tier*</p>
            </label>
            <select
              required
              type="text"
              id="sponsorTier"
              placeholder="Choose sponsor tier"
              className="sponsor-form-body__input--field"
              name="sponsorTier"
              aria-label="sponsorTier"
              onChange={handleChange}
            >
              <option value={null}>Select...</option>
              <option value="Institutional">Institutional</option>
              <option value="Platinum">Platinum</option>
              <option value="Gold">Gold</option>
              <option value="Silver">Silver</option>
            </select>
          </div>

          <button className="btn-add-sponsor" type="submit">
            <div className="d-flex justify-content-center align-items-center">
              {!mutation.isLoading ? <span>Add Sponsor</span> : null}
              {!mutation.isLoading ? null : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading
                </span>
              )}
            </div>
          </button>
        </form>
      </div>
    </React.Fragment>
  );
};
export default AddSponsor;
