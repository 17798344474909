import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { AiOutlineSend } from "react-icons/ai";
import { GoSmiley } from "react-icons/go";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { IoCloseCircleOutline } from "react-icons/io5";

import BoothDefault from "../../../../../assets/images/golfie.jpg";
import UserDefault from "../../../../../assets/images/users/User.png";

import "./booth-body.styles.scss"; // Create a CSS file for styling

const BoothBody = ({ booth }) => {
  const [activeLink, setActiveLink] = useState(0);
  const [inputs, setInputs] = useState({});
  const [isChatBoxVisible, setChatBoxVisible] = useState(false);

  const handleClickInside = (e) => {
    e.stopPropagation(); // Prevent the click event from propagating to the overlay
  };

  const handleCloseClick = () => {
    setChatBoxVisible(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // console.log("booth...details....", booth);

  return (
    <React.Fragment>
      <div className="booth-body">
        <div className="booth-body__details">
          <img
            src={BoothDefault}
            width="95%"
            height="40%"
            alt=""
            style={{ borderRadius: "12px" }}
          />

          <div
            style={{
              display: "flex",
              gap: "16px",
              margin: "24px 16px 24px 0",
              paddingBottom: "18px",
              alignItems: "center",
              borderRight: "1px solid rgba(255, 255, 255, 0.2)",
              borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
            }}
          >
            <img
              src={UserDefault}
              width="30%"
              alt=""
              style={{ borderRadius: "50%" }}
            />
            {/* <span style={{ color: "white" }}>|</span> */}
            <h3 style={{ color: "white", marginBottom: "-8px" }}>
              Jed Kazadi
              <br />
              <span style={{ fontSize: "12px", color: "#afacbb" }}>
                | By Organization
              </span>
            </h3>
          </div>

          <div className="booth-body__details__description">
            <h4 style={{ color: "#fefefe" }}>Description</h4>
            <p
              style={{
                color: "#afacbb",
                fontWeight: "normal",
                fontSize: "14px",
                margin: "16px auto",
              }}
            >
              Step into the world of precision engineering and automotive
              excellence at our Motor Parts Broadcast Booth! Immerse yourself in
              a showcase of high-performance motor parts meticulously crafted
              for enthusiasts and professionals alike.
            </p>
          </div>

          <div
            className="with-exhibitors"
            style={{
              background: "rgba(255, 255, 255, 0.1",
              color: "#131216",
              borderRadius: "7px",
              padding: "4px 8px",
              fontSize: "10px",
              marginBottom: "-12px",
              width: "max-content",
              // marginLeft: "240px",
            }}
          >
            <span style={{ color: "white" }}>#Talks</span>
          </div>
        </div>

        <div className="booth-body__chat">
          <div
            className="chat-box"
            style={{
              background: "#afacbb",
              color: "#26252d",
              maxWidth: "400px",
              height: "calc(100vh - 240px)",
              minHeight: "500px",
              padding: "12px 14px",
              marginRight: "8px",
              border: "1px solid #ffffff",
              borderRadius: "12px",
              //   position: "absolute",
              //   zIndex: "999",
              //   top: "0%",
              //   left: "24%",
              display: "flex",
              flexDirection: "column",
              // filter: "drop-shadow(0 0 2rem rgba(0,0,0,.582))",
            }}
          >
            <div
              className="chat-box__head"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <p
                  style={{
                    fontSize: "17px",
                    fontWeight: "700",
                    margin: "0px",
                  }}
                >
                  Chat with Us
                </p>
                <span style={{ fontSize: "12px", color: "#5b5b68" }}>
                  Chat or ask questions to the booth exhibitor
                </span>
              </div>
              {/* <IoCloseCircleOutline
                size={28}
                style={{ cursor: "pointer" }}
                onClick={handleCloseClick}
              /> */}
            </div>
            <div className="chat-box__body" style={{ flexGrow: "1" }}>
              <div
                className="chat-box__body--empty-chat"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <IoChatbubbleEllipsesSharp size={32} />
                <p>
                  This is the Booth Chat. <br />
                  You can view and share your <br /> thoughts/questions here
                </p>
              </div>
            </div>
            <div className="chat-box__footer">
              <div
                style={{
                  backgroundColor: "#1d1c21",
                  border: "0.4px solid #9d9c9e",
                  borderRadius: "8px",
                  padding: "8px 12px",
                  width: "100%",
                }}
              >
                <form
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <GoSmiley />
                  <input
                    type="text"
                    placeholder="Send a message or question"
                    style={{
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      width: "max-content",
                      flexGrow: "1",
                      color: "#fefefe",
                    }}
                    onChange={handleChange}
                  />
                  <AiOutlineSend
                    style={{ cursor: "pointer", color: "#fefefe" }}
                  />
                </form>
              </div>
            </div>
          </div>

          <button className="download-session-documents">
            Download Profile
          </button>

          <div className="notify-on-live">
            <span className="notify-on-live--description">
              Get notified when this booth goes live.
            </span>
            <button className="notify-on-live--button">Notify Me</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BoothBody;
