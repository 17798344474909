import React, { useState } from "react";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import LoaderSM from "../../../../../../components/Loader/LoaderSM";
import { Spinner } from "reactstrap";
import AddSession from "../../AddSession";
import AddSpeaker from "../../AddSpeaker";
import SlideInRightBar from "../../../../../../components/Common/SlideInComponent/SlideInComponent";
import "../../css/Summary.scss";
import "../../css/summary.styles.scss";
import { AiOutlinePlusCircle } from "react-icons/ai";

function Schedule({ eventID, token, userId, eventFormat }) {
  const [slideInOpenSession, setSlideInOpenSession] = useState(false);
  const [slideInOpenSpeaker, setSlideInOpenSpeaker] = useState(false);
  const toggleSlideInSession = () => {
    setSlideInOpenSession(!slideInOpenSession);
  };
  const toggleSlideInSpeaker = () => {
    setSlideInOpenSpeaker(!slideInOpenSpeaker);
  };

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/${eventID}`,
    token
  );
  const {
    data: getMySpeakers,
    error: errorGetMySpeakers,
    isLoading: isLoadingGetMySpeakers,
  } = useCustomQuery(
    ["mySpeakers", eventID],
    `${process.env.REACT_APP_API}speakers/event/${eventID}`,
    token
  );
  if (errorGetMySessions) {
    return <></>;
  }
  if (isLoadingGetMySessions) {
    <LoaderSM />;
  }

  const getLastTwoSessions = (mySessions) => {
    if (mySessions.length >= 2) {
      return mySessions.slice(-2);
    }

    if (mySessions.length === 1) {
      return [mySessions[0]];
    }
    return [];
  };

  let lastTwoSessions = [];
  if (getMySessions) {
    lastTwoSessions = getLastTwoSessions(getMySessions);
  }

  const getLastTwoSpeakers = (mySpeakers) => {
    if (mySpeakers.length >= 2) {
      return mySpeakers.slice(-2);
    }

    if (mySpeakers.length === 1) {
      return [mySpeakers[0]];
    }
    return [];
  };

  let lastTwoSpeakers = [];
  if (getMySpeakers) {
    lastTwoSpeakers = getLastTwoSpeakers(getMySpeakers);
  }

  const toggleRightModal = () => {
    setSlideInOpenSession(!slideInOpenSession);
  };
  return (
    <div className="summary-setup__content">
      <table>
        <tr>
          <td
            className="d-flex justify-content-between"
            style={{ color: "#3b5de7" }}
          >
            <h5>Schedule</h5>
            <div className="">
              <p type="button" onClick={toggleRightModal}>
                Add Session{" "}
              </p>
            </div>
          </td>
          <td style={{ borderLeft: "1px solid #232633" }}>
            <h5>Speakers</h5>
          </td>
        </tr>
        {lastTwoSessions[0] ? (
          <tr>
            <td
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: 10,
              }}
            >
              <div className="session-date">
                <span>
                  {lastTwoSessions[0]?.sessionDate?.substring(7, 11)}
                  {lastTwoSessions[0]?.sessionDate?.substring(4, 8)}
                </span>
                <p>
                  {lastTwoSessions[0]?.startTime} -{" "}
                  {lastTwoSessions[0]?.endTime}
                </p>
              </div>
              <div
                className="d-flex px-2 session-name"
                // style={{ width: "10em", backgroundColor: "green" }}
              >
                <i
                  className="fa fa-microphone"
                  aria-hidden="true"
                  style={{ marginRight: 8, marginTop: 2, fontSize: 20 }}
                ></i>
                <div>
                  <span style={{ fontSize: 11, fontWeight: 600 }}>
                    {lastTwoSessions[0]?.sessionName?.length < 20
                      ? lastTwoSessions[0]?.sessionName
                      : `${lastTwoSessions[0]?.sessionName?.substring(
                          0,
                          7
                        )}...`}
                  </span>
                  <p>{lastTwoSessions[1]?.object?.substring(0, 7)}</p>
                </div>
              </div>
            </td>
            <td style={{ borderLeft: "1px solid #232633" }}>
              <div className="adding-speakers">
                <div className="d-flex">
                  <AiOutlinePlusCircle
                    size={40}
                    style={{ marginLeft: 8 }}
                    type="button"
                    onClick={toggleSlideInSpeaker}
                  />
                </div>
              </div>
            </td>
          </tr>
        ) : null}

        <tr>
          <td
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontSize: 10,
            }}
          >
            {lastTwoSessions[1] ? (
              <>
                <div className="session-date">
                  <span>
                    {lastTwoSessions[1]?.sessionDate?.substring(7, 11)}
                    {lastTwoSessions[1]?.sessionDate?.substring(4, 8)}
                  </span>
                  <p>
                    {lastTwoSessions[1]?.startTime} -{" "}
                    {lastTwoSessions[1]?.endTime}
                  </p>
                </div>
                <div
                  className="d-flex px-2 session-name"
                  style={{
                    // width: "10em",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  <i
                    className="fa fa-microphone"
                    aria-hidden="true"
                    style={{ marginRight: 8, marginTop: 2, fontSize: 20 }}
                  ></i>
                  <div>
                    <span style={{ fontSize: 11, fontWeight: 600 }}>
                      {lastTwoSessions[0]?.sessionName?.length < 20
                        ? lastTwoSessions[0]?.sessionName
                        : `${lastTwoSessions[0]?.sessionName?.substring(
                            0,
                            15
                          )}...`}
                    </span>
                    <p>{lastTwoSessions[1]?.object?.substring(0, 7)}</p>
                  </div>
                </div>
              </>
            ) : null}
          </td>
          <td className="speakers-space">
            {lastTwoSpeakers.map((speaker, i) => (
              <img
                className="avatar"
                src={speaker?.profileImage}
                alt=""
                key={i}
                style={{ width: "40px" }}
              />
            ))}
          </td>
        </tr>
      </table>
      {slideInOpenSession ? (
        <SlideInRightBar
          isOpen={slideInOpenSession}
          title="Add Session"
          toggle={toggleSlideInSession}
          body={
            <AddSession
              userId={userId}
              token={token}
              eventID={eventID}
              eventFormat={eventFormat}
              setSlideInOpenSession={setSlideInOpenSession}
            />
          }
        />
      ) : null}
      {slideInOpenSpeaker ? (
        <SlideInRightBar
          isOpen={slideInOpenSpeaker}
          title="Add Speaker"
          toggle={toggleSlideInSpeaker}
          body={
            <AddSpeaker
              userId={userId}
              token={token}
              eventID={eventID}
              setSlideInOpenSpeaker={setSlideInOpenSpeaker}
            />
          }
        />
      ) : null}
    </div>
  );
}

export default Schedule;
