import React, { useState } from "react";
import { Card, CardBody, CardTitle, Col, Collapse, Row } from "reactstrap";
import classnames from "classnames";
function FAQS() {
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  const [col5, setcol5] = useState(false);

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
  };

  const t_col3 = () => {
    setcol5(false);
    setcol4(false);
    setcol3(!col3);
    setcol2(false);
    setcol1(false);
  };

  const t_col4 = () => {
    setcol5(false);
    setcol4(!col4);
    setcol3(false);
    setcol2(false);
    setcol1(false);
  };
  const t_col5 = () => {
    setcol5(!col5);
    setcol4(false);
    setcol3(false);
    setcol2(false);
    setcol1(false);
  };

  return (
    <div className="mb-5">
      <Card>
        <CardBody>
          <Row>
            <Col lg={12}>
              <CardTitle>
                <h2>Frequently Asked Questions</h2>
              </CardTitle>
              <p className="card-title-desc">
                {/* Add <code>.accordion-flush</code> to remove the default{" "}
                <code>background-color</code>, some borders, and some rounded
                corners to render accordions edge-to-edge with their parent
                container. */}
              </p>

              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFlushOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col1,
                      })}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer" }}
                    >
                      Q1) How to Edit an Event ?
                    </button>
                  </h2>

                  <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                        Nihil anim keffiyeh helvetica, craft beer labore wes
                        anderson cred nesciunt sapiente ea proident. Ad vegan
                        excepteur butcher vice.
                      </div>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFlushTwo">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col2,
                      })}
                      type="button"
                      onClick={t_col2}
                      style={{ cursor: "pointer" }}
                    >
                      Q2) How to Edit create a Session ?
                    </button>
                  </h2>

                  <Collapse isOpen={col2} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                        Nihil anim keffiyeh helvetica, craft beer raw denim
                        aesthetic synth nesciunt you probably haven't heard of
                        them accusamus labore.
                      </div>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFlushThree">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col3,
                      })}
                      type="button"
                      onClick={t_col3}
                      style={{ cursor: "pointer" }}
                    >
                      Q3) How to Add a Speaker ?
                    </button>
                  </h2>
                  <Collapse isOpen={col3} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                        Nihil anim keffiyeh helvetica, craft beer labore wes
                        anderson cred nesciunt sapiente ea proident.
                      </div>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFlushThree">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col4,
                      })}
                      type="button"
                      onClick={t_col4}
                      style={{ cursor: "pointer" }}
                    >
                      Q4) How to Add my Organizer Team ?
                    </button>
                  </h2>
                  <Collapse isOpen={col4} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                        Nihil anim keffiyeh helvetica, craft beer labore wes
                        anderson cred nesciunt sapiente ea proident.
                      </div>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFlushThree">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col5,
                      })}
                      type="button"
                      onClick={t_col5}
                      style={{ cursor: "pointer" }}
                    >
                      Q5) How to Add Exhibitor?
                    </button>
                  </h2>
                  <Collapse isOpen={col5} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                        Nihil anim keffiyeh helvetica, craft beer labore wes
                        anderson cred nesciunt sapiente ea proident.
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}

export default FAQS;
