import React, { useState } from "react";
import { Row, Col, Card } from "reactstrap";
import "../../css/Summary.scss";
import "../../css/summary.styles.scss";
import SlideInRightBar from "../../../../../../components/Common/SlideInComponent/SlideInComponent";
import AddBooth from "../../Addbooth";
import Company from "./Company/Company";
import Sponsors from "./Sponsors/Sponsors";
import Products from "./Products/Products";

function Main({
  eventID,
  token,
  boothDetails,
  boothID,
  company,
  sponsors,
  products,
}) {
  const [slideInAddBooth, setSlideInAddBooth] = useState(false);
  const [slideInEditBooth, setSlideInEditBooth] = useState(false);
  const toggleSlideAddBooth = () => {
    setSlideInAddBooth(!slideInAddBooth);
  };
  const toggleSlideEditBooth = () => {
    setSlideInEditBooth(!slideInEditBooth);
  };
  let isBoothLength =
    boothDetails?.boothDescription &&
    boothDetails?.boothDescription?.length > 200;
  //   console.log("eventID....", eventID);
  //   console.log("token....", token);
  //   console.log("boothDetails....", boothDetails);
  //   console.log("boothID....", boothID);
  //   console.log("boothInvite....", boothInvite);
  //   console.log("eventDetails....", eventDetails);
  return (
    <div className="w-100">
      <Row>
        <Col sm={12} md={8} lg={8}>
          <Card className="summary-setup__content">
            <table>
              <tbody>
                <tr>
                  <td
                    className="d-flex justify-content-between"
                    style={{ color: "#3b5de7" }}
                  >
                    <h5>Booth</h5>
                    {boothID ? (
                      <div className="">
                        <p type="button" onClick={toggleSlideEditBooth}>
                          Edit Booth
                        </p>
                      </div>
                    ) : (
                      <div className="">
                        <p type="button" onClick={toggleSlideAddBooth}>
                          Set Up Booth
                        </p>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
              {boothDetails && boothDetails?.id && (
                <div className="d-flex w-100">
                  <div>
                    <div className="activity--session--img--container">
                      <img
                        src={boothDetails?.boothLogo}
                        alt="Activity Image"
                        className="activity--session--img"
                      />
                    </div>
                  </div>
                  <div className="verticalLine"></div>
                  <div className="activity--session--text">
                    <div className="">
                      <div className="d-flex justify-content-between">
                        <h4>{boothDetails?.boothName}</h4>
                      </div>
                      {isBoothLength ? (
                        <p>
                          {boothDetails?.boothDescription?.substring(0, 200)}
                          ...
                        </p>
                      ) : (
                        <p>{boothDetails?.boothDescription}</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </table>
          </Card>
          <Company
            eventID={eventID}
            token={token}
            boothDetails={boothDetails}
            boothID={boothID}
            company={company}
          />
        </Col>
        <Col sm={12} md={4} lg={4}>
          <Sponsors
            eventID={eventID}
            token={token}
            boothDetails={boothDetails}
            boothID={boothID}
            sponsors={sponsors}
          />
          <Products
            eventID={eventID}
            token={token}
            boothDetails={boothDetails}
            boothID={boothID}
            products={products}
          />
        </Col>
      </Row>
      {slideInAddBooth ? (
        <SlideInRightBar
          isOpen={slideInAddBooth}
          title="Set Up Booth"
          toggle={toggleSlideAddBooth}
          body={
            <AddBooth
              token={token}
              eventID={eventID}
              setSlideInOpenBooth={setSlideInAddBooth}
            />
          }
        />
      ) : null}
    </div>
  );
}

export default Main;
