import React from "react";
import Search from "./Search";
import Chats from "./Chats";

const Sidebar = () => {
  return (
    <div className="sidebar--chat">
      {/* <Navbar /> */}
      <Search />
      {/* <p>hello</p> */}
      <Chats />
    </div>
  );
};

export default Sidebar;
