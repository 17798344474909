import React from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";

function Products({ eventID, token, boothID }) {
  const {
    data: MyBoothSponsors,
    error: errorGetMyBoothSponsors,
    isLoading: isLoadingGetMyBoothSponsors,
  } = useCustomQuery(
    ["exhibtitor-SponsorsBooth", eventID],
    `${process.env.REACT_APP_API}sponsors/booth/${boothID}`,
    token
  );
  return (
    <div className="w-100">
      <div className="w-100">
        {MyBoothSponsors?.map((sponsor, i) => {
          return (
            <img
              src={sponsor?.sponsorLogo}
              alt="sponsor"
              key={i}
              style={{ width: 120, height: 120, borderRadius: 10, margin: 8 }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Products;
