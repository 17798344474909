import React, { useState } from "react";
import {
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { makeStyles } from "@material-ui/core/styles";

import usePost from "../../../../Hooks/usePost";
import "./AttendeeForm.scss";
import "./AttendeeStepperForm.scss";
// // import "./talk.styles";

// ---------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    // backgroundColor: "#1a87a3",
  },
}));

function getSteps() {
  return ["Profile Picture", "Participants Details", "Set Password"];
}

function getStepContent(step, updateFormInputs, formInputs) {
  switch (step) {
    case 0:
      return (
        <>
          <div>
            <h5>Upload Your Profile Picture</h5>
            <span>Your Face, Ears and Chin must be visible (35x45 mm)</span>
          </div>

          {/* Form begins here here here here */}
        </>
      );
    case 1:
      return (
        <>
          <h5>Participant details</h5>
          <span>Your personal deatils</span>
          <div className="booth__body--form-input-container field-col-half">
            <label
              htmlFor="firstName"
              className="booth__body--form-input-container__label"
            >
              <p className="input-heading" style={{ marginBottom: "4px" }}>
                First Name *
              </p>
            </label>
            <div className="single-attendee__body--form-input-container gzIdbH">
              <input
                id="firstName"
                name="firstName"
                type="text"
                required
                placeholder="Enter first name"
                className="single-attendee__body--form-input-container-field"
                onFocus={(e) => e.target.classList.add("active")}
                onBlur={(e) =>
                  e.target.value === "" && e.target.classList.remove("active")
                }
                // onChange={handleChange}
              />
            </div>
          </div>
        </>
      );
    case 2:
      return (
        <>
          <h5>Name your community</h5>
          <span>
            Your community houses all your events and keeps your audience
            engaged throughout the year.
          </span>
          <AvForm>
            <div className="d-flex mb-4">
              <div className="fromInputContaoiner">
                <AvField
                  className="fromInput"
                  name="communityName"
                  type="text"
                  placeholder="Community Name"
                  label="Community Name"
                  required
                  value={formInputs.communityName || ""} // Populate with formInputs
                  onChange={(e) =>
                    updateFormInputs({ communityName: e.target.value })
                  }
                />
              </div>
              <div className="fromInputContaoiner">
                <AvField
                  className="fromInput"
                  type="select"
                  name="defaultLanguage"
                  label="Default Language"
                  value={formInputs.defaultLanguage || ""} // Populate with formInputs
                  onChange={(e) =>
                    updateFormInputs({ defaultLanguage: e.target.value })
                  }
                >
                  <option>Default Language</option>
                  <option value="en">English</option>
                  <option value="es">Spanish</option>
                  <option value="fr">French</option>
                  <option value="de">German</option>
                  <option value="zh">Chinese</option>
                  <option value="ja">Japanese</option>
                  <option value="ko">Korean</option>
                  <option value="ru">Russian</option>
                  <option value="ar">Arabic</option>
                  <option value="hi">Hindi</option>
                  <option value="pt">Portuguese</option>
                  <option value="it">Italian</option>
                  <option value="nl">Dutch</option>
                  <option value="tr">Turkish</option>
                  <option value="pl">Polish</option>
                  <option value="sv">Swedish</option>
                </AvField>
              </div>
            </div>
            <p className="">
              All your community and event-related URLs will contain{" "}
              <span className="">shadowops-community</span>
            </p>
            <ul
              style={{
                width: "100%",
                borderRadius: "8px",
                background: "rgb(250, 250, 250)",
                padding: "12px 8px",
                boxSizing: "border-box",
              }}
            >
              <li style={{ color: "rgb(140, 140, 140)", lineHeight: "18px" }}>
                The chosen name will appear in all of your community and event
                URLs.
              </li>
              <li style={{ color: "rgb(140, 140, 140)", lineHeight: "18px" }}>
                Community name can be updated afterward, but not the URL.{" "}
              </li>
              <li style={{ color: "rgb(140, 140, 140)", lineHeight: "18px" }}>
                We recommend including your brand name in your community name.{" "}
              </li>
              <li style={{ color: "rgb(140, 140, 140)", lineHeight: "18px" }}>
                Avoid including a year as it will show in all your future event
                URLs.
              </li>
            </ul>
          </AvForm>
        </>
      );
    default:
      return "unknown step";
  }
}

const AttendeeStepper = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();
  const [formInputs, setFormInputs] = useState({});
  const { execute, status } = usePost();

  // console.log(status);
  // status ? <RenderThanks /> : null

  const isStepOptional = (step) => {
    return step === 1 || step === 2;
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
  };

  const handleFinish = (e, values) => {
    e.preventDefault();
    const Method = "POST",
      endPoint = "leads/add_lead",
      isJSON = null,
      token = null;

    var raw = JSON.stringify({
      organizationName: formInputs.organizationName,
      organizationType: formInputs.organizationType,
      organizationCountry: formInputs.organizationCountry,
      eventsPerYear: formInputs.eventsPerYear,
      attendeesPerYear: formInputs.attendeesPerYear,
      organizationEmail: formInputs.organizationEmail,
      organizationPhoneNumber: formInputs.organizationPhoneNumber,
      communityName: formInputs.communityName,
      language: formInputs.defaultLanguage,
      terms: true,
    });

    execute(endPoint, raw, Method, "Successful register", token, isJSON);
    console.log(formInputs);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };

  const updateFormInputs = (values) => {
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      ...values,
    }));
  };

  return (
    <div className="page-content bg-danger d-flex justify-content-center">
      <div className="rounded-4 stepperContainer">
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          className="stepperHeader"
        >
          {steps.map((step, index) => {
            const labelProps = {};
            const stepProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step {...stepProps} key={index}>
                <StepLabel {...labelProps}>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === steps.length ? (
          <Typography variant="h3" align="center">
            Thank You
          </Typography>
        ) : (
          <>
            <form>
              {getStepContent(activeStep, updateFormInputs, formInputs)}
              <input type="checkbox" id="myCheckbox"></input>
              <label htmlFor="myCheckbox">
                {" "}
                <span className="required">*</span> I have read and consent to
                the Organizer's terms and conditions
              </label>
            </form>
            <Button
              className={classes.button}
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                className={[classes.button, "bg-primary text-light"]}
                variant="contained"
                color="#1a87a3"
                onClick={handleFinish}
              >
                Finish
              </Button>
            ) : (
              <Button
                className={[classes.button, "bg-primary text-light"]}
                variant="contained"
                color="#1a87a3"
                onClick={handleNext}
              >
                Next
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AttendeeStepper;
