import React, { useState } from "react";
import { Modal, Button } from "reactstrap";
import { FaPlay } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";

import ImgSession from "../../../../assets/images/banners/sessions/bannerSession.png";
import "./css/Summary.scss";
import "./css/AddSpeaker.scss";
import UploadVideoLink from "./UploadVideoLink";

const ShowVideoLink = ({
  userId,
  token,
  eventID,
  eventDetails,
  setShowVideoLink,
}) => {
  const key = ["myEventDetail", eventID];
  const [showUploadVideoLink, setShowUploadVideoLink] = useState(false);

  const toggle_uploadVideoLink = () => {
    setShowUploadVideoLink(!showUploadVideoLink);
  };

  return (
    <React.Fragment>
      <div className="">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="booth__body--form"
          // onSubmit={handleSubmitForm}
        >
          <div className="mt-0">
            <div className="d-flex justify-content-center">
              <div className="section---banner---container mt-2">
                <img
                  src={ImgSession}
                  alt="session Image"
                  className="w-100 h-100 section---banner---container---img"
                />
                <Button
                  size="md"
                  variant="separator-light"
                  className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
                  onClick={() => toggle_uploadVideoLink()}
                >
                  <FaPlay
                    size={70}
                    style={{
                      color: "black",
                      filter: "drop-shadow(0px 0px 10px white)",
                    }}
                  />
                </Button>
              </div>
            </div>
            <div
              className="sponsor-form-body__sponsor-logo"
              style={{ marginRight: "12px", marginBottom: "-16px" }}
            >
              <p
                color="ambience.6"
                font-size="1"
                className="sponsor-form-body--input-description"
              >
                Recommended format: 400x200px | JPG, SVG, PNG | Up to 1 MB
              </p>
            </div>
          </div>
        </form>
        <Modal
          size="lg"
          isOpen={showUploadVideoLink}
          toggle={() => {
            toggle_uploadVideoLink();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Paste Video Link
            </h5>
            <button
              onClick={() => {
                setShowUploadVideoLink(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <UploadVideoLink
              userId={userId}
              token={token}
              eventID={eventID}
              setShowVideoLink={setShowUploadVideoLink}
            />
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default ShowVideoLink;
