import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import SimpleBar from "simplebar-react";
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Row,
  Col,
  Spinner,
} from "reactstrap";

//Rextangle.png
import "./Main.scss";
import Profile from "./Profile";
import Products from "./Products";
import Enquiry from "./Enquiry";
import Chats from "./Chats";
import Live from "./Live";
import Sponsors from "./Sponsors";

import classnames from "classnames";

const Main = ({ eventID, token, boothDetails, boothID }) => {
  const [activeTabJustify, setactiveTabJustify] = useState("5");

  function toggleCustomJustified(tab) {
    if (activeTabJustify !== tab) {
      setactiveTabJustify(tab);
    }
  }
  const {
    data: getMyEventBoothDetail,
    error: errorGetMyEventBoothDetail,
    isLoading: isLoadingGetMyEventBoothDetail,
  } = useCustomQuery(
    ["myEvent-Booth", eventID],
    `${process.env.REACT_APP_API}booths/${boothID}`,
    token
  );

  // console.log("Booth details...", getMyEventBoothDetail);

  // console.log("boothID...", boothID);

  // if (boothDetails && boothDetails.length < 1) {
  //   return (
  //     <div className="mt-5 d-flex justify-content-center align-items-center">
  //       <Spinner animation="border" role="status">
  //         <span className="visually-hidden">Loading...</span>
  //       </Spinner>
  //     </div>
  //   );
  // }

  return (
    <React.Fragment>
      <div className="d-flex px-4 justify-content-center align-items-center ">
        <div className="w-100">
          <Row className="mt-5  w-100">
            <Col md={12}>
              {/* <div className="shadow-sm mt-2 card-brd  w-100 preview-booth-container">
                <img
                  src={getMyEventBoothDetail?.boothLogo}
                  className="card-brd"
                />
                <div className="p-3 bg-white preview-booth-option">
                  Company Logo
                </div>
              </div> */}

              <div className="shadow-sm" style={{ backgroundColor: "white" }}>
                <Nav
                  pills
                  className="navtab-bg nav-justified mt-2 mb-2"
                  style={{ backgroundColor: "white" }}
                >
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "5",
                      })}
                      onClick={() => {
                        toggleCustomJustified("5");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block btn--text">
                        Profile
                      </span>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "6",
                      })}
                      onClick={() => {
                        toggleCustomJustified("6");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block btn--text">
                        Live Session
                      </span>
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "7",
                      })}
                      onClick={() => {
                        toggleCustomJustified("7");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-calendar"></i>
                      </span>
                      <span className="d-none d-sm-block btn--text">
                        Products
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "8",
                      })}
                      onClick={() => {
                        toggleCustomJustified("8");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-calendar"></i>
                      </span>
                      <span className="d-none d-sm-block btn--text">
                        Sponsors
                      </span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "9",
                      })}
                      onClick={() => {
                        toggleCustomJustified("9");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-star"></i>
                      </span>
                      <span className="d-none d-sm-block btn--text">
                        Enquiry
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "10",
                      })}
                      onClick={() => {
                        toggleCustomJustified("10");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-message"></i>
                      </span>
                      <span className="d-none d-sm-block btn--text">
                        Talk to us
                      </span>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "11",
                      })}
                      onClick={() => {
                        toggleCustomJustified("11");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-message"></i>
                      </span>
                      <span className="d-none d-sm-block btn--text">
                        Rate Us
                      </span>
                    </NavLink>
                  </NavItem> */}
                </Nav>
                <TabContent activeTab={activeTabJustify}>
                  <TabPane
                    tabId="5"
                    className="p-3"
                    style={{ backgroundColor: "white" }}
                  >
                    <Profile
                      eventID={eventID}
                      token={token}
                      boothID={boothID}
                    />
                  </TabPane>
                  <TabPane
                    tabId="6"
                    className="p-3"
                    style={{ backgroundColor: "white" }}
                  >
                    <Live
                      eventID={eventID}
                      token={token}
                      boothID={boothID}
                      boothDetails={getMyEventBoothDetail}
                    />
                  </TabPane>

                  <TabPane
                    tabId="7"
                    className="p-3 "
                    style={{ backgroundColor: "white" }}
                  >
                    <Products
                      eventID={eventID}
                      token={token}
                      boothID={boothID}
                      boothDetails={getMyEventBoothDetail}
                    />
                  </TabPane>
                  <TabPane
                    tabId="8"
                    className="p-3 "
                    style={{ backgroundColor: "white" }}
                  >
                    <Sponsors
                      eventID={eventID}
                      token={token}
                      boothID={boothID}
                      boothDetails={getMyEventBoothDetail}
                    />
                  </TabPane>
                  <TabPane
                    tabId="9"
                    className="p-3"
                    style={{ backgroundColor: "white" }}
                  >
                    <Enquiry
                      eventID={eventID}
                      token={token}
                      boothID={boothID}
                    />
                  </TabPane>
                  <TabPane
                    tabId="10"
                    className="p-3"
                    style={{ backgroundColor: "white" }}
                  >
                    <Chats eventID={eventID} token={token} boothID={boothID} />
                  </TabPane>
                  {/* <TabPane
                    tabId="11"
                    className="p-3"
                    style={{ backgroundColor: "white" }}
                  >
                    <Enquiry
                      eventID={eventID}
                      token={token}
                      boothID={boothID}
                    />
                  </TabPane> */}
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Main;
