import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { MdClose } from "react-icons/md";

const SlideBigBarDark = ({ isOpen, toggle, title, body }) => {
  const handleClickInside = (e) => {
    e.stopPropagation(); // Prevent the click event from propagating to the overlay
  };

  return (
    <div className={`booths-slide-in-modal-dark ${isOpen ? "show" : ""}`}>
      <div className="booths-slide-in-overlay-dark" onClick={toggle}></div>
      <div className="booths-slide-in-content-dark" onClick={handleClickInside}>
        <div className="booths-slide-in-header-dark">
          <div>
            <h3 className="modal-title-dark mt-0">{title}</h3>
          </div>
          <MdClose
            size={30}
            color="white"
            style={{
              marginTop: "-25px",
            }}
            onClick={toggle}
          />
        </div>
        <div className="booths-slide-in-body-dark">{body}</div>
      </div>
    </div>
  );
};

export default SlideBigBarDark;
