import React from "react";
import "./allBooth.scss";

function CompnayNames({ companyNames, setSearchRef }) {
  return (
    <div className="mt-3 allBooth--companyNames">
      {companyNames?.map((company, i) => {
        return (
          <div
            className="allBooth--companyNames-text"
            key={i}
            onClick={() => setSearchRef(company)}
          >
            {company}
            {i < companyNames.length - 1 && company && (
              <hr style={{ borderColor: "white" }} />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default CompnayNames;
