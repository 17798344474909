import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useCustomMutation from "../../../../../../Hooks/useCustomMutation";
import { Spinner, Row, Col } from "reactstrap";
import "../../css/CreateTicket.scss";
// import { Row, Col, Button, Form, InputGroup, Label } from "reactstrap";

function CreateTicket({ userId, token, eventID, handleTicket }) {
  // ["myEventDetail", eventID],
  const queryClient = useQueryClient();
  const key = ["getTicketSettings", eventID];
  const mutation = useCustomMutation(key);
  const [inputs, setInputs] = useState({});
  const [ticketTerms, setTerms] = useState("");
  const [isEarlyBird, setIsEarlyBird] = useState(false);
  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "POST",
      url = `ticketing/${eventID}/event`,
      isJSON = null;
    const raw = JSON.stringify({
      tierOne: "STANDARD",
      tierTwo: "VIP",
      tierThree: "VVIP",
      tierOnePrice: parseInt(inputs.tierOnePrice),
      tierTwoPrice: parseInt(inputs.tierTwoPrice),
      tierThreePrice: parseInt(inputs.tierThreePrice),
      tierOneQuantity: parseInt(inputs.tierOneQuantity),
      tierTwoQuantity: parseInt(inputs.tierTwoQuantity),
      tierThreeQuantity: parseInt(inputs.tierThreeQuantity),
      allowEarlyBird: isEarlyBird,
      earlyBirdStartDate: inputs.earlyBirdStartDate,
      earlyBirdEndDate: inputs.earlyBirdEndDate,
      discount: parseInt(inputs.discount),
      ticketTerms: ticketTerms,
    });

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Ticket Successfully Created",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const onChangeTicketTerms = (event) => {
    setTerms(event.target.value);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      queryClient.invalidateQueries(["get-TicketSettings", eventID]);
      handleTicket();
    }
  }, [mutation?.isSuccess]);

  // const handleIsEarlyBird = (event) => {
  //   console.log("event.target.checked...", event.target.checked);
  //   setIsEarlyBird(event.target.checked);
  // };

  // console.log("isEarlyBird...", isEarlyBird);
  // console.log("inputs...", inputs);

  return (
    <div className="w-100">
      <hr></hr>
      <form className="form-create-ticket" onSubmit={handleSubmitForm}>
        <div className="d-flex justify-content-center align-item-center">
          <div className="w-100 text-dark">
            <Row>
              <Col md={4}>
                <label className="">
                  Ticket Name
                  <input
                    type="text"
                    name="standard"
                    className="mb-3 form-control"
                    // value={inputs.standard}
                    value="STANDARD"
                    onChange={handleChange}
                    disabled
                  />
                </label>
              </Col>
              <Col md={4}>
                <label className="">
                  Price
                  <input
                    type="number"
                    name="tierOnePrice"
                    className="mb-3 form-control fromInput w-100"
                    value={inputs.tierOnePrice}
                    onChange={handleChange}
                  />
                </label>
              </Col>
              <Col md={4}>
                <label className="">
                  Quatity
                  <input
                    type="number"
                    name="tierOneQuantity"
                    className="mb-3 form-control fromInput w-100"
                    value={inputs.tierOneQuantity}
                    onChange={handleChange}
                  />
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <input
                  type="text"
                  name="vip"
                  className="mb-3 form-control fromInput w-100"
                  value="VIP"
                  onChange={handleChange}
                  disabled
                />
              </Col>
              <Col md={4}>
                <input
                  type="number"
                  name="tierTwoPrice"
                  className="mb-3 form-control fromInput w-100"
                  value={inputs.tierTwoPrice}
                  onChange={handleChange}
                />
              </Col>
              <Col md={4}>
                <input
                  type="number"
                  name="tierTwoQuantity"
                  className="mb-3 form-control fromInput w-100"
                  value={inputs.tierTwoQuantity}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <input
                  type="text"
                  name="vvip"
                  className="mb-3 form-control fromInput w-100"
                  value="VVIP"
                  onChange={handleChange}
                  disabled
                />
              </Col>
              <Col md={4}>
                <input
                  type="number"
                  name="tierThreePrice"
                  className="mb-3 form-control fromInput w-100"
                  value={inputs.tierThreePrice}
                  onChange={handleChange}
                />
              </Col>
              <Col md={4}>
                <input
                  type="number"
                  name="tierThreeQuantity"
                  className="mb-3 form-control fromInput w-100"
                  value={inputs.tierThreeQuantity}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <label className="w-100">
                  Tickets Terms and Conditions
                  <textarea
                    value={ticketTerms}
                    onChange={onChangeTicketTerms}
                    placeholder="Write your Ticket Tems and Condition"
                    className="mb-2 form-control fromInput"
                    rows={5}
                    required
                  ></textarea>
                </label>
              </Col>
            </Row>
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                // name="formatRadio"
                id="exampleRadios1"
                // value={true}
                // onChange={handleIsEarlyBird}
                onClick={() => setIsEarlyBird(!isEarlyBird)}
                setIsEarlyBird
                checked={isEarlyBird}
              />
              <label className="mx-2">Include Early Bird Price</label>
            </div>
            {isEarlyBird ? (
              <>
                <Row>
                  <Col md={4}>
                    <label>
                      Early Bird Start Date
                      <input
                        type="date"
                        name="earlyBirdStartDate"
                        value={inputs.earlyBirdStartDate}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col md={4}>
                    <label>
                      Early Bird Start Date
                      <input
                        type="date"
                        name="earlyBirdEndDate"
                        value={inputs.earlyBirdEndDate}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col md={4}>
                    <label>
                      Discount (%)
                      <input
                        type="number"
                        name="discount"
                        value={inputs.discount}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                </Row>
              </>
            ) : null}

            <div className="d-flex justify-content-center align-item-center">
              <button className="btn btn-primary" type="submit">
                <div className="d-flex justify-content-center align-items-center">
                  {!mutation.isLoading ? <span>Create</span> : null}
                  {!mutation.isLoading ? null : (
                    <span>
                      <Spinner as="span" animation="border" size="sm" /> Loading
                    </span>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
export default CreateTicket;
