import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

function Search({ searchRef, setSearchRef }) {
  const handleSearch = (event) => {
    setSearchRef(event.target.value);
  };

  return (
    <div
      style={{
        background: "#0000",
        paddingTop: ".58rem",
        paddingBottom: ".5rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        marginRight: 32,
        border: "solid",
        borderWidth: 0.1,
        borderRadius: 8,
        userSelect: "none",
      }}
    >
      <AiOutlineSearch size={24} />
      <input
        type="text"
        placeholder={"Search Company name"}
        value={searchRef}
        onChange={handleSearch}
        style={{
          border: "none",
          marginLeft: 8,
          paddingRight: 24,
          color: "white",
          backgroundColor: "transparent",
          userSelect: "none",
        }}
      />
    </div>
  );
}

export default Search;
