import React, { useState } from "react";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Spinner } from "reactstrap";
import "../../css/Summary.scss";

import { useParams } from "react-router-dom";

import SlideInRightBar from "../../../../../../components/Common/SlideInComponent/SlideInComponent";

import { AiOutlinePlusCircle } from "react-icons/ai";

import "./sponsors.scss";
import AddSponsor from "../../AddSponsor";

const Sponsors = ({ token, eventID }) => {
  const [modal_large, setmodal_large] = useState(false);
  // add speaker
  const [modal_large2, setmodal_large2] = useState(false);

  // edit event
  const [modal_large3, setmodal_large3] = useState(false);

  const [activeLink, setActiveLink] = useState(null);
  // add session
  const [slideInOpen, setSlideInOpen] = useState(false);

  const {
    data: getMySponsors,
    error: errorGetMySponsors,
    isLoading: isLoadingGetMySponsors,
  } = useCustomQuery(
    ["mySponsors", eventID],
    `${process.env.REACT_APP_API}sponsors/event/${eventID}`,
    token
  );

  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }
  function tog_large2() {
    setmodal_large2(!modal_large2);
    removeBodyCss();
  }
  function tog_large3() {
    setmodal_large3(!modal_large3);
    removeBodyCss();
  }

  // Function to handle link clicks
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  const tiers = [
    {
      tierPosition: 1,
      tierRank: "Gold",
    },
    {
      tierPosition: 2,
      tierRank: "Silver",
    },
    {
      tierPosition: 3,
      tierRank: "Bronze",
    },
  ];

  if (isLoadingGetMySponsors) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }

  // console.log("getMySponsors....", getMySponsors);

  return (
    <React.Fragment>
      <div className="sponsors-list__heading">
        <div>
          <h3>Sponsors</h3>
          <span className="text">Manage sponsors for your event.</span>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <button
            className="btn btn-primary"
            type="button"
            onClick={toggleSlideIn}
          >
            <AiOutlinePlusCircle size={20} />
            <span className="px-2">Add Sponsor</span>
          </button>
          <SlideInRightBar
            isOpen={slideInOpen}
            setSlideInOpenSponsor={setSlideInOpen}
            toggle={toggleSlideIn}
            title="Add Sponsor"
            body={
              <AddSponsor
                token={token}
                eventID={eventID}
                setSlideInOpenSponsor={setSlideInOpen}
              />
            }
          />
        </div>
      </div>
      <div className="sponsors-list__body">
        <div className="sponsors-list__body__heading">
          <h4>Tiers</h4>
          <div className="d-flex my-4">
            {tiers.map((tier) => (
              <div className="tier">
                <span className="tier--position">{tier.tierPosition}</span>
                <span className="tier--rank">{tier.tierRank}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="sponsors-list__body__content">
          <table className="sponsors">
            <thead className="sponsors__head">
              <tr className="sponsors__row">
                <th className="sponsors__head--cell">Position</th>
                <th className="sponsors__head--cell">Tier</th>
                <th className="sponsors__head--cell">Name</th>
                <th className="sponsors__head--cell">Website URL</th>
              </tr>
            </thead>
            <tbody className="sponsors__body">
              {getMySponsors?.map((sponsor, i) => {
                return (
                  <tr className="sponsors__row" key={i}>
                    <td className="sponsors--data">{i + 1}</td>
                    <td className="sponsors--data">Gold</td>
                    <td className="sponsors--data participant-type">
                      {sponsor?.sponsorName}
                    </td>
                    <td className="sponsors--data">https://wesponsor.com</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Sponsors;
