import React from "react";
import { Row, Col } from "reactstrap";
import { ThreeCircles } from "react-loader-spinner";

function Loader() {
  return (
    <div className="w-100 d-flex justify-content-center align-items-center">
      <Row>
        <Col md={12}>
          <ThreeCircles
            height="200"
            width="200"
            color="#1a87a3"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </Col>
      </Row>
    </div>
  );
}

export default Loader;
