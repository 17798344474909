import React from "react";
import { Row, Col, Progress, Spinner } from "reactstrap";
import "./TicketSettingsCard.scss";
import TicketCard from "./TicketCard";

import Standard from "./Standard";
import Vip from "./Vip";
import Vvip from "./Vvip";

function TicketItems(ticketDetails) {
  // console.log("ticketDetails...", ticketDetails);
  return (
    <div className="ticketing-card-main">
      <Standard
        title={ticketDetails?.ticketDetails?.tierOne}
        price={ticketDetails?.ticketDetails?.tierOnePrice}
        totalQuantity={ticketDetails?.ticketDetails?.tierOneQuantity}
        totalSold={ticketDetails?.ticketDetails?.tierOneQuantitySold}
        totalPercentageSold={
          ticketDetails?.ticketDetails?.tierOnePercentageSold
        }
        totalRevenue={ticketDetails?.ticketDetails?.tierOneRevenue}
        closingDate={ticketDetails?.ticketDetails?.ticketsClosingDate}
        user={ticketDetails?.ticketDetails?.userId}
      />

      <Vip
        title={ticketDetails?.ticketDetails?.tierTwo}
        price={ticketDetails?.ticketDetails?.tierTwoPrice}
        totalQuantity={ticketDetails?.ticketDetails?.tierTwoQuantity}
        totalSold={ticketDetails?.ticketDetails?.tierTwoQuantitySold}
        totalPercentageSold={
          ticketDetails?.ticketDetails?.tierTwoPercentageSold
        }
        totalRevenue={ticketDetails?.ticketDetails?.tierTwoRevenue}
        closingDate={ticketDetails?.ticketDetails?.ticketsClosingDate}
        user={ticketDetails?.ticketDetails?.userId}
      />

      <Vvip
        title={ticketDetails?.ticketDetails?.tierThree}
        price={ticketDetails?.ticketDetails?.tierThreePrice}
        totalQuantity={ticketDetails?.ticketDetails?.tierThreeQuantity}
        totalSold={ticketDetails?.ticketDetails?.tierThreeQuantitySold}
        totalPercentageSold={
          ticketDetails?.ticketDetails?.tierThreePercentageSold
        }
        totalRevenue={ticketDetails?.ticketDetails?.tierThreeRevenue}
        closingDate={ticketDetails?.ticketDetails?.ticketsClosingDate}
        user={ticketDetails?.ticketDetails?.userId}
      />
    </div>
  );
}

export default TicketItems;
