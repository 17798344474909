import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "./SessionSlide.scss"; // Create a CSS file for styling

const SessionSlide = ({ isOpen, toggle, title, body }) => {
  const handleClickInside = (e) => {
    e.stopPropagation(); // Prevent the click event from propagating to the overlay
  };

  return (
    <div className={`booths-slide-in-modal ${isOpen ? "show" : ""}`}>
      <div className="booths-slide-in-overlay" onClick={toggle}></div>
      <div className="booths-slide-in-content" onClick={handleClickInside}>
        <div className="booths-slide-in-header">
          <div>
            <h3 className="modal-title mt-0">{title} </h3>
          </div>
          <button
            className="close btn---close"
            onClick={toggle}
            aria-label="Close"
            style={{
              paddingTop: "48px",
              paddingRight: "56px",
              fontSize: 14,
              // color: "#e4e5e6",
              // color: "red",
            }}
          >
            {/* <IoCloseCircleOutline size={28} style={{ cursor: "pointer" }} /> */}
          </button>
        </div>
        <div className="booths-slide-in-body">{body}</div>
      </div>
    </div>
  );
};

export default SessionSlide;
