import React, { useState } from "react";
import TicketSelection from "./TicketSelection";
import TicketQuantitySelection from "./TicketQuantitySelection";
import RegistrationForm from "./RegistrationForm";
import Confirmation from "./Confirmation";
import MUIStepper from "./MUIStepper";
import AttachBulDoc from "./AttachBulDoc";
// import BulkForm from "./BulkForm";
import "./css/ticketForm.scss";
import AttachBulkDoc from "./AttachBulDoc";

const Main = ({ eventDetails, token }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [ticketQuantity, setTicketQuantity] = useState(0);
  const [ticketType, setTicketType] = useState(null);

  const handleNext = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <TicketSelection
            setSelectedTicket={setSelectedTicket}
            handleNext={handleNext}
            eventDetails={eventDetails}
            token={token}
          />
        );
      case 1:
        return (
          <TicketQuantitySelection
            selectedTicket={selectedTicket}
            setTicketQuantity={setTicketQuantity}
            setTicketType={setTicketType}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 2:
        if (ticketType === "Individual") {
          return (
            <RegistrationForm
              selectedTicket={selectedTicket}
              ticketQuantity={1}
              handleNext={handleNext}
              eventDetails={eventDetails}
              token={token}
              // handleBack={handleBack}
            />
          );
        } else if (ticketType === "Bulk") {
          return (
            <AttachBulkDoc
              handleNext={handleNext}
              eventDetails={eventDetails}
              token={token}
            />
          );
        }
        break;
      // return (
      //   <RegistrationForm
      //     selectedTicket={selectedTicket}
      //     ticketQuantity={ticketQuantity}
      //     handleNext={handleNext}
      //     eventDetails={eventDetails}
      //     token={token}

      //     // handleBack={handleBack}
      //   />
      // );
      case 3:
        return <Confirmation eventDetails={eventDetails} />;
      default:
        throw new Error("Unknown step");
    }
  };

  return (
    <div className="p-3 d-flex justify-content-center">
      <div className="buy--ticket-container p-3">
        <MUIStepper activeStep={activeStep} />
        <div className="mt-2">{getStepContent(activeStep)}</div>
      </div>
    </div>
  );
};

export default Main;
