import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import { Spinner, Button, Row, Col } from "reactstrap";

//EventDefaultPicIdicator.png
import BannerPlaceholder from "../../../../assets/images/placeholder/SocialBanner.png";

import { countryList } from "../../../../components/Countries/countries";
import { BiEdit } from "react-icons/bi";

function Expo({ setSlideInOpenExpo, userId, token }) {
  const navigate = useNavigate();
  const [Banner, setBanner] = useState();
  const [BannerServer, setBannerServer] = useState();
  const [eventStatus, setEventStatus] = useState("Public");
  const [eventDescription, setEventDescription] = useState("");
  const [eventFormat, setEventFormat] = useState("Hybrid");
  const [isPhysicalEvent, setIsPhysicalEvent] = useState("");
  const [inputs, setInputs] = useState({});

  // connnect to api
  const key = ["myEvents", userId];
  const mutation = useCustomMutation(key);

  // function to set event
  const hybridFunc = (e) => {
    setEventFormat("Hybrid");
    setIsPhysicalEvent("");
  };
  const inPersonFunc = (e) => {
    setEventFormat("In Person");
    setIsPhysicalEvent("");
  };
  const virtualFunc = (e) => {
    setEventFormat("Virtual");
    setIsPhysicalEvent(e);
  };

  const EventStatePublic = (e) => {
    setEventStatus("Public");
  };
  const EventStateFunc2 = (e) => {
    setEventStatus("Private");
  };

  // image upload

  let iconStyles = { color: "black" };

  const refFileUpload = useRef("");

  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setBannerServer(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setBanner(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  // // handleValidSubmit

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const onChangeEventDescription = (event) => {
    setEventDescription(event.target.value);
  };

  const handleSubmitForm = (event) => {
    // console.log(BannerServer);
    event.preventDefault();
    const Method = "POST",
      url = `events/create/?organizerId=${userId}`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("bannerImage", BannerServer);
    formdata.append("eventName", inputs.EventName);
    formdata.append("eventDescription", eventDescription);
    formdata.append("eventStatus", "Upcoming");
    formdata.append("startDate", inputs.startDate__event);
    formdata.append("endDate", inputs.endDate__event);
    formdata.append("startTime", inputs.startTime__event);
    formdata.append("endTime", inputs.endTime__event);
    formdata.append("eventFormat", eventFormat);
    formdata.append("eventType", "Conference");
    formdata.append("eventType", "Exhibition");
    formdata.append("visibility", eventStatus);
    formdata.append("state", "Not Published");

    // formdata.append("eventSlug", "Event");

    // formdata.append("eventSlug", "Event");

    // formdata.append("eventOrganizerId", userId);
    // formdata.append("children_Allow", "true");

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Event Successfully Created",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  // console.log("mutation.isSuccess....", mutation.isSuccess);

  if (mutation.isSuccess) {
    navigate("/dashboard");
    // setSlideInOpenConference(false);
  }

  return (
    <div className="d-flex justify-content-center">
      <div className="w-100 justify-content-center">
        <div className="w-100 d-flex justify-content-center">
          <div className="event__form__banner_container">
            <img
              src={Banner === undefined ? BannerPlaceholder : Banner}
              alt="eventBanner"
              className="Image_Fit event__form__banner_img "
            />
            <Button
              size="md"
              variant="separator-light"
              className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
              onClick={onThumbChangeClick}
            >
              <BiEdit size={40} style={iconStyles} />
            </Button>
            <input
              type="file"
              ref={refFileUpload}
              className="file-upload d-none"
              accept="image/*"
              onChange={changeThumb}
            />
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center mt-4">
          <form className="form-create-event" onSubmit={handleSubmitForm}>
            <Row>
              <Col md={12}>
                <label className="event__input__form__Creation">
                  Event Name
                  <input
                    type="text"
                    name="EventName"
                    className="mb-3 form-control fromInput w-100"
                    value={inputs.EventName}
                    onChange={handleChange}
                    required
                  />
                </label>
              </Col>
              <Col md={12}>
                <label className="event__input__form__Creation">
                  Event Type
                  <input
                    type="text"
                    name="eventType"
                    placeholder="Expo"
                    className="mb-3 form-control fromInput w-100"
                    value={inputs.eventType}
                    // onChange={handleChange}
                    disabled
                  />
                </label>
              </Col>
              <Col md={12}>
                <label className="event__input__form__Creation">
                  Event Description
                  <textarea
                    value={eventDescription}
                    onChange={onChangeEventDescription}
                    placeholder="Write a short summary about the event..."
                    className="mb-3 form-control fromInput"
                    rows={5}
                    required
                  ></textarea>
                </label>
              </Col>
              <Col sm={12} md={4} lg={3}>
                <label className="event__form__Creation">
                  Start Date
                  <input
                    type="date"
                    name="startDate__event"
                    placeholder="dd-mm-yyyy"
                    value={inputs.startDate__event}
                    onChange={handleChange}
                    className="form-control fromInput"
                    required
                  />
                </label>
              </Col>
              <Col sm={12} md={4} lg={3}>
                <label className="event__form__Creation">
                  End Date
                  <input
                    type="date"
                    name="endDate__event"
                    value={inputs.endDate__event}
                    onChange={handleChange}
                    className="mb-3 form-control fromInput"
                    required
                  />
                </label>
              </Col>
              {/* <Col sm={12} md={4} lg={4}>
                <label className="event__form__Creation">
                  Time Zone
                  <select
                    name="timeZone__event"
                    value={inputs.timeZone__event}
                    onChange={handleChange}
                    className="mb-3 form-control fromInput"
                    required
                  >
                    {countryList.map((country, index) => (
                      <option key={index}> {country} </option>
                    ))}
                  </select>
                </label>
              </Col> */}
              <Col sm={12} md={4} lg={3}>
                <label>
                  Start Time
                  <input
                    type="time"
                    name="startTime__event"
                    value={inputs.startTime__event}
                    onChange={handleChange}
                    className="mb-3 form-control fromInput"
                    required
                  />
                </label>
              </Col>
              <Col sm={12} md={4} lg={3}>
                <label>
                  End Time
                  <input
                    type="time"
                    name="endTime__event"
                    value={inputs.endTime__event}
                    onChange={handleChange}
                    className="mb-3 form-control fromInput"
                    required
                  />
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12}>
                <div>
                  <label>Format</label>
                  <div className="d-flex">
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="formatRadio"
                        id="exampleRadios1"
                        value="Public"
                        onChange={(e) => hybridFunc(e.target.value)}
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios1"
                      >
                        Hybrid
                      </label>
                    </div>
                    <div className="px-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="formatRadio"
                          id="exampleRadios2"
                          value={"In Person"}
                          onChange={(e) => inPersonFunc(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios2"
                        >
                          In Person
                        </label>
                      </div>
                    </div>
                    <div className="px-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="formatRadio"
                          id="exampleRadios2"
                          value={"Virtual"}
                          onChange={(e) => virtualFunc(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios2"
                        >
                          Virtual
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            {!isPhysicalEvent ? (
              <div>
                <Row>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      Location
                      <input
                        type="text"
                        name="location__venue"
                        placeholder="Venue Name"
                        value={inputs.location__venue}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      Physical Address
                      <input
                        type="text"
                        name="address__venue"
                        placeholder="Venue name"
                        value={inputs.address__venue}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      City
                      <input
                        type="text"
                        name="city"
                        placeholder="City"
                        value={inputs.city}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      State
                      <input
                        type="text"
                        name="state"
                        value={inputs.state}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      Zip
                      <input
                        type="text"
                        name="zip"
                        value={inputs.zip}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      Country
                      <select
                        name="country"
                        value={inputs.country}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      >
                        {countryList.map((country, i) => (
                          <option key={i}> {country} </option>
                        ))}
                      </select>
                    </label>
                  </Col>
                </Row>
              </div>
            ) : null}
            <Row>
              <Col md={12} lg={12}>
                <div className="mt-3">
                  <label>Set Event</label>
                  <div className="d-flex">
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        value="Public"
                        onChange={(e) => EventStatePublic(e.target.value)}
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios1"
                      >
                        Public
                      </label>
                    </div>
                    <div className="px-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          value={"Private"}
                          onChange={(e) => EventStateFunc2(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios2"
                        >
                          Private
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-center align-item-center">
              <div className="d-flex justify-content-center align-item-center">
                <button className="btn btn-primary" type="submit">
                  <div className="d-flex justify-content-center align-items-center">
                    {!mutation.isLoading ? <span>Submit</span> : null}
                    {!mutation.isLoading ? null : (
                      <span>
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        Loading
                      </span>
                    )}
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Expo;
