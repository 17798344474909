import React from "react";
import { useParams } from "react-router-dom";
import Main from "./components/Main";

const Index = () => {
  document.title = "Showbay 24 | Event Set Up";
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userId = obj?.data?.data?.id;
  const token = obj?.token;
  const params = useParams();

  const eventID = params.eventID;
  const eventName = params.eventName;
  const myEventName = eventName.replace(/-/g, " ");

  return (
    <React.Fragment>
      <div className="page-content-custom" style={{ userSelect: "none" }}>
        <Main
          userId={userId}
          token={token}
          eventID={eventID}
          eventName={myEventName}
        />
      </div>
    </React.Fragment>
  );
};

export default Index;
