import React from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import { Spinner } from "reactstrap";
import JoinConference from "./JoinConference";

const Main = ({
  userDetails,
  token,
  eventID,
  sessionID,
  eventName,
  sessionName,
}) => {
  // document.title = "Showbay 24 | Live Session";
  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  if (isLoadingGetMyEventDetail) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }

  // console.log("getMyEventDetail...", getMyEventDetail?.eventType);

  return (
    <React.Fragment>
      <div className="page-content">
        {getMyEventDetail?.eventType?.includes("Conference") || "Social" ? (
          <JoinConference
            userDetails={userDetails}
            token={token}
            eventID={eventID}
            sessionID={sessionID}
            eventName={eventName}
            sessionName={sessionName}
            eventDetail={getMyEventDetail}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};
export default Main;
