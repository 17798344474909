import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/Summary.scss";

import Attendees from "./attendees/attendees.component";
import Speakers from "./speakers/speakers.component";
import Sponsors from "./sponsors/sponsors.component";
import Teams from "./Teams/teams.component";
import Volunteer from "./volunteer/Volunteer";
// import Speakers from "./components/speakers/speakers.component";
import Exhibitors from "./exhibitors/Exhibitor";
import EventOfficer from "./EventOfficer/eventOfficer.component";

import "./css/participants.scss";

const ParticipantsTab = ({ token, eventID, eventType }) => {
  const [activeLink, setActiveLink] = useState(0);

  // Function to handle link clicks
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  // console.log("eventType.....", eventType);
  return (
    <React.Fragment>
      <div className="people-container">
        <div className="column people-menu">
          <ul className="people-menu__list">
            <li className="people-menu__item">
              <a
                href="#"
                className={`people-menu__link ${
                  activeLink === 0 ? "people-menu__list--active" : ""
                }`}
                onClick={() => handleLinkClick(0)}
              >
                Attendees
              </a>
            </li>
            {eventType?.includes("Conference") ? (
              <li className="people-menu__item">
                <a
                  href="#"
                  className={`people-menu__link ${
                    activeLink === 2 ? "people-menu__list--active" : ""
                  }`}
                  onClick={() => handleLinkClick(2)}
                >
                  Speakers
                </a>
              </li>
            ) : null}
            {eventType?.includes("Exhibition") ? (
              <li className="people-menu__item">
                <a
                  href="#"
                  className={`people-menu__link ${
                    activeLink === 4 ? "people-menu__list--active" : ""
                  }`}
                  onClick={() => handleLinkClick(4)}
                >
                  Exhibitors
                </a>
              </li>
            ) : null}

            <li className="people-menu__item">
              <a
                href="#"
                className={`people-menu__link ${
                  activeLink === 6 ? "people-menu__list--active" : ""
                }`}
                onClick={() => handleLinkClick(6)}
              >
                Sponsors
              </a>
            </li>
            <li className="people-menu__item">
              <a
                href="#"
                className={`people-menu__link ${
                  activeLink === 8 ? "people-menu__list--active" : ""
                }`}
                onClick={() => handleLinkClick(8)}
              >
                Volunteer
              </a>
            </li>
            <li className="people-menu__item">
              <a
                href="#"
                className={`people-menu__link ${
                  activeLink === 10 ? "people-menu__list--active" : ""
                }`}
                onClick={() => handleLinkClick(10)}
              >
                Organizer Team
              </a>
            </li>
            <li className="people-menu__item">
              <a
                href="#"
                className={`people-menu__link ${
                  activeLink === 10 ? "people-menu__list--active" : ""
                }`}
                onClick={() => handleLinkClick(12)}
              >
                Event Officer
              </a>
            </li>
          </ul>
        </div>
        <div className="column people-content">
          {activeLink === 0 && <Attendees token={token} eventID={eventID} />}
          {activeLink === 2 && <Speakers token={token} eventID={eventID} />}
          {activeLink === 4 && <Exhibitors token={token} eventID={eventID} />}
          {activeLink === 6 && <Sponsors token={token} eventID={eventID} />}
          {activeLink === 8 && <Volunteer token={token} eventID={eventID} />}
          {activeLink === 10 && <Teams token={token} eventID={eventID} />}
          {activeLink === 12 && (
            <EventOfficer token={token} eventID={eventID} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default ParticipantsTab;
