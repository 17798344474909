import React from "react";
import Sidebar from "./Sidebar";
import Chat from "./Chat";

const Index = () => {
  return (
    <div className="home--chat">
      <div className="container--chat">
        <Sidebar />
        <Chat />
      </div>
    </div>
  );
};

export default Index;
