import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useCustomQuery from "../../../../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../../../../Hooks/useCustomMutation";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";
import "../../../css/Summary.scss";

import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import "./bulk-attendee.styles.scss";

const BulkAttendee = () => {
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userId = obj?.data?.data?.id;
  const token = obj?.token;
  const params = useParams();
  const eventID = params.eventID;

  const key = ["myAttendees", eventID];

  const mutation = useCustomMutation(key);
  const [inputs, setInputs] = useState({});

  const [modal_large, setmodal_large] = useState(false);
  // add speaker
  const [modal_large2, setmodal_large2] = useState(false);

  // edit event
  const [modal_large3, setmodal_large3] = useState(false);

  const [activeLink, setActiveLink] = useState(null);

  // add attendee
  const [slideInOpen, setSlideInOpen] = useState(false);

  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  // const {
  //   data: getMySessions,
  //   error: errorGetMySessions,
  //   isLoading: isLoadingGetMySessions,
  // } = useCustomQuery(
  //   ["mySession", eventID],
  //   `https://orca-app-65xn9.ondigitalocean.app/sessions/by-event/standard/${eventID}`,
  //   token
  // );

  // console.log("getMyEventDetail...", getMyEventDetail);
  // console.log("getSession...", getMySessions);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }
  function tog_large2() {
    setmodal_large2(!modal_large2);
    removeBodyCss();
  }
  function tog_large3() {
    setmodal_large3(!modal_large3);
    removeBodyCss();
  }

  const DeleteEvent = (e, values) => {
    e.preventDefault();
    const Method = "DELETE",
      endPoint = `events/${eventID}`,
      isJSON = null;
    var raw = null;

    // execute(endPoint, raw, Method, RegisterMsg, token, isJSON);
  };

  if (isLoadingGetMyEventDetail) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // if (isLoadingGetMySessions) {
  //   <div className="mt-5 d-flex justify-content-center align-items-center">
  //     <Spinner animation="border" role="status">
  //       <span className="visually-hidden">Loading...</span>
  //     </Spinner>
  //   </div>;
  // }

  // Function to handle link clicks
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    // setIloadBtn(true);
    event.preventDefault();
    const Method = "POST",
      url = `attendees/add/${eventID}`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("attendeeFile", inputs.attendeeFile);

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Attendees Successfully Added",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  return (
    <React.Fragment>
      <div className="bulk-attendee__heading">
        <p className="bulk-attendee__heading--header">
          Upload the list of attendees
        </p>
        <p className="bulk-attendee__heading--description">
          You can use the attached template as a guide. Please fill out all
          required fields to avoid issues after submitting attendee information.
          Keep in mind that csv uploads are limited to 5000 rows and 1MB in
          size.
        </p>
      </div>
      <form
        noValidate
        data-testid="form"
        // className="single-attendee__body--form"
        onSubmit={handleSubmitForm}
      >
        <div className="bulk-attendee__body">
          <AiOutlinePlusCircle size={16} />
          <p className="bulk-attendee__body--header">
            Upload .CSV list of your attendees
          </p>
          <p className="bulk-attendee__body--description">
            Drag a file here or click the frame to select a file
          </p>

          <input
            type="file"
            id="attendeeFile"
            name="attendeeFile"
            onChange={handleChange}
            accept=".csv"
          />
        </div>
        {/* <div className="next-container">
          <button type disabled>
            <p>Next</p>
          </button>
        </div> */}
        <div className="next-container" style={{}}>
          <button className="ShowBayBtn__Login" type="submit">
            <div className="d-flex justify-content-center align-items-center">
              {!mutation.isLoading ? <span>Next</span> : null}
              {!mutation.isLoading ? null : (
                <span>
                  <Spinner as="span" animation="border" size="sm" />
                </span>
              )}
            </div>
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};
export default BulkAttendee;
