import React, { useState, useEffect } from "react";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../../Hooks/useCustomMutation";
import SimpleBar from "simplebar-react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Spinner,
} from "reactstrap";
import "../css/Summary.scss";

import SlideInRightBar from "../../../../../components/Common/SlideInComponent/SlideInComponent";
import ActivityPreviewSlider from "../../../../../components/Modal/SessionSlide";
import ActivityPreviewBody from "../../../../../components/Modal/ActivityBody";
import EditActivity from "../../../../../components/Common/SlideInComponent/SlideInComponent";

import { AiOutlinePlusCircle } from "react-icons/ai";
// import NoAttendees from "../../../../../../../assets/images/no-attendees.png";

import "./activity-list.scss";
import AddActivity from "../AddActivity";
import AddActivityLineup from "../AddActivityLineup";

const ActivityList = ({ userId, token, eventID }) => {
  const [dropdownOpenStates, setDropdownOpenStates] = useState({});
  // add actuivity
  const [slideInOpen, setSlideInOpen] = useState(false);
  const [detatils, setDetatils] = useState(false);
  const [showdetatils, setShowDetatils] = useState(false);
  const [showAddLineup, setShowAddLineup] = useState(false);
  const [deleteActivityModal, setDeleteActivityModal] = useState(false);
  const key = ["mySessions", eventID];
  const mutationDelete = useCustomMutation(key);

  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/${eventID}`,
    token
  );

  const DeleteActivity = () => {
    const Method = "DELETE",
      url = `sessions/${detatils?.id}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Activity Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  useEffect(() => {
    if (mutationDelete.isSuccess) {
      setDetatils(false);
      setDeleteActivityModal(false);
    }
  }, [mutationDelete.isSuccess]);

  if (isLoadingGetMySessions) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }

  const handlePreviewActivity = (activity) => {
    setDetatils(activity);
    setShowDetatils(!showdetatils);
  };
  const handleAddLineup = (activity) => {
    setDetatils(activity);
    setShowAddLineup(!showAddLineup);
  };

  const tog_deleteActivity = (activity) => {
    setDetatils(activity);
    setDeleteActivityModal(!deleteActivityModal);
  };

  const toggleDropdown = (index) => {
    setDropdownOpenStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };

  return (
    <React.Fragment>
      <div className="sessions-list__heading">
        <div>
          <h3>Activies</h3>
          <span className="text">
            Choose from the different types of activities to add to your event
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <button
            className="btn btn-primary"
            type="button"
            onClick={toggleSlideIn}
          >
            <AiOutlinePlusCircle size={20} />
            <span className="px-2">Add activity </span>
          </button>
          <SlideInRightBar
            isOpen={slideInOpen}
            toggle={toggleSlideIn}
            title="Add Activity"
            body={
              <AddActivity
                userId={userId}
                token={token}
                eventID={eventID}
                setSlideInOpenSession={setSlideInOpen}
              />
            }
          />
        </div>
      </div>
      <SimpleBar style={{ height: "425px" }}>
        {getMySessions?.map((activity, i) => {
          return (
            <div
              className="mt-2 mb-5 activities---container d-flex w-100 "
              key={i}
            >
              <div className="p-3 d-flex justify-content-center ">
                <div>
                  <h5 className="text-primary">
                    {activity?.sessionDate?.substring(0, 3)}
                  </h5>
                  <h2 className="text-primary">
                    {activity?.sessionDate?.substring(8, 10)}
                  </h2>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <div className="verticalLine"></div>
              </div>
              <div className="p-3 w-100 d-flex">
                <div className="">
                  <div className="activity---time--container">
                    <div className="activity---time">
                      <i
                        className="fas fa-clock"
                        aria-hidden="true"
                        style={{ marginRight: 8, marginTop: 2, fontSize: 14 }}
                      ></i>
                      <span>{activity?.startTime}</span>
                      {"-"}
                      <span>{activity?.endTime}</span>
                    </div>
                    <div className="activity---location">
                      <i
                        className="fas fa-map-marker-alt"
                        aria-hidden="true"
                        style={{ marginRight: 8, marginTop: 2, fontSize: 14 }}
                      ></i>
                      <span>Online</span>
                    </div>
                  </div>
                </div>
                <div className="w-100 d-flex">
                  <div className="w-75 d-flex justify-content-start">
                    <h5>{activity?.sessionName}</h5>
                  </div>
                  <div className="mx-4 d-flex ">
                    <div className="btn-group activity--btn-edit" role="group">
                      <ButtonDropdown
                        isOpen={dropdownOpenStates[i]}
                        toggle={() => toggleDropdown(i)}
                      >
                        <DropdownToggle caret color="primary">
                          More {/*  <i className="mdi mdi-chevron-down"></i> */}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>Edit Activity</DropdownItem>
                          <DropdownItem
                            onClick={() => handlePreviewActivity(activity)}
                          >
                            Preview Activity
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => tog_deleteActivity(activity)}
                          >
                            Delete Activity
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handleAddLineup(activity)}
                          >
                            Add Lineup
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </SimpleBar>
      {/* EditActivity */}
      {showdetatils ? (
        <ActivityPreviewSlider
          isOpen={showdetatils}
          toggle={handlePreviewActivity}
          title="Preview Activity"
          body={<ActivityPreviewBody activity={detatils} />}
        />
      ) : null}
      {showAddLineup ? (
        <SlideInRightBar
          isOpen={showAddLineup}
          toggle={handleAddLineup}
          title="Add Lineup"
          body={
            <AddActivityLineup
              eventID={eventID}
              token={token}
              setSlideInOpenSession={setShowAddLineup}
              activity={detatils}
            />
          }
        />
      ) : null}

      <Modal
        size="md"
        isOpen={deleteActivityModal}
        toggle={() => {
          tog_deleteActivity();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Delete Activity
          </h5>
          <button
            onClick={() => {
              setDeleteActivityModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5 className="text-danger">
            Are you sure you want to delete this Activity ?
          </h5>
        </div>
        <div className="modal-footer d-flex justify-content-between">
          <button
            onClick={() => {
              setDeleteActivityModal(false);
            }}
            type="button"
            className="btn btn-dark"
          >
            Cancel
          </button>
          <button
            onClick={() => DeleteActivity(detatils?._id)}
            type="button"
            className="btn btn-danger"
          >
            {!mutationDelete?.isLoading ? (
              <span className="px-2">Sure</span>
            ) : (
              <span>
                <Spinner as="span" animation="border" size="sm" />
                Loading
              </span>
            )}
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default ActivityList;
