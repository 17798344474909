import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useCustomQuery from "../../../../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../../../../Hooks/useCustomMutation";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";
import "../../../css/Summary.scss";

import "./single-attendee.styles.scss";

const SingleAttendee = ({ toggle }) => {
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userId = obj?.data?.data?.id;
  const token = obj?.token;
  const params = useParams();
  const eventID = params.eventID;

  const key = ["myAttendees", eventID];
  const mutation = useCustomMutation(key);
  const [inputs, setInputs] = useState({});

  const [modal_large, setmodal_large] = useState(false);
  // add speaker
  const [modal_large2, setmodal_large2] = useState(false);

  // edit event
  const [modal_large3, setmodal_large3] = useState(false);

  const [activeLink, setActiveLink] = useState(null);

  // add attendee
  const [slideInOpen, setSlideInOpen] = useState(false);

  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };

  // const {
  //   data: getMyEventDetail,
  //   error: errorGetMyEventDetail,
  //   isLoading: isLoadingGetMyEventDetail,
  // } = useCustomQuery(
  //   ["myEventDetail", eventID],
  //   `${process.env.REACT_APP_API}events/${eventID}`,
  //   token
  // );

  // console.log("getMyEventDetail..", getMyEventDetail);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }
  function tog_large2() {
    setmodal_large2(!modal_large2);
    removeBodyCss();
  }
  function tog_large3() {
    setmodal_large3(!modal_large3);
    removeBodyCss();
  }

  // if (errorGetMyEventDetail) {
  //   return (
  //     <div className="mt-5 d-flex justify-content-center align-items-center">
  //       <Spinner animation="border" role="status">
  //         <span className="visually-hidden">Loading..........</span>
  //       </Spinner>
  //     </div>
  //   );
  // }
  // if (isLoadingGetMyEventDetail) {
  //   return (
  //     <div className="mt-5 d-flex justify-content-center align-items-center">
  //       <Spinner animation="border" role="status">
  //         <span className="visually-hidden">Loading...</span>
  //       </Spinner>
  //     </div>
  //   );
  // }

  // Function to handle link clicks
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    // setIloadBtn(true);
    event.preventDefault();
    const Method = "POST",
      url = `attendees/add/${eventID}`,
      isJSON = null;

    const raw = JSON.stringify({
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      email: inputs.email,
      jobTitle: inputs.jobTitle,
      organization: inputs.organization,
      city: inputs.city,
      country: inputs.country,
    });

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Attendee added successfully",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };
  useEffect(() => {
    if (mutation.mutate === "success") {
      toggle();
    }
  }, [mutation.mutate]);

  return (
    <React.Fragment>
      <div className="single-attendee__body">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="single-attendee__body--form"
          onSubmit={handleSubmitForm}
        >
          <div className="mt-0">
            <div className="d-flex">
              <div className="single-attendee__body--form-input-container gzIdbH field-col-half">
                <label
                  htmlFor="firstName"
                  className="booth__body--form-input-container__label"
                  style={{ marginBottom: "-8px" }}
                >
                  <p className="input-heading">First Name*</p>
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder=" "
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
                {/* <label
                  htmlFor="firstName"
                  placeholder
                  value
                  className="single-attendee__body--form-input-container-label"
                >
                  First Name*
                </label> */}
              </div>
              <div className="single-attendee__body--form-input-container gzIdbH field-col-half">
                <label
                  htmlFor="firstName"
                  className="booth__body--form-input-container__label"
                  style={{ marginBottom: "-8px" }}
                >
                  <p className="input-heading">Last Name*</p>
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  onChange={handleChange}
                  placeholder=" "
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
                {/* <label
                  htmlFor="lastName"
                  placeholder
                  value
                  className="single-attendee__body--form-input-container-label"
                >
                  Last Name
                </label> */}
              </div>
            </div>
            <div className="single-attendee__body--form-input-container gzIdbH">
              <label
                htmlFor="firstName"
                className="booth__body--form-input-container__label"
                style={{ marginBottom: "-8px" }}
              >
                <p className="input-heading">Email *</p>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                required
                placeholder=" "
                className="single-attendee__body--form-input-container-field"
                onFocus={(e) => e.target.classList.add("active")}
                onBlur={(e) =>
                  e.target.value === "" && e.target.classList.remove("active")
                }
              />
              {/* <label
                htmlFor="email"
                placeholder
                value
                className="single-attendee__body--form-input-container-label"
              >
                Email*
              </label> */}
            </div>
            <div className="single-attendee__body--form-input-container gzIdbH">
              <label
                htmlFor="firstName"
                className="booth__body--form-input-container__label"
                style={{ marginBottom: "-8px" }}
              >
                <p className="input-heading">Job Title</p>
              </label>
              <input
                id="jobTitle"
                name="jobTitle"
                type="text"
                onChange={handleChange}
                required
                placeholder=" "
                className="single-attendee__body--form-input-container-field"
                onFocus={(e) => e.target.classList.add("active")}
                onBlur={(e) =>
                  e.target.value === "" && e.target.classList.remove("active")
                }
              />
              {/* <label
                htmlFor="jobTitle"
                placeholder
                value
                className="single-attendee__body--form-input-container-label"
              >
                Job Title
              </label> */}
            </div>
            <div className="single-attendee__body--form-input-container gzIdbH">
              <label
                htmlFor="firstName"
                className="booth__body--form-input-container__label"
                style={{ marginBottom: "-8px" }}
              >
                <p className="input-heading">Organization</p>
              </label>
              <input
                id="organization"
                name="organization"
                type="text"
                onChange={handleChange}
                required
                placeholder=" "
                className="single-attendee__body--form-input-container-field"
                onFocus={(e) => e.target.classList.add("active")}
                onBlur={(e) =>
                  e.target.value === "" && e.target.classList.remove("active")
                }
              />
              {/* <label
                htmlFor="organization"
                placeholder
                value
                className="single-attendee__body--form-input-container-label"
              >
                Organization
              </label> */}
            </div>
            <div className="single-attendee__body--form-input-container gzIdbH">
              <label
                htmlFor="firstName"
                className="booth__body--form-input-container__label"
                style={{ marginBottom: "-8px" }}
              >
                <p className="input-heading">City</p>
              </label>
              <input
                id="city"
                name="city"
                type="text"
                onChange={handleChange}
                placeholder=" "
                className="single-attendee__body--form-input-container-field"
                onFocus={(e) => e.target.classList.add("active")}
                onBlur={(e) =>
                  e.target.value === "" && e.target.classList.remove("active")
                }
              />
              {/* <label
                htmlFor="city"
                placeholder
                value
                className="single-attendee__body--form-input-container-label"
              >
                City
              </label> */}
            </div>
            <div aria-label="country-dropdown">
              <div className="single-attendee__body--form-input-container gzIdbH">
                <label
                  htmlFor="firstName"
                  className="booth__body--form-input-container__label"
                  style={{ marginBottom: "-8px" }}
                >
                  <p className="input-heading">Country</p>
                </label>
                <input
                  id="country"
                  name="country"
                  type="text"
                  onChange={handleChange}
                  placeholder=" "
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
                {/* <label
                  htmlFor="country"
                  placeholder
                  value
                  className="single-attendee__body--form-input-container-label"
                >
                  Country
                </label> */}
              </div>
            </div>
            <div className="next-container" style={{ marginTop: "-32px" }}>
              <button className="ShowBayBtn__Login" type="submit">
                <div className="d-flex justify-content-center align-items-center text-light">
                  {!mutation.isLoading ? <span>Submit</span> : null}
                  {!mutation.isLoading ? null : (
                    <span>
                      <Spinner as="span" animation="border" size="sm" />
                    </span>
                  )}
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default SingleAttendee;
