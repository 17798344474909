import React from "react";
import QRCode from "react-qr-code";
import useCustomQuery from "../../Hooks/useCustomQuery";
import "./myTickets.scss";

function MyTickets({}) {
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const token = obj?.token;
  const userId = obj?.data?.data?._id;
  const userEmail = obj?.data?.data?.email;

  const {
    data: getEventTickets,
    error: errorGetEventTickets,
    isFetching: isFetchingGetEventTickets,
  } = useCustomQuery(
    ["my-event-tickets", userId],
    `${process.env.REACT_APP_API}tickets/?email=${userEmail}`,
    token
  );
  //     const handleViewInvoice = () => {
  //    =
  // }
  // console.log("userEmail....", userEmail);

  return (
    <React.Fragment>
      <div className="page-content w-100">
        <div className="my--ticket--container d-flex justify-content-center">
          {getEventTickets?.map((ticket, i) => {
            return (
              <div className="mt-1 my--ticket--box" key={i}>
                <div className="my--ticket--box--img">
                  <img
                    src={ticket?.eventId?.bannerImage}
                    alt="ticket"
                    className="w-100 h-100"
                  />
                </div>
                <div className="my--ticket--event--details--ticket">
                  {ticket?.eventId?.eventName}
                </div>
                <div
                  className="my--ticket--event--qr-code"
                  style={{
                    height: "auto",
                    margin: "0 auto",
                    maxWidth: 90,
                    width: "100%",
                  }}
                >
                  <QRCode
                    size={256}
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                    value={ticket?._id}
                    viewBox={`0 0 256 256`}
                  />
                </div>
                <div className="my--ticket--event--date">
                  {ticket?.date?.substring(0, 10)}
                </div>
                <div className="my--ticket--attendee--details">
                  {ticket?.firstName} {ticket?.lastName}
                </div>
                <div className="d-flex justify-content-center">
                  {ticket?.email}
                </div>
                <div className="my--ticket--attendee--ticket-type">
                  {ticket?.ticketType}
                </div>
                {/* <div
                  className="my--ticket--view--invoice"
                  onClick={() => handleViewInvoice(ticket?._id)}
                >
                  View Invoice
                </div> */}
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default MyTickets;
