import React, { useState, useRef, useEffect } from "react";
import useCustomMutation from "../../../../../../../Hooks/useCustomMutation";
// import useCustomQuery from "../../../../../../../Hooks/useCustomQuery";
import "../../../css/Summary.scss";
import "../landingPage.scss";
import { Spinner, Button } from "reactstrap";
import { BiEdit } from "react-icons/bi";
import ImgSession from "../../../../../../../assets/images/banners/sessions/bannerSession.png";

function EditReceptionBanner({ token, eventID, eventReception }) {
  const key = ["myEventReception", eventID];
  const mutation = useCustomMutation(key);
  const [isPicture, setIsPicture] = useState(false);
  const [logoServer, setLogoServer] = useState(null);
  const [profile, setProfile] = useState(null);
  const refFileUpload = useRef("");
  let reception = Array.isArray(eventReception);
  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLogoServer(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const UpdateReceptionBanner = (event) => {
    // event.preventDefault();
    let receptionId = null;
    // console.log("receptionId.........", receptionId);
    if (reception) {
      receptionId = eventReception[0]?._id;
    }
    const Method = "PATCH",
      url = `event-reception/${receptionId}`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("bannerImage", logoServer);
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Reception Banner Successfully Updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (reception) {
      setLogoServer(eventReception[0]?.bannerImage);
    }
    // if (mutation?.isSuccess) {

    // }
    // mutation?.isSuccess;
  }, [reception]);

  console.log("eventReception...", eventReception);
  return (
    <div className="event-branding--body">
      <h5>Event Reception Banner</h5>

      {/* <p className="text">
            Add your own Event Reception Banner (1200 X 450px ).
          </p> */}
      <div
        className="sponsor-form-body__sponsor-logo"
        style={{ marginRight: "12px", marginBottom: "-16px" }}
      >
        <div className="d-flex">
          <div
            className="section---banner---container mt-2"
            style={{ width: 800, height: 300 }}
          >
            {logoServer ? (
              <img
                src={profile ? profile : logoServer}
                alt="Event Banner"
                className="w-100 h-100 section---banner---container---img"
                style={{ objectFit: "cover" }}
              />
            ) : null}

            {!logoServer ? (
              <img
                src={ImgSession}
                alt="lobby Banner "
                className="w-100 h-100 section---banner---container---img"
                style={{ objectFit: "cover" }}
              />
            ) : null}

            <Button
              size="md"
              variant="separator-light"
              className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
              onClick={onThumbChangeClick}
            >
              <BiEdit
                size={40}
                style={{
                  color: "black",
                  filter: "drop-shadow(0px 0px 10px white)",
                }}
              />
            </Button>
            <input
              type="file"
              ref={refFileUpload}
              className="file-upload d-none"
              accept="image/*"
              onChange={changeThumb}
            />
          </div>
        </div>
        <p
          color="ambience.6"
          font-size="1"
          className="sponsor-form-body--input-description"
        >
          Recommended format: 1200 X 450px | JPG, PNG | Up to 8 MB
        </p>
      </div>
      <div className="d-flex" style={{}}>
        <button
          className="btn btn-primary"
          onClick={() => UpdateReceptionBanner()}
        >
          <div className="d-flex justify-content-center align-items-center text-light">
            {!mutation.isLoading ? <span>Update</span> : null}
            {!mutation.isLoading ? null : (
              <span>
                <Spinner as="span" animation="border" size="sm" /> Loading
              </span>
            )}
          </div>
        </button>
      </div>
    </div>
  );
}

export default EditReceptionBanner;
