import {
  LOGIN_USER,
  CUSTOM_LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  CUSTOM_LOGIN_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
} from "./actionTypes";

export const loginUser = (user, history, eventID) => {
  // console.log("actions...", history);
  return {
    type: LOGIN_USER,
    payload: { user, history, eventID },
  };
};

export const customLoginUser = (user, history, eventID, userRole) => {
  return {
    type: CUSTOM_LOGIN_USER,
    payload: { user, history, eventID, userRole },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const customLoginSuccess = (user) => {
  return {
    type: CUSTOM_LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  };
};
