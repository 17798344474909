import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import All from "./All";
import Ongoing from "./Ongoing";
import Upcoming from "./Upcoming";
import Completed from "./Completed";
import { AiOutlineSearch } from "react-icons/ai";

const ShowEvents = ({ t, userRole }) => {
  // const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  // const user = obj?.data?.data;
  const Navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [ViewAll, setViewAll] = useState(true);
  const [ViewOngoing, setViewOngoing] = useState(false);
  const [ViewUpcoming, setViewUpcoming] = useState(false);
  const [ViewCompleted, setViewCompleted] = useState(false);

  const handleViewAllClick = (v) => {
    setViewAll(v);
    setViewOngoing(false);
    setViewUpcoming(false);
    setViewCompleted(false);
  };
  const handleViewOngoingClick = (v) => {
    setViewOngoing(v);
    setViewUpcoming(false);
    setViewCompleted(false);
    setViewAll(false);
  };
  const handleViewUpcomingClick = (v) => {
    setViewUpcoming(v);
    setViewOngoing(false);
    setViewCompleted(false);
    setViewAll(false);
  };
  const handleViewCompletedClick = (v) => {
    console.log("Completed.");
    setViewUpcoming(false);
    setViewOngoing(false);
    setViewCompleted(v);
    setViewAll(false);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  // useEffect(() => {}, [userRole]);

  // if (userRole?.includes("event_organizer")) {
  //   setIsOrganizer(true);
  // }
  const handleEventBtn = () => {
    if (userRole?.includes("event_organizer")) {
      Navigate("/create-event");
    } else {
      Navigate("/talk-to-us");
    }
  };

  return (
    <div className="w-100">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="p-2 mt-4 d-flex align-items-center justify-content-between">
            <div>
              <h2>{t("My Events")}</h2>
            </div>
            <div>
              <h2>
                {/* <Link
                      to={
                        userRole?.includes("event_organizer")
                          ? "/talk-to-us"
                          : null
                      }
                    > */}
                {userRole?.includes("event_organizer") ? (
                  <button
                    type="button"
                    className="btn px-3 rounded-5 btn-md shadow-sm text-light"
                    style={{ backgroundColor: "#15A27A" }}
                    onClick={() => {
                      handleEventBtn();
                    }}
                  >
                    {t("Create an Event")}
                  </button>
                ) : (
                  <>
                    <span style={{ fontSize: 16, marginRight: 4 }}>
                      Want to host an event ?
                    </span>
                    <button
                      className="btn rounded-5 px-3 btn-md shadow-sm text-light"
                      style={{ backgroundColor: "#15A27A" }}
                      onClick={() => {
                        handleEventBtn();
                      }}
                    >
                      {t("Get started")}
                    </button>
                  </>
                )}

                {/* </Link> */}
              </h2>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="p-2 mb-4 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center justify-content-center">
                <div
                  style={{
                    background: "#ffffff",
                    paddingTop: ".58rem",
                    paddingBottom: ".5rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    marginRight: 32,
                    border: "solid",
                    borderWidth: 0.1,
                    borderRadius: 8,
                  }}
                >
                  <AiOutlineSearch size={24} />
                  <input
                    type="text"
                    placeholder={t("Search event name")}
                    value={search}
                    onChange={handleSearch}
                    style={{
                      border: "none",
                      marginLeft: 8,
                      paddingRight: 24,
                    }}
                  />
                </div>
                <div>
                  <h4 className="text-dark font-weight-bold">{t("Showing")}</h4>
                </div>
                <div className="d-flex align-items-center justify-content-around px-4">
                  <div className="px-2">
                    <button
                      type="button"
                      value={true}
                      className={
                        ViewAll
                          ? `btnMyEventFilterBtn1`
                          : `btnMyEventFilterBtn2`
                      }
                      onClick={handleViewAllClick}
                    >
                      {t("All")}
                    </button>
                  </div>
                  <div className="px-2">
                    <button
                      type="button"
                      value={true}
                      className={
                        !ViewUpcoming
                          ? `btnMyEventFilterBtn2`
                          : `btnMyEventFilterBtn1`
                      }
                      onClick={handleViewUpcomingClick}
                    >
                      {t("Upcoming")}
                    </button>
                  </div>
                  <div className="px-2">
                    <button
                      type="button"
                      value={true}
                      className={
                        !ViewOngoing
                          ? `btnMyEventFilterBtn2`
                          : `btnMyEventFilterBtn1`
                      }
                      onClick={handleViewOngoingClick}
                    >
                      {t("Ongoing")}
                    </button>
                  </div>
                  <div className="px-2">
                    <button
                      type="button"
                      value={true}
                      className={
                        !ViewCompleted
                          ? `btnMyEventFilterBtn2`
                          : `btnMyEventFilterBtn1`
                      }
                      onClick={handleViewCompletedClick}
                    >
                      {t("Completed")}
                    </button>
                  </div>
                  <Link
                    to={`/explore-events`}
                    // to={`/#`}
                    style={{
                      color: "#1a87a3",
                      marginLeft: "34px",
                      fontWeight: 600,
                    }}
                  >
                    {t("Explore More Events")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        {/* <div className="dashboard-heading-container-card">
          <div className="dashboard-heading-event-name">
            <h5>{t("Event Name")}</h5>
          </div>
          <div>
            <h5>{t("Event Status")}</h5>
          </div>
        </div> */}
        <div
          className="d-flex px-4 mb-4 event-headings"
          style={{
            background: "#ffffff",
            color: "#191c27",
            border: "1px solid rgba(73, 80, 87, .2)",
            borderWidth: 0.1,
            borderRadius: 8,
            paddingBottom: 4,
            paddingTop: 12,
          }}
        >
          <h5 style={{ flex: 0.4 }}>{t("Event Name")}</h5>
          <div
            className="d-flex justify-content-evenly event-heading--more"
            style={{ flex: 0.6 }}
          >
            <h5>{t("Event Status")}</h5>
            <h5>{t("Actions")}</h5>
          </div>
        </div>
      </Row>
      {ViewAll ? (
        <Row>
          <All t={t} search={search} />
        </Row>
      ) : null}
      {!ViewOngoing ? null : (
        <Row>
          <Ongoing t={t} />
        </Row>
      )}
      {!ViewUpcoming ? null : (
        <Row>
          <Upcoming t={t} />
        </Row>
      )}
      {!ViewCompleted ? null : (
        <Row>
          <Completed t={t} />
        </Row>
      )}
    </div>
  );
};
export default ShowEvents;
