import React, { useState } from "react";
import { Card } from "reactstrap";
import SponsorsSlideBar from "../../../../../../../components/Common/SlideInComponent/SlideInComponent";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AddProducts from "../../../AddProducts";

function Products({ eventID, token, boothID, products }) {
  const [slideInOpenProducts, setSlideInOpenProducts] = useState(false);

  const toggleSlideInProducts = () => {
    setSlideInOpenProducts(!slideInOpenProducts);
  };

  const getLastTwoProducts = (myProducts) => {
    if (myProducts?.length >= 2) {
      return myProducts?.slice(-2);
    }

    if (myProducts?.length === 1) {
      return [myProducts[0]];
    }
    return [];
  };
  let lastTwoProducts = [];
  if (products) {
    lastTwoProducts = getLastTwoProducts(products);
  }

  // console.log("products....", products);

  return (
    <div className="mt-1">
      <Card>
        <div className="summary-details__content">
          <div className="summary-details__content--heading">
            <div className="w-100">
              <div className="d-flex justify-content-between">
                <h4>Products</h4>
              </div>

              {products && boothID && (
                <div className="d-flex">
                  <div
                    className="btn-add-speaker d-flex justify-content-center align-items-center"
                    style={{
                      width: 100,
                      borderStyle: "dashed",
                      borderRadius: 5,
                      borderWidth: 1,
                      padding: 10,
                      marginRight: 3,
                    }}
                  >
                    <AiOutlinePlusCircle
                      size={24}
                      type="button"
                      onClick={toggleSlideInProducts}
                    />
                  </div>
                  {lastTwoProducts?.map((product, i) => {
                    return (
                      <div
                        className="btn-add-speaker d-flex"
                        style={{
                          width: 100,
                          borderRadius: 5,
                          marginRight: 3,
                        }}
                        key={i}
                      >
                        <img
                          src={product?.productImage}
                          alt="booth-product"
                          className="w-100 h-100"
                          style={{
                            borderRadius: 5,
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
      {slideInOpenProducts ? (
        <SponsorsSlideBar
          isOpen={true}
          title="Add Booth Products"
          toggle={toggleSlideInProducts}
          body={
            <AddProducts
              token={token}
              boothID={boothID}
              eventID={eventID}
              setSlideInOpenProduct={setSlideInOpenProducts}
            />
          }
        />
      ) : null}
    </div>
  );
}

export default Products;
