import React, { useState } from "react";
import ChartProgress from "../../../../../../components/Common/chart/ChartProgress";
import "./attendeeCard.scss";

function AttendeesRates() {
  const [steps, setsteps] = useState(1800);
  const attendeesNumber = 3000;
  return (
    <div className="charts-analystic-card d-flex justify-content-center align-items-center">
      <div className="">
        <div className="d-flex justify-content-center  h4">Attendees Rates</div>
        <div className="d-flex justify-content-center align-items-center">
          <ChartProgress
            value={steps}
            fgColor="#0ac074"
            lineCap="round"
            step={10}
            height={180}
            width={180}
            min={0}
            max={attendeesNumber}
            onChange={(e) => {
              setsteps(e);
            }}
            readOnly={true}
          />
        </div>
      </div>
    </div>
  );
}

export default AttendeesRates;
