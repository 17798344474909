import React from "react";
import { Row, Container, Spinner } from "reactstrap";
import AllAttendeeEvents from "./AllAttendeeEvents";
import AllExhibitorEvents from "./AllExhibitorEvents";
import AllSpeakerEvents from "./AllSpeakerEvents";
import AllOrganizer from "./AllOrganizer";

const All = ({ t, search }) => {
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userId = obj?.data?.data?.id;
  const userEmail = obj?.data?.data?.email;
  const userRole = obj?.data?.data?.roles;
  const token = obj?.token;

  // console.log("user role....", obj?.data?.data?.roles);

  const isEventLoading = false;

  if (isEventLoading) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Container>
        <div>
          {userRole?.includes("attendee") ? (
            <Row>
              <AllExhibitorEvents
                search={search}
                userEmail={userEmail}
                token={token}
                t={t}
              />
            </Row>
          ) : null}
          {userRole?.includes("attendee") ? (
            <Row>
              <AllSpeakerEvents
                search={search}
                userEmail={userEmail}
                token={token}
                t={t}
              />
            </Row>
          ) : null}
          {userRole?.includes("attendee") ? (
            <Row>
              <AllAttendeeEvents
                search={search}
                userEmail={userEmail}
                token={token}
                t={t}
              />
            </Row>
          ) : null}
          {userRole?.includes("event_organizer") ? (
            <Row>
              <AllOrganizer
                search={search}
                userId={userId}
                userEmail={userEmail}
                token={token}
                t={t}
              />
            </Row>
          ) : null}
        </div>
      </Container>
    </React.Fragment>
  );
};
export default All;
