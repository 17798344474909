import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";

import Search from "./components/Search";
import CompnayNames from "./CompnayNames";
import Booths from "./components/Booths";
import "./allBooth.scss";

const AllBooth = () => {
  const params = useParams();
  const eventID = params.eventID;
  const eventName = params.eventName;
  const myEventName = eventName.replace(/-/g, " ");
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const token = obj?.token;
  const [searchRef, setSearchRef] = useState("");
  const [allCompanies, setAllCompanies] = useState("");
  const [companyNames, setCompanyNames] = useState([]);

  useEffect(() => {
    if (allCompanies?.length > 0) {
      const names = allCompanies?.map(
        (company) => company.companyProfiles[0]?.companyName
      );
      setCompanyNames(names);
    }
  }, [allCompanies]);
  return (
    <React.Fragment>
      <div className="page-content bg-dark" style={{ userSelect: "none" }}>
        <div className="w-100 d-flex bg-dark p-3">
          <div className="w-25">
            <Search searchRef={searchRef} setSearchRef={setSearchRef} />
            <SimpleBar style={{ height: "230px" }}>
              <div>
                <CompnayNames
                  setSearchRef={setSearchRef}
                  companyNames={companyNames}
                />
              </div>
            </SimpleBar>
          </div>
          <div className="w-75">
            <Booths
              eventID={eventID}
              token={token}
              search={searchRef}
              evName={myEventName}
              setAllCompanies={setAllCompanies}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllBooth;
