import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import CustomModal from "../../../../components/Modal/CustomModal";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import Conference from "./Conference";
import "./css/SelectOption.scss";
import Exhibition from "./Exhibition";
import Social from "./Social";
import Expo from "./Expo";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const PricingData = [
  {
    eventType: "Conference",
    eventFormat: "Virtual",
    icon: "fas fa-cube",
    price: "900",
    isChild: [
      { id: "1", features: "1200 Attendees" },
      { id: "2", features: "20 sessions" },
      { id: "3", features: "10 speakers" },
      { id: "4", features: "Tickets" },
    ],
  },
  {
    eventType: "Social",
    eventFormat: "In Person",
    icon: "fas fa-trophy",
    price: "400",
    isChild: [
      { id: "1", features: "10000 Attendees" },
      { id: "2", features: "10 Activities" },
      { id: "3", features: "No Recording" },
      { id: "4", features: "Free" },
    ],
  },
  {
    eventType: "Exhibition",
    eventFormat: "Hybrid",
    icon: "fas fa-shield-alt",
    price: "250",
    isChild: [
      { id: "1", features: "8000 attendees" },
      { id: "2", features: "40 booths" },
      { id: "3", features: "No Time Tracking" },
      { id: "4", features: "Free Setup" },
    ],
  },
  {
    eventType: "Expo",
    eventFormat: "Virtual",
    icon: "fas fa-headset",
    price: "1000",
    isChild: [
      { id: "1", features: "Free Live Support" },
      { id: "2", features: "Unlimited User" },
      { id: "3", features: "No Time Tracking" },
      { id: "4", features: "Free Setup" },
    ],
  },
  {
    eventType: "Exhibition",
    eventFormat: "In Person",
    icon: "fas fa-headset",
    price: "600",
    isChild: [
      { id: "1", features: "Ticketing" },
      { id: "2", features: "Unlimited User" },
      { id: "3", features: "No Time Tracking" },
      { id: "4", features: "Free Setup" },
    ],
  },
];

function SelectOption({}) {
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userID = obj?.data?.data?._id;
  const userEmail = obj?.data?.data?.email;
  const token = obj?.token;

  const [slideInOpenConference, setSlideInOpenConference] = useState(false);
  const toggleSlideInConference = () => {
    setSlideInOpenConference(!slideInOpenConference);
  };

  // add exhibition
  const [slideInOpenExhibition, setSlideInOpenExhibition] = useState(false);

  const toggleSlideInExhibition = () => {
    setSlideInOpenExhibition(!slideInOpenExhibition);
  };

  // add social
  const [slideInOpenSocial, setSlideInOpenSocial] = useState(false);
  const toggleSlideInSocial = () => {
    setSlideInOpenSocial(!slideInOpenSocial);
  };

  // add expo
  const [slideInOpenExpo, setSlideInOpenExpo] = useState(false);
  const toggleSlideInExpo = () => {
    setSlideInOpenExpo(!slideInOpenExpo);
  };

  const {
    data: getMyPackages,
    error: errorGetMyPackage,
    isLoading: isLoadingGetMyPackage,
  } = useCustomQuery(
    ["myEventPackages", userID],
    `${process.env.REACT_APP_API}packages/lead/?email=${userEmail}`,
    token
  );

  console.log("getMyPackages....#######....", getMyPackages);
  console.log("getMyPackages....@@@@@.......", getMyPackages);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };
  return (
    <div className="mt-4 mx-3">
      <div>
        <h1>Create Your Event</h1>
        <div>Select what kind of event you would like to create</div>
      </div>
      <div className="">
        <Slider {...settings}>
          {PricingData.map((item, key) => (
            <div
              style={{ width: 200, height: 450, backgroundColor: "blue" }}
              key={key}
            >
              <Card className="w-100" style={{ width: 200 }}>
                <CardBody className="p-4">
                  <div className="d-flex mb-1">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar-sm">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className={item.icon + " font-size-20"}></i>
                        </span>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-size-16">{item.eventType}</h5>
                      <p className="text-muted">{item.eventFormat}</p>
                    </div>
                  </div>
                  <div className="py-4 border-bottom">
                    <div className="float-end plan-btn">
                      <button
                        className="btn btn-primary btn-sm waves-effect waves-light"
                        onClick={() => {
                          if (item.eventType === "Conference") {
                            toggleSlideInConference();
                          }
                          if (item.eventType === "Exhibition") {
                            toggleSlideInExhibition();
                          }
                          if (item.eventType === "Social") {
                            toggleSlideInSocial();
                          }
                          if (item.eventType === "Expo") {
                            toggleSlideInExpo();
                          }
                        }}
                      >
                        Create Event
                      </button>
                    </div>
                    <h4>
                      <sup>
                        <small>$</small>
                      </sup>
                      {item.price}
                      {/* {item.price}/ <span className="font-size-16">m</span> */}
                    </h4>
                  </div>
                  <div className="plan-features mt-4">
                    <h5 className="text-center font-size-15 mb-4">
                      Plan Features :
                    </h5>
                    {item.isChild.map((subitem, key) => (
                      <p key={key}>
                        <i className="mdi mdi-checkbox-marked-circle-outline font-size-16 align-middle text-primary me-2"></i>{" "}
                        {subitem.features}
                      </p>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </div>
          ))}
        </Slider>
      </div>
      <div className="d-flex mt-5 gap-3 Specify__Card__container">
        {/* <div
          onClick={toggleSlideInConference}
          className="Specify__Card event-type"
        >
          <img
            src="https://images.pexels.com/photos/2833037/pexels-photo-2833037.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="eventType"
            className="Specify__Card__img"
          />
          <div className="h4 d-flex justify-content-center mt-2 py-2">
            Conference
          </div>
        </div> */}

        {/* <div
          onClick={toggleSlideInExhibition}
          className="Specify__Card event-type"
        >
          <img
            src="https://images.pexels.com/photos/7648055/pexels-photo-7648055.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="eventType"
            className="Specify__Card__img"
          />
          <div className="h4 d-flex justify-content-center mt-2 py-2">
            Exhibition
          </div>
        </div> */}

        {/* <div onClick={toggleSlideInExpo} className="Specify__Card event-type">
          
          <img
            src="https://images.pexels.com/photos/167478/pexels-photo-167478.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="eventType"
            className="Specify__Card__img"
          />
          <div className="h4 d-flex justify-content-center mt-2 py-2">Expo</div>
        </div> */}

        {/* <div onClick={toggleSlideInSocial} className="Specify__Card event-type">
          <img
            src="https://images.pexels.com/photos/1763075/pexels-photo-1763075.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
            alt="eventType"
            className="Specify__Card__img"
          />
          <div className="h4 d-flex justify-content-center mt-2 py-2">
            Social
          </div>
        </div> */}
      </div>
      {/* <CustomModal
        isOpen={slideInOpenConference}
        toggleEvent={"hello"}
        title="Create Conference"
        body={<p>hello</p>}
      /> */}
      <CustomModal
        isOpen={slideInOpenConference}
        toggleEvent={toggleSlideInConference}
        title="Create Conference Event"
        body={
          <Conference
            setSlideInOpenConference={setSlideInOpenConference}
            userId={userID}
            token={token}
          />
        }
      />

      <CustomModal
        isOpen={slideInOpenExhibition}
        toggleEvent={toggleSlideInExhibition}
        title="Create Exhibiton Event"
        body={
          <Exhibition
            setSlideInOpenExhibition={setSlideInOpenExhibition}
            userId={userID}
            token={token}
          />
        }
      />

      <CustomModal
        isOpen={slideInOpenExpo}
        toggleEvent={toggleSlideInExpo}
        title="Create Expo"
        body={
          <Expo
            setSlideInOpenExpo={setSlideInOpenExpo}
            userId={userID}
            token={token}
          />
        }
      />

      <CustomModal
        isOpen={slideInOpenSocial}
        toggleEvent={toggleSlideInSocial}
        title="Create Social Event"
        body={
          <Social
            setSlideInOpenSocial={setSlideInOpenSocial}
            userId={userID}
            token={token}
          />
        }
      />

      {/* <div
        style={{
          background: "#ffffff",
          width: "100%",
          borderRadius: 12,
          margin: "3rem 0 1rem",
          padding: 24,
        }}
      >
       
        <table className="glossary">
          <tr className="glossary__term-and-definition">
            <td>
              <h6>Conference: </h6>
            </td>
            <td>
              a great way to achieve a swarm of potential business goals, such
              as brand awareness, lead generation, and thought leadership.
            </td>
          </tr>
          <tr className="glossary__term-and-definition">
            <td>
              <h6>Expo: </h6>
            </td>
            <td>
              {" "}
              a public event where businesses and organizations can showcase
              their products, services, and new developments.
            </td>
          </tr>
          <tr className="glossary__term-and-definition">
            <td>
              <h6>Social: </h6>
            </td>
            <td>
              {" "}
              an occasion where people come together for the purpose of
              socializing, interacting, celebrating, or engaging in shared
              activities.
            </td>
          </tr>
          <tr className="glossary__term-and-definition">
            <td>
              <h6>Exhibition: </h6>
            </td>
            <td>
              exhibitions offer attendees not only opportunities for buying but
              also serve as ideal environments for industry networking.
            </td>
          </tr>
        </table>
      </div> */}
    </div>
  );
}

export default SelectOption;
