import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineUsergroupAdd } from "react-icons/ai";

const TicketQuantitySelection = ({
  selectedTicket,
  setTicketQuantity,
  setTicketType,
  handleNext,
  handleBack,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  // const [quantity, setQuantity] = useState(1);
  // const [price, setPrice] = useState(selectedTicket?.price);

  // useEffect(() => {
  //   setPrice(quantity * selectedTicket.price);
  // }, [quantity, selectedTicket.price]);

  // const increaseQuantity = () => {
  //   setQuantity((prevQuantity) => prevQuantity + 1);
  // };

  // const decreaseQuantity = () => {
  //   if (quantity > 1) {
  //     setQuantity((prevQuantity) => prevQuantity - 1);
  //   }
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setTicketQuantity(quantity);
  //   handleNext();
  // };

  const selectOption = (option) => {
    setSelectedOption(option);
    setTicketType(option);
    handleNext();
  };
  const isSelected = (option) => selectedOption === option;
  const optionStyle = (option) => ({
    borderRadius: "50%",
    backgroundColor: isSelected(option) ? "#7479FF" : "#f6f6f6",
    color: isSelected(option) ? "white" : "black",
    width: 60,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });

  // const handleIndividual = () => {
  //   setTicketType("Individual");
  //   //  handleNext();
  // };
  // const handleBulk = () => {
  //   setTicketType("Bulk");
  //   // handleNext();
  // };

  return (
    // <form onSubmit={handleSubmit}>
    <>
      <div className="d-flex justify-content-center mt-5">
        <div
          className="buy--ticket--quatity--card"
          onClick={() => selectOption("Individual")}
          style={{
            backgroundColor: isSelected("Individual") ? "blue" : "",
            color: isSelected("Individual") ? "white" : "",
          }}
        >
          <div className="">
            <div className="d-flex justify-content-center">
              <div style={optionStyle("Individual")}>
                <AiOutlineUser
                  size={30}
                  color={isSelected("Individual") ? "white" : "blue"}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: 14,
              }}
              className="mt-2"
            >
              Individual
            </div>
            <div className="mt-2 d-flex justify-content-center">
              Select Option for Single attendee
            </div>
          </div>
        </div>
        <div
          className="buy--ticket--quatity--card"
          onClick={() => selectOption("Bulk")}
          style={{
            backgroundColor: isSelected("Bulk") ? "blue" : "",
            color: isSelected("Bulk") ? "white" : "",
          }}
        >
          <div className="">
            <div className="d-flex justify-content-center">
              <div style={optionStyle("Bulk")}>
                <AiOutlineUsergroupAdd
                  size={30}
                  color={isSelected("Bulk") ? "white" : "blue"}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: 14,
              }}
              className="mt-2"
            >
              Group
            </div>
            <div className="mt-2 d-flex justify-content-center">
              Select Option for Bulk Attendee
            </div>
          </div>
        </div>
        {/* <div className="buy--ticket--quatity--card">
          <div className="d-flex justify-content-center">
            <div
              style={{
                borderRadius: "50%",
                backgroundColor: "red",
                width: 60,
                height: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AiOutlineUsergroupAdd size={30} color="blue" />
            </div>
            <div>Individual</div>
            <div>Select Option for Single attendee</div>
          </div>
        </div> */}
        {/* <div className="buy--ticket--quatity--card">
          <div className="w-100">
            <div className="buy--ticket--quatity--details">
              Ticket details
              <div className="d-flex justify-content-between">
                <div>{quantity} Ticket(s)</div>
                <div>R {price}</div>
              </div>
              Additional fee details, if any
              <div className="mt-2 d-flex justify-content-between">
                <div>FEES</div>
                <div>R 0.00</div>
              </div>
              Total price display
              <div className="mt-2 d-flex justify-content-between h5">
                <div>TOTAL</div>
                <div>R {price}</div>
              </div>
            </div>
            Quantity adjustment buttons
            <div className="buy--ticket--quatity--btn">
              <Button
                onClick={decreaseQuantity}
                variant="outlined"
                style={{
                  backgroundColor: "blue",
                  color: "white",
                }}
              >
                -
              </Button>
              <div className="mx-2 h2 mt-2">{quantity}</div>
              <Button
                onClick={increaseQuantity}
                variant="outlined"
                style={{
                  backgroundColor: "blue",
                  color: "white",
                }}
              >
                +
              </Button>
            </div>
          </div>
        </div> */}
      </div>
      {/* Navigation buttons */}
      <div className="d-flex justify-content-center mt-5">
        <Button
          onClick={handleBack}
          variant="outlined"
          style={{ marginRight: "10px" }}
        >
          Back
        </Button>
        {/* <Button type="submit" variant="contained">
          Continue
        </Button> */}
      </div>
    </>
    // </form>
  );
};

export default TicketQuantitySelection;
