import React, { useState } from "react";
import { Col } from "reactstrap";
import CustomModal from "../../../../../components/Common/boothModal/slide-in-modal.component";
import BoothBody from "./boothBody";
import BoothCompanyProfile from "./BoothCompanyProfile";
import PreviewBooth from "../../../PreviewBooth/Index";

function BoothCard({ userId, boothDetails, token, eventID }) {
  const [isChatBoxVisible, setChatBoxVisible] = useState(false);
  const [slideInOpenBoothDetails, setSlideInOpenBoothDetails] = useState(false);
  const handleChatClick = () => {
    setChatBoxVisible(true);
  };
  const toggleSlideInBoothDetails = () => {
    setSlideInOpenBoothDetails(!slideInOpenBoothDetails);
  };
  return (
    <>
      <Col md={6}>
        <div className="booths-content__body">
          <div
            className="booths-content__body--booth__container"
            style={{ position: "relative", cursor: "pointer" }}
            onClick={toggleSlideInBoothDetails}
          >
            <div
              className="booths-content__body--booth"
              style={{
                padding: "18px",
                width: "100%",
                background: "#5d5d5f",
                borderRadius: "8px",
              }}
            >
              <img
                src={boothDetails?.boothLogo}
                width="100%"
                height="40%"
                alt=""
                style={{ borderRadius: "12px" }}
              />
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  marginTop: "16px",
                  alignItems: "center",
                }}
              >
                <BoothCompanyProfile
                  token={token}
                  boothId={boothDetails?._id}
                />
                <div
                  style={{
                    color: "white",
                  }}
                >
                  |
                </div>
                <p style={{ color: "white", marginBottom: "-8px" }}>
                  {boothDetails?.boothName}
                </p>
                <div
                  className="with-exhibitors"
                  style={{
                    background: "#131216",
                    borderRadius: "8px",
                    padding: "4px 8px",
                    fontSize: "10px",
                    marginBottom: "-12px",
                    marginLeft: "240px",
                  }}
                >
                  {boothDetails?.boothTags?.map((tag, i) => {
                    return (
                      <span style={{ color: "white" }} key={i}>
                        {tag}
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>

            <CustomModal
              isOpen={slideInOpenBoothDetails}
              toggle={toggleSlideInBoothDetails}
              title="Booth"
              body={
                <PreviewBooth
                  eventID={eventID}
                  boothID={boothDetails?._id}
                  boothDetails={boothDetails}
                  token={token}
                />
              }
            />
          </div>
        </div>
      </Col>
    </>
  );
}

export default BoothCard;
