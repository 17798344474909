import React from "react";
import CustomHeader from "../../components/Common/CustomTopBar";
import AttendeeForm from "./components/Attendee/AttendeeForm";
import AttendeeStepper from "./components/Attendee/AttendeeStepper";

const AttendeeReg = () => {
  document.title = "Showbay 24 | Complete Your Profile";

  return (
    <>
      <CustomHeader />
      {/* <AttendeeStepper /> */}
      <AttendeeForm />
    </>
  );
};

export default AttendeeReg;
