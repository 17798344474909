/* eslint-disable no-unused-expressions */

import {
  dangerMessage,
  successMessage,
  warningMessage,
} from "../components/Toast/Toast";

async function Post(
  endPoint,
  bodyContent,
  Method,
  displayMessage,
  token,
  isJSON
) {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  isJSON ? null : myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: Method,
    headers: myHeaders,
    body: bodyContent,
    redirect: "follow",
  };

  const result = await fetch(
    `${process.env.REACT_APP_API}${endPoint}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((res) => {
      // console.log("res...", res);
      if (res?.status === "success") {
        displayMessage === null ? null : successMessage(`${displayMessage}`);
        return res;
      }
      if (res?.status === "fail") {
        warningMessage(`${res?.message}`);
        return res?.message;
      }
      if (res?.status === "error") {
        if (res?.message === "jwt malformed") {
          dangerMessage("You need to login");
        } else {
          // dangerMessage("Network issue please try again");
        }
      }
    })
    .catch((error) => {
      console.log("res...error..", error);
      dangerMessage(`${error.message}`);
    });
  return result;
}

export default Post;
