import React, { useState, useEffect } from "react";
import { Spinner, Button } from "reactstrap";
import { BiEdit } from "react-icons/bi";
import "bootstrap/dist/css/bootstrap.min.css";

import useCustomQuery from "../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import ImgSession from "../../../../assets/images/banners/sessions/bannerSession.png";
import "./css/Summary.scss";
import "./css/AddSpeaker.scss";

const UploadVideoLink = ({ token, eventID, setShowVideoLink }) => {
  const key = ["myEventDetail", eventID];
  const mutation = useCustomMutation(key);
  const [inputs, setInputs] = useState({});

  // Function to handle link clicks

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    // Check if it's a YouTube link
    if (value.includes("youtube.com") || value.includes("youtu.be")) {
      // Transform the link into an embedded format
      const youtubeId = value.split(/(v=|\/)([0-9A-Za-z_-]{11})/)[2];
      if (youtubeId) {
        value = `https://www.youtube.com/embed/${youtubeId}`;
      }
    }

    // Check if it's a Vimeo link
    else if (value.includes("vimeo.com")) {
      // Transform the link into an embedded format
      const vimeoId = value.split("/").pop();
      if (vimeoId) {
        value = `https://player.vimeo.com/video/${vimeoId}`;
      }
    }
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "PATCH",
      url = `events/${eventID}`,
      isJSON = true;
    const formdata = new FormData();
    formdata.append("videoLink", inputs.videoLink);

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Video Link Successfully Updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      setShowVideoLink(false);
    }
  }, [mutation.isSuccess]);

  return (
    <React.Fragment>
      <div className="">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className=""
          onSubmit={handleSubmitForm}
        >
          <div className="mt-0">
            {/* ------------------ Form Fields ---------------------- */}

            <div className="booth__body--form-input-container">
              <label
                htmlFor="videoLink"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Video Link</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="videoLink"
                  name="videoLink"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="htpps://www.youtube.com/watch"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
          </div>
          <div className="next-container" style={{}}>
            <button className="btn btn-primary" type="submit">
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? <span>Save</span> : null}
                {!mutation.isLoading ? null : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                )}
              </div>
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default UploadVideoLink;
