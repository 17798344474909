import React, { useState } from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
// import { Modal } from "reactstrap";

import { isNull } from "lodash";
import SponsorsDetails from "./components/SponsorsDetails";

function Sponsors({ eventID, token, boothID, boothDetails }) {
  const [viewProducts, setViewProducts] = useState(false);
  const [sponsorDetails, setSponsorsDetails] = useState(isNull);
  const {
    data: getSponsors,
    error: errorGetProducts,
    isLoading: isLoadingGetProducts,
  } = useCustomQuery(
    ["exhibition-Sponsors-Booth", eventID],
    `${process.env.REACT_APP_API}sponsors/booth/${boothID}`,
    token
  );
  let handleViewProduct = (product) => {
    setViewProducts(!viewProducts);
    setSponsorsDetails(product);
  };

  function tog_viewProduct() {
    setViewProducts(!viewProducts);
  }
  return (
    <>
      <div className="w-100 d-flex mb-5">
        {getSponsors?.map((sponsor, i) => {
          return (
            <div key={i} className="mx-2">
              <div
                style={{ width: 200, height: 200, borderRadius: 10 }}
                onClick={() => handleViewProduct(sponsor)}
              >
                <img
                  src={sponsor?.sponsorLogo}
                  className="w-100 h-100"
                  style={{ borderRadius: 10 }}
                />
                <div className="w-100 mt-1  d-flex justify-content-center">
                  {sponsor?.sponsorName}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* <Modal
        size="md"
        isOpen={viewProducts}
        toggle={() => {
          tog_viewProduct();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Product Details
          </h5>
          <button
            onClick={() => {
              setViewProducts(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <SponsorsDetails details={sponsorDetails} />
        </div>
        
      </Modal> */}
    </>
  );
}

export default Sponsors;
