import React, { useState, useRef } from "react";
import { Spinner } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import useCustomQuery from "../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import "./css/Summary.scss";
import "./css/AttachSessionDocument.scss";

const AttachSessionDocument = ({
  token,
  eventID,
  session,
  setSlideInOpenDocument,
}) => {
  const key = ["mySessions", eventID];
  const mutation = useCustomMutation(key);
  const [inputs, setInputs] = useState({});
  const [isAttachmentDocument, setIsAttachmentDocument] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const {
    data: getSessionDetails,
    error: errorGetSessionDetails,
    isLoading: isLoadingGetSessionDetails,
  } = useCustomQuery(
    ["sessionDetails", eventID],
    `${process.env.REACT_APP_API}sessions/${session?._id}`,
    token
  );

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "POST",
      url = `sessions-documents/${session?._id}`,
      isJSON = true;
    const formdata = new FormData();
    if (isAttachmentDocument) {
      isAttachmentDocument?.map((item) => {
        formdata.append("document", item);
      });
    }
    formdata.append("name", inputs.name);
    formdata.append("description", inputs.description);
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Speaker Successfully Created",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  if (isLoadingGetSessionDetails) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }

  //attach document function
  const handleAttachment = (event) => {
    const selectedFiles = event.target.files;
    const fileArray = Array.from(selectedFiles);
    setIsAttachmentDocument(fileArray);

    const previews = fileArray.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  //define file icon
  const getFileIcon = (fileName) => {
    if (fileName.endsWith(".pdf")) {
      return "far fa-file-pdf";
    } else if (fileName.endsWith(".xls") || fileName.endsWith(".xlsx")) {
      return "far fa-file-excel";
    } else if (fileName.endsWith(".doc") || fileName.endsWith(".docx")) {
      return "far fa-file-word";
    } else if (fileName.endsWith(".JPG") || fileName.endsWith(".jpg")) {
      return "fas fa-file-image";
    } else if (fileName.endsWith(".PNG") || fileName.endsWith(".png")) {
      return "fas fa-file-image";
    } else if (fileName.endsWith(".JPEG") || fileName.endsWith(".jpeg")) {
      return "fas fa-file-image";
    }
    // Add more conditions for other file types if needed
    return "far fa-file"; // Default icon
  };

  if (mutation.isSuccess) {
    setSlideInOpenDocument(false);
  }

  return (
    <React.Fragment>
      <div className="booth__body">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="booth__body--form"
          onSubmit={handleSubmitForm}
        >
          <div className="mt-0">
            {/* ------------------ Form Fields ---------------------- */}

            <div className="booth__body--form-input-container">
              <label
                htmlFor="name"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">name*</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Document Name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>

            <div className="booth__body--form-input-container">
              <label
                htmlFor="description"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Description </p>
                <p className="input-description">Describe your document.</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="description"
                  name="description"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder=""
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                  style={{ minHeight: 100 }}
                />
              </div>
              <p className="input-heading">Session Document(s)* </p>
              <div className="file-previews-doc">
                {isAttachmentDocument?.map((document, index) => {
                  // console.log("file...", document);
                  const isImage =
                    getFileIcon(document.name) === "fas fa-file-image";
                  const imageUrl = isImage
                    ? URL.createObjectURL(document)
                    : null;
                  return (
                    <>
                      {isImage ? (
                        <div key={index} className="file-preview-doc-img">
                          <img
                            src={imageUrl}
                            alt={document.name}
                            style={{
                              width: "70px",
                              height: "60px",
                              borderRadius: "5px",
                            }}
                          />
                        </div>
                      ) : (
                        <div key={index} className="file-preview-doc">
                          <i
                            className={`${getFileIcon(
                              document.name
                            )} file-icon-doc`}
                          ></i>
                          <div className="file-details-doc">
                            <div className="file-name-doc">{document.name}</div>
                            <div className="file-size-doc">
                              {(document.size / 1024).toFixed(2)} KB
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>

              <div className="mb-3 drop-zone">
                <label htmlFor="attachment" className="drop-zone-label">
                  <i className="fas fa-cloud-upload-alt"></i>
                  <div className="drop-zone-text">Attach file(s)</div>
                </label>
                <input
                  type="file"
                  id="attachment"
                  className="form-control drop-zone-input"
                  multiple
                  accept=".pdf, .xls, .xlsx, .doc, .docx,image/*"
                  onChange={handleAttachment}
                />
              </div>
              <div
                className="sponsor-form-body__sponsor-logo"
                style={{ marginRight: "12px", marginBottom: "-16px" }}
              >
                <p
                  color="ambience.6"
                  // font-size="1"
                  className="sponsor-form-body--input-description"
                >
                  <span className="h6">Recommended format: </span>
                  PDF, WORD, EXCEL, POWERPOINT, PICTURE(400x200px) | Up to 10 MB
                </p>
              </div>
            </div>
          </div>
          <div className="next-container" style={{}}>
            <button className="btn btn-primary" type="submit">
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? <span>Submit</span> : null}
                {!mutation.isLoading ? null : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                )}
              </div>
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default AttachSessionDocument;
