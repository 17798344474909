import React from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
// import useCustomMutation from "../../../../../hooks/useCustomMutation";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";
//icons

import { useNavigate } from "react-router-dom";

const Reception = ({ token, eventID, eventDetail }) => {
  const Navigate = useNavigate();
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userName = obj?.data?.data?.firstName;

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/${eventID}`,
    token
  );

  const {
    data: getReceptionBanner,
    error: errorGetReceptionBanner,
    isLoading: isLoadingGetReceptionBanner,
  } = useCustomQuery(
    ["myEventReception", eventID],
    `${process.env.REACT_APP_API}event-reception/${eventID}`,
    token
  );
  let receptionImage = Array.isArray(getReceptionBanner);

  // console.log("eventDetail...", eventDetail);
  // console.log("getSession...", getMySessions);

  if (isLoadingGetMySessions) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }

  const handleJoinSession = (sessionName, sessionID) => {
    const sanitizedEventName = eventDetail?.eventName.replace(
      /[^a-zA-Z0-9]/g,
      "-"
    );
    const sanitizedSessionName = sessionName.replace(/[^a-zA-Z0-9]/g, "-");

    Navigate(
      `/live-session/organizer/${sanitizedEventName}/${sanitizedSessionName}/${eventID}/${sessionID}`
    );
  };

  // Render sessions based on the count
  const renderSessions = () => {
    if (!getMySessions || getMySessions.length === 0) {
      return null;
    }

    if (getMySessions.length === 1) {
      return (
        <div
          className="event-details__upcoming__sessions--session"
          style={{
            border: "1px solid #828283",
            borderRadius: "12px",
            width: "100%",
            position: "relative",
            color: "#c2c2c3",
            padding: "16px",
            background: "rgba(255, 255, 255, 0.1)",
            marginBottom: "16px",
          }}
        >
          <p style={{ color: "white", fontWeight: 600, fontSize: "13px" }}>
            {getMySessions[0]?.startTime} - {getMySessions[0]?.endTime}
          </p>
          <h4
            style={{
              color: "white",
              fontWeight: 600,
              margin: "-4px 0 10px",
            }}
          >
            {getMySessions[0]?.sessionName}
          </h4>
          {getMySessions[0]?.speakers?.map((speaker, i) => {
            return (
              <div
                key={i}
                style={{ display: "flex", gap: "8px", marginBottom: "12px" }}
              >
                <img
                  width="8%"
                  height="8%"
                  alt=""
                  style={{ borderRadius: "50%" }}
                />
                <p style={{ fontSize: "13px" }}>Shadow Sketch</p>
              </div>
            );
          })}

          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <span
              style={{
                backgroundColor: "#2d2c32",
                border: "0.8px solid #5c5c5c",
                borderRadius: "8px",
                padding: "4px 8px",
                fontSize: "12px",
              }}
            >
              Session
            </span>
            <span> &#8226;</span>
            {getMySessions[0]?.tags?.map((tag, i) => {
              return (
                <div key={i} className="d-flex">
                  <span style={{ fontSize: "13px", marginRight: "5px" }}>
                    #{tag}
                  </span>
                </div>
              );
            })}
          </div>

          <button
            style={{
              position: "absolute",
              zIndex: "999",
              top: "5%",
              right: "5%",
              backgroundColor: "#2dd173",
              color: "white",
              fontWeight: "600",
              letterSpacing: "0.8px",
              padding: "8px 16px",
              border: "none",
              borderRadius: "7px",
            }}
            onClick={() =>
              handleJoinSession(
                getMySessions[0]?.sessionName,
                getMySessions[0]?._id
              )
            }
          >
            Join now
          </button>
        </div>
      );
    }

    return getMySessions.slice(0, 2).map((session, i) => {
      return (
        <div
          key={i}
          className="event-details__upcoming__sessions--session"
          style={{
            border: "1px solid #828283",
            borderRadius: "12px",
            width: "100%",
            position: "relative",
            color: "#c2c2c3",
            padding: "16px",
            background: "rgba(255, 255, 255, 0.1)",
            marginBottom: "16px",
          }}
        >
          <p style={{ color: "white", fontWeight: 600, fontSize: "13px" }}>
            {session?.startTime} - {session?.endTime}
          </p>
          <h4
            style={{
              color: "white",
              fontWeight: 600,
              margin: "-4px 0 10px",
            }}
          >
            {session?.sessionName}
          </h4>
          {session?.speakers?.map((speaker, i) => {
            return (
              <div
                key={i}
                style={{ display: "flex", gap: "8px", marginBottom: "12px" }}
              >
                <img
                  width="8%"
                  height="8%"
                  alt=""
                  style={{ borderRadius: "50%" }}
                />
                <p style={{ fontSize: "13px" }}>Shadow Sketch</p>
              </div>
            );
          })}

          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <span
              style={{
                backgroundColor: "#2d2c32",
                border: "0.8px solid #5c5c5c",
                borderRadius: "8px",
                padding: "4px 8px",
                fontSize: "12px",
              }}
            >
              Session
            </span>
            <span> &#8226;</span>
            {session?.tags?.map((tag, i) => {
              return (
                <div key={i} className="d-flex">
                  <span style={{ fontSize: "13px", marginRight: "5px" }}>
                    #{tag}
                  </span>
                </div>
              );
            })}
          </div>

          <button
            style={{
              position: "absolute",
              zIndex: "999",
              top: "5%",
              right: "5%",
              backgroundColor: "#2dd173",
              color: "white",
              fontWeight: "600",
              letterSpacing: "0.8px",
              padding: "8px 16px",
              border: "none",
              borderRadius: "7px",
            }}
            onClick={() =>
              handleJoinSession(session?.sessionName, session?._id)
            }
          >
            Join now
          </button>
        </div>
      );
    });
  };

  const isBannerReception =
    getReceptionBanner && getReceptionBanner[0]?.bannerImage;
  return (
    <div className="reception-content">
      <div className="community">
        <div
          className="community-banner"
          style={{
            backgroundImage: receptionImage
              ? `url(${getReceptionBanner[0]?.bannerImage})`
              : null,
          }}
        >
          <div className="community-content">
            <div className="community-content__wrapper">
              {isBannerReception ? null : <h6>{eventDetail?.eventName}</h6>}
            </div>
          </div>
        </div>
      </div>

      <div className="event-details">
        <div className="event-details__welcome">
          <h2 style={{ color: "white", margin: "24px 0" }}>
            Welcome, {userName}
          </h2>
          <p style={{ color: "#c2c2c3", marginTop: "-12px", fontSize: "13px" }}>
            {eventDetail?.eventDescription}
          </p>
          {eventDetail?.videoLink ? (
            <iframe
              width="560"
              height="387"
              src={eventDetail?.videoLink}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              style={{ border: "0.4px solid #8f8f90", borderRadius: "12px" }}
            ></iframe>
          ) : null}
        </div>
        <div className="event-details__upcoming">
          <h2 style={{ color: "white", margin: "24px 0" }}>Upcoming</h2>
          <p style={{ color: "#c2c2c3", marginTop: "-12px", fontSize: "13px" }}>
            Explore what's about to come next from the session summary below.
          </p>
          <div className="event-details__upcoming__sessions">
            {renderSessions()}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Reception;
