import React, { useState, useRef, useEffect } from "react";
import { BiEdit } from "react-icons/bi";
import "./hexagonePicture.scss";

function SecondSidebar({ secondSever, setSecondSever }) {
  const [profile, setProfile] = useState(null); // Initialize profile state with null
  const refFileUpload = useRef(null);

  const onEditClick = () => {
    if (refFileUpload.current) {
      refFileUpload.current.click(); // Trigger file input click
    }
  };

  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSecondSever(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center hexagonBoxSponsors-sidebar">
      <div className="hexagonImgContainer" onClick={onEditClick}>
        {profile && (
          <img
            src={profile}
            alt="SponsorImage"
            className="w-100 h-100 section---banner---container---img"
            style={{ objectFit: "cover" }}
          />
        )}
        <BiEdit
          size={30}
          style={{
            position: "absolute",
            top: "41%",
            right: "40%",
            color: "black",
            filter: "drop-shadow(0px 0px 10px white)",
            cursor: "pointer",
          }}
        />
        <input
          type="file"
          ref={refFileUpload}
          className="file-upload d-none"
          accept="image/*"
          onChange={changeThumb}
        />
      </div>
    </div>
  );
}

export default SecondSidebar;
