import React, { useState, useEffect, useRef } from "react";

import SimpleBar from "simplebar-react";
import { useParams } from "react-router-dom";
import axios from "axios";
// import { AiOutlineSend } from "react-icons/ai";
// import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
// import { GoSmiley } from "react-icons/go";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardFooter,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";
import "./chatCommunity.scss";
import { socket } from "../../../../utils/socketConfig";

function Community({}) {
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userImg = obj?.data?.data?.photo;
  const userId = obj?.data?.data?._id;
  const { eventID } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const messagesEndRef = useRef(null);
  // console.log("obj...new", userImg);
  const [inputs, setInputs] = useState({});
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if guestUserId is available before making the API call
        if (eventID) {
          // socket.emit("join-community", eventID);

          const response = await axios.post(
            "https://orca-app-65xn9.ondigitalocean.app/api/v1/messages/community/getmsg",
            {
              communityId: eventID,
            }
          );
          setMessages(response);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchData();
  }, [eventID]);
  console.log("Messagesssssssssssssssssssssssss", messages);

  // --------------------------------------------------- handle send message -------------------------- handle send message -----------------
  const handleSendMsg = async () => {
    try {
      // Generate timestamp for the message
      const currentTime = new Date();
      const formattedTime = currentTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      socket.emit("send-community-msg", {
        communityId: eventID,
        sender: userId,
        msg: message,
        timestamp: formattedTime, // Include timestamp in the message object
      });
      await axios.post(
        "https://orca-app-65xn9.ondigitalocean.app/api/v1/messages/community/addmsg",
        {
          communityId: eventID,
          sender: userId,
          message: message,
          timestamp: formattedTime, // Include timestamp in the message object
        }
      );

      // setMessages((prevMessages) => [
      //   ...prevMessages,
      //   { fromSelf: true, message: message },
      // ]);
      setMessages([
        ...messages,
        { fromSelf: true, message: message, timestamp: formattedTime },
      ]); // Include timestamp in the message object
      setMessage(""); // Clear the input field
    } catch (error) {
      console.error("Error sending message:", error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // other than 2xx.
        console.log("Error status:", error.response.status);
        console.log("Error data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received.
        console.log("No response received");
      } else {
        // Something happened in setting up the request that triggered an Error.
        console.log("Error message:", error.message);
      }
    }
  };

  // Enter key to send message
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMsg();
    }
  };

  useEffect(() => {
    const handleMsgReceive = (msg) => {
      setArrivalMessage({
        fromSelf: false,
        message: msg.message,
        timestamp: msg.timestamp,
      });
    };

    socket.on("community-msg-recieve", handleMsgReceive);

    return () => {
      socket.off("community-msg-recieve", handleMsgReceive);
    };
  }, [socket]);

  useEffect(() => {
    if (arrivalMessage) {
      setMessages((prev) => [...prev, arrivalMessage]);
    }
  }, [arrivalMessage]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  // Function to scroll to the bottom of the chat
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Scroll to bottom on initial render and whenever messages are updated
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <MDBContainer style={{ background: "#131216" }}>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="12" lg="12" xl="12">
            <MDBCard id="chat2" style={{ borderRadius: "15px" }}>
              {/* <MDBCardHeader className="d-flex justify-content-between align-items-center p-3">
                <h5 className="mb-0">Chat</h5>
                <MDBBtn color="primary" size="sm" rippleColor="dark">
                  Let's Chat App
                </MDBBtn>
              </MDBCardHeader> */}
              <SimpleBar
                // suppressScrollX
                style={{ position: "relative", height: "400px" }}
              >
                <MDBCardBody>
                  {isLoading ? (
                    <h1>Loading...</h1>
                  ) : (
                    messages.map((msg, _idx) =>
                      msg?.fromSelf || msg?.sender === userId ? (
                        <div
                          className="d-flex flex-row justify-content-end mb-4"
                          key={_idx}
                        >
                          <img
                            src={userImg}
                            style={{
                              width: "45px",
                              height: "100%",
                              borderRadius: "50%",
                            }}
                          />
                          <div>
                            <p
                              className="small p-2 ms-3 mb-1 rounded-3"
                              style={{ backgroundColor: "#f5f6f7" }}
                            >
                              {msg?.message}
                            </p>
                            <p className="small ms-3 mb-3 rounded-3 text-muted">
                              {msg?.timestamp}{" "}
                              {/* Display the received timestamp */}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="d-flex flex-row justify-content-start mb-4"
                          key={_idx}
                        >
                          <div>
                            <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                              {msg?.message}
                            </p>
                            <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-start">
                              {msg?.timestamp}{" "}
                              {/* Display the received timestamp */}
                            </p>
                          </div>
                          <img
                            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4-bg.webp"
                            alt="avatar 1"
                            style={{ width: "45px", height: "100%" }}
                          />
                        </div>
                      )
                    )
                  )}
                  <div ref={messagesEndRef}></div>
                </MDBCardBody>
              </SimpleBar>
              <MDBCardFooter className="text-muted d-flex justify-content-end align-items-center p-3">
                <img
                  src={userImg}
                  alt="avatar 3"
                  style={{ width: "45px", height: "100%", borderRadius: "50%" }}
                />
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="exampleFormControlInput1"
                  placeholder="Type message"
                  value={message}
                  onChange={(text) => setMessage(text.target.value)}
                  onKeyPress={handleKeyPress}
                ></input>
                {/* <a className="ms-1 text-muted" href="#!">
                  <MDBIcon fas icon="paperclip" />
                </a> */}
                <a className="ms-3 text-muted" href="#!">
                  <MDBIcon fas icon="smile" />
                </a>
                <a className="ms-3" href="#!" onClick={handleSendMsg}>
                  <MDBIcon fas icon="paper-plane" />
                </a>
              </MDBCardFooter>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}

export default Community;
