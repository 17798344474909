import React, { useState, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Spinner } from "reactstrap";
import { BiEdit } from "react-icons/bi";
import "bootstrap/dist/css/bootstrap.min.css";

import useCustomMutation from "../../../../Hooks/useCustomMutation";
import { teamRoles } from "../../../../components/TeamRols/teamroles";
import ImgSession from "../../../../assets/images/banners/sessions/bannerSession.png";
import "./css/Summary.scss";
import "./css/AddSponsor.scss";

const AddEventOfficer = ({ userId, token, eventID, setSlideInOpenSponsor }) => {
  const queryClient = useQueryClient();
  const key = ["myOfficer", eventID];
  const mutation = useCustomMutation(key);
  const [logoServer, setLogoServer] = useState();
  const [profile, setProfile] = useState();
  const [inputs, setInputs] = useState({});

  // Function to handle link clicks
  const refFileUpload = useRef("");

  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLogoServer(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "POST",
      url = `auth/registration-officer/${eventID}`,
      isJSON = true;
    const formdata = new FormData();

    formdata.append("photo", logoServer);
    formdata.append("firstName", inputs.firstName);
    formdata.append("lastName", inputs.lastName);
    formdata.append("email", inputs.email);
    // formdata.append("roles", inputs.roles);
    // formdata.append("email", inputs.sponsorTier);

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Team added successfully",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  if (mutation.isSuccess) {
    queryClient.invalidateQueries(["myTeams", eventID]);
    setSlideInOpenSponsor(false);
  }
  return (
    <React.Fragment>
      <div className="sponsor-form-container">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="sponsor-form-body"
          onSubmit={handleSubmitForm}
        >
          <div className="d-flex justify-content-center">
            <div className="section---banner---container mt-2">
              <img
                src={!logoServer ? ImgSession : profile}
                alt="session Image"
                className="w-100 h-100 section---banner---container---img"
              />
              <Button
                size="md"
                variant="separator-light"
                className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
                onClick={onThumbChangeClick}
              >
                <BiEdit
                  size={40}
                  style={{
                    color: "black",
                    filter: "drop-shadow(0px 0px 10px white)",
                  }}
                />
              </Button>
              <input
                type="file"
                ref={refFileUpload}
                className="file-upload d-none"
                accept="image/*"
                onChange={changeThumb}
              />
            </div>
          </div>
          <div
            className="sponsor-form-body__sponsor-logo"
            style={{ marginRight: "12px", marginBottom: "-16px" }}
          >
            <p
              color="ambience.6"
              font-size="1"
              className="sponsor-form-body--input-description"
            >
              Recommended Team Profile Picture format: 400x400px | JPG, PNG | Up
              to 5 MB
            </p>
          </div>

          <div className="sponsor-form-body__input">
            <label htmlFor="firstName">
              <p className="sponsor-form-body--input-heading">First name*</p>
            </label>
            <input
              type="text"
              id="firstName"
              placeholder=""
              className="sponsor-form-body__input--field"
              name="firstName"
              aria-label="firstName"
              onChange={handleChange}
            />
          </div>
          <div className="sponsor-form-body__input">
            <label htmlFor="lastName">
              <p className="sponsor-form-body--input-heading">Last name*</p>
            </label>
            <input
              type="text"
              id="lastName"
              placeholder="Enter your Last name"
              className="sponsor-form-body__input--field"
              name="lastName"
              aria-label="lastName"
              onChange={handleChange}
            />
          </div>
          <div className="sponsor-form-body__input">
            <label htmlFor="email">
              <p className="sponsor-form-body--input-heading">Email</p>
            </label>
            <input
              type="text"
              id="email"
              placeholder="Enter your email"
              className="sponsor-form-body__input--field"
              name="email"
              aria-label="email"
              onChange={handleChange}
            />
          </div>

          {/* <div className="sponsor-form-body__input">
            <label htmlFor="sponsorTier">
              <p className="sponsor-form-body--input-heading">Sponsor tier*</p>
            </label>
            <select
              required
              type="text"
              id="sponsorTier"
              placeholder="Choose sponsor tier"
              className="sponsor-form-body__input--field"
              name="sponsorTier"
              aria-label="sponsorTier"
              onChange={handleChange}
            >
              <option value={null}>Select...</option>
              <option value="Institutional">Institutional</option>
              <option value="Platinum">Platinum</option>
              <option value="Gold">Gold</option>
              <option value="Silver">Silver</option>
            </select>
          </div> */}

          <button className="btn-add-sponsor" type="submit">
            <div className="d-flex justify-content-center align-items-center">
              {!mutation.isLoading ? <span>Add Event Officer</span> : null}
              {!mutation.isLoading ? null : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading
                </span>
              )}
            </div>
          </button>
        </form>
      </div>
    </React.Fragment>
  );
};
export default AddEventOfficer;
