import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";

import useCustomMutation from "../../../../../../../Hooks/useCustomMutation";
import { paymentOption } from "../../../../../../../components/paymentOption/PaymentOption";

import "../../../css/Request.scss";

const SendWithdrawRequest = ({ token, eventID, setSlideInOpenSession }) => {
  const key = ["myWitdrawalRequest", eventID];
  const mutation = useCustomMutation(key);

  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // console.log("session...inputs", inputs);
  // console.log("selectedDate...", selectedDate);

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "POST",
      url = `sessions/${eventID}`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("startTime", inputs?.startTime);
    formdata.append("duration", inputs?.duration);
    formdata.append("host", inputs?.host);
    formdata.append("tags", inputs?.sessionTags);
    formdata.append("sessionDescription", inputs?.sessionDescription);
    formdata.append("access", inputs?.access);

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Request Widtdrawal Successfully Sent",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      setSlideInOpenSession(false);
    }
  }, [mutation.isSuccess]);

  return (
    <React.Fragment>
      <div className="booth__body mx-2 mt-2">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="booth__body--form"
          onSubmit={handleSubmitForm}
        >
          <div className="mt-0">
            <div className="booth__body--form-input-container">
              <label
                htmlFor="amount"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Amount*</p>
                <p className="input-description">Put your claimed amount</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="amount"
                  name="amount"
                  type="number"
                  onChange={handleChange}
                  required
                  // placeholder="Be clear and descriptive"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="access"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Choose Method*</p>
                <p className="input-description">Choose your payment method</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <select
                  id="access"
                  name="access"
                  value={inputs.access}
                  onChange={handleChange}
                  className="single-attendee__body--form-input-container-field"
                  required
                >
                  <option value={null}> Select... </option>
                  {paymentOption.map((method, i) => (
                    <option key={i} value={method}>
                      {method}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="booth__body--form-input-container">
              <label
                htmlFor="biography"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Note</p>
                <p className="input-description">Additional Information</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <textarea
                  name="sessionDescription"
                  id="sessionDescription"
                  placeholder="Write here..."
                  onChange={handleChange}
                  style={{
                    minHeight: "120px",
                    width: "100%",
                    marginBottom: "2px",
                    position: "relative",
                    border: "1px solid rgb(154, 151, 170)",
                    borderRadius: "8px",
                    background: "transparent",
                    padding: "12px",
                  }}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="next-container" style={{}}>
            <button className="btn btn-primary" type="submit">
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? <span>Submit</span> : null}
                {!mutation.isLoading ? null : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                )}
              </div>
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default SendWithdrawRequest;
