import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../../Hooks/useCustomMutation";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal, Spinner } from "reactstrap";
import "../css/Summary.scss";

import { AiOutlineUserAdd } from "react-icons/ai";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import NoAttendees from "../../../../../assets/images/event/no-attendees.png";
import SlideInRightBar from "../../../../../components/Common/SlideInComponent/SlideInComponent";
import CustomModal from "./SlideInComponent";
// import { AiFillEye } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";

import { AiOutlineSearch } from "react-icons/ai";
// import { BiFilter } from "react-icons/bi";
import "./speakers.styles.scss";
import AddSpeaker from "../AddSpeaker";

const Speakers = ({ token, eventID }) => {
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userEmail = obj?.data?.data?.email;
  const [search, setSearch] = useState("");

  // add attendee
  const [slideInOpen, setSlideInOpen] = useState(false);
  const [speakerID, setSpeakerID] = useState(null);
  const [speakerSessionID, setSpeakerSessionID] = useState(null);
  const [speakerEmail, setSpeakerEmail] = useState(null);

  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const key = ["mySpeakers", eventID];
  const mutationDelete = useCustomMutation(key);

  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };

  const {
    data: getMySpeakers,
    error: errorGetMySpeakers,
    isLoading: isLoadingGetMySpeakers,
  } = useCustomQuery(
    ["mySpeakers", eventID],
    `${process.env.REACT_APP_API}speakers/event/${eventID}`,
    token
  );

  // console.log("getMySpeakers....@@@@......####", getMySpeakers);

  const tog_deleteParticipant = (id, sessionID, email) => {
    setSpeakerID(id);
    setSpeakerSessionID(sessionID);
    setSpeakerEmail(email);
    setIsToggleDelete(!isToggleDelete);
  };

  const DeleteParticitant = () => {
    console.log(getMySpeakers);
    const Method = "DELETE",
      url = `speakers/${speakerID}/?eventId=${eventID}&sessionId=${speakerSessionID}&email=${speakerEmail}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Speaker Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  // Function to handle link clicks
  // const handleLinkClick = (index) => {
  //   setActiveLink(index);
  // };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Logo</span>,
      selector: (row) => (
        <img
          src={row?.profileImage}
          style={{ width: "40px", margin: 3, height: "40px", borderRadius: 10 }}
        />
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Sponsor Name</span>,
      selector: (row) => (
        <>
          {row?.firstName} {row?.lastName}
        </>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email,
    },
    {
      name: <span className="font-weight-bold fs-13">Job Title</span>,
      selector: (row) => <>{row?.jobTitle}</>,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div>
            <span>
              <BiEdit size={16} className="mx-1" />
            </span>
            {/* <span>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => tog_viewSponsor(row)}
              />
            </span> */}
            <span>
              <AiFillDelete
                size={20}
                className="mx-1"
                onClick={() =>
                  tog_deleteParticipant(row?._id, row?.sessionId, row?.email)
                }
              />
            </span>
          </div>
        );
      },
    },
  ];
  const data = getMySpeakers;
  // console.log("getMySpeakers...", getMySpeakers);
  useEffect(() => {
    if (mutationDelete.isSuccess) {
      setIsToggleDelete(false);
    }
  }, [mutationDelete.isSuccess]);

  return (
    <React.Fragment>
      <div className="people-attendees__heading">
        <h3>Speakers</h3>
      </div>
      <div className="people-attendees__body">
        {/* <div className="people-attendees__body--heading">
          <h5>Registration Summary</h5>
        </div> */}
        {getMySpeakers ? (
          <React.Fragment>
            <div className="attendees-list-description-container">
              <p className="text">
                Manage all event speakers and invite speakers. Your invited
                Speakers will not affect this limit until they attend your
                event. You can also download all the registration details of
                your speakers.
              </p>
              {/* <div className="d-flex align-items-center">
                <div className="circle">!</div>
                <p
                  className="text"
                  style={{
                    color: "#21929c",
                    marginBottom: "-3px",
                    lineHeight: "1",
                  }}
                >
                  Please note that <strong>speaker</strong> count may vary from{" "}
                  <strong>registration</strong> count, speaker list may have
                  additional entries where registration is yet to be confirmed.
                </p>
              </div> */}
            </div>

            <div className="mb-2 d-flex justify-content-between w-100">
              <div className="d-flex align-items-center">
                <div
                  style={{
                    background: "#ffffff",
                    paddingTop: ".58rem",
                    paddingBottom: ".5rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    marginRight: 32,
                    border: "solid",
                    borderWidth: 0.1,
                    borderRadius: 8,
                  }}
                >
                  <AiOutlineSearch size={24} />
                  <input
                    type="text"
                    placeholder={"Search Speaker"}
                    value={search}
                    onChange={handleSearch}
                    style={{
                      border: "none",
                      marginLeft: 8,
                      paddingRight: 24,
                    }}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div
                  className="d-flex"
                  style={{
                    border: "1px solid #21929c",
                    borderRadius: "8px",
                    fontSize: 14,
                    background: "#21929c",
                    color: "#fefefe",
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: "14px",
                    height: 50,
                  }}
                  onClick={toggleSlideIn}
                  pointer="cursor"
                >
                  <p>
                    <AiOutlineUsergroupAdd
                      size={22}
                      // style={{ marginRight: 8 }}
                    />
                    <span pointer="cursor">Add speakers</span>
                    <SlideInRightBar
                      isOpen={slideInOpen}
                      title="Add Speakers"
                      toggle={toggleSlideIn}
                      body={
                        <AddSpeaker
                          // userId={userId}
                          token={token}
                          eventID={eventID}
                          setSlideInOpenSpeaker={setSlideInOpen}
                        />
                      }
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="analystic-table-card">
              <div className="w-100">
                <DataTable
                  fixedHeader
                  fixedHeaderScrollHeight="300px"
                  columns={columns}
                  responsive
                  data={data}
                  pagination
                />
              </div>
            </div>
            {/* <table className="attendees-table">
              <thead className="attendees-table__head">
                <tr className="attendees-table__row">
                  <th className="attendees-table__head--cell">Name</th>
                  <th className="attendees-table__head--cell">Email Address</th>
                  <th className="attendees-table__head--cell">Type</th>
                  <th className="attendees-table__head--cell">Session Name</th>
                  <th className="attendees-table__head--cell">Added via</th>
                  <th className="attendees-table__head--cell">
                    Registration Status
                  </th>
                  <th className="attendees-table__head--cell">Action</th>
                </tr>
              </thead>
              <tbody className="attendees-table__body">
                {getMySpeakers?.map((attendee, i) => {
                  if (attendee) {
                    return (
                      <tr className="attendees-table__row" key={i}>
                        <td className="attendees-table--data">{`${attendee.firstName} ${attendee.lastName}`}</td>
                        <td className="attendees-table--data">{`${attendee.email}`}</td>
                        <td className="attendees-table--data participant-type">
                          <i
                            className="fa fa-microphone"
                            aria-hidden="true"
                            style={{
                              marginRight: 8,
                              marginTop: 2,
                              fontSize: 14,
                            }}
                          ></i>
                          Speaker
                          <MdGroups size={16} className="mx-1" />
                        </td>
                        <td className="attendees-table--data">Session Name</td>
                        <td className="attendees-table--data">Invitation</td>
                        <td className="attendees-table--data">Registered</td>
                        <td className="attendees-table--data">
                          <span>
                            <AiFillEdit size={16} className="mx-1" />
                          </span>
                          <span>
                            <AiFillEye size={20} className="mx-1" />
                          </span>
                          <span>
                            <AiFillDelete
                              size={20}
                              className="mx-1"
                              onClick={() =>
                                tog_deleteParticipant(attendee?._id)
                              }
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table> */}
            <Modal
              size="md"
              isOpen={isToggleDelete}
              toggle={() => {
                tog_deleteParticipant();
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="mySmallModalLabel">
                  Delete Event
                </h5>
                <button
                  onClick={() => {
                    setIsToggleDelete(false);
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h5 className="text-danger">
                  Are you sure you want to delete this Speaker ?
                </h5>
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <button
                  onClick={() => {
                    setIsToggleDelete(false);
                  }}
                  type="button"
                  className="btn btn-dark"
                >
                  Cancel
                </button>
                <button
                  onClick={() => DeleteParticitant()}
                  type="button"
                  className="btn btn-danger"
                >
                  {!mutationDelete?.isLoading ? (
                    <span className="px-2">Sure</span>
                  ) : (
                    <span>
                      <Spinner as="span" animation="border" size="sm" />
                      Loading
                    </span>
                  )}
                </button>
              </div>
            </Modal>
          </React.Fragment>
        ) : (
          <div className="people-attendees__body--content">
            <img
              className="no-attendees"
              src={NoAttendees}
              alt="Showbay no attendees illustration"
              width="22%"
              height="45%"
            />
            <h3>There is no registration for this event.</h3>
            <p>
              Please go ahead and invite attendees or upload a list of confirmed
              registrants.
            </p>
            <Button onClick={toggleSlideIn}>
              <AiOutlineUserAdd size={16} />
              <span>Add speakers</span>
            </Button>
            <CustomModal
              isOpen={slideInOpen}
              toggle={toggleSlideIn}
              title="Add attendees"
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default Speakers;
