import React from "react";

import SummaryNavigation from "./SummaryNavigation";

const Main = ({ userId, token, eventID, eventName }) => {
  return (
    <div>
      <SummaryNavigation
        style={{ borderBottom: "1px solid #f3f5f7" }}
        userId={userId}
        token={token}
        eventID={eventID}
        eventName={eventName}
      />
    </div>
  );
};
export default Main;
