import React from "react";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import Main from "./Components/Main";

const Index = () => {
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userDetails = obj?.data?.data;
  const token = obj?.token;
  const params = useParams();
  const eventID = params.eventID;
  const sessionID = params.sessionID;
  const eventName = params.eventName;
  const myEventName = eventName?.replace(/-/g, " ");
  const sessionName = params.sessionName;
  const mySessionName = sessionName?.replace(/-/g, " ");

  // console.log("eventID..", eventID);
  // console.log("sessionID..", sessionID);
  // console.log("myEventName..", myEventName);
  // console.log("mySessionName..", mySessionName);

  return (
    <React.Fragment>
      <div className="page-content">
        <Main
          userDetails={userDetails}
          token={token}
          eventID={eventID}
          eventName={myEventName}
          sessionID={sessionID}
          sessionName={mySessionName}
        />
      </div>
    </React.Fragment>
  );
};

export default Index;
