import React, { useState } from "react";
import CompanySlideBar from "../../../../../../../components/Common/SlideBigModal/SlideBigBar";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AddCompanyProfile from "../../../AddCompanyProfile";
import EditCompanyProfile from "../../../EditCompanyProfile";
import { FaPhoneAlt } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

function Company({ eventID, token, boothDetails, boothID, company }) {
  const [slideInCompany, setSlideInCompany] = useState(false);
  const [slideInEdit, setSlideEdit] = useState(false);

  const toggleSlideInCompanyProfile = () => {
    setSlideInCompany(!slideInCompany);
  };

  const toggleSlideInEdit = () => {
    setSlideEdit(!slideInEdit);
  };
  // console.log("company...", company);
  return (
    <div className="mt-0">
      <div className="summary-details__content">
        <div className="summary-details__content--heading">
          <div className="w-100">
            <div className="d-flex justify-content-between">
              <h4>Company Profile</h4>
              <div>
                {company?._id && boothID && (
                  <p
                    type="button"
                    onClick={toggleSlideInEdit}
                    data-toggle="modal"
                    data-target=".bs-example-modal-lg"
                    style={{ color: "#3b5de7", userSelect: "none" }}
                  >
                    Edit
                  </p>
                )}

                {!company?._id && boothID && (
                  <p
                    type="button"
                    onClick={toggleSlideInCompanyProfile}
                    data-toggle="modal"
                    data-target=".bs-example-modal-lg"
                    style={{ color: "#3b5de7", userSelect: "none" }}
                  >
                    Add
                  </p>
                )}
              </div>
            </div>
            {company?._id && boothID && (
              <div className="w-100 d-flex mb-2">
                <div style={{ width: 200, height: 120, borderRadius: 5 }}>
                  <img
                    src={company?.companyLogo}
                    alt="Sponsor-img"
                    // className="w-100 h-100 rounded-2"
                    style={{ width: 140, height: 140, borderRadius: 5 }}
                  />
                </div>
                <div className="mx-2">
                  <p className="h5">{company?.companyName}</p>
                  <div className="">
                    {company?.companyDescription?.length > 125
                      ? `${company?.companyDescription?.substring(0, 125)}...`
                      : company?.companyDescription}
                  </div>
                  <div className="mt-0">
                    <span>
                      <FaPhoneAlt color="#4E4E4E" size={14} />
                    </span>
                    <span
                      className="mx-1"
                      style={{ fontWeight: "600", color: "#4E4E4E" }}
                    >
                      {company?.companyPhone}
                    </span>
                  </div>
                  <div className="d-flex mt-2">
                    {company?.companyFacebook ? (
                      <span className="mx-1">
                        <FaFacebook color="#4E4E4E" size={20} />
                      </span>
                    ) : null}
                    {company?.companyInstagram ? (
                      <span className="mx-1">
                        <FaInstagramSquare color="#4E4E4E" size={20} />
                      </span>
                    ) : null}

                    {company?.companyLinkdIn ? (
                      <span className="mx-1">
                        <FaLinkedin color="#4E4E4E" size={20} />
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            )}

            {!company?._id && boothID && (
              <div
                className="btn-add-speaker d-flex justify-content-center align-items-center"
                style={{
                  borderStyle: "dashed",
                  borderRadius: 5,
                  borderWidth: 1,
                  padding: 10,
                }}
              >
                <AiOutlinePlusCircle
                  size={24}
                  type="button"
                  onClick={toggleSlideInCompanyProfile}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {slideInCompany ? (
        <CompanySlideBar
          isOpen={true}
          title="Add Company Profile"
          toggle={toggleSlideInCompanyProfile}
          body={
            <AddCompanyProfile
              token={token}
              boothID={boothID}
              eventID={eventID}
              setSlideInOpenCompany={setSlideInCompany}
              // reFetchDetails={reFetchDetails}
            />
          }
        />
      ) : null}
      {/* companyDetails */}
      {slideInEdit ? (
        <CompanySlideBar
          isOpen={true}
          title="Edit Company Profile"
          toggle={toggleSlideInEdit}
          body={
            <EditCompanyProfile
              token={token}
              boothID={boothID}
              eventID={eventID}
              companyDetails={company}
              setSlideInOpenCompany={setSlideEdit}
              // reFetchDetails={reFetchDetails}
            />
          }
        />
      ) : null}{" "}
    </div>
  );
}

export default Company;
