import React, { createContext, useState } from "react";

const ContactContext = createContext();

const ContactProvider = ({ children }) => {
  const [selectedContactId, setSelectedContactId] = useState(null);

  const selectContact = (contactId) => {
    setSelectedContactId(contactId);
  };

  return (
    <ContactContext.Provider value={{ selectedContactId, selectContact }}>
      {children}
    </ContactContext.Provider>
  );
};

export { ContactContext, ContactProvider };
