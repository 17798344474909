// import { doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
// import { ChatContext } from "../context/ChatContext";
// import { db } from "../firebase";
import { data } from "./data";
import Message from "./Message";

const Messages = () => {
  const [messages, setMessages] = useState([data]);
  // const { data } = useContext(ChatContext);

  // useEffect(() => {
  //   const unSub = onSnapshot(doc(db, "chats", data.chatId), (doc) => {
  //     doc.exists() && setMessages(doc.data().messages);
  //   });

  //   return () => {
  //     unSub();
  //   };
  // }, [data.chatId]);

  // console.log(messages);

  return (
    <div className="messages--chat">
      {data.map((m) => (
        <Message message={m} key={m.id} />
      ))}
      {/* {messages.map((m) => (
        <Message message={m} key={m.id} />
      ))} */}
    </div>
  );
};

export default Messages;
