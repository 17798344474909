import React, { useState, useEffect } from "react";
import useCustomQuery from "../../../Hooks/useCustomQuery";
import "bootstrap/dist/css/bootstrap.min.css";

import { IoArrowForwardOutline } from "react-icons/io5";
import bgImg from "../../../assets/images/event/event-background-one.png";
import Loading from "../../../components/Loader/Loader";

import CustomModal from "../../../components/Common/SlideInComponent/SlideInComponent";
import HostProfile from "./Conference/HostProfile";

//Rextangle.png
import { useNavigate, Link } from "react-router-dom";

import Schedule from "./Conference/Schedule";

// import "./LandingPage.scss";

const Main = ({ userId, token, eventID, eventName }) => {
  const Navigate = useNavigate();
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userID = obj?.data?.data?._id;
  const userEmail = obj?.data?.data?.email;

  const [activeTabJustify, setactiveTabJustify] = useState("5");
  const [groupedSessions, setGroupedSessions] = useState({});

  // add attendee
  const [slideInOpen, setSlideInOpen] = useState(false);

  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isFetchGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  const {
    data: backgroundImage,
    error: errorGetMyBackgroundImage,
    isLoading: isLoadingGetMyBackgroundImage,
  } = useCustomQuery(
    ["background-landing", eventID],
    `${process.env.REACT_APP_API}event-branding/${eventID}`,
    token
  );

  const {
    data: getMyHosts,
    error: errorGetMyHosts,
    isFetching: isFetchingGetMyHosts,
  } = useCustomQuery(
    ["myHosts", eventID],
    `${process.env.REACT_APP_API}users/event-host/${eventID}`,
    token
  );

  // console.log("getMyEventDetail....", getMyEventDetail);

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/${eventID}`,
    token
  );

  // group sessions by date

  const groupSessionsByDate = (getMySessions) => {
    const groups = {};

    getMySessions.forEach((session) => {
      const sessionDate = new Date(session.sessionDate);
      const dateKey = sessionDate.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'

      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }

      groups[dateKey].push(session);
    });

    setGroupedSessions(groups);
  };

  useEffect(() => {
    if (getMySessions) {
      groupSessionsByDate(getMySessions);
    }
  }, [getMySessions]);

  const {
    data: getMySponsors,
    error: errorGetMySponsors,
    isLoading: isLoadingGetMySponsors,
  } = useCustomQuery(
    ["mySponsors", eventID],
    `${process.env.REACT_APP_API}sponsors/event/${eventID}`,
    token
  );

  const {
    data: getMySponsorsInstitutional,
    error: errorGetMySponsorsInstitutional,
    isLoading: isLoadingGetSponsorsInstitutional,
  } = useCustomQuery(
    ["mySponsorsInstitutional", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=institutional`,
    token
  );

  const {
    data: getMySponsorsPlatinum,
    error: errorGetMySponsorsPlatinum,
    isLoading: isLoadingGetMySponsorsPlatinum,
  } = useCustomQuery(
    ["mySponsorsPlatinum", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=platinum`,
    token
  );

  const {
    data: getMySponsorsGold,
    error: errorGetMySponsorsGold,
    isLoading: isLoadingGetMySponsorsGold,
  } = useCustomQuery(
    ["mySponsorsGold", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=gold`,
    token
  );

  const {
    data: getMySponsorsSilver,
    error: errorGetMySponsorsSilver,
    isLoading: isLoadingGetSponsorsSilver,
  } = useCustomQuery(
    ["mySponsorsSilver", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=silver`,
    token
  );

  const isInstitutionalSponsors =
    getMySponsorsInstitutional && getMySponsorsInstitutional?.length > 0;
  const isPlatinumSponsors =
    getMySponsorsPlatinum && getMySponsorsPlatinum?.length > 0;
  const isGoldSponsors = getMySponsorsGold && getMySponsorsGold?.length > 0;
  const isSilverSponsors =
    getMySponsorsSilver && getMySponsorsSilver?.length > 0;

  // console.log("getMyEventDetail...", getMyEventDetail);
  // console.log("getMyHosts...", getMyHosts);

  // console.log("eventID..", eventID);
  // console.log("getMySponsors..", getMySponsors);

  if (isFetchGetMyEventDetail) {
    return (
      <div className="page-content">
        <div className="mt-5 d-flex justify-content-center align-items-center">
          <Loading />
        </div>
      </div>
    );
  }
  if (isLoadingGetMySessions) {
    return (
      <div className="page-content">
        <div className="mt-5 d-flex justify-content-center align-items-center">
          <Loading />
        </div>
      </div>
    );
  }

  function toggleCustomJustified(tab) {
    if (activeTabJustify !== tab) {
      setactiveTabJustify(tab);
    }
  }
  const enterEventBtn = () => {
    return (
      <button
        className="btn waves-effect waves-light event-landing-btn "
        onClick={
          getMyEventDetail?.eventOrganizerId === userID
            ? () => handleJoinAsOrganizer()
            : getMyEventDetail?.exhibitors?.includes(userEmail)
            ? () => handleJoinAsExhibitor()
            : getMyEventDetail?.speakers?.includes(userEmail)
            ? () => handleJoinAsSpeaker()
            : getMyEventDetail?.attendees?.includes(userEmail)
            ? () => handleJoinAsAttendee()
            : null
        }
      >
        <div className="d-flex justify-content-center align-center">
          <span
            className="px-2"
            style={{ color: "#fff", fontWeight: "600", fontSize: 16 }}
          >
            Enter Event
          </span>
        </div>
      </button>
    );
  };

  const isSponsors = getMySponsors && getMySponsors?.length > 0;

  const handleJoinAsOrganizer = () => {
    Navigate(
      `/${getMyEventDetail?.eventName.replace(
        / /g,
        "-"
      )}/event-reception/${eventID}`
    );
  };

  const handleJoinAsExhibitor = () => {
    Navigate(
      `/${getMyEventDetail?.eventName.replace(
        / /g,
        "-"
      )}/exhibitor/event/reception/${eventID}`
    );
  };

  const handleJoinAsSpeaker = () => {
    Navigate(
      `/${getMyEventDetail?.eventName.replace(
        / /g,
        "-"
      )}/speaker/event/reception/:${eventID}`
    );
  };
  const handleJoinAsAttendee = () => {
    Navigate(
      `/${getMyEventDetail?.eventName.replace(
        / /g,
        "-"
      )}/event/reception/${eventID}`
    );
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Event date configuration
  let dateValue = new Date(getMyEventDetail?.startDate);

  let day = dayNames[dateValue.getUTCDay()];
  let month = monthNames[dateValue.getUTCMonth()];
  let year = dateValue.getUTCFullYear();
  let date = dateValue.getUTCDate();
  let formattedDate = `${day} ${date} ${month} ${year}`;

  let endDateValue = new Date(getMyEventDetail?.endDate);
  let endDay = dayNames[endDateValue.getUTCDay()];
  let endMonth = monthNames[endDateValue.getUTCMonth()];
  let endYear = endDateValue.getUTCFullYear();
  let endDate = endDateValue.getUTCDate();
  let formattedendDate = `${endDay} ${endDate} ${endMonth} ${endYear}`;

  // console.log("getMyHosts...", getMyHosts);

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          backgroundColor: "#000",
          userSelect: "none",
        }}
      >
        <nav
          className="event-landing-navbar"
          // style={{ position: "fixed", zIndex: 1000 }}
        >
          <ul className="event-landing-navbar__list">
            <li className="event-landing-navbar__list--item">
              <a
                href="#event-details"
                className="event-landing-navbar__list--item-link"
                style={{ color: "#fff" }}
              >
                Event Details
              </a>
            </li>
            <li className="event-landing-navbar__list--item">
              <a
                href="#overview"
                className="event-landing-navbar__list--item-link"
                style={{ color: "#fff" }}
              >
                Overview
              </a>
            </li>
            {getMyEventDetail?.eventType?.includes("Conference") ? (
              <li className="event-landing-navbar__list--item">
                <a
                  href="#schedule"
                  className="event-landing-navbar__list--item-link"
                  style={{ color: "#fff" }}
                >
                  Schedule
                </a>
              </li>
            ) : null}
            {/* {getMyEventDetail?.eventType?.includes("Exhibition") ? (
              <li className="event-landing-navbar__list--item">
                <a
                  href="#booths"
                  className="event-landing-navbar__list--item-link"
                >
                  Booths
                </a>
              </li>
            ) : null} */}
            {getMyHosts && (
              <li className="event-landing-navbar__list--item">
                <a
                  href="#hosts"
                  className="event-landing-navbar__list--item-link"
                  style={{ color: "#fff" }}
                >
                  Hosts
                </a>
              </li>
            )}
            {isSponsors && (
              <li className="event-landing-navbar__list--item">
                <a
                  href="#sponsors"
                  className="event-landing-navbar__list--item-link"
                  style={{ color: "#fff" }}
                >
                  Sponsors
                </a>
              </li>
            )}
          </ul>
        </nav>
        <div
          id="event-details"
          className="event-landing-container text-center py-5"
          style={{
            backgroundImage: `url(${
              backgroundImage ? backgroundImage?.eventBackground : bgImg
            })`,
          }}
        >
          <h1 style={{ color: "#fff" }}>{getMyEventDetail?.eventName}</h1>
          {getMyHosts && (
            <h5
              className="py-2 "
              style={{ color: "#fff", fontWeight: "normal" }}
            >
              Event by{" "}
              {getMyHosts?.organizationName ? (
                getMyHosts?.organizationName
              ) : (
                <>
                  {getMyHosts?.firstName} {getMyHosts?.lastName}
                </>
              )}
            </h5>
          )}
          <span style={{ color: "#fff", fontSize: "10px", marginBottom: 8 }}>
            EVENT DATE{" "}
          </span>
          <h5 style={{ color: "#fff" }}>
            {formattedDate} - {formattedendDate}
          </h5>
          <table
            style={{
              borderCollapse: "collapse",
              width: "300px",
              margin: "0 auto",
              color: "#fff",
            }}
          >
            <tr>
              <td style={{ whiteSpace: "nowrap" }}>
                <span style={{ fontSize: "12px" }}>START TIME</span>
                <br />

                <span
                  style={{
                    padding: "8px 12px",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {`${getMyEventDetail.startTime} SAST`}
                </span>
              </td>
              <td
                style={{ whiteSpace: "nowrap", borderLeft: "1px solid #fff" }}
              >
                <span style={{ fontSize: "12px" }}>END TIME</span>
                <br />

                <span
                  style={{
                    padding: "8px 12px",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {`${getMyEventDetail.endTime} SAST`}
                </span>
              </td>
            </tr>
          </table>

          {getMyEventDetail?.eventOrganizerId === userID ? (
            <>{enterEventBtn()}</>
          ) : null}

          {getMyEventDetail?.exhibitors?.includes(userEmail) ? (
            <>{enterEventBtn()}</>
          ) : null}

          {getMyEventDetail?.speakers?.includes(userEmail) ? (
            <>{enterEventBtn()}</>
          ) : null}

          {getMyEventDetail?.attendees?.includes(userEmail) ? (
            <>{enterEventBtn()}</>
          ) : null}
          <div className="event-landing-banner-container">
            <div
              className="shadow-sm event-landing-banner-wrapper"
              // style={{ overflow: "hidden" }}
            >
              <img
                src={getMyEventDetail?.bannerImage}
                //  className="card-brd"
              />
            </div>
          </div>

          <div
            id="overview"
            className="overview "
            style={{
              borderStyle: "solid",
              borderWidth: "0 2px 2px 0",
              borderColor: "transparent #1a87a3 #1a87a3 transparent",
            }}
          >
            <h3 style={{ color: "#fff" }}>Overview</h3>
            <p style={{ color: "#fff" }}>{getMyEventDetail.eventDescription}</p>
          </div>
          {getMyEventDetail?.eventType?.includes("Conference") ? (
            <div
              id="schedule"
              className="hosts"
              style={{
                borderStyle: "solid",
                borderWidth: "0 0px 2px 0",
                borderColor: "transparent transparent #1a87a3 transparent",
              }}
            >
              <h3 style={{ color: "#fff" }}>Schedule</h3>
              <Schedule mySchedule={getMySessions} />
            </div>
          ) : null}

          {getMyHosts && (
            <div
              id="hosts"
              className="hosts text-dark"
              style={{
                borderStyle: "solid",
                borderWidth: "0 0px 2px 0",
                borderColor: "transparent transparent #1a87a3 transparent",
              }}
            >
              <h3 style={{ color: "#fff" }}>Hosts</h3>
              <div className="host">
                {/* <div className="host--profile-img"> */}
                <img
                  src={getMyHosts?.photo}
                  alt="host-pic"
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "50%",
                  }}
                />
                {/* {getMyEventDetail?.eventOrganizerId?.firstName.substring(0, 1)} */}
                {/* </div> */}
                <p className="host--name" style={{ color: "#fff" }}>
                  {getMyHosts?.organizationName ? (
                    getMyHosts?.organizationName
                  ) : (
                    <>
                      {getMyHosts?.firstName} {getMyHosts?.lastName}
                    </>
                  )}
                </p>
                <p
                  className="host--profile-slider"
                  style={{ color: "#1a87a3" }}
                  onClick={toggleSlideIn}
                >
                  View Profile {"  "}
                  <IoArrowForwardOutline size={16} />
                  <CustomModal
                    isOpen={slideInOpen}
                    toggle={toggleSlideIn}
                    title="Host Profile"
                    body={<HostProfile hostDetails={getMyHosts} />}
                  />
                </p>
              </div>
            </div>
          )}
          {isSponsors ? (
            <div id="sponsors" className="event-sponsors">
              <h3 style={{ color: "#fff" }}>Sponsors</h3>
              {isInstitutionalSponsors ? (
                <>
                  <h5 className="mt-5" style={{ color: "#fff" }}>
                    INSTITUTIONAL
                  </h5>
                  <div className="d-flex">
                    {getMySponsorsInstitutional?.map((sponsor, i) => {
                      return (
                        <div className="event-sponsors__item" key={i}>
                          <img
                            src={sponsor?.sponsorLogo}
                            alt="Showbay sponsor banner"
                            style={{
                              borderStyle: "solid",
                              borderWidth: "2px 2px 2px 2px",
                              borderColor: "#1a87a3",
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : null}
              {isPlatinumSponsors ? (
                <>
                  <h5 className="mt-5" style={{ color: "#fff" }}>
                    PLATINUM
                  </h5>
                  <div className="d-flex" style={{ color: "#fff" }}>
                    {getMySponsorsPlatinum?.map((sponsor, i) => {
                      return (
                        <div className="event-sponsors__item" key={i}>
                          <img
                            src={sponsor?.sponsorLogo}
                            alt="Showbay sponsor banner"
                            style={{
                              borderStyle: "solid",
                              borderWidth: "2px 2px 2px 2px",
                              borderColor: "#1a87a3",
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : null}
              {isGoldSponsors ? (
                <>
                  <h5 className="mt-5" style={{ color: "#fff" }}>
                    GOLD
                  </h5>
                  <div className="d-flex">
                    {getMySponsorsGold?.map((sponsor, i) => {
                      return (
                        <div className="event-sponsors__item" key={i}>
                          <img
                            src={sponsor?.sponsorLogo}
                            alt="Showbay sponsor banner"
                            style={{
                              borderStyle: "solid",
                              borderWidth: "2px 2px 2px 2px",
                              borderColor: "#1a87a3",
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : null}
              {isSilverSponsors ? (
                <>
                  <h5 className="mt-5" style={{ color: "#fff" }}>
                    SILVER
                  </h5>
                  <div className="d-flex">
                    {getMySponsorsSilver?.map((sponsor, i) => {
                      return (
                        <div
                          className="event-sponsors__item"
                          // style={{ border: "1px solid" }}
                          key={i}
                        >
                          <img
                            src={sponsor?.sponsorLogo}
                            alt="Showbay sponsor banner"
                            style={{
                              borderStyle: "solid",
                              borderWidth: "2px 2px 2px 2px",
                              borderColor: "#1a87a3",
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : null}
            </div>
          ) : null}

          {/* <div id="support" className="support">
            <p>
              In case you need any support, kindly drop us <br /> a message at{" "}
              <a
                href={`mailto:${getMyEventDetail?.eventOrganizerId?.email}`}
                style={{ color: "#faa200" }}
              >
                {getMyEventDetail?.eventOrganizerId?.email}
              </a>
            </p>
            <p>
              Powered by{" "}
              <a
                href="https://www.showbay24.com"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                showbay24.com
              </a>
            </p>
            <p>
              Powered by{" "}
              <span style={{ textDecoration: "underline" }}>showbay24.com</span>
            </p>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Main;
