import React, { useState } from "react";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import SlideInRightBar from "../../../../../../components/Common/SlideInComponent/SlideInComponent";
import { Row, Col, Spinner } from "reactstrap";

import AddBooth from "../../Addbooth";
function Booth({ eventID, token, userId }) {
  const [slideInOpenBooth, setSlideInOpenBooth] = useState(false);
  const toggleSlideInBooth = () => {
    setSlideInOpenBooth(!slideInOpenBooth);
  };

  const {
    data: getMyBooths,
    error: errorGetMyBooths,
    isLoading: isLoadingGetMyBooths,
  } = useCustomQuery(
    ["myBooths", eventID],
    `${process.env.REACT_APP_API}exhibitors/${eventID}`,
    token
  );

  if (errorGetMyBooths) {
    return <></>;
  }

  if (isLoadingGetMyBooths) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }
  const getLastTwoBooths = (myBooths) => {
    if (myBooths.length >= 2) {
      return myBooths.slice(-2);
    }

    if (myBooths.length === 1) {
      return [myBooths[0]];
    }
    return [];
  };

  let lastTwoBooths = [];
  if (getMyBooths) {
    lastTwoBooths = getLastTwoBooths(getMyBooths);
  }

  return (
    <div className="summary-setup__content">
      <table>
        <tr>
          <td
            className="d-flex justify-content-between"
            style={{ color: "#3b5de7" }}
          >
            <h5>Booth</h5>
            <div className="">
              {/* <p type="button" onClick={toggleSlideInSession}> */}
              <p type="button" onClick={toggleSlideInBooth}>
                Add Booth
              </p>
            </div>
          </td>
          {/* <td style={{ borderLeft: "1px solid #232633" }}>
                  <h5>Speakers</h5>
                </td> */}
        </tr>
        {lastTwoBooths?.map((lastTwoBooths, i) => {
          return (
            <tr key={i}>
              <td
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: 10,
                }}
              >
                <Row>
                  <Col md={4}>
                    <div className="session-date">
                      <span>{lastTwoBooths?.sessionDate}</span>
                      <h5>{lastTwoBooths?.boothNumber}</h5>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="session-date">
                      <span>{lastTwoBooths?.sessionDate}</span>
                      <p>Assigned to</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="d-flex px-2 session-name">
                      <i
                        className="fa fa-user"
                        aria-hidden="true"
                        style={{
                          marginRight: 8,
                          marginTop: 2,
                          fontSize: 20,
                        }}
                      ></i>
                      <div>
                        <span style={{ fontSize: 11, fontWeight: 600 }}>
                          {lastTwoBooths?.firstName} {lastTwoBooths?.lastName}
                        </span>
                        <p>{lastTwoBooths?.email}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </td>
            </tr>
          );
        })}
      </table>
      {slideInOpenBooth ? (
        <SlideInRightBar
          isOpen={slideInOpenBooth}
          title="Add Booth"
          toggle={toggleSlideInBooth}
          body={
            <AddBooth
              userId={userId}
              token={token}
              eventID={eventID}
              setSlideInOpenBooth={setSlideInOpenBooth}
            />
          }
        />
      ) : null}
    </div>
  );
}

export default Booth;
