import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/Summary.scss";

import SlideInRightBar from "../../../../../../components/Common/SlideInComponent/SlideInComponent";
import SendRequest from "./components/SendWithdrawRequest";
import AllRequest from "./components/AllRequest";

const Withdrawal = ({ userId, token, eventID }) => {
  const [slideInOpen, setSlideInOpen] = useState(false);
  const key = ["myEventDetail", eventID];
  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };
  return (
    <React.Fragment>
      <div className="column registration-content">
        <div className="registration-content__heading ">
          <div>
            <h2>Withdrawal</h2>
            <span className="registration-content__faded">
              Submit your withdrawal request after the completion of your event.
            </span>
          </div>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={toggleSlideIn}
          >
            <div className="d-flex justify-content-center align-center ">
              <span className="px-2">Request</span>
            </div>
          </button>
        </div>
        <div className="mt-2">
          <AllRequest />
        </div>

        {/* <div className="registration-content__body">
          <div className="registration-content__body--no-tickets">
            <img
              src={NoTickets}
              alt="Showbay no tickets illustration"
              width="50%"
              height="50%"
            />
            <h3>Request Once the Event is done</h3>
            <p className="registration-content__faded">
              By adding Request for withdrawal you accept our terms and
              conditions.
            </p>
          </div>
        </div> */}
        <SlideInRightBar
          isOpen={slideInOpen}
          toggle={toggleSlideIn}
          title="Send Withdrawal Request"
          body={
            <SendRequest
              userId={userId}
              token={token}
              eventID={eventID}
              setSlideInOpenSession={setSlideInOpen}
            />
          }
        />
      </div>
    </React.Fragment>
  );
};
export default Withdrawal;
