import React, { useState, useEffect, useRef } from "react";
import usePost from "../../../../Hooks/usePost";
import useCustomMutation from "../../../../Hooks/useCustomMutation";

// actions

import {
  Spinner,
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
// import

import avatar from "../../../../assets/images/users/User.png";

const Profile = ({
  userId,
  name,
  lastName,
  isEventOrganizer,
  photo,
  token,
}) => {
  const { execute, pending, data, status } = usePost();
  const refFileUpload = useRef("");
  const [picture, setPicture] = useState(photo);
  const [serverPicture, setServerPicture] = useState();
  const [isBtnClick, setIsBtnClick] = useState(false);
  const key = ["myProfile", userId];
  const mutation = useCustomMutation(key);

  const SaveBtn = () => {
    const Method = "PATCH",
      url = `users/updateMe`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("photo", serverPicture);
    execute(url, formdata, Method, "Profile Successfully Updated", token, true);
  };

  const toggleChange = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };

  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setServerPicture(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setIsBtnClick(true);
        setPicture(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  //   console.log("data after submition", mutation?.data?.data?.data);

  useEffect(() => {
    if (status === "success") {
      setIsBtnClick(false);
      const user = data;
      if (user) {
        localStorage.clear();
        localStorage.setItem("authShowbayUser", JSON.stringify(user));
      }
    }
  }, [status]);

  return (
    <div className="d-flex justify-content-between">
      <div className="w-100 d-flex">
        <div className="profile-img-container">
          <img src={picture ? picture : avatar} alt="picture-user" />
        </div>

        <div className="d-flex justify-content-between align-items-center mx-3 profile-container-main">
          <div>
            <div className="profile-container-Title">
              {name} {lastName}
            </div>
            <div className="profile-container-subtitle">
              {isEventOrganizer ? "Event Organizer" : "Participant"}
            </div>
          </div>
          {isBtnClick ? (
            <>
              <button
                className="btn btn-primary rounded-3"
                onClick={() => SaveBtn()}
              >
                {pending ? (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                ) : (
                  "Save Change"
                )}
              </button>
            </>
          ) : (
            <div style={{ position: "relative" }}>
              <button
                className="btn btn-dark rounded-3"
                onClick={() => toggleChange()}
              >
                Change Picture
              </button>
              <input
                type="file"
                ref={refFileUpload}
                className="file-upload d-none"
                accept="image/*"
                onChange={changeThumb}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Profile;
