import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { MdMarkEmailRead } from "react-icons/md";

const Confirmation = ({ eventDetails }) => {
  const Navigate = useNavigate();

  const handleRedirection = () => {
    Navigate("/dashboard");
  };
  return (
    <div className="d-flex justify-content-center">
      <div>
        <div className="mt-4">
          <div className="w-100 d-flex justify-content-center">
            <MdMarkEmailRead size={40} color="#0ac074" />
          </div>
          <div className="w-100 d-flex justify-content-center">
            <p style={{ fontSize: 16, textAlign: "justify", width: 500 }}>
              Thank you for purchasing a ticket to{" "}
              <span style={{ fontWeight: "600" }}>
                {eventDetails?.eventName}
              </span>{" "}
              We look forward to welcoming you on. Your presence makes our event
              extraordinary.
            </p>
          </div>
        </div>
        <div className="mt-3 d-flex justify-content-center">
          <Button
            type="submit"
            variant="contained"
            onClick={() => handleRedirection()}
          >
            Ok
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
