import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../../Hooks/useCustomMutation";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal, Spinner } from "reactstrap";
import "../css/Summary.scss";

import { AiOutlineUserAdd } from "react-icons/ai";
import { AiOutlineUsergroupAdd } from "react-icons/ai";

import NoAttendees from "../../../../../assets/images/event/no-attendees.png";

import CustomModal from "./SlideInComponent";
import { AiFillEye } from "react-icons/ai";
import { AiFillEdit } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";

import { AiOutlineSearch } from "react-icons/ai";
// import { BiFilter } from "react-icons/bi";
import "./attendees.styles.scss";

const Attendees = ({ token, eventID }) => {
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userEmail = obj?.data?.data?.email;
  const [search, setSearch] = useState("");

  // add attendee
  const [slideInOpen, setSlideInOpen] = useState(false);
  const [attendeeID, setAttendeeID] = useState(false);
  const [attendEmail, setAttendeeEmail] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const key = ["myAttendees", eventID];
  const mutationDelete = useCustomMutation(key);

  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };

  const {
    data: getAttendees,
    error: errorGetAttendees,
    isLoading: isLoadingGeAttendees,
  } = useCustomQuery(
    ["myAttendees", eventID],
    `${process.env.REACT_APP_API}attendees/${eventID}/invited`,
    token
  );

  const tog_deleteParticipant = (id, email) => {
    setAttendeeID(id);
    setAttendeeEmail(email);
    setIsToggleDelete(!isToggleDelete);
  };

  const DeleteParticitant = () => {
    const Method = "DELETE",
      url = `attendees/${attendeeID}/?eventId=${eventID}&email=${attendEmail}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Attendee Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  // console.log("getAttendees...", getAttendees);

  // Function to handle link clicks
  // const handleLinkClick = (index) => {
  //   setActiveLink(index);
  // };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const columns = [
    // {
    //   name: <span className="font-weight-bold fs-13">Logo</span>,
    //   selector: (row) => (
    //     <img
    //       src={row?.sponsorLogo}
    //       style={{ width: "50px", height: "50px", borderRadius: 10 }}
    //     />
    //   ),
    //   sortable: true,
    // },
    {
      name: <span className="font-weight-bold fs-13">First Name</span>,
      selector: (row) => row?.firstName,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Last Name</span>,
      selector: (row) => row?.lastName,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email,
      sortable: true,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: (row) => {
        return (
          <div>
            <span>
              <BiEdit size={16} className="mx-1" />
            </span>
            {/* <span>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => tog_viewSponsor(row)}
              />
            </span> */}
            <span>
              <AiFillDelete
                size={20}
                className="mx-1"
                onClick={() => tog_deleteParticipant(row?._id, row?.email)}
              />
            </span>
          </div>
        );
      },
    },
  ];
  const data = getAttendees;

  useEffect(() => {
    if (mutationDelete.isSuccess) {
      setIsToggleDelete(false);
    }
  }, [mutationDelete.isSuccess]);

  return (
    <React.Fragment>
      <div className="people-attendees__heading">
        <h3>Attendees</h3>
        {/* <span className="text">Manage your attendees from here</span> */}
      </div>
      <div className="people-attendees__body mb-5">
        {/* <div className="people-attendees__body--heading">
          <h5>Registration Summary</h5>
        </div> */}
        {getAttendees ? (
          <>
            {/* <React.Fragment> */}
            <div className="attendees-list-description-container">
              <p className="text">
                Manage all event attendees and invite attendees. Your invited
                attendees will not affect this limit until they attend your
                event. You can also download all the registration details of
                your attendees.
              </p>
              <div className="d-flex align-items-center">
                <div className="circle">!</div>
                <p
                  className="text"
                  style={{
                    color: "#21929c",
                    marginBottom: "-3px",
                    lineHeight: "1",
                  }}
                >
                  Please note that <strong>attendee</strong> count may vary from{" "}
                  <strong>registration</strong> count, attendee list may have
                  additional entries where registration is yet to be confirmed.
                </p>
              </div>
            </div>

            <div className="mb-5 d-flex justify-content-between w-100">
              <div className="d-flex align-items-center">
                <div
                  style={{
                    background: "#ffffff",
                    paddingTop: ".58rem",
                    paddingBottom: ".5rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    marginRight: 32,
                    border: "solid",
                    borderWidth: 0.1,
                    borderRadius: 8,
                  }}
                >
                  <AiOutlineSearch size={24} />
                  <input
                    type="text"
                    placeholder={"Search Attendee"}
                    value={search}
                    onChange={handleSearch}
                    style={{
                      border: "none",
                      marginLeft: 8,
                      paddingRight: 24,
                    }}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div
                  className="d-flex"
                  style={{
                    border: "1px solid #21929c",
                    borderRadius: "8px",
                    fontSize: 14,
                    background: "#21929c",
                    color: "#fefefe",
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: "14px",
                    height: 50,
                  }}
                  onClick={toggleSlideIn}
                  pointer="cursor"
                >
                  <p>
                    <AiOutlineUsergroupAdd
                      size={22}
                      // style={{ marginRight: 8 }}
                    />
                    <span pointer="cursor">Add attendees</span>
                    <CustomModal
                      isOpen={slideInOpen}
                      toggle={toggleSlideIn}
                      title="Add attendees"
                    />
                  </p>
                </div>
              </div>
            </div>

            <div className="analystic-table-card">
              <div className="w-100">
                <DataTable
                  fixedHeader
                  fixedHeaderScrollHeight="300px"
                  columns={columns}
                  responsive
                  data={data}
                  pagination
                />
              </div>
            </div>

            <Modal
              size="md"
              isOpen={isToggleDelete}
              toggle={() => {
                tog_deleteParticipant();
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="mySmallModalLabel">
                  Delete Event
                </h5>
                <button
                  onClick={() => {
                    setIsToggleDelete(false);
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h5 className="text-danger">
                  Are you sure you want to delete this Attendee ?
                </h5>
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <button
                  onClick={() => {
                    setIsToggleDelete(false);
                  }}
                  type="button"
                  className="btn btn-dark"
                >
                  Cancel
                </button>
                <button
                  onClick={() => DeleteParticitant()}
                  type="button"
                  className="btn btn-danger"
                >
                  {!mutationDelete?.isLoading ? (
                    <span className="px-2">Sure</span>
                  ) : (
                    <span>
                      <Spinner as="span" animation="border" size="sm" />
                      Loading
                    </span>
                  )}
                </button>
              </div>
            </Modal>
          </>
        ) : (
          // </React.Fragment>
          <div className="people-attendees__body--content">
            <img
              className="no-attendees"
              src={NoAttendees}
              alt="Showbay no attendees illustration"
              width="22%"
              height="45%"
            />
            <h3>There is no registration for this event.</h3>
            <p>
              Please go ahead and invite attendees or upload a list of confirmed
              registrants.
            </p>
            <Button onClick={toggleSlideIn}>
              <AiOutlineUserAdd size={16} />
              <span>Add attendee</span>
            </Button>
            <CustomModal
              isOpen={slideInOpen}
              toggle={toggleSlideIn}
              title="Add attendees"
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default Attendees;
