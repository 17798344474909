import React, { useRef, useState } from "react";
import "./Analystics.scss";
import { Row, Col } from "reactstrap";
// import "../css/Summary.scss";
import AttendeeCard from "./components/AttendeeCard";
import RegisterUsers from "./components/RegisteredUsers";
import EventRatings from "./components/EventRatings";
import TicketsSales from "./components/TicketsSales";
import LiveSession from "./components/LiveSessions";
import SessionsRatings from "./components/SessionsRatings";
import LiveBooths from "./components/LiveBooth";
import BoothsRatings from "./components/BoothsRatings";
import AllAttendeeReviews from "./components/AllAttendeeReviews";
import AttendeesRates from "./components/AttendeesRates";
import SessionsReviews from "./components/SessionsReviews";
import SessionsRates from "./components/SessionsRates";
import BoothReviews from "./components/BoothReviews";
import BoothRates from "./components/BoothRates";
import ActivityReviews from "./components/ActivityReviews";
import ActivityRates from "./components/ActivityRates";

function Analytics({ userId, token, eventID }) {
  const cardRef = useRef();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  return (
    <React.Fragment>
      <div className="p-5 analytics-container">
        <Row>
          <Col md={2}>
            <AttendeeCard token={token} eventID={eventID} />
          </Col>
          <Col md={6}>
            <Row>
              <Col md={6}>
                <RegisterUsers token={token} eventID={eventID} />
              </Col>
              <Col md={6}>
                <EventRatings token={token} eventID={eventID} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <LiveSession token={token} eventID={eventID} />
              </Col>
              <Col md={6}>
                <SessionsRatings token={token} eventID={eventID} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <LiveBooths token={token} eventID={eventID} />
              </Col>
              <Col md={6}>
                <BoothsRatings />
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <TicketsSales token={token} eventID={eventID} />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <AllAttendeeReviews />
          </Col>
          <Col md={4}>
            <AttendeesRates />
          </Col>
        </Row>
        {/* <Row>
          <Col md={8}>
            <SessionsReviews />
          </Col>
          <Col md={4}>
            <SessionsRates />
          </Col>
          <Col md={8}>
            <BoothReviews />
          </Col>
          <Col md={4}>
            <BoothRates />
          </Col>
          <Col md={8}>
            <ActivityReviews />
          </Col>
          <Col md={4}>
            <ActivityRates />
          </Col>
        </Row> */}
      </div>
    </React.Fragment>
  );
}

export default Analytics;
