import React, { useState } from "react";
import LinearStepper from "./components/TalkToUsComponents/LinearStepper";
import { Container, Paper, Box } from "@material-ui/core";
import { MdMarkEmailRead } from "react-icons/md";
import CustomHeader from "../../components/Common/CustomTopBar";
import { FormProvider } from "../../helpers/formstate.context";
import "./components/TalkToUsComponents/talkToUs.scss";
import { Link } from "react-router-dom";

const TalkToUs = () => {
  document.title = "Showbay 24 | Talk To Us";
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  return (
    <>
      <CustomHeader />

      <div className="bodyContainer">
        <Container
          className="d-flex justify-content-center"
          component={Box}
          p={4}
          style={{ filter: "drop-shadow(0 0 0.4rem rgba(0,0,0,.382))" }}
        >
          <Paper
            className="text-center"
            component={Box}
            p={3}
            style={{ borderRadius: "16px", width: "max-content" }}
          >
            {isFormSubmitted ? (
              <>
                <p className="h1" style={{ color: "#5E5E5E" }}>
                  Success
                </p>
                <div className="d-flex justify-content-center">
                  <MdMarkEmailRead size={70} color="#0ac074" />
                </div>
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "500",
                    textAlign: "justify",
                  }}
                >
                  Thank you for Submitting your Request<br></br> Our Team will
                  be in touch with you shortly<br></br>{" "}
                </p>
                <div className="d-flex justify-content-center">
                  <Link to="/login"> go back to login</Link>
                </div>
              </>
            ) : (
              <FormProvider>
                <LinearStepper setIsFormSubmitted={setIsFormSubmitted} />
              </FormProvider>
            )}
          </Paper>
        </Container>
      </div>
    </>
  );
};

export default TalkToUs;
