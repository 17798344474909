import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SimpleBar from "simplebar-react";
import "./ActivityBody.scss"; // Create a CSS file for styling
import { MdAccessTime } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa";
import { AddToCalendarButton } from "add-to-calendar-button-react";

const SessionBody = ({ session }) => {
  // console.log("session...body...", session);
  const toggleDownloadDoc = () => {};
  let isAdditionalMaterial = session && session?.additionalMaterial;
  const givenSessionStartDate = new Date(session?.sessionDate);
  const year = givenSessionStartDate?.getFullYear();
  const month = String(givenSessionStartDate.getMonth() + 1).padStart(2, "0");
  const day = String(givenSessionStartDate.getDate()).padStart(2, "0");

  const date = `${year}-${month}-${day}`;
  return (
    <React.Fragment>
      <SimpleBar style={{ height: "43em" }}>
        <div className="booth___body">
          <div className="w-100 d-flex">
            <div className="booth___img">
              <img
                src={session?.sessionLogo}
                className="w-100 h-100"
                alt="session-img"
                style={{ borderRadius: "12px" }}
              />
            </div>
            <div className="booth___extra p-2" style={{ color: "#fff" }}>
              {/* <div className="w-100 d-flex justify-content-end"></div> */}
              <div className="w-100 mt-1">
                <h4 style={{ color: "#fff" }}>Session Time</h4>
                <span>
                  <MdAccessTime size={16} color="#e4e5e6" />
                </span>
                <span className="mx-1" style={{ color: "#e4e5e6" }}>
                  {session?.startTime} - {session?.endTime}
                </span>
              </div>
              <div className="w-100 mt-2">
                {isAdditionalMaterial ? (
                  <>
                    <h4 style={{ color: "#fff" }}>Session Document</h4>

                    <div className="w-100 d-flex">
                      {session?.additionalMaterial?.map((material, i) => {
                        return (
                          <div className="w-100 d-flex mt-1">
                            <a
                              href={material}
                              download={material}
                              key={i}
                              target="_blank"
                            >
                              <FaFilePdf size={90} color="#1a87a3" />
                              <span style={{ textDecorationLine: "underline" }}>
                                download here
                              </span>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <div>Session Host Profile</div>
                )}
              </div>
              <div
                style={{
                  // position: "absolute",
                  // zIndex: "1001",
                  // top: "64%",
                  // right: "2%",
                  color: "white",
                  fontWeight: "600",
                  letterSpacing: "0.8px",
                  border: "none",
                }}
              >
                <AddToCalendarButton
                  name={session?.sessionName}
                  options={[
                    "Apple",
                    "Google",
                    "Outlook.com",
                    "iCal",
                    "Microsoft365",
                  ]}
                  location={session?.sessionLocation}
                  startDate={date}
                  endDate={date}
                  startTime={session?.startTime}
                  endTime={session?.endTime}
                  // timeZone={timeZone}
                >
                  Add To Calendar
                </AddToCalendarButton>
              </div>
            </div>
          </div>
          {session?.speakers?.length > 0 ? (
            <div className="w-100 mt-2">
              <h4 style={{ color: "#fff" }}>Session Speaker(s)</h4>
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  margin: "24px 16px 2px 0",
                  paddingBottom: "18px",
                  alignItems: "center",
                  borderRight: "1px solid rgba(255, 255, 255, 0.2)",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                }}
              >
                {session?.speakers?.map((speaker, i) => {
                  // console.log("speaker..", speaker);
                  return (
                    <div
                      className="d-flex justify-content-center align-items-center h3"
                      key={i}
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        backgroundColor: "#1a87a3",
                        color: "white",
                        zIndex: i,
                        // border: "2px solid white",
                      }}
                    >
                      {speaker?.profileImage ? (
                        <img
                          src={speaker?.profileImage}
                          className="w-100 h-100"
                          style={{ borderRadius: "50%" }}
                        />
                      ) : (
                        `${speaker?.firstName?.substring(0, 1)}`
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          <div className="w-100">
            <h4 style={{ color: "#fff" }}>Session Description</h4>
            <div>
              <p
                style={{
                  color: "#e4e5e6",
                  fontWeight: "normal",
                  fontSize: "14px",
                  // margin: "16px auto",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                {session?.sessionDescription}
              </p>
            </div>
          </div>
          <div
            className="with-exhibitors"
            style={{
              background: "#4E4E4E",
              color: "#131216",
              borderRadius: "7px",
              padding: "4px 8px",
              fontSize: "10px",
              marginBottom: "-12px",
              width: "max-content",
              // marginLeft: "240px",
            }}
          >
            {session?.tags?.map((tag, i) => {
              return (
                <span style={{ color: "white" }} key={i}>
                  {tag}
                </span>
              );
            })}
          </div>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SessionBody;
