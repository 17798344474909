import React from "react";
import "../css/Schedule.scss";

function Sessions({ sessions }) {
  // console.log("sessions...schedule", sessions);

  return (
    <div>
      {sessions.map((session, index) => {
        // console.log("session....", session);
        return (
          <div key={index} className="w-100 ">
            <div className="mb-3 p-3 w-100 d-flex session---landing---card text-dark">
              <div className="vertical---landing---line"></div>
              <div>
                <div className="d-flex session---landing---time">
                  <div>
                    <i
                      className="fa fa-clock session---landing---time"
                      aria-hidden="true"
                    ></i>
                    <span>{session?.sessionDate}</span>{" "}
                    <span>{session?.startTime}</span>
                    {"-"}
                    <span>{session?.endTime}</span>
                  </div>
                </div>
                <div className="mt-2 session---landing---title">
                  <p>{session?.sessionName}</p>
                </div>
                <div className="d-flex">
                  {session?.speakers?.map((speaker, i) => {
                    // console.log("speaker..", speaker);
                    return (
                      <div
                        className="d-flex justify-content-center align-items-center h5"
                        key={i}
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          backgroundColor: "#1a87a3",
                          color: "white",
                          marginLeft: "-7px",
                          zIndex: i,
                          border: "2px solid white",
                        }}
                      >
                        {speaker?.profileImage ? (
                          <img
                            src={speaker?.profileImage}
                            alt="speaker-picture"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <>{speaker?.firstName}</>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="session---landing---time">
                  <i
                    className="fas fa-map-marker-alt"
                    aria-hidden="true"
                    // style={{ marginRight: 8, marginTop: 2, fontSize: 14 }}
                  ></i>
                  <span>Online</span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Sessions;
