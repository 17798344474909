import React, { useState } from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";
import "./css/Summary.scss";

// import { useHistory, useParams } from "react-router-dom";

import "./css/booth.scss";

const AddBooth = ({ userId, token, eventID, setSlideInOpenBooth }) => {
  const key = ["myBooths", eventID];
  const mutation = useCustomMutation(key);
  const [isLiveBroadcast, setIsLiveBroadcast] = useState(false);

  const [inputs, setInputs] = useState({});

  const [modal_large, setmodal_large] = useState(false);

  const [activeLink, setActiveLink] = useState(null);

  // add attendee
  const [slideInOpen, setSlideInOpen] = useState(false);

  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `https://orca-app-65xn9.ondigitalocean.app/api/v1/events/${eventID}`,
    token
  );

  // console.log("getMyEventDetail...", getMyEventDetail);
  // console.log("getSession...", getMySessions);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  if (isLoadingGetMyEventDetail) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // if (isLoadingGetMySessions) {
  //   <div className="mt-5 d-flex justify-content-center align-items-center">
  //     <Spinner animation="border" role="status">
  //       <span className="visually-hidden">Loading...</span>
  //     </Spinner>
  //   </div>;
  // }

  // Function to handle link clicks
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if (event.target.type === "file") {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = function (e) {
        setInputs((values) => ({ ...values, [name]: e.target.result }));
      };

      reader.readAsDataURL(file);
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();

    // console.log("inputs...", inputs);

    const Method = "POST",
      url = `exhibitors/${eventID}`,
      isJSON = false;
    const raw = JSON.stringify({
      boothNumber: inputs.boothNumber,
      firstName: inputs.exhibitorFirstName,
      lastName: inputs.exhibitorLastName,
      email: inputs.exhibitorEmail,
      organizationNumber: inputs.exhibitorContact,
      organization: inputs.boothOrganization,
      city: inputs.exhibitorCity,
      // exhibitionDate,
    });

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Booth Successfully Created",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  if (mutation?.isSuccess) {
    setSlideInOpenBooth(false);
  }

  return (
    <React.Fragment>
      <div className="booth__body">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="booth__body--form"
          enctype="multipart/form-data"
          onSubmit={handleSubmitForm}
        >
          <div className="mt-0">
            <div className="booth__body--form-input-container">
              <label
                htmlFor="boothNumber"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Booth Reference / Location*</p>
                <p className="input-description">
                  Add reference number to your booth.
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="boothNumber"
                  name="boothNumber"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Booth Reference / Location"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="exhibitorFirstName"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Exhibitor First Name </p>
                {/* <p className="input-description">
                  Create a tag or select from the ones available.
                </p> */}
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="exhibitorFirstName"
                  name="exhibitorFirstName"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter exhibitor first name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="exhibitorLastName"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Exhibitor Last Name </p>
                {/* <p className="input-description">
                  Create a tag or select from the ones available.
                </p> */}
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="exhibitorLastName"
                  name="exhibitorLastName"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter exhibitor last name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="exhibitorEmail"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Exhibitor Email</p>
                <p className="input-description">
                  On saving changes, an invitation link will be automatically{" "}
                  <br />
                  sent to the email addresses added or modified.
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="exhibitorEmail"
                  name="exhibitorEmail"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter exhibitor email address"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="boothOrganization"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Exhibitor Organization</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="boothOrganization"
                  name="boothOrganization"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Organization Name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="exhibitorContact"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Exhibitor Contact Number</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="exhibitorContact"
                  name="exhibitorContact"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter exhibitor phone number"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="exhibitorCity"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading"> City</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="exhibitorCity"
                  name="exhibitorCity"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter exhibitor city"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="exhibitionDate"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading"> Date</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="exhibitionDate"
                  name="exhibitionDate"
                  type="date"
                  onChange={handleChange}
                  required
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
          </div>

          {/* ------------------ Features ---------------------- */}
          <div className="booth__body--form__features">
            <h5>Features</h5>
            <div className="booth__body--form__features--options">
              <div className="booth__body--form__features--options-option">
                <div className="booth__body--form__features--options-option-label">
                  <div>
                    <p className="input-heading">Live Session</p>
                  </div>
                  <div className="booth__body--form__features--options-option-label__toggle-container">
                    <label htmlFor="liveBroadcast">
                      <div className="booth__body--form__features--options-option-label__toggle">
                        <div className="booth__body--form__features--options-option-label__toggle--switch">
                          <input
                            type="checkbox"
                            id="liveBroadcast"
                            name="liveBroadcast"
                            checked={isLiveBroadcast}
                            onClick={() => {
                              setIsLiveBroadcast(!isLiveBroadcast);
                            }}
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                  {/* handleLiveBroadcast */}
                </div>
                <p className="input-description">
                  Choose whether this booth will host a live session
                </p>
              </div>

              {isLiveBroadcast ? (
                <div className="booth__body--form-input-container field-col-half">
                  <label
                    htmlFor="sessionDuration"
                    className="booth__body--form-input-container__label"
                  >
                    <p className="input-heading">Session Duration</p>
                    <p className="input-description w-100">
                      Specify in minutes the time the session will last
                    </p>
                  </label>
                  <div className="single-attendee__body--form-input-container gzIdbH">
                    <input
                      id="sessionDuration"
                      name="sessionDuration"
                      type="number"
                      onChange={handleChange}
                      required
                      placeholder="0 minutes"
                      className="single-attendee__body--form-input-container-field"
                      onFocus={(e) => e.target.classList.add("active")}
                      onBlur={(e) =>
                        e.target.value === "" &&
                        e.target.classList.remove("active")
                      }
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="next-container" style={{ width: 250 }}>
            <button className="btn btn-success" type="submit">
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? (
                  <span className="text-light">Create</span>
                ) : null}
                {!mutation.isLoading ? null : (
                  <div className="d-flex">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      className="text-light"
                    />
                    <span className="text-light">Loading</span>
                  </div>
                )}
              </div>
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default AddBooth;
