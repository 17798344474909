// FormContext.js
import React, { createContext, useState } from "react";

const FormContext = createContext();

const FormProvider = ({ children }) => {
  const [formInputs, setFormInputs] = useState({});

  const updateFormInputs = (newInputs) => {
    setFormInputs({ ...formInputs, ...newInputs });
  };

  return (
    <FormContext.Provider value={{ formInputs, updateFormInputs }}>
      {children}
    </FormContext.Provider>
  );
};

export { FormProvider, FormContext };
