/* eslint-disable no-unused-expressions */

import { useState } from "react";
import {
  dangerMessage,
  successMessage,
  warningMessage,
} from "../components/Toast/Toast";

const usePost = () => {
  const [postData, updatePostData] = useState({
    pending: false,
    data: undefined,
    error: undefined,
    lengths: undefined,
    status: null,
  });

  const execute = async (
    endPoint,
    raw,
    Method,
    displayMessage,
    token,
    isJSON
  ) => {
    updatePostData({
      pending: true,
      data: undefined,
      error: undefined,
      lengths: undefined,
      status: null,
    });

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    isJSON ? null : myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: Method,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(`${process.env.REACT_APP_API}${endPoint}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("usePost.....", res);
        if (res?.status === "success") {
          // console.log("data..", res);
          updatePostData({
            pending: false,
            data: res,
            error: undefined,
            lengths: res.result,
            status: res?.status,
          });
          displayMessage === true ? null : successMessage(`${displayMessage}`);
        }
        if (res?.status === "fail") {
          updatePostData({
            pending: false,
            data: undefined,
            error: res?.message,
            lengths: undefined,
            status: res?.status,
          });
          warningMessage(`${res?.message}`);
        }
        if (res?.status === "error") {
          updatePostData({
            pending: false,
            data: undefined,
            error: res?.message,
            lengths: undefined,
          });
          // if (res?.message === "jwt malformed") {
          //   warningMessage("Registered successful");
          // } else {
          //   warningMessage("Network Issue Please try again");
          // }
        }
      })
      .catch((error) => {
        // console.log("usePost.....", error);
        updatePostData({
          pending: false,
          data: undefined,
          error: error.message,
          lengths: undefined,
        });
        dangerMessage(`${error.message}`);
      });
  };

  return {
    execute,
    pending: postData.pending,
    data: postData.data,
    status: postData.status,
    error: postData.error,
    lengths: postData.lengths,
  };
};

export default usePost;
