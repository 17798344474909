import React, { useEffect, useState } from "react";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";

export default function JoinConference({
  userDetails,
  token,
  eventID,
  sessionID,
  eventName,
  sessionName,
  eventDetail,
}) {
  // console.log("eventID...", eventID);
  // console.log("sessionID...", sessionID);

  // const userFullName = `${user?.data?.data?.firstName} ${user?.data?.data?.lastName}`;
  // console.log("userId...@@@@@@@@@@@@@@@@@@@", userDetails?._id);
  // console.log("eventDetail?.bannerImage...", eventDetail?.bannerImage);
  const userFullName = `${userDetails?.firstName} ${userDetails?.lastName}`;

  useEffect(() => {
    const apiKey = "b6d0b019-fd1d-4773-ae33-547dd07381d6";
    // const apiKey = process.env.REACT_APP_VIDEOSDK_API_KEY;
    // const meetingId = `Conference/${eventName}/${sessionName}/${eventID}/${sessionID}`;
    const meetingId = `Conference-${eventID}-${sessionID}`;
    const name = `${userFullName}`;
    // console.log("apiKey...", apiKey);

    const config = {
      name: name,
      meetingId: meetingId,
      apiKey: apiKey,

      region: "sg001", // region for new meeting

      containerId: null,
      redirectOnLeave: `https://demo.showbay24.com/${eventDetail?.eventName?.replace(
        / /g,
        "-"
      )}/event-reception/${eventID}`,

      micEnabled: true,
      webcamEnabled: true,
      participantCanToggleSelfWebcam: true,
      participantCanToggleSelfMic: true,
      participantCanLeave: true, // if false, leave button won't be visible

      chatEnabled: true,
      screenShareEnabled: true,
      pollEnabled: true,
      whiteboardEnabled: true,
      raiseHandEnabled: true,

      recording: {
        autoStart: false, // auto start recording on participant joined
        enabled: false,
        webhookUrl: "https://www.videosdk.live/callback",
        awsDirPath: `/meeting-recordings/conference/${meetingId}/`, // automatically save recording in this s3 path
      },

      livestream: {
        autoStart: true,
        enabled: true,
      },

      layout: {
        type: "SPOTLIGHT", // "SPOTLIGHT" | "SIDEBAR" | "GRID"
        priority: "SPEAKER", // "SPEAKER" | "PIN",
        // gridSize: 3,
      },

      branding: {
        enabled: false,
        logoURL: eventDetail?.bannerImage,
        name: sessionName,
        poweredBy: false,
      },

      permissions: {
        pin: true,
        askToJoin: false, // Ask joined participants for entry in meeting
        canCreatePoll: true,
        toggleParticipantMic: true, // Can toggle other participant's mic
        toggleParticipantWebcam: true, // Can toggle other participant's webcam
        drawOnWhiteboard: true, // Can draw on whiteboard
        toggleWhiteboard: true, // Can toggle whiteboard
        toggleRecording: true, // Can toggle meeting recording
        toggleLivestream: true, //can toggle live stream
        removeParticipant: true, // Can remove participant
        endMeeting: true, // Can end meeting
        changeLayout: true, //can change layout
      },

      joinScreen: {
        visible: true, // Show the join screen ?
        title: sessionName, // Meeting title
        // meetingUrl: window.location.href, // Meeting joining url
      },

      leftScreen: {
        // visible when redirect on leave not provieded
        actionButton: {
          // optional action button
          label: "Showbay 24", // action button label
          href: `https://showbay-24-web.vercel.app/${eventDetail?.eventName?.replace(
            / /g,
            "-"
          )}/event-reception/${eventID}`, // action button href
        },
      },

      notificationSoundEnabled: true,

      debug: true, // pop up error during invalid config or netwrok error

      maxResolution: "hd", // "hd" or "sd"
      // you can change made to conference or viewer
      mode: "CONFERENCE",
      // For more features check: /prebuilt/guide/prebuilt-video-and-audio-calling/getting-started

      //Set Session Close And Time
      // autoCloseConfig: {
      //   type: "session-end-and-deactivate",
      //   duration: 10,
      // },
    };

    const meeting = new VideoSDKMeeting();
    meeting.init(config);
  }, []);

  return <div></div>;
}
