import React, { useState, useRef, useEffect } from "react";
import useCustomMutation from "../../../../../../../Hooks/useCustomMutation";
import { Spinner } from "reactstrap";
import Landing1 from "../../../../../../../assets/images/event/landing1.png";
import Landing2 from "../../../../../../../assets/images/event/landing2.png";
function LandingForm({ eventID, setUploadLandingBanner }) {
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userId = obj?.data?.data?._id;
  const token = obj?.token;
  const key = ["background-landing", eventID];
  const mutation = useCustomMutation(key);
  const [logoServer, setLogoServer] = useState();
  const [profile, setProfile] = useState();
  const [selectedEventImage, setSelectedEventImage] = useState(null);
  const selectEventImage = (imageSrc) => {
    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "landing.png", { type: "image/png" });
        setLogoServer(file); // Set the file as logoServer
        setProfile(imageSrc); // Update the profile to show the selected image
        setSelectedEventImage(imageSrc); // Set the selected image URL
      });
  };
  const handleSubmitForm = (event) => {
    event.preventDefault();

    const Method = "POST",
      url = `event-branding/${eventID}/?oid=${userId}`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("eventBackground", logoServer);
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Background Successfully Set",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };
  useEffect(() => {
    if (mutation?.isSuccess) {
      setUploadLandingBanner(false);
    }
  }, [mutation?.isSuccess]);

  return (
    <div>
      <div className="mt-3 mb-3 h5 text-center">
        Select Event Background Image
      </div>
      <div className="w-100 d-flex justify-content-center">
        {[Landing1, Landing2].map((backgroundEventImage, index) => (
          <img
            key={index}
            src={backgroundEventImage}
            alt={`Booth ${index + 1}`}
            onClick={() => selectEventImage(backgroundEventImage)}
            style={{
              width: "150px",
              height: "150px",

              cursor: "pointer",
              border:
                selectedEventImage === backgroundEventImage
                  ? "2px solid red"
                  : "1px solid black",
              borderRadius: "8px",
              marginRight: "10px",
            }}
          />
        ))}
      </div>
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        enctype="multipart/form-data"
        onSubmit={handleSubmitForm}
      >
        <div className="mt-3  mb-3 w-100 d-flex justify-content-center">
          <button className="btn btn-primary" type="submit">
            <div className="d-flex justify-content-center align-items-center">
              {!mutation.isLoading ? (
                <span className="text-light">Submit</span>
              ) : null}
              {!mutation.isLoading ? null : (
                <div className="d-flex">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    className="text-light"
                  />
                  <span className="text-light">Loading</span>
                </div>
              )}
            </div>
          </button>
        </div>
      </form>
    </div>
  );
}

export default LandingForm;
