import React, { useState, useRef, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useCustomMutation from "../../../../../Hooks/useCustomMutation";
import { Spinner, Button } from "reactstrap";

import { BiEdit } from "react-icons/bi";

function EditBanner({
  userId,
  token,
  eventID,
  eventDetails,
  setShowEditEvent,
}) {
  const queryClient = useQueryClient();
  const [isPicture, setIsPicture] = useState(false);
  const [Banner, setBanner] = useState(false);
  const [BannerServer, setBannerServer] = useState();

  // connnect to api
  const key = ["myEvents", userId];

  const mutation = useCustomMutation(key);

  // image upload

  let iconStyles = {
    color: "#1a87a3",
    filter: "drop-shadow(0px 0px 10px white)",
    backgroundColor: "white",
    borderRadius: 5,
  };

  const refFileUpload = useRef("");

  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setBannerServer(event.target.files[0]);
      setIsPicture(false);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setBanner(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  // handleValidSubmit

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "PATCH",
      url = `events/${eventID}`,
      isJSON = true;
    var formdata = new FormData();
    // console.log("BannerServer...", BannerServer);
    if (BannerServer) {
      formdata.append("bannerImage", BannerServer);
    }

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Banner Successfully Updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      queryClient.invalidateQueries(["myEventDetail", eventID]);
      setShowEditEvent(false);
    }
    if (eventDetails) {
      if (eventDetails?.bannerImage) {
        setBannerServer(eventDetails?.bannerImage);
        setIsPicture(true);
      }
    }
  }, [mutation.isSuccess, eventDetails]);
  // console.log("eventID......", eventID);
  return (
    <div className="d-flex justify-content-center">
      <div className="w-100 justify-content-center">
        <div className="w-100 d-flex justify-content-center">
          <div className="event__form__banner_container">
            <img
              // src={isPicture ? BannerServer : ConferenceBanner}
              src={isPicture ? BannerServer : Banner}
              alt="eventBanner"
              className="Image_Fit event__form__banner_img "
            />
            <Button
              size="md"
              variant="separator-light"
              className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
              onClick={onThumbChangeClick}
            >
              <BiEdit size={40} style={iconStyles} />
            </Button>
            <input
              type="file"
              ref={refFileUpload}
              className="file-upload d-none"
              accept="image/*"
              onChange={changeThumb}
            />
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center mt-4">
          <form className="form-create-event" onSubmit={handleSubmitForm}>
            <div className="d-flex justify-content-center align-item-center">
              <div className="d-flex justify-content-center align-item-center">
                <button className="btn btn-primary" type="submit">
                  <div className="d-flex justify-content-center align-items-center">
                    {!mutation.isLoading ? <span>Update Banner</span> : null}
                    {!mutation.isLoading ? null : (
                      <span>
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        Loading
                      </span>
                    )}
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditBanner;
