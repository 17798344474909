import React, { useState } from "react";
import Main from "./componenets/Main";
import SponsorsTab from "./componenets/SponsorsTab/SponsorTab";
import ProductsTab from "./componenets/ProductsTab/ProductTab";
import LeadsTab from "./componenets/LeadsTab/LeadsTab";

function Index({
  eventID,
  token,
  boothDetails,
  boothID,
  boothInvite,
  eventDetails,
  company,
  sponsors,
  products,
  leads,
}) {
  const [activeLink, setActiveLink] = useState(0);
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  return (
    <div className="people-container">
      <div className="column people-menu">
        <ul className="people-menu__list">
          <li className="people-menu__item">
            <a
              href="#"
              className={`people-menu__link ${
                activeLink === 0 ? "people-menu__list--active" : ""
              }`}
              onClick={() => handleLinkClick(0)}
            >
              Summary
            </a>
          </li>
          <li className="people-menu__item">
            <a
              href="#"
              className={`people-menu__link ${
                activeLink === 2 ? "people-menu__list--active" : ""
              }`}
              onClick={() => handleLinkClick(2)}
            >
              Sponsors
            </a>
          </li>
          <li className="people-menu__item">
            <a
              href="#"
              className={`people-menu__link ${
                activeLink === 4 ? "people-menu__list--active" : ""
              }`}
              onClick={() => handleLinkClick(4)}
            >
              Products
            </a>
          </li>
          <li className="people-menu__item">
            <a
              href="#"
              className={`people-menu__link ${
                activeLink === 6 ? "people-menu__list--active" : ""
              }`}
              onClick={() => handleLinkClick(6)}
            >
              Leads
            </a>
          </li>
          {/* <li className="people-menu__item">
            <a
              href="#"
              className={`people-menu__link ${
                activeLink === 8 ? "people-menu__list--active" : ""
              }`}
              onClick={() => handleLinkClick(8)}
            >
              Reviews
            </a>
          </li> */}
        </ul>
      </div>
      <div className="column people-content">
        {activeLink === 0 && (
          <Main
            eventID={eventID}
            token={token}
            boothDetails={boothDetails}
            boothID={boothID}
            boothInvite={boothInvite}
            eventDetails={eventDetails}
            sponsors={sponsors}
            company={company}
            products={products}
          />
        )}
        {activeLink === 2 && (
          <SponsorsTab
            boothID={boothID}
            eventID={eventID}
            token={token}
            sponsors={sponsors}
          />
        )}
        {activeLink === 4 && (
          <ProductsTab
            boothID={boothID}
            eventID={eventID}
            token={token}
            products={products}
          />
        )}
        {activeLink === 6 && (
          <LeadsTab
            boothID={boothID}
            eventID={eventID}
            token={token}
            leads={leads}
          />
        )}
        {/* {activeLink === 4 && <Reiviews />} */}
      </div>
    </div>
  );
}

export default Index;
