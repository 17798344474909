import PropTypes from "prop-types";
import React, { useState } from "react";
import { Spinner } from "reactstrap";

import banner from "../../assets/images/banners/login/login.png";
import Logo from "../../assets/images/showbay-color.png";

import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

import usePost from "../../Hooks/usePost";
import ConfirmationMessage from "./components/ConfirmMessage/ConfirmationMessage";
// import { warningMessage } from "../../components/Toast/Toast";

import "./components/Login.scss";
const Login = (props) => {
  document.title = "Showbay 24| Forgot Password";
  const { execute, pending, data } = usePost();
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleValidSubmit = async (e) => {
    e.preventDefault();
    const Method = "POST",
      endPoint = "auth/forgotPassword",
      token = null;
    const raw = JSON.stringify({
      email: inputs.email,
    });
    execute(endPoint, raw, Method, true, token);
  };

  if (data?.status === "success") {
    return (
      <div className="page-content">
        <ConfirmationMessage />
      </div>
    );
    // dispatch(loginUser(data, props.router.navigate));
  }

  return (
    <React.Fragment>
      <div className="login">
        <div className="login__form">
          <div className="login__form__container">
            <div className="login__form__container--header">
              <img src={Logo} width="44%" alt="Showbay24 dark logo" />
            </div>
            <div className="login__form__container--body">
              <div className="login__form__container--body__heading">
                <h2>Forgot Password</h2>
                <p>Please Enter your email below</p>
              </div>
              <form onSubmit={handleValidSubmit}>
                <div className="form-input-group">
                  <div className="w-100">
                    <label className="form-input-group--label" htmlFor="email">
                      Email Address
                    </label>
                    <div className="form-input-group__input-box">
                      <input
                        className="input form-input-group__input-box--field"
                        name="email"
                        id="email"
                        autoComplete="off"
                        type="email"
                        feedbacktext="The entered email address is invalid."
                        message="Email Address"
                        maxLength="75"
                        color="#333"
                        aria-required="false"
                        aria-describedby="feedback_loginEmail"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                {!pending ? (
                  <button className="btn-login" type="submit" id="logInAttempt">
                    Send
                  </button>
                ) : (
                  <button className="btn-login" type="submit" id="logInAttempt">
                    <span>
                      <Spinner as="span" animation="border" size="sm" /> Loading
                    </span>
                  </button>
                )}

                <div className="mt-5 mb-5">
                  <p>
                    Already have an account ?{" "}
                    <Link
                      // to="/register"
                      to="/login"
                      className="fw-medium"
                      style={{ color: "#1c213a" }}
                    >
                      Login{" "}
                    </Link>{" "}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="login__slider">
          {/* <button className="btn-signup" type="submit" id="logInAttempt">
            Sign In
          </button> */}
          <div className="login-page-slider">
            <img
              className="login-page-slider--image"
              src={banner}
              alt="Showbay24 login page slider"
            />
            <div className="login-page-slider__description">
              <h3>Attend or host to captivate.</h3>
              <p>
                Our dedicated support team always has your back, <br />
                no matter the day or time (no bots here!)
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
