import React, { useState } from "react";
import { Modal } from "reactstrap";
import { FaQuestionCircle } from "react-icons/fa";
import ShowVideoLink from "../../ShowVideoLink";
import UploadVideoLink from "../../UploadVideoLink";

function VideoLink({ userId, token, eventID, eventDetails }) {
  const [showUploadVideoLink, setShowUploadVideoLink] = useState(false);
  const toggle_uploadVideoLink = () => {
    setShowUploadVideoLink(!showUploadVideoLink);
  };
  return (
    <div className="summary-details__content">
      <div className="summary-details__settings--heading">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div className="pt-1">
              <h5>Video Introduction Link</h5>
            </div>
            <div className="mx-2 pt-1">
              {/* <Tooltip
                      placement="top"
                      isOpen={ttop}
                      // target="videoLinkInfo"
                      toggle={() => {
                        setttop(!ttop);
                      }}
                    >
                      Tooltip on top
                    </Tooltip> */}
              <FaQuestionCircle size={18} id="videoLinkInfo" />
            </div>
          </div>

          {eventDetails?.videoLink ? (
            <p
              type="button"
              onClick={() => {
                toggle_uploadVideoLink();
              }}
              data-toggle="modal"
              data-target=".bs-example-modal-lg"
              style={{ color: "#3b5de7" }}
            >
              Edit Link
            </p>
          ) : null}
        </div>
      </div>
      {eventDetails?.videoLink ? (
        <iframe
          width="460"
          height="200"
          src={eventDetails?.videoLink}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          style={{ border: "0.4px solid #8f8f90", borderRadius: "12px" }}
        ></iframe>
      ) : (
        <ShowVideoLink
          userId={userId}
          token={token}
          eventID={eventID}
          eventDetails={eventDetails}
          setShowVideoLink={setShowUploadVideoLink}
        />
      )}
      <Modal
        size="lg"
        isOpen={showUploadVideoLink}
        toggle={() => {
          toggle_uploadVideoLink();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Paste Video Link
          </h5>
          <button
            onClick={() => {
              setShowUploadVideoLink(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <UploadVideoLink
            userId={userId}
            token={token}
            eventID={eventID}
            setShowVideoLink={setShowUploadVideoLink}
          />
        </div>
      </Modal>
    </div>
  );
}

export default VideoLink;
