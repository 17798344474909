import React, { useState } from "react";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import { Spinner } from "reactstrap";
import AddActivity from "../../AddActivity";
import SlideInRightBar from "../../../../../../components/Common/SlideInComponent/SlideInComponent";
import "../../css/Summary.scss";
import "../../css/summary.styles.scss";
import { AiOutlinePlusCircle } from "react-icons/ai";

function Sessions({ eventID, token, userId }) {
  const [slideInOpenSession, setSlideInOpenSession] = useState(false);
  const [slideInOpenSpeaker, setSlideInOpenSpeaker] = useState(false);
  const toggleSlideInSession = () => {
    setSlideInOpenSession(!slideInOpenSession);
  };
  const toggleSlideInSpeaker = () => {
    setSlideInOpenSpeaker(!slideInOpenSpeaker);
  };

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isFetching: isFetchingGetMySessions,
  } = useCustomQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/${eventID}`,
    token
  );

  if (errorGetMySessions) {
    return <></>;
  }
  if (isFetchingGetMySessions) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }

  const getLastTwoSessions = (mySessions) => {
    if (mySessions.length >= 2) {
      return mySessions.slice(-2);
    }

    if (mySessions.length === 1) {
      return [mySessions[0]];
    }
    return [];
  };

  let lastTwoSessions = [];
  if (getMySessions) {
    lastTwoSessions = getLastTwoSessions(getMySessions);
  }

  const toggleRightModal = () => {
    setSlideInOpenSession(!slideInOpenSession);
  };
  return (
    <div className="summary-setup__content">
      <div className="summary-setup__affiliates--sponsors">
        <div className="d-flex justify-content-end">
          <p
            type="button"
            className="text-info"
            onClick={() => setSlideInOpenSession(!slideInOpenSession)}
          >
            Add Activity
          </p>
        </div>
        {lastTwoSessions?.map((activity, i) => {
          let description = "";
          let data = "";
          // console.log("activity...", activity);
          if (activity?.sessionDescription?.length > 20) {
            description = activity?.sessionDescription.substring(0, 20);
          } else {
            description = activity?.sessionDescription;
          }
          return (
            <>
              {i > 0 ? <hr className="text-dark"></hr> : null}
              <div className="d-flex w-100">
                <div>
                  <div className="activity--session--img--container">
                    <img
                      src={activity?.sessionLogo}
                      alt="Activity Image"
                      className="activity--session--img"
                    />
                  </div>
                </div>
                <div className="verticalLine"></div>
                <div className="activity--session--text">
                  <div className="">
                    <div className="d-flex justify-content-between">
                      <h4>{activity?.sessionName}</h4>
                    </div>
                    <p>{description}</p>
                    <div className="d-flex">
                      <i
                        className="fas fa-clock"
                        aria-hidden="true"
                        style={{
                          marginRight: 8,
                          marginTop: 2,
                          fontSize: 14,
                        }}
                      ></i>
                      <p>{activity?.sessionDate?.substring(0, 15)}</p>
                    </div>
                    <div className="d-flex">
                      {/* <i
                        className="fas fa-map-marker-alt"
                        aria-hidden="true"
                        style={{
                          marginRight: 8,
                          marginTop: 2,
                          fontSize: 14,
                        }}
                      ></i> */}
                      {/* <p>{eventDetails?.eventFormat}</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      {/* <table>
        <tr>
          <td
            className="d-flex justify-content-between"
            style={{ color: "#3b5de7" }}
          >
            <h5>Schedule</h5>
            <div className="">
              <p type="button" onClick={toggleRightModal}>
                Add Session{" "}
              </p>
            </div>
          </td>
          <td style={{ borderLeft: "1px solid #232633" }}>
            <h5>Speakers</h5>
          </td>
        </tr>
        {lastTwoSessions[0] ? (
          <tr>
            <td
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: 10,
              }}
            >
              <div className="session-date">
                <span>
                  {lastTwoSessions[0]?.sessionDate?.substring(7, 11)}
                  {lastTwoSessions[0]?.sessionDate?.substring(4, 8)}
                </span>
                <p>
                  {lastTwoSessions[0]?.startTime} -{" "}
                  {lastTwoSessions[0]?.endTime}
                </p>
              </div>
              <div className="d-flex px-2 session-name">
                <i
                  className="fa fa-microphone"
                  aria-hidden="true"
                  style={{ marginRight: 8, marginTop: 2, fontSize: 20 }}
                ></i>
                <div>
                  <span style={{ fontSize: 11, fontWeight: 600 }}>
                    {lastTwoSessions[0]?.sessionName}
                  </span>
                  <p>{lastTwoSessions[0]?.object?.substring(0, 7)}</p>
                </div>
              </div>
            </td>
            <td style={{ borderLeft: "1px solid #232633" }}>
              <div className="adding-speakers">
                <div className="d-flex">
                  <AiOutlinePlusCircle
                    size={40}
                    style={{ marginLeft: 8 }}
                    type="button"
                    onClick={toggleSlideInSpeaker}
                  />
                </div>
              </div>
            </td>
          </tr>
        ) : null}

        <tr>
          <td
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontSize: 10,
            }}
          >
            {lastTwoSessions[1] ? (
              <>
                <div className="session-date">
                  <span>
                    {lastTwoSessions[1]?.sessionDate?.substring(7, 11)}
                    {lastTwoSessions[1]?.sessionDate?.substring(4, 8)}
                  </span>
                  <p>
                    {lastTwoSessions[1]?.startTime} -{" "}
                    {lastTwoSessions[1]?.endTime}
                  </p>
                </div>
                <div className="d-flex px-2 session-name">
                  <i
                    className="fa fa-microphone"
                    aria-hidden="true"
                    style={{ marginRight: 8, marginTop: 2, fontSize: 20 }}
                  ></i>
                  <div>
                    <span style={{ fontSize: 11, fontWeight: 600 }}>
                      {lastTwoSessions[1]?.sessionName}
                    </span>
                    <p>{lastTwoSessions[1]?.object?.substring(0, 7)}</p>
                  </div>
                </div>
              </>
            ) : null}
          </td>
        </tr>
      </table> */}
      {slideInOpenSession ? (
        <SlideInRightBar
          isOpen={slideInOpenSession}
          title="Add Activities"
          toggle={toggleSlideInSession}
          body={
            <AddActivity
              userId={userId}
              token={token}
              eventID={eventID}
              setSlideInOpenSession={setSlideInOpenSession}
            />
          }
        />
      ) : null}
    </div>
  );
}

export default Sessions;
