import React, { useState, useEffect } from "react";
import Sessions from "./Sessions";

function Schedule({ mySchedule }) {
  const [selectedDate, setSelectedDate] = useState("");
  const [sessions] = useState(mySchedule);

  // Function to format the date
  const formatDate = (date) => {
    const d = new Date(date);
    const day = d.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[d.getMonth()];
    return `${day} ${month}`;
  };

  // Categorize sessions by day and month
  const categorizedSessions = sessions.reduce((acc, session) => {
    const dateString = formatDate(session.sessionDate);
    if (!acc[dateString]) {
      acc[dateString] = [];
    }
    acc[dateString].push(session);
    return acc;
  }, {});

  // Set initial selected date
  useEffect(() => {
    const initialDate = Object.keys(categorizedSessions)[0];
    setSelectedDate(initialDate);
  }, [sessions]);

  const handleBoxClick = (dateString) => {
    setSelectedDate(dateString);
  };

  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {Object.keys(categorizedSessions).map((dateString, index) => (
          <div
            key={index}
            onClick={() => handleBoxClick(dateString)}
            style={{
              // backgroundColor: "#297ea6",
              backgroundColor: "#297ea6",
              width: 120,
              height: 70,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 10,
              padding: 10,
              fontSize: 20,
              border: selectedDate === dateString ? "2px solid #faa200" : null,
              cursor: "pointer",
              userSelect: "none",
            }}
            className="schedule__date mb-4"
          >
            {dateString}
          </div>
        ))}
      </div>
      {selectedDate && (
        <Sessions sessions={categorizedSessions[selectedDate]} />
      )}
    </div>
  );
}

export default Schedule;
