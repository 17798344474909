import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import DataTable from "react-data-table-component";
import useCustomMutation from "../../../../../../../Hooks/useCustomMutation";
import SlideInRightBar from "../../../../../../../components/Common/SlideInComponent/SlideInComponent";
import * as XLSX from "xlsx";
import { Modal, Spinner } from "reactstrap";
import { AiFillEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";

function LeadsTab({ boothID, token, leads, eventID }) {
  const queryClient = useQueryClient();
  const key = ["exhibtitor-ProductsBooth", eventID];

  const [leadID, setLeadID] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const [slideInOpen, setSlideInOpen] = useState(false);
  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };
  const mutationDelete = useCustomMutation(key);
  const tog_deleteParticipant = (id) => {
    setLeadID(id);
    setIsToggleDelete(!isToggleDelete);
  };
  const DeleteParticitant = () => {
    const Method = "DELETE",
      url = `booth-leads/${leadID}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Product Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Full Name</span>,
      selector: (row) => row?.name,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email,
    },
    {
      name: <span className="font-weight-bold fs-13">Note</span>,
      selector: (row) => <>{row?.note ? row?.note?.substring(0, 30) : null}</>,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div>
            <span>
              <AiFillEye size={20} className="mx-1" />
            </span>

            <span>
              <AiFillDelete
                size={20}
                className="mx-1"
                onClick={() => tog_deleteParticipant(row?._id)}
              />
            </span>
          </div>
        );
      },
    },
  ];
  const data = leads;

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(
      leads?.map(({ name, email, note }) => ({ name, email, note }))
    );

    XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");
    XLSX.writeFile(workbook, "Leads.xlsx");
  };

  // console.log("boothID...", boothID);

  useEffect(() => {
    if (mutationDelete.isSuccess) {
      queryClient.invalidateQueries(["exhibtitor-LeadsBooth", eventID]);
      setIsToggleDelete(!isToggleDelete);
    }
  }, [mutationDelete.isSuccess]);
  return (
    <React.Fragment>
      <div className="people-attendees__heading">
        <h3>Leads</h3>
        <span className="text">Manage your booth leads from here</span>
      </div>
      <div className="people-attendees__body">
        <div className="d-flex justify-content-start align-items-center mb-3 w-100">
          <div className="d-flex align-items-center">
            <button
              className="d-flex"
              style={{
                border: "1px solid #21929c",
                borderRadius: "8px",
                fontSize: 14,
                background: "#21929c",
                color: "#fefefe",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 9,
                height: 45,
              }}
              onClick={exportToExcel}
              pointer="cursor"
            >
              <p>
                <span pointer="cursor">Download Leads</span>
              </p>
            </button>
          </div>
        </div>
        <div className="analystic-table-card">
          <div className="w-100">
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="300px"
              columns={columns}
              responsive
              data={data}
              pagination
            />
          </div>
        </div>
        <Modal
          size="md"
          isOpen={isToggleDelete}
          toggle={() => {
            tog_deleteParticipant();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Delete Event
            </h5>
            <button
              onClick={() => {
                setIsToggleDelete(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h5 className="text-danger">
              Are you sure you want to delete this Lead ?
            </h5>
          </div>
          <div className="modal-footer d-flex justify-content-between">
            <button
              onClick={() => {
                setIsToggleDelete(false);
              }}
              type="button"
              className="btn btn-dark"
            >
              Cancel
            </button>
            <button
              onClick={() => DeleteParticitant()}
              type="button"
              className="btn btn-danger"
            >
              {!mutationDelete?.isLoading ? (
                <span className="px-2">Sure</span>
              ) : (
                <span>
                  <Spinner as="span" animation="border" size="sm" />
                  Loading
                </span>
              )}
            </button>
          </div>
        </Modal>
        {/* <SlideInRightBar
          isOpen={slideInOpen}
          title="Add Booth Products"
          toggle={toggleSlideIn}
          body={
            <AddProducts
              token={token}
              boothID={boothID}
              eventID={eventID}
              setSlideInOpenProduct={setSlideInOpen}
            />
          }
        /> */}
      </div>
    </React.Fragment>
  );
}

export default LeadsTab;
