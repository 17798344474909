import React, { useState } from "react";
import { Modal } from "reactstrap";
import UploadVideoLink from "../../UploadVideoLink";
import EditEvent from "../../event/Event";
import EditBannerEvent from "../../event/EditBannerEvent";

function EventDetails({ eventID, eventDetails, token, userId }) {
  const [showEditBannerEvent, setShowEditBannerEvent] = useState(false);
  const [showEditEvent, setShowEditEvent] = useState(false);
  const [showUploadVideoLink, setShowUploadVideoLink] = useState(false);
  const getStartDate = eventDetails?.startDate;
  const date = new Date(getStartDate);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const startDate = date.toLocaleDateString("en-US", options);
  function toggle_event() {
    setShowEditEvent(!showEditEvent);
  }
  function toggle_eventBanner() {
    setShowEditBannerEvent(!showEditBannerEvent);
  }
  const toggle_uploadVideoLink = () => {
    setShowUploadVideoLink(!showUploadVideoLink);
  };
  return (
    <div className="summary-details__content">
      <div className="event_banner_img_container">
        <img
          src={eventDetails?.bannerImage}
          alt="eventPic"
          className="event_banner_img"
        />
        <div className="d-flex justify-content-between"></div>
      </div>
      <div className="w-100 d-flex mt-3">
        <h4 className="w-75">{eventDetails?.eventName}</h4>
        <div className="w-25 d-flex justify-content-end">
          {/* <div className="">
            <div className="h-50 event--month--activity d-flex justify-content-center align-items-center">
              <h5 className="align-center">{startDate?.substring(5, 8)}</h5>
            </div>
            <div className="h-50 event--day--activity d-flex justify-content-center">
              <h4 className="event--day--activity--text--day">
                {startDate?.substring(9, 11)}
              </h4>
            </div>
          </div> */}
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-end">
        <button
          className="btn btn-light mx-3"
          onClick={() => {
            toggle_event();
          }}
        >
          Edit Event
        </button>
        <button
          className="btn btn-light"
          onClick={() => {
            toggle_eventBanner();
          }}
        >
          Change Banner
        </button>
      </div>
      <Modal
        size="xl"
        isOpen={showEditEvent}
        toggle={() => {
          toggle_event();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Edit Event
          </h5>
          <button
            onClick={() => {
              setShowEditEvent(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <EditEvent
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetails={eventDetails}
            setShowEditEvent={setShowEditEvent}
          />
        </div>
        {/* <div className="modal-footer d-flex justify-content-between">
            
            
          </div> */}
      </Modal>
      <Modal
        size="xl"
        isOpen={showEditBannerEvent}
        toggle={() => {
          toggle_eventBanner();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Edit Event Banner
          </h5>
          <button
            onClick={() => {
              setShowEditBannerEvent(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <EditBannerEvent
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetails={eventDetails}
            setShowEditEvent={setShowEditEvent}
          />
        </div>
      </Modal>
      <Modal
        size="lg"
        isOpen={showUploadVideoLink}
        toggle={() => {
          toggle_uploadVideoLink();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Paste Video Link
          </h5>
          <button
            onClick={() => {
              setShowUploadVideoLink(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <UploadVideoLink
            userId={userId}
            token={token}
            eventID={eventID}
            setShowVideoLink={setShowUploadVideoLink}
          />
        </div>
      </Modal>
    </div>
  );
}

export default EventDetails;
