import React, { useState, useEffect, useRef } from "react";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
import { Spinner } from "reactstrap";

import { BsPeople } from "react-icons/bs";

// import { BiSolidBellRing } from "react-icons/bi";

import Reception from "../reception/reception.component";
// import Community from "../../../../../components/Common/chats/Index";
// import Community from "../community/Community";
// import Sessions from "../sessions/sessions.component";
import Booths from "../booth/Index";
import Sponsors from "../sponsors/Sponsors";

// import DropDown from "./components/drop-down/drop-down.component";
import { attendees, messages, alerts } from "./components/attendees";

import "./reception-header.styles.scss";

const ReceptionHeader = ({ userId, token, eventID }) => {
  const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [activeLink, setActiveLink] = useState(0);
  const [isDropdownVisiblePeople, setDropdownVisibilityPeople] =
    useState(false);
  const [isDropdownVisibleMessages, setDropdownVisibilityMessages] =
    useState(false);
  const [isDropdownVisibleAlerts, setDropdownVisibilityAlerts] =
    useState(false);

  const peopleDropdownRef = useRef(null);
  const messagesDropdownRef = useRef(null);
  const alertsDropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        peopleDropdownRef.current &&
        !peopleDropdownRef.current.contains(event.target) &&
        messagesDropdownRef.current &&
        !messagesDropdownRef.current.contains(event.target) &&
        alertsDropdownRef.current &&
        !alertsDropdownRef.current.contains(event.target)
      ) {
        setDropdownVisibilityPeople(false);
        setDropdownVisibilityMessages(false);
        setDropdownVisibilityAlerts(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleDropdownPeople = () => {
    setDropdownVisibilityPeople(!isDropdownVisiblePeople);
  };
  const toggleDropdownMessages = () => {
    setDropdownVisibilityMessages(!isDropdownVisibleMessages);
  };
  const toggleDropdownAlerts = () => {
    setDropdownVisibilityAlerts(!isDropdownVisibleAlerts);
  };

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  if (isLoadingGetMyEventDetail) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // console.log("getMyEventDetail...", getMyEventDetail);

  return (
    <React.Fragment>
      <header className="reception-header">
        <nav className="reception-navbar">
          <ul className="reception-navbar__menu">
            <li className="reception-navbar__menu--item">
              <a
                href="#"
                className={`reception-navbar__menu--link ${
                  activeLink === 0 ? "reception-navbar__menu--link--active" : ""
                }`}
                onClick={() => handleLinkClick(0)}
              >
                Reception
              </a>
            </li>

            {getMyEventDetail?.eventType?.includes("Exhibition") ? (
              <li className="reception-navbar__menu--item">
                <a
                  href="#"
                  className={`reception-navbar__menu--link ${
                    activeLink === 2
                      ? "reception-navbar__menu--link--active"
                      : ""
                  }`}
                  onClick={() => handleLinkClick(2)}
                >
                  My Booth
                </a>
              </li>
            ) : null}
            <li className="reception-navbar__menu--item">
              <a
                href="#"
                className={`reception-navbar__menu--link ${
                  activeLink === 4 ? "reception-navbar__menu--link--active" : ""
                }`}
                onClick={() => handleLinkClick(4)}
              >
                Sponsors
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <div
        className="pt-5"
        style={{ background: "#131216", height: "max-content" }}
      >
        {activeLink === 0 && (
          <Reception
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetail={getMyEventDetail}
          />
        )}

        {activeLink === 2 && (
          <Booths
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetail={getMyEventDetail}
          />
        )}
        {activeLink === 4 && (
          <Sponsors
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetail={getMyEventDetail}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ReceptionHeader;
