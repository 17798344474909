import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Spinner } from "reactstrap";
import { useQueryClient } from "@tanstack/react-query";
import useCustomMutation from "../../../../../../Hooks/useCustomMutation";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/Summary.scss";
import NoTickets from "../../../../../../assets/images/event/no_tickets.webp";
import TicketSlider from "../../../../../../components/Modal/TicketSlide";
import AddTicketForm from "../ticket/CreateTicket";
import EditTicketForm from "../ticket/EditTicket";
import TicketItems from "./components/TicketItems";

import "../../css/ticketing.scss";

const Ticketing = ({ userId, token, eventID }) => {
  const queryClient = useQueryClient();
  const key = ["myEventDetail", eventID];
  const mutationDelete = useCustomMutation(key);
  const [showAddTicket, setShowAddTicket] = useState(false);
  const [showEditTicket, setShowEditTicket] = useState(false);
  const [deleteTicketModal, setDeleteTicketModal] = useState(false);

  const {
    data: getMyEventDetails,
    error: errorGetMyEventDetails,
    isFetching: isFetchingMyEventDetails,
  } = useCustomQuery(
    key,
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  const {
    data: getMyTicketSettings,
    error: errorGetMyTicketSettings,
    isFetching: isFetchingGetMyTicketSettings,
  } = useCustomQuery(
    ["get-TicketSettings", eventID],
    `${process.env.REACT_APP_API}ticketing/${eventID}/event`,
    token
  );

  // console.log("getMyEventDetails......", getMyEventDetails);
  // console.log("getMyTicketSettings....", getMyTicketSettings?._id);

  const handleAddTicket = () => {
    // setEditTicketsModal(!editTicketsModal);
    setShowAddTicket(!showAddTicket);
  };

  function tog_deleteTicketModal() {
    setDeleteTicketModal(!deleteTicketModal);
  }
  const handleEditTicket = () => {
    setShowEditTicket(!showEditTicket);
  };

  const DeleteTickets = () => {
    const Method = "DELETE",
      url = `ticketing/${getMyTicketSettings?._id}/?event=${eventID}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Tickets Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  // if (isFetchingMyEventDetails) {
  //   return (
  //     <div className="mt-5 d-flex justify-content-center align-items-center">
  //       <Spinner animation="border" role="status">
  //         <span className="visually-hidden">Loading...</span>
  //       </Spinner>
  //     </div>
  //   );
  // }

  useEffect(() => {
    if (mutationDelete.isSuccess) {
      queryClient.invalidateQueries("get-TicketSettings", eventID);
      setDeleteTicketModal(false);
    }
  }, [mutationDelete.isSuccess]);

  return (
    <React.Fragment>
      <div className="w-100 column ticketing-content">
        <div className="registration-content__heading ">
          <div className="w-100 d-flex">
            <div className="w-75">
              <h2>Tickets</h2>
              <span className="registration-content__faded text-justify mr-3">
                Manage ticketing tiers to maximize reach and value of your
                event.
              </span>
            </div>
            <div className="d-flex tickets-all-btn justify-content-end align-items-center">
              {getMyTicketSettings?._id ? null : (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={handleAddTicket}
                >
                  <div className="d-flex justify-content-center align-center ">
                    <span className="px-2">Add Ticket</span>
                  </div>
                </button>
              )}

              {getMyTicketSettings?._id ? (
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-dark waves-effect waves-light mx-2"
                    onClick={handleEditTicket}
                  >
                    <div className="d-flex justify-content-center align-center">
                      <span className="px-2">Edit Ticket</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={tog_deleteTicketModal}
                  >
                    <div className="d-flex justify-content-center align-center">
                      <span className="px-2">Delete Ticket</span>
                    </div>
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {isFetchingGetMyTicketSettings ? (
          <div className="mt-5 d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {getMyTicketSettings?._id ? (
              <TicketItems ticketDetails={getMyTicketSettings} />
            ) : null}
          </>
        )}

        {!getMyTicketSettings && !getMyTicketSettings?._id ? (
          <div className="ticketing-placeholder-container">
            <div className="">
              <h3>No tickets added yet</h3>
              <p className="registration-content__faded">
                By adding tickets to this event, all the registered participants
                will be considered null and void. You can add them again via
                .csv upload.
              </p>
              <div className="w-100 d-flex justify-content-center">
                <div className="ticketing-placeholder-img">
                  <img src={NoTickets} alt="Showbay no tickets illustration" />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {getMyTicketSettings && !getMyTicketSettings?._id ? (
          <div className="ticketing-placeholder-container">
            <div className="">
              <h3>No tickets added yet</h3>
              <p className="registration-content__faded">
                By adding tickets to this event, all the registered participants
                will be considered null and void. You can add them again via
                .csv upload.
              </p>
              <div className="w-100 d-flex justify-content-center">
                <div className="ticketing-placeholder-img">
                  <img src={NoTickets} alt="Showbay no tickets illustration" />
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {getMyTicketSettings?.allowEarlyBird ? (
          <div className="mt-3">
            <p>
              <span className="discount">
                {/* {getMyTicketSettings?.discount}% */}
              </span>
              {/* <span className="discount-text mx-2"> Until </span> */}
              <span className="discount-date ">
                {/* {getMyTicketSettings?.earlyBirdEndDate} */}
              </span>
            </p>
          </div>
        ) : null}

        {showAddTicket ? (
          <TicketSlider
            isOpen={showAddTicket}
            toggle={handleAddTicket}
            title="Add Ticket(s)"
            body={
              <AddTicketForm
                userId={userId}
                token={token}
                eventID={eventID}
                handleTicket={handleAddTicket}
              />
            }
          />
        ) : null}
        <TicketSlider
          isOpen={showEditTicket}
          toggle={handleEditTicket}
          title="Edit Ticket(s)"
          body={
            <EditTicketForm
              userId={userId}
              token={token}
              eventID={eventID}
              handleEditTicket={handleEditTicket}
              TicketID={getMyTicketSettings?._id}
              ticketDetails={getMyTicketSettings}
            />
          }
        />
        <Modal
          size="md"
          isOpen={deleteTicketModal}
          toggle={() => {
            tog_deleteTicketModal();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Delete Event
            </h5>
            <button
              onClick={() => {
                setDeleteTicketModal(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h5 className="text-danger">
              Are you sure you want to delete these Tickets ?
            </h5>
          </div>
          <div className="modal-footer d-flex justify-content-between">
            <button
              onClick={() => {
                setDeleteTicketModal(false);
              }}
              type="button"
              className="btn btn-dark"
            >
              Cancel
            </button>
            <button
              onClick={() => DeleteTickets()}
              type="button"
              className="btn btn-danger"
            >
              {!mutationDelete?.isLoading ? (
                <span className="px-2">Sure</span>
              ) : (
                <span>
                  <Spinner as="span" animation="border" size="sm" />
                  Loading
                </span>
              )}
            </button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default Ticketing;
