import React, { useState } from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Spinner } from "reactstrap";

// import { useStore1Selector } from "../../../..";
// import { userdetails } from "../../../../Redux/Slices/userSlice";

// import { Link, useParams } from "react-router-dom";

const Profile = ({ eventID, boothID }) => {
  // const { eventID } = useParams();
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const token = obj?.token;
  const [ViewShedule, setViewShedule] = useState();

  const {
    data: getMyProfileBooth,
    error: errorGetMyProfileBooth,
    isLoading: isLoadingGetMyProfileBooth,
  } = useCustomQuery(
    ["myExProfile", eventID],
    `${process.env.REACT_APP_API}company-profile/booth/${boothID}`,
    token
  );

  if (isLoadingGetMyProfileBooth) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div>
        <Container>
          <div className="d-flex w-100">
            <div
              // className="w-25"
              style={{
                width: "15%",
              }}
            >
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "10px",
                }}
              >
                <img
                  src={getMyProfileBooth?.companyLogo}
                  className="w-100 h-100 rounded-2"
                />
              </div>
            </div>
            <div
              style={{
                width: "85%",
              }}
            >
              <p>{getMyProfileBooth?.companyName}</p>
              <p>{getMyProfileBooth?.companyAddress}</p>
              <p style={{ textDecoration: "underline", color: "blue" }}>
                <a href={getMyProfileBooth?.companyWebsite} target="_blank">
                  {getMyProfileBooth?.companyWebsite}
                </a>
              </p>
              <p>{getMyProfileBooth?.companyFax}</p>
            </div>
          </div>
          <div className="w-100 mt-2">
            <p>{getMyProfileBooth?.companyDescription}</p>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Profile;
