import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";

// import SessionDefault from "../../../../../../../assets/images/student.jpg";
// import UserDefaultOne from "../../../../../../../assets/images/users/avatar-1.jpg";
// import UserDefaultTwo from "../../../../../../../assets/images/users/avatar-2.jpg";
// import UserDefaultThree from "../../../../../../../assets/images/users/avatar-3.jpg";

import "./ActivityBody.scss"; // Create a CSS file for styling

const ActivityBody = ({ activity }) => {
  return (
    <React.Fragment>
      <div className="booth-body">
        <div className="booth-body__details">
          <img
            src={activity?.sessionLogo}
            width="95%"
            height="40%"
            alt=""
            style={{ borderRadius: "12px" }}
          />

          {activity?.lineup.length > 0 ? (
            <div style={{ margin: "24px auto" }}>
              <h4 style={{ color: "#e4e5e6" }}>Lineup(s)</h4>
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  margin: "24px 16px 24px 0",
                  paddingBottom: "18px",
                  alignItems: "center",
                  borderRight: "1px solid rgba(255, 255, 255, 0.2)",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                }}
              >
                {activity?.lineup?.map(() => {
                  return (
                    <img
                      // src={UserDefaultOne}
                      width="25%"
                      alt=""
                      style={{
                        borderRadius: "50%",
                        border: "1px solid rgba(255, 255, 255, 0.4)",
                      }}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}

          <div className="booth-body__details__description">
            <h4 style={{ color: "#fefefe" }}>Description</h4>
            <p
              style={{
                color: "#afacbb",
                fontWeight: "normal",
                fontSize: "14px",
                margin: "16px auto",
              }}
            >
              {activity?.sessionDescription}
            </p>
          </div>

          <div
            className="with-exhibitors"
            style={{
              background: "rgba(255, 255, 255, 0.1",
              color: "#131216",
              borderRadius: "7px",
              padding: "4px 8px",
              fontSize: "10px",
              marginBottom: "-12px",
              width: "max-content",
              // marginLeft: "240px",
            }}
          >
            {activity?.tags?.map((tag, i) => {
              return (
                <span style={{ color: "white" }} key={i}>
                  #{tag}
                </span>
              );
            })}
          </div>
        </div>
        {activity?.additionalMaterial?.length >= 1 ? (
          <div className="booth-body__chat">
            <div className="notify-on-live">
              <h4 style={{ color: "#e4e5e6", marginBottom: "24px" }}>
                Additional Material
              </h4>
              <p className="input-description" style={{ fontSize: "12px" }}>
                Download additional material or session documents such as
                catalogues, brochures, organization profile etc.
              </p>
              {activity?.additionalMaterial?.map((material, i) => {
                // console.log("length...", activity?.additionalMaterial?.length);
                return (
                  <button className="notify-on-live--button" key={i}>
                    Download here
                  </button>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default ActivityBody;
