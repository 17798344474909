import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import {
  MDBIcon,
  MDBTypography,
  MDBInputGroup,
  // MDBScrollbar,
} from "mdb-react-ui-kit";
import ContactList from "./ContactList";

function Contancts({ eventDetail }) {
  return (
    <div className="p-3">
      <MDBInputGroup className="rounded mb-3">
        <input
          className="form-control rounded"
          placeholder="Search"
          type="search"
        />
        <span className="input-group-text border-0" id="search-addon">
          <MDBIcon fas icon="search" />
        </span>
      </MDBInputGroup>

      <SimpleBar
        style={{
          position: "relative",
          height: "300px",
          width: "100%",
        }}
      >
        <MDBTypography listUnStyled className="mb-0">
          <ContactList />
        </MDBTypography>
      </SimpleBar>
    </div>
  );
}

export default Contancts;
