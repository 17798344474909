import React from "react";
import useCustomQuery from "../../Hooks/useCustomQuery";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import Loader from "../../components/Loader/Loader";
import Main from "./Components/Conference/Main";
import SocialLanding from "./Components/Social/SocialLanding";

const Index = () => {
  document.title = "Showbay 24 | Event Landing";
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userId = obj?.data?.data?.id;
  const token = obj?.token;
  const params = useParams();

  const eventID = params.eventID;
  const eventName = params.eventName;
  const myEventName = eventName.replace(/-/g, " ");

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isFetching: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  if (isLoadingGetMyEventDetail) {
    <div className="page-content mt-5 d-flex justify-content-center align-items-center">
      <Loader />
    </div>;
  }

  const eventType = getMyEventDetail?.eventType;
  const socialEventLandingPage = () => {
    return (
      <SocialLanding
        eventDetails={getMyEventDetail}
        token={token}
        eventID={eventID}
      />
    );
  };
  const conferenceEventLandingPage = () => {
    return (
      <Main
        userId={userId}
        token={token}
        eventID={eventID}
        eventName={myEventName}
      />
    );
  };
  // console.log("eventType...vvvv...@@@@@", eventType);
  // style={{ backgroundColor: "#000" }}
  return (
    <React.Fragment>
      {eventType?.includes("Conference") && eventType.length === 1
        ? conferenceEventLandingPage()
        : null}
      {eventType?.includes("Exhibition") && eventType.length === 1
        ? conferenceEventLandingPage()
        : null}
      {eventType?.includes("Social") && eventType.length === 1
        ? socialEventLandingPage()
        : null}
      {eventType?.includes("Conference") && eventType?.includes("Exhibition")
        ? conferenceEventLandingPage()
        : null}

      {/* <div className="page-content"> */}
      {/* <Container fluid={false}>
        
      </Container> */}
      {/* </div> */}
    </React.Fragment>
  );
};

export default Index;
