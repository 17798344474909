import React, { useState } from "react";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import SlideInRightBar from "../../../../../../components/Common/SlideInComponent/SlideInComponent";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AddSponsor from "../../AddSponsor";

function Sponsors({ userId, eventID, token }) {
  const [slideInOpenSponsor, setSlideInOpenSponsor] = useState(false);

  const toggleSlideInSponsor = () => {
    setSlideInOpenSponsor(!slideInOpenSponsor);
  };
  const {
    data: getMySponsors,
    error: errorGetMySponsors,
    isFetching: isFetchingGetMySponsors,
  } = useCustomQuery(
    ["my-Sponsors", eventID],
    `${process.env.REACT_APP_API}sponsors/event/${eventID}`,
    token
  );
  const getLastTwoSponsors = (mySponsors) => {
    if (mySponsors.length >= 2) {
      return mySponsors.slice(-4);
    }

    if (mySponsors.length === 1) {
      return [mySponsors[0]];
    }
    return [];
  };

  let lastTwoSponsors = [];
  if (getMySponsors) {
    lastTwoSponsors = getLastTwoSponsors(getMySponsors);
  }

  return (
    <div className="summary-setup__affiliates--sponsors">
      <div className="summary-setup__affiliates--sponsors__heading">
        <h5>Sponsors</h5>
      </div>
      <div className="summary-setup__affiliates--sponsors__body">
        <div className="btn-add-speaker d-flex justify-content-center align-items-center">
          <AiOutlinePlusCircle
            size={24}
            type="button"
            onClick={toggleSlideInSponsor}
          />
        </div>
        {lastTwoSponsors.map((sponsor, i) => {
          return (
            <img
              src={sponsor?.sponsorLogo}
              style={{ width: "70px", height: "70px", borderRadius: 10 }}
              alt="sponsor-picture"
              key={i}
            />
          );
        })}
      </div>
      {slideInOpenSponsor ? (
        <SlideInRightBar
          isOpen={true}
          title="Add Sponsor"
          toggle={toggleSlideInSponsor}
          body={
            <AddSponsor
              userId={userId}
              token={token}
              eventID={eventID}
              setSlideInOpenSponsor={setSlideInOpenSponsor}
            />
          }
        />
      ) : null}
    </div>
  );
}

export default Sponsors;
