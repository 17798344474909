import React, { useState } from "react";
import { Spinner } from "reactstrap";
import useCustomMutation from "../../../../../../Hooks/useCustomMutation";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import "./hexagonePicture.scss";
import SponsorsSideBar from "../../../../../../assets/images/exhibitonLobby/SponsorsSideBar.png";
import FirstSidebar from "./FirstSidebar";
import SecondSidebar from "./SecondSidebar";
import ThirdSidebar from "./ThirdSidebar";
import FourthSidebar from "./FourthSidebar";

function MainSidebar({ userId, token, eventID }) {
  const [firstSever, setFirstSever] = useState();
  const [secondSever, setSecondSever] = useState();
  const [thirdSever, setThirdSever] = useState();
  const [fourthSever, setFourthSever] = useState();
  const [fifthSever, setFifthSever] = useState();

  return (
    <div className="w-100">
      <h4>Sidebar Exhibition Sponsors</h4>
      <p>Upload maximum 4 sponsors with no background</p>
      <div className="hexagonIllustrationContainer">
        <div className="hexagonIllustrationContainerImg">
          <img src={SponsorsSideBar} alt="mainSponsors" />
        </div>
      </div>
      <div
        className="w-100 mt-4 d-flex justify-content-center"
        style={{ gap: 10 }}
      >
        <FirstSidebar firstSever={firstSever} setFirstSever={setFirstSever} />
        <SecondSidebar
          secondSever={secondSever}
          setSecondSever={setSecondSever}
        />
        <ThirdSidebar thirdSever={thirdSever} setThirdSever={setThirdSever} />
        <FourthSidebar
          fourthSever={fourthSever}
          setFourthSever={setFourthSever}
        />
      </div>
      <div className="d-flex justify-content-center mt-5">
        <button className="btn btn-primary mt-3">Upload Sponsors</button>
        {/* <Spinner as="span" animation="border" size="sm" /> */}
      </div>
    </div>
  );
}

export default MainSidebar;
