import React, { useState, useRef, useEffect } from "react";
import { Spinner, Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useQueryClient } from "@tanstack/react-query";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import ImgSession from "../../../../assets/images/banners/sessions/bannerSession.png";
import { BiEdit } from "react-icons/bi";

import "./css/Summary.scss";
import "./css/AddSponsor.scss";

const AddBoothSponsor = ({
  token,
  boothID,
  eventID,
  setSlideInOpenSponsor,
}) => {
  const queryClient = useQueryClient();
  // const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  // const userEmail = obj?.data?.data?.email;
  const key = ["myExbSponsors", eventID];
  const mutation = useCustomMutation(key);
  const [logoServer, setLogoServer] = useState();
  const [profile, setProfile] = useState();
  const [inputs, setInputs] = useState({});

  const refFileUpload = useRef("");

  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLogoServer(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // console.log("eventId...", eventID);
  // console.log("getMyBoothDetails...", getMyBoothDetails[0]?._id);

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "POST",
      url = `sponsors/booth/${boothID}`,
      isJSON = true;
    const formdata = new FormData();

    formdata.append("sponsorLogo", logoServer);
    formdata.append("sponsorName", inputs.sponsorName);
    formdata.append("websiteUrl", inputs.websiteUrl);
    formdata.append("sponsorTier", inputs.sponsorTier);

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Sponsor added successfully",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      queryClient.invalidateQueries(["exhibtitor-SponsorsBooth", eventID]);
      setSlideInOpenSponsor(false);
    }
  }, [mutation.isSuccess]);

  return (
    <React.Fragment>
      <div className="sponsor-form-container">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="sponsor-form-body"
          onSubmit={handleSubmitForm}
        >
          <div className="sponsor-form-body__sponsor-logo">
            <div className="d-flex justify-content-center">
              <div className="section---banner---container mt-2">
                <img
                  src={!logoServer ? ImgSession : profile}
                  alt="session Image"
                  className="w-100 h-100 section---banner---container---img"
                />
                <Button
                  size="md"
                  variant="separator-light"
                  className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
                  onClick={onThumbChangeClick}
                >
                  <BiEdit
                    size={40}
                    style={{
                      color: "black",
                      filter: "drop-shadow(0px 0px 10px white)",
                    }}
                  />
                </Button>
                <input
                  type="file"
                  ref={refFileUpload}
                  className="file-upload d-none"
                  accept="image/*"
                  onChange={changeThumb}
                />
              </div>
            </div>
            <div
              className="sponsor-form-body__sponsor-logo"
              style={{ marginRight: "12px", marginBottom: "-56px" }}
            >
              <p
                color="ambience.6"
                font-size="1"
                className="sponsor-form-body--input-description"
              >
                Recommended format: 400x200px | JPG, SVG, PNG | Up to 1 MB
              </p>
            </div>
          </div>

          <div className="sponsor-form-body__input">
            <label htmlFor="sponsorName">
              <p className="sponsor-form-body--input-heading">Sponsor name*</p>
            </label>
            <input
              type="text"
              id="sponsorName"
              placeholder=""
              className="sponsor-form-body__input--field"
              name="sponsorName"
              aria-label="sponsorName"
              onChange={handleChange}
            />
          </div>
          <div className="sponsor-form-body__input">
            <label htmlFor="websiteUrl">
              <p className="sponsor-form-body--input-heading">Website URL</p>
            </label>
            <input
              type="text"
              id="websiteUrl"
              placeholder="https://www.yourwebsite.com"
              className="sponsor-form-body__input--field"
              name="websiteUrl"
              aria-label="websiteUrl"
              onChange={handleChange}
            />
          </div>
          <div className="sponsor-form-body__input">
            <label htmlFor="sponsorTier">
              <p className="sponsor-form-body--input-heading">Sponsor tier*</p>
            </label>
            <select
              required
              type="text"
              id="sponsorTier"
              placeholder="Choose sponsor tier"
              className="sponsor-form-body__input--field"
              name="sponsorTier"
              aria-label="sponsorTier"
              onChange={handleChange}
            >
              <option value={null}>Select...</option>
              <option value="Institutional">Institutional</option>
              <option value="Platinum">Platinum</option>
              <option value="Gold">Gold</option>
              <option value="Silver">Silver</option>
            </select>
          </div>

          <button className="btn-add-sponsor" type="submit">
            <div className="d-flex justify-content-center align-items-center">
              {!mutation.isLoading ? <span>Add Sponsor</span> : null}
              {!mutation.isLoading ? null : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading
                </span>
              )}
            </div>
          </button>
        </form>
      </div>
    </React.Fragment>
  );
};
export default AddBoothSponsor;
