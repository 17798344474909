import React from "react";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import "./attendeeCard.scss";

function AttendeeCard({ token, eventID }) {

  const {
    data: getMyEventAttendees,
    error: errorgetMyEventAttendees,
    isLoading: isLoadinggetMyEventAttendees,
  } = useCustomQuery(
    ["myEventAttendees", eventID],
    `${process.env.REACT_APP_API}attendees/${eventID}/invited`,
    token
  );

  return (
    <div className="attendee-analistics">
      <div className="text-center">
        <div style={{ color: "white", fontSize: 25, fontWeight: "900" }}>
          Attendees
        </div>
        <div style={{ color: "white", fontSize: 25, fontWeight: "900" }}>
          Invited
        </div>
        <p style={{ color: "white", fontSize: 36, fontWeight: "900" }}>{Array.isArray(getMyEventAttendees) ? getMyEventAttendees.length : null}</p>
        {/* <button
          className="rounded-2 border border-light"
          style={{
            width: "120px",
            height: "40px",
            color: "white",
            fontSize: 13,
            fontWeight: "400",
            backgroundColor: "transparent",
          }}
        >
          View More
        </button> */}
      </div>
    </div>
  );
}

export default AttendeeCard;
