import React from "react";
import { Row, Col, Container, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import useCustomQuery from "../../../Hooks/useCustomQuery";
import defaultImg from "../../../assets/images/event/DefaultBanner.jpg";

const AllOrganizer = ({ search, userId, token, t }) => {
  const Navigate = useNavigate();
  // const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  // const userId = obj?.data?.data?.id;
  // const userEmail = obj?.data?.data?.email;
  // const token = obj?.token;

  const {
    data: getMyEvents,
    error: errorGetMyEvents,
    isLoading: isLoadingGetMyEvents,
  } = useCustomQuery(
    ["myEvents", userId],
    `${process.env.REACT_APP_API}events/event-organizer/${userId}`,
    token
  );

  // if (errorGetMyEvents) {
  //   return (
  //     <div className="mt-5 d-flex justify-content-center align-items-center">
  //       <Spinner animation="border" role="status">
  //         <span className="visually-hidden">Refresh your browser...</span>
  //       </Spinner>
  //     </div>
  //   );
  // }

  // if (isLoadingGetMyEvents) {
  //   return (
  //     <div className="mt-5 d-flex justify-content-center align-items-center">
  //       <Spinner animation="border" role="status">
  //         <span className="visually-hidden">Loading...</span>
  //       </Spinner>
  //     </div>
  //   );
  // }

  const filterEventsByName = (events, searchQuery) => {
    if (!searchQuery) return events;
    return events.filter((event) =>
      event?.eventName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };
  const filteredEvents = filterEventsByName(getMyEvents, search);

  // if (getMyEvents?.length === 0) {
  //   return (
  //     <div className="mt-5 d-flex justify-content-center align-items-center">
  //       <h1>No Event.....</h1>
  //     </div>
  //   );
  // }

  // console.log("filteredEvents....", filteredEvents);
  // eventStatus;

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const handleSetUpEvent = (eventName, evID) => {
    Navigate(
      `/${eventName?.replace(/ /g, "-")}/event-summary/${evID}
    `
    );
  };

  const handlePreviewEvent = (eventName, evID) => {
    Navigate(`/${eventName?.replace(/ /g, "-")}/event-landing/${evID}`);
  };

  return (
    <React.Fragment>
      <Container>
        <div>
          <Row>
            {filteredEvents?.map((item, index) => {
              // const date = item.StartDateTime.split(" ");
              // console.log(item);

              // Event date configuration
              let dateValue = new Date(item.startDate);

              let day = dayNames[dateValue.getUTCDay()];
              let month = monthNames[dateValue.getUTCMonth()];
              let year = dateValue.getUTCFullYear();
              let date = dateValue.getUTCDate();

              let formattedDate = `${day} ${date} ${month} ${year}`;

              // Event date configuration end
              // console.log("item?.bannerImage", item?.bannerImage);

              return (
                <>
                  <Col xs={12} sm={12} md={12} lg={12} key={index}>
                    {/* <div className="dashboard-container-card">
                      <div className="dashboard-wrapper">
                        <div className="dashboard-container-img-card">
                          <div className="dashboard-container-img-card">
                            <img
                              src={
                                item?.bannerImage
                                  ? item?.bannerImage
                                  : defaultImg
                              }
                            />
                          </div>
                          <div className="MyEvent_banner_div text-light">
                            <div
                              className={
                                item?.eventType?.includes("Social")
                                  ? `ribbon-4 px-4 MyEvent__ribbon__text`
                                  : item?.eventType?.includes("Exhibition")
                                  ? `ribbon-3 px-4 MyEvent__ribbon__text`
                                  : `ribbon-2 px-4 MyEvent__ribbon__text`
                              }
                            >
                              {item?.eventType?.includes("Social")
                                ? `Social`
                                : item?.eventType?.includes("Exhibition")
                                ? `Exhibition`
                                : `Conference`}
                            </div>
                          </div>
                        </div>
                        <div className="dashboard-event-content">
                          <h3>
                            hellogsgdsgdgegergherherhtrhtrhjtrjrthjtrhtrhherhtrherhheherherhergreeeeeeegreergersgewrgerwgereeeeeeeeegergtrhtryjthrefwdferhtjukjhgefwdfeghkefwrty
                            bbbbbbbbbbbbbb bbbbbbb
                          </h3>
                        </div>
                      </div>
                      <div className="dashboard-event-status">hello</div>
                      <div className="dashboard-event-action">hello</div>
                    </div> */}

                    <div className="d-flex align-items-center p-3 mb-4 shadow-sm bg-white card-brd">
                      {/* ------------Event Name---------------- */}
                      <div
                        className="MyEvent_left_container-div d-flex align-items-center"
                        style={{ flex: 0.4 }}
                      >
                        <div className="MyEvent_Parent_div">
                          <div className="MyEvent_image_div">
                            <img
                              src={
                                item?.bannerImage
                                  ? item?.bannerImage
                                  : defaultImg
                              }
                              width="100%"
                              height="100%"
                              // className="Image_Fit card-brd"
                              alt="event"
                            />
                          </div>
                          <div className="MyEvent_banner_div text-light">
                            <div
                              className={
                                item?.eventType?.includes("Social") &&
                                item?.eventType?.length === 1
                                  ? "ribbon-4 px-4 MyEvent__ribbon__text"
                                  : item?.eventType?.includes("Exhibition") &&
                                    item?.eventType?.includes("Conference")
                                  ? "ribbon-5 px-4 MyEvent__ribbon__text" // New class for both "Exhibition" and "Conference"
                                  : item?.eventType?.includes("Exhibition") &&
                                    item?.eventType?.length === 1
                                  ? "ribbon-3 px-4 MyEvent__ribbon__text"
                                  : item?.eventType?.includes("Conference") &&
                                    item?.eventType?.length === 1
                                  ? "ribbon-2 px-4 MyEvent__ribbon__text"
                                  : null
                              }
                            >
                              {item?.eventType?.includes("Social") &&
                              item?.eventType?.length === 1
                                ? "Social"
                                : item?.eventType?.includes("Exhibition") &&
                                  item?.eventType?.includes("Conference")
                                ? "Expo"
                                : item?.eventType?.includes("Conference") &&
                                  item?.eventType?.length === 1
                                ? "Conference"
                                : item?.eventType?.includes("Exhibition") &&
                                  item?.eventType?.length === 1
                                ? "Exhibition"
                                : null}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex flex-column justify-content-around p-4 mx-3">
                          <h5
                            className="MyEvent__text"
                            style={{ marginBottom: -8 }}
                          >
                            {item.eventName}
                          </h5>
                          <div
                            className="mt-3 justify-content-end"
                            style={{ color: "#000000", fontSize: 12 }}
                          >
                            <div className="MyEvent__date">{formattedDate}</div>
                            <div className="MyEvent__date">
                              {`${item.startTime} - ${item.endTime} SAST`}
                            </div>
                          </div>
                          <div className="mt-3 justify-content-end">
                            <div className="MyEvent__date">
                              {item?.state === "Not Published" ? (
                                <h5
                                  className="MyEvent__text text-danger"
                                  style={{ fontSize: 12 }}
                                >
                                  Not Published
                                </h5>
                              ) : item?.state === "Published" ? (
                                <h5
                                  className="MyEvent__text text-success"
                                  style={{ fontSize: 12 }}
                                >
                                  Published
                                </h5>
                              ) : (
                                <h5
                                  className="MyEvent__text text-warning"
                                  style={{ fontSize: 12 }}
                                >
                                  Draft
                                </h5>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="d-flex justify-content-evenly align-items-center"
                        style={{ flex: 0.6 }}
                      >
                        {/* ------------ Status---------------------- */}
                        {/* Upcoming */}
                        <div>
                          <h5
                            className={
                              item?.eventStatus === "Upcoming"
                                ? `text-info font-weight-bold MyEvent__status`
                                : item?.eventStatus === "Ongoing"
                                ? `text-success font-weight-bold MyEvent__status`
                                : `text-danger font-weight-bold MyEvent__status`
                            }
                          >
                            <span style={{ visibility: "hidden" }}>data</span>
                            {t(`${item?.eventStatus}`)}
                          </h5>
                        </div>
                        {/* ------------ Actions------------------ */}
                        <div
                          style={{
                            display: "grid",
                            gridTemplateRows: "1fr 1fr",
                            gap: 12,
                            marginLeft: 25,
                          }}
                        >
                          <div>
                            {/* <Link> */}
                            <button
                              type="button"
                              className="btn px-3 btn-md shadow-sm text-light btnMyEventEdit"
                              onClick={() =>
                                handleSetUpEvent(item?.eventName, item._id)
                              }
                            >
                              Setup Event
                              <span style={{ visibility: "hidden" }}>ii.</span>
                            </button>
                            {/* </Link> */}
                          </div>
                          <div>
                            <button
                              type="button"
                              className="btn px-3 btn-md shadow-sm text-light btnPublish"
                              onClick={() =>
                                handlePreviewEvent(item?.eventName, item._id)
                              }
                            >
                              Preview Event
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              );
              // }
            })}
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default AllOrganizer;
