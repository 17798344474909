import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IoWarningOutline } from "react-icons/io5";

// import {
//   dangerMessage,
//   successMessage,
//   warningMessage,
// } from "../../components/Toast/Toast";

import { Button } from "reactstrap";
import "./verify.scss";

function Index() {
  document.title = "Showbay 24 | Verify";
  const Navigate = useNavigate();
  let params = useParams();
  const Id = params.ID;
  const token = params.Token;
  const [btnLoad, setBtnLoad] = useState(false);
  const [message, setMessage] = useState(null);

  const displayMessage = (warning) => {};

  const verifyRequest = () => {
    setBtnLoad(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API}auth/verify/${Id}/${token}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res?.status === "success") {
          setBtnLoad(false);
          Navigate("/login");
        }
        if (res?.status === "fail") {
          // console.log("res...", res);
          // setBtnLoad(false);
          setMessage(res?.message);
          // setMessage("This Email has already been Verified !!!");
          // warningMessage("This Email has already been Verified !!!");
        }
      })
      .catch((error) => {
        setBtnLoad(false);
        // displayMessage("Invalid Link");
      });
  };
  useEffect(() => {
    verifyRequest();
  }, [token]);

  return (
    <>
      {message && (
        <div className="verify---container">
          <div className="verify---card">
            <div>
              <div className="d-flex justify-content-center">
                <IoWarningOutline size={70} color="red" />
              </div>

              <p className="mt-2 text-danger h2">{message}</p>
              <div className="d-flex justify-content-center">
                <Button
                  className="btn bg-primary rounded-3"
                  onClick={() => {
                    Navigate("/login");
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Index;
