import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import ExploreEvent from "./ExploreEvent";
import { IoArrowBack } from "react-icons/io5";
import { AiOutlineSearch } from "react-icons/ai";

const ShowEvents = ({ t, userRole }) => {
  const Navigate = useNavigate();
  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  return (
    <React.Fragment>
      <Container>
        <div>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="p-2 mt-4 d-flex align-items-center justify-content-between">
                <div
                  className="d-flex"
                  onClick={() => {
                    Navigate("/Dashboard");
                  }}
                >
                  <div style={{ paddingRight: 18 }}>
                    <IoArrowBack size={30} color="black" />
                  </div>
                  <h2>{t("My Events")}</h2>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="p-2 mb-4 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      style={{
                        background: "#ffffff",
                        paddingTop: ".58rem",
                        paddingBottom: ".5rem",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                        marginRight: 32,
                        border: "solid",
                        borderWidth: 0.1,
                        borderRadius: 8,
                      }}
                    >
                      <AiOutlineSearch size={24} />
                      <input
                        type="text"
                        placeholder={t("Search event name")}
                        value={search}
                        onChange={handleSearch}
                        style={{
                          border: "none",
                          marginLeft: 8,
                          paddingRight: 24,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div
                className="d-flex px-4 mb-4 event-headings"
                style={{
                  background: "#ffffff",
                  color: "#191c27",
                  border: "1px solid rgba(73, 80, 87, .2)",
                  borderWidth: 0.1,
                  borderRadius: 8,
                  paddingBottom: 4,
                  paddingTop: 12,
                }}
              >
                <h5 style={{ flex: 0.4 }}>{t("Event Name")}</h5>
                <div
                  className="d-flex justify-content-evenly event-heading--more"
                  style={{ flex: 0.6 }}
                >
                  <h5>{t("Event Status")}</h5>
                  <h5>{t("Actions")}</h5>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <ExploreEvent t={t} search={search} />
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default ShowEvents;
