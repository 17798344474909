import React, { useState } from "react";
import { Button } from "@mui/material";
import useCustomQuery from "../../../Hooks/useCustomQuery";
import "./css/ticketSelection.scss";
import StandardTicket from "./StandardTicket";
import VipTicket from "./VipTicket";
import VvipTicket from "./VvipTicket";
const TicketSelection = ({
  setSelectedTicket,
  handleNext,
  eventDetails,
  token,
}) => {
  const [ticketType, setTicketType] = useState(null);
  const [ticketPrice, setTicketPrice] = useState(null);

  const {
    data: getMyTicketSettings,
    error: errorGetMyTicketSettings,
    isLoading: isLoadingGetMyTicketSettings,
  } = useCustomQuery(
    ["getTicketSettings", eventDetails?._id],
    `${process.env.REACT_APP_API}ticketing/${eventDetails?.ticketSettings[0]}`,
    token
  );
  let earlyBirdEndDate = getMyTicketSettings?.earlyBirdEndDate
    ? getMyTicketSettings?.earlyBirdEndDate?.substring(0, 10)
    : null;

  const handleContinue = () => {
    handleNext();
  };
  const fullDateErlyBird = getMyTicketSettings?.earlyBirdEndDate;

  const handleEarlyBird = (fullDateErlyBird) => {
    const earlyBirdDate = new Date(fullDateErlyBird);
    const currentDate = new Date();
    return earlyBirdDate > currentDate;
  };
  let isEarlyBirdActive = handleEarlyBird(fullDateErlyBird);

  // console.log("....getMyTicketSettings......", getMyTicketSettings);

  return (
    <div>
      <div className="mt-4 d-flex justify-content-center">
        <div className="ticket-card-main">
          <StandardTicket
            handleContinue={handleContinue}
            type={getMyTicketSettings?.tierOne}
            price={getMyTicketSettings?.tierOnePrice}
            earlyBird={
              isEarlyBirdActive
                ? getMyTicketSettings?.tierOneEarlyBirdPrice
                : null
            }
            earlyBirdDate={isEarlyBirdActive ? earlyBirdEndDate : null}
            setSelectedTicket={setSelectedTicket}
          />
          <VipTicket
            handleContinue={handleContinue}
            type={getMyTicketSettings?.tierTwo}
            price={getMyTicketSettings?.tierTwoPrice}
            earlyBird={
              isEarlyBirdActive
                ? getMyTicketSettings?.tierTwoEarlyBirdPrice
                : null
            }
            earlyBirdDate={isEarlyBirdActive ? earlyBirdEndDate : null}
            setSelectedTicket={setSelectedTicket}
          />
          <VvipTicket
            handleContinue={handleContinue}
            type={getMyTicketSettings?.tierThree}
            price={getMyTicketSettings?.tierThreePrice}
            earlyBird={
              isEarlyBirdActive
                ? getMyTicketSettings?.tierThreeEarlyBirdPrice
                : null
            }
            earlyBirdDate={isEarlyBirdActive ? earlyBirdEndDate : null}
            setSelectedTicket={setSelectedTicket}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketSelection;
