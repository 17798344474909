import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../../../Hooks/useCustomMutation";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import { Spinner, Row, Col } from "reactstrap";
import "../../css/CreateTicket.scss";
// import { Row, Col, Button, Form, InputGroup, Label } from "reactstrap";

function EditTicket({
  token,
  eventID,
  handleEditTicket,
  TicketID,
  ticketDetails,
}) {
  // ["myEventDetail", eventID],
  const key = ["myEventDetail", eventID];
  const {
    data: getMyTicketSettings,
    error: errorGetMyTicketSettings,
    isLoading: isLoadingGetMyTicketSettings,
  } = useCustomQuery(
    ["getTicketSettings", eventID],
    `${process.env.REACT_APP_API}ticketing/${TicketID}`,
    token
  );

  // console.log("TicketDetails..", getMyTicketSettings);

  const mutation = useCustomMutation(key);
  // const [tierOne, setTierOne] = useState(null);
  const [tierOnePrice, setTierOnePrice] = useState(null);
  const [tierOneQuantity, setTierOneQuantity] = useState(null);

  // const [tierTwo, setTierTwo] = useState(null);
  const [tierTwoPrice, setTierTwoPrice] = useState(null);
  const [tierTwoQuantity, setTierTwoQuantity] = useState(null);

  // const [tierThree, setTierThree] = useState(null);
  const [tierThreePrice, setTierThreePrice] = useState(null);
  const [tierThreeQuantity, setTierThreeQuantity] = useState(null);

  const [ticketTerms, setTicketTerms] = useState("");
  const [isEarlyBird, setIsEarlyBird] = useState(null);
  const [earlyBirdStartDate, setEarlyBirdStartDate] = useState(null);
  const [earlyBirdEndDate, setEarlyBirdEndDate] = useState(null);
  const [discount, setDiscount] = useState(null);

  const handleSubmitForm = (event) => {
    event.preventDefault();
    // console.log("tierOnePrice....", tierOnePrice);
    const Method = "PATCH",
      url = `ticketing/${TicketID}`,
      isJSON = null;
    const raw = JSON.stringify({
      tierOne: "STANDARD",
      tierTwo: "VIP",
      tierThree: "VVIP",
      tierOnePrice: parseInt(tierOnePrice),
      tierTwoPrice: parseInt(tierTwoPrice),
      tierThreePrice: parseInt(tierThreePrice),
      tierOneQuantity: parseInt(tierOneQuantity),
      tierTwoQuantity: parseInt(tierTwoQuantity),
      tierThreeQuantity: parseInt(tierThreeQuantity),
      allowEarlyBird: isEarlyBird,
      earlyBirdStartDate: earlyBirdStartDate,
      earlyBirdEndDate: earlyBirdEndDate,
      discount: parseInt(discount),
      ticketTerms: ticketTerms,
    });

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Ticket Successfully Updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const onChangeTicketTerms = (event) => {
    setTicketTerms(event.target.value);
  };

  // console.log("ticketDetails...", ticketDetails);
  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   const value = event.target.value;
  //   setInputs((values) => ({ ...values, [name]: value }));
  // };

  useEffect(() => {
    if (ticketDetails) {
      setTierOnePrice(ticketDetails?.tierOnePrice);
      setTierOneQuantity(ticketDetails?.tierOneQuantity);
      setTierTwoPrice(ticketDetails?.tierTwoPrice);
      setTierTwoQuantity(ticketDetails?.tierTwoQuantity);
      setTierThreePrice(ticketDetails?.tierThreePrice);
      setTierThreeQuantity(ticketDetails?.tierThreeQuantity);
      setTicketTerms(ticketDetails?.ticketTerms);
      setIsEarlyBird(ticketDetails?.allowEarlyBird);
      setEarlyBirdStartDate(ticketDetails?.earlyBirdStartDate);
      setEarlyBirdEndDate(ticketDetails?.earlyBirdEndDate);
      setDiscount(ticketDetails?.discount);
    }
    if (mutation?.isSuccess) {
      handleEditTicket();
    }
  }, [mutation?.isSuccess, ticketDetails]);

  // const handleIsEarlyBird = (event) => {
  //   console.log("event.target.checked...", event.target.checked);
  //   setIsEarlyBird(event.target.checked);
  // };

  // console.log("isEarlyBird...", isEarlyBird);
  // console.log("inputs...", inputs);

  return (
    <div className="w-100">
      <hr></hr>
      <form className="form-create-ticket" onSubmit={handleSubmitForm}>
        <div className="d-flex justify-content-center align-item-center">
          <div className="w-100 text-dark">
            <Row>
              <Col md={4}>
                <label className="">
                  Ticket Name
                  <input
                    type="text"
                    name="standard"
                    className="mb-3 form-control"
                    value="STANDARD"
                    disabled
                  />
                </label>
              </Col>
              <Col md={4}>
                <label className="">
                  Price
                  <input
                    type="number"
                    name="tierOnePrice"
                    className="mb-3 form-control fromInput w-100"
                    value={tierOnePrice}
                    onChange={(e) => setTierOnePrice(parseInt(e.target.value))}

                    // onChange={handleChange}
                  />
                </label>
              </Col>
              <Col md={4}>
                <label className="">
                  Quatity
                  <input
                    type="number"
                    name="tierOneQuantity"
                    className="mb-3 form-control fromInput w-100"
                    value={tierOneQuantity}
                    onChange={(e) =>
                      setTierOneQuantity(parseInt(e.target.value))
                    }
                  />
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <input
                  type="text"
                  name="vip"
                  className="mb-3 form-control fromInput w-100"
                  value="VIP"
                  disabled
                />
              </Col>
              <Col md={4}>
                <input
                  type="number"
                  name="tierTwoPrice"
                  className="mb-3 form-control fromInput w-100"
                  value={tierTwoPrice}
                  onChange={(e) => setTierTwoPrice(parseInt(e.target.value))}
                />
              </Col>
              <Col md={4}>
                <input
                  type="number"
                  name="tierTwoQuantity"
                  className="mb-3 form-control fromInput w-100"
                  value={tierTwoQuantity}
                  onChange={(e) => setTierTwoQuantity(parseInt(e.target.value))}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <input
                  type="text"
                  name="vvip"
                  className="mb-3 form-control fromInput w-100"
                  value="VVIP"
                  disabled
                />
              </Col>
              <Col md={4}>
                <input
                  type="number"
                  name="tierThreePrice"
                  className="mb-3 form-control fromInput w-100"
                  value={tierThreePrice}
                  onChange={(e) => setTierThreePrice(parseInt(e.target.value))}
                />
              </Col>
              <Col md={4}>
                <input
                  type="number"
                  name="tierThreeQuantity"
                  className="mb-3 form-control fromInput w-100"
                  value={tierThreeQuantity}
                  onChange={(e) =>
                    setTierThreeQuantity(parseInt(e.target.value))
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <label className="w-100">
                  Tickets Terms and Conditions
                  <textarea
                    value={ticketTerms}
                    onChange={onChangeTicketTerms}
                    placeholder="Write your Ticket Tems and Condition"
                    className="mb-2 form-control fromInput"
                    rows={5}
                    required
                  ></textarea>
                </label>
              </Col>
            </Row>
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                // name="formatRadio"
                id="exampleRadios1"
                // value={true}
                // onChange={handleIsEarlyBird}
                onClick={() => setIsEarlyBird(!isEarlyBird)}
                setIsEarlyBird
                checked={isEarlyBird}
              />
              <label className="mx-2">Include Early Bird Price</label>
            </div>
            {isEarlyBird ? (
              <>
                <Row>
                  <Col md={4}>
                    <label>
                      Early Bird Start Date
                      <input
                        type="date"
                        name="earlyBirdStartDate"
                        value={earlyBirdStartDate}
                        onChange={(e) => setEarlyBirdStartDate(e.target.value)}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col md={4}>
                    <label>
                      Early Bird Start Date
                      <input
                        type="date"
                        name="earlyBirdEndDate"
                        value={earlyBirdEndDate}
                        onChange={(e) => setEarlyBirdEndDate(e.target.value)}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col md={4}>
                    <label>
                      Discount (%)
                      <input
                        type="number"
                        name="discount"
                        value={discount}
                        onChange={(e) => setDiscount(parseInt(e.target.value))}
                        // onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                </Row>
              </>
            ) : null}

            <div className="d-flex justify-content-center align-item-center">
              <button className="btn btn-primary" type="submit">
                <div className="d-flex justify-content-center align-items-center">
                  {!mutation.isLoading ? <span>Update</span> : null}
                  {!mutation.isLoading ? null : (
                    <span>
                      <Spinner as="span" animation="border" size="sm" /> Loading
                    </span>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
export default EditTicket;
