import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import {
  LOGIN_USER,
  CUSTOM_LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
} from "./actionTypes";
import {
  apiError,
  loginSuccess,
  customLoginSuccess,
  logoutUserSuccess,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  // postFakeLogin,
  // postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history, eventID } }) {
  localStorage.setItem("authShowbayUser", JSON.stringify(user));
  yield put(loginSuccess(user));

  // history("/dashboard");
  if (user?.data?.data?.roles?.includes("registrationOfficer")) {
    history(`/portal/${eventID}`);
  } else {
    history("/dashboard");
  }
}

function* customLoginUser({ payload: { user, history, eventID, userRole } }) {
  if (userRole?.includes("registrationOfficer")) {
    localStorage.setItem("authShowbayUser", JSON.stringify(user));
    yield put(customLoginSuccess(user));
    history(`/portal/${eventID}`);
  }

  if (userRole?.includes("event_organizer")) {
    // console.log("...organiser.......", history);
    localStorage.setItem("authEventKagoUser", JSON.stringify(user));
    yield put(loginSuccess(user));
    history("/");
    // console.log("...userRole.......", userRole);
  }

  // console.log("...user details.......", user);
  //
  //

  //
  //
  // history(`/event-landing/${eventId}`);
  // history(`/portal/${eventId}`);
}

function* logoutUser() {
  try {
    localStorage.removeItem("authShowbayUser");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(LOGOUT_USER, response));
    } else {
      yield put(logoutUserSuccess(LOGOUT_USER, true));
    }
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, data, type);
      localStorage.setItem("authShowbayUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authShowbayUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(CUSTOM_LOGIN_USER, customLoginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
