import React, { useState } from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import Loader from "../../../../components/Loader/Loader";
// import useCustomMutation from "../../../../Hooks/useCustomMutation";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Spinner } from "reactstrap";
import "./css/Summary.scss";

// import AddSpeaker from "./AddSpeaker";

import EventBranding from "./branding/event-branding/Branding";
import EventLandingPage from "./branding/event-lading-page/LandingPage";
import Sponsors from "./branding/sponsors/Sponsors";

import "./css/branding.scss";

const BrandingTab = ({ userId, token, eventID }) => {
  const [activeLink, setActiveLink] = useState(0);

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  // const {
  //   data: getMySessions,
  //   error: errorGetMySessions,
  //   isLoading: isLoadingGetMySessions,
  // } = useCustomQuery(
  //   ["mySession", eventID],
  //   `https://orca-app-65xn9.ondigitalocean.app/api/v1/sessions/by-event/standard/${eventID}`,
  //   token
  // );

  // console.log("getMyEventDetail...", getMyEventDetail);
  // console.log("getSession...", getMySessions);

  if (isLoadingGetMyEventDetail) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );
  }

  // if (isLoadingGetMySessions) {
  //   <div className="mt-5 d-flex justify-content-center align-items-center">
  //     <Spinner animation="border" role="status">
  //       <span className="visually-hidden">Loading...</span>
  //     </Spinner>
  //   </div>;
  // }

  // Function to handle link clicks
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  return (
    <React.Fragment>
      <div className="branding-container">
        <div className="column branding-menu">
          <ul className="branding-menu__list">
            <li className="branding-menu__item">
              <a
                href="#"
                className={`branding-menu__link ${
                  activeLink === 0 ? "branding-menu__list--active" : ""
                }`}
                onClick={() => handleLinkClick(0)}
              >
                Event Reception
              </a>
            </li>
            <li className="branding-menu__item">
              <a
                href="#"
                className={`branding-menu__link ${
                  activeLink === 2 ? "branding-menu__list--active" : ""
                }`}
                onClick={() => handleLinkClick(2)}
              >
                Event Landing Page
              </a>
            </li>

            {/* <li className="branding-menu__item">
              <a
                href="#"
                className={`branding-menu__link ${
                  activeLink === 4 ? "branding-menu__list--active" : ""
                }`}
                onClick={() => handleLinkClick(4)}
              >
                Sponsors
              </a>
            </li> */}
          </ul>
        </div>
        <div className="column branding-content">
          {activeLink === 0 && (
            <EventBranding token={token} eventID={eventID} />
          )}
          {activeLink === 2 && (
            <EventLandingPage
              token={token}
              eventID={eventID}
              eventDetails={getMyEventDetail}
            />
          )}
          {activeLink === 4 && <Sponsors token={token} eventID={eventID} />}
        </div>
      </div>
    </React.Fragment>
  );
};
export default BrandingTab;
