import React, { useState } from "react";
import { Spinner } from "reactstrap";
import useCustomMutation from "../../../../../../Hooks/useCustomMutation";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import "./hexagonePicture.scss";
// import MainSponsorsExbition from "../../../../../../assets/images/exhibitonLobby/MainSponsorsExbition.png";
import MainSponsorsExbition from "../../../../../../assets/images/exhibitonLobby/MainSponsorsExbition.png";

import First from "./First";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Fourth";
import Fifth from "./Fifth";
import { Mutation } from "@tanstack/react-query";

function MainSponsors({ userId, token, eventID }) {
  const [firstSever, setFirstSever] = useState("");
  const [secondSever, setSecondSever] = useState("");
  const [thirdSever, setThirdSever] = useState("");
  const [fourthSever, setFourthSever] = useState("");
  const [fifthSever, setFifthSever] = useState("");

  console.log("token...", token);
  const key = ["Main-sponsors-exhibition", eventID];
  const mutation = useCustomMutation(key);

  const handleSponsor = () => {
    const Method = "POST",
      url = `lobby-banners/${eventID}`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("files", firstSever);
    formdata.append("files", secondSever);
    formdata.append("files", thirdSever);
    formdata.append("files", fourthSever);
    formdata.append("files", fifthSever);
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Main Sponsors Successfully Created",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  return (
    <div className="w-100">
      <h4>Main Exhibition Sponsors</h4>
      <p>Upload maximum 5 sponsors with no background</p>
      <div className="hexagon-banner-conatiner">
        <div className="hexagonIllustrationContainer">
          <div className="hexagonIllustrationContainerImg">
            <img src={MainSponsorsExbition} alt="mainSponsors" />
          </div>
        </div>
        <div
          className="w-100 mt-4 d-flex justify-content-center"
          style={{ gap: 10 }}
        >
          <First firstSever={firstSever} setFirstSever={setFirstSever} />
          <Second secondSever={secondSever} setSecondSever={setSecondSever} />
          <Third thirdSever={thirdSever} setThirdSever={setThirdSever} />
          <Fourth fourthSever={fourthSever} setFourthSever={setFourthSever} />
          <Fifth fifthSever={fifthSever} setFifthSever={setFifthSever} />
        </div>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <button
          className="btn btn-primary mt-3"
          onClick={() => handleSponsor()}
        >
          {mutation?.isLoading ? (
            <Spinner as="span" animation="border" size="sm" />
          ) : (
            "Upload Sponsors"
          )}
        </button>
      </div>
    </div>
  );
}

export default MainSponsors;
