import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/Summary.scss";
//icons

import { BsEyeFill } from "react-icons/bs";

import { Link } from "react-router-dom";

import "../css/summary.styles.scss";

import Schedule from "./Schedule/Schedule";
import Booth from "./Booth/Booth";
import Activities from "./Activities/Sessions";
import Host from "./Host/Host";
import Sponsors from "./Sponsor/Sponsors";
import EventDetails from "./EventDetails/EventDetails";
import VideoLink from "./VideoLink/VideoLink";
import FAQS from "./components/FAQS";

const Summary = ({ userId, token, eventID, eventDetails, envetType }) => {
  // console.log("...Type...", eventDetails?.eventType);
  return (
    <React.Fragment>
      <div className="summary-container">
        <div className="summary-setup">
          <div className="summary-setup__heading">
            <h4>Summary</h4>
            <div>
              <Link
                to={`/${eventDetails?.eventName?.replace(
                  / /g,
                  "-"
                )}/event-landing/${eventID}`}
              >
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                >
                  <div className="d-flex justify-content-center align-center ">
                    <span>
                      <BsEyeFill size={20} />
                    </span>
                    <span className="px-2">View Event Page</span>
                  </div>
                </button>
              </Link>
            </div>
          </div>
          {eventDetails?.eventType?.includes?.("Conference") ? (
            <Schedule
              eventID={eventID}
              token={token}
              userId={userId}
              eventFormat={eventDetails?.eventFormat}
            />
          ) : null}
          {eventDetails?.eventType?.includes?.("Social") ? (
            <Activities
              eventID={eventID}
              token={token}
              userId={userId}
              eventDetails={eventDetails}
            />
          ) : null}
          {eventDetails?.eventType?.includes?.("Exhibition") ? (
            <Booth eventID={eventID} token={token} userId={userId} />
          ) : null}
          <Host userId={userId} eventID={eventID} token={token} />
          <Sponsors eventID={eventID} token={token} />
          <FAQS />
        </div>
        <div className="summary-details">
          <div
            className="summary-setup__heading"
            style={{ visibility: "hidden" }}
          >
            <h4>Summary</h4>
            <div>
              <Link to={`/event-landing/${eventID}`}>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                >
                  <div className="d-flex justify-content-center align-center ">
                    <span>
                      <BsEyeFill size={20} />
                    </span>
                    <span className="px-2">View Event Page</span>
                  </div>
                </button>
              </Link>
            </div>
          </div>
          <EventDetails
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetails={eventDetails}
          />

          <hr className="break-details" />

          <VideoLink
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetails={eventDetails}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Summary;
