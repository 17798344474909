import { useMutation, useQueryClient } from "@tanstack/react-query";
import post from "./post";
import { dangerMessage } from "../components/Toast/Toast";

function useCustomMutation(key) {
  const queryClient = useQueryClient();

  return useMutation(
    (data) =>
      post(
        data.endPoint,
        data.bodyContent,
        data.Method,
        data.displayMessage,
        data.token,
        data.isJSON
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        dangerMessage("Oops! your internet is not working");
      },
    }
  );
}
export default useCustomMutation;
