import React, { useState, useEffect, useRef } from "react";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
import { Spinner } from "reactstrap";

import { BsPeople } from "react-icons/bs";
import { BiMessageSquareDetail } from "react-icons/bi";
import { IoNotificationsOutline } from "react-icons/io5";
import { BiBell } from "react-icons/bi";

// import { BiSolidBellRing } from "react-icons/bi";

import Reception from "../reception/reception.component";
import Community from "../../../../../components/Common/chats/Index";
// import Community from "../community/Community";
import Sessions from "../sessions/sessions.component";
import Booths from "../booth/Index";

// import DropDown from "./components/drop-down/drop-down.component";
import { attendees, messages, alerts } from "./components/attendees";

import "./reception-header.styles.scss";

const ReceptionHeader = ({ userId, token, eventID }) => {
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userEmail = obj?.data?.data?.email;
  const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [activeLink, setActiveLink] = useState(0);
  const [isDropdownVisiblePeople, setDropdownVisibilityPeople] =
    useState(false);
  const [isDropdownVisibleMessages, setDropdownVisibilityMessages] =
    useState(false);
  const [isDropdownVisibleAlerts, setDropdownVisibilityAlerts] =
    useState(false);

  const peopleDropdownRef = useRef(null);
  const messagesDropdownRef = useRef(null);
  const alertsDropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        peopleDropdownRef.current &&
        !peopleDropdownRef.current.contains(event.target) &&
        messagesDropdownRef.current &&
        !messagesDropdownRef.current.contains(event.target) &&
        alertsDropdownRef.current &&
        !alertsDropdownRef.current.contains(event.target)
      ) {
        setDropdownVisibilityPeople(false);
        setDropdownVisibilityMessages(false);
        setDropdownVisibilityAlerts(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleDropdownPeople = () => {
    setDropdownVisibilityPeople(!isDropdownVisiblePeople);
  };
  const toggleDropdownMessages = () => {
    setDropdownVisibilityMessages(!isDropdownVisibleMessages);
  };
  const toggleDropdownAlerts = () => {
    setDropdownVisibilityAlerts(!isDropdownVisibleAlerts);
  };

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  const {
    data: getMySession,
    error: errorGetMySession,
    isLoading: isLoadingGetMySession,
  } = useCustomQuery(
    ["mySessionAsSpeaker", eventID],
    `${process.env.REACT_APP_API}sessions/speaker-sessions/${userEmail}`,
    token
  );

  console.log("getMySession...", getMySession);

  if (isLoadingGetMyEventDetail) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // console.log("getMyEventDetail...", getMyEventDetail);

  return (
    <React.Fragment>
      <header className="reception-header">
        <nav className="reception-navbar">
          <ul className="reception-navbar__menu">
            <li className="reception-navbar__menu--item">
              <a
                href="#"
                className={`reception-navbar__menu--link ${
                  activeLink === 0 ? "reception-navbar__menu--link--active" : ""
                }`}
                onClick={() => handleLinkClick(0)}
              >
                Reception
              </a>
            </li>
            {getMyEventDetail?.eventType?.includes("Exhibition") ? null : (
              <li className="reception-navbar__menu--item">
                <a
                  href="#"
                  className={`reception-navbar__menu--link ${
                    activeLink === 0
                      ? "reception-navbar__menu--link--active"
                      : ""
                  }`}
                  onClick={() => handleLinkClick(2)}
                >
                  My Session(s)
                </a>
              </li>
            )}

            {getMyEventDetail?.eventType?.includes("Exhibition") ? (
              <li className="reception-navbar__menu--item">
                <a
                  href="#"
                  className={`reception-navbar__menu--link ${
                    activeLink === 0
                      ? "reception-navbar__menu--link--active"
                      : ""
                  }`}
                  onClick={() => handleLinkClick(4)}
                >
                  Booths
                </a>
              </li>
            ) : null}
            <li className="reception-navbar__menu--item">
              <a
                href="#"
                className={`reception-navbar__menu--link ${
                  activeLink === 0 ? "reception-navbar__menu--link--active" : ""
                }`}
                onClick={() => handleLinkClick(6)}
              >
                Community Chat
              </a>
            </li>
          </ul>
        </nav>
        <div className="reception-header__extended">
          <div className="tooltiping" ref={peopleDropdownRef}>
            <BsPeople
              className="reception-header__extended--icon"
              size={23}
              onClick={toggleDropdownPeople}
            />
            <div
              className="tooltiptext reception-header__extended--icon--tooltip"
              style={{ textAlign: "left", padding: "8px" }}
            >
              <span style={{ fontWeight: 600, marginBottom: "8px" }}>
                People
              </span>
              <br />
              <span style={{ fontSize: "12px" }}>
                Discover and connect with fellow attendees
              </span>
            </div>
            {isDropdownVisiblePeople && (
              <div className="tooltiping__content">
                {attendees.slice(0, 4).map((attendee) => (
                  <div className="tooltiping__content--attendee">
                    <img
                      className="tooltiping__content--attendee--img"
                      src={attendee.profileImg}
                      alt="showbay attendee list attendee"
                    />
                    <div className="tooltiping__content--attendee--info">
                      <p className="tooltiping__content--attendee--info-name">
                        {`${attendee.firstName} ${attendee.lastName}`}
                      </p>
                      <div className="tooltiping__content--attendee--info-status">
                        <div
                          style={{
                            width: "4px",
                            height: "4px",
                            backgroundColor: "#2dd173",
                            borderRadius: "50%",
                          }}
                        ></div>
                        {attendee.status}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="tooltiping__content--show-all">Load more</div>
              </div>
            )}
          </div>

          <div className="tooltiping" ref={messagesDropdownRef}>
            <BiMessageSquareDetail
              className="reception-header__extended--icon"
              size={22}
              onClick={toggleDropdownMessages}
            />
            <div
              className="tooltiptext reception-header__extended--icon--tooltip"
              style={{ textAlign: "left", padding: "8px" }}
            >
              <span style={{ fontWeight: 600, marginBottom: "8px" }}>
                Messages
              </span>
              <br />
              <span style={{ fontSize: "12px" }}>
                Reply to your direct messages here
              </span>
            </div>
            {isDropdownVisibleMessages && (
              <div className="tooltiping__content">
                {messages.slice(0, 4).map((message) => (
                  <div className="tooltiping__content--message">
                    <div style={{ width: "15%", height: "15%" }}>
                      <img
                        className="tooltiping__content--message--img"
                        src={message.profileImg}
                        alt="showbay attendee list attendee"
                      />
                      <div className="tooltiping__content--message--activity-track"></div>
                    </div>
                    <div className="tooltiping__content--message--info">
                      <p className="tooltiping__content--message--info-name">
                        {`${message.firstName} ${message.lastName}`}
                      </p>
                      <span className="tooltiping__content--message--info-status">
                        {message.msg}
                      </span>
                    </div>
                  </div>
                ))}
                <div className="tooltiping__content--show-all">
                  See all messages
                </div>
              </div>
            )}
          </div>

          <div className="tooltiping" ref={alertsDropdownRef}>
            <IoNotificationsOutline
              className="reception-header__extended--icon"
              size={23}
              onClick={toggleDropdownAlerts}
            />
            <div
              className="tooltiptext reception-header__extended--icon--tooltip"
              style={{ textAlign: "left", padding: "8px" }}
            >
              <span style={{ fontWeight: 600, marginBottom: "8px" }}>
                Alerts
              </span>
              <br />
              <span style={{ fontSize: "12px" }}>
                View event announcements sent by hosts
              </span>
            </div>
            {isDropdownVisibleAlerts && (
              <div className="tooltiping__content">
                {alerts.slice(0, 4).map((alert) => (
                  <div className="tooltiping__content--alerts">
                    <div style={{ width: "10%", height: "10%" }}>
                      <BiBell className="tooltiping__content--alerts--img" />
                    </div>
                    <div className="tooltiping__content--alerts--info">
                      <p className="tooltiping__content--alerts--info-name">
                        {`${alert.title}`}
                      </p>
                      <span className="tooltiping__content--alerts--info-status">
                        {alert.alert}
                      </span>
                    </div>
                  </div>
                ))}
                <div className="tooltiping__content--show-all">
                  Show all activity
                </div>
              </div>
            )}
          </div>
          {/* <div className="reception-header__extended--profile">
            <span>JD</span>
          </div> */}
        </div>
      </header>
      <div
        className="pt-5"
        style={{ background: "#131216", height: "max-content" }}
      >
        {activeLink === 0 && (
          <Reception
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetail={getMyEventDetail}
          />
        )}
        {activeLink === 2 && (
          <Sessions
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetail={getMyEventDetail}
          />
        )}
        {activeLink === 4 && (
          <Booths
            userId={userId}
            token={token}
            eventID={eventID}
            eventDetail={getMyEventDetail}
          />
        )}
        {activeLink === 6 && <Community />}
      </div>
    </React.Fragment>
  );
};

export default ReceptionHeader;
