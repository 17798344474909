import React, { useState } from "react";
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from "reactstrap";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate, Link } from "react-router-dom";
// import classnames from "classnames";
import CustomModal from "../../../../components/Common/boothModal/slide-in-modal.component";
import BoothBody from "../../PreviewBooth/Index";

function NavBar({
  eventID,
  token,
  eventDetails,
  boothDetails,
  boothID,
  boothInvite,
  myEventName,
}) {
  const Navigate = useNavigate();
  const [previewBooth, setPreviewBooth] = useState(false);
  const toggleSlideInPreview = () => {
    setPreviewBooth(!previewBooth);
  };

  // <Link
  //   to={`/${myEventName?.replace(
  //     / /g,
  //     "-"
  //   )}/event-landing/${eventID}`}
  // >
  //   <button type="button" className="btn btn-primary waves-effect waves-light">
  //     <div className="d-flex justify-content-center align-center ">
  //       <span>
  //         <BsEyeFill size={20} />
  //       </span>
  //       <span className="px-2">View Landing Page</span>
  //     </div>
  //   </button>
  // </Link>;
  //   console.log("boothDetails....", boothDetails);

  return (
    <div>
      <Navbar style={{ backgroundColor: "#fff" }}>
        <NavbarBrand href="/dashboard">
          <div className="d-flex">
            <div style={{ paddingLeft: 36, paddingRight: 18 }}>
              <IoArrowBack size={30} />
            </div>
            <div>
              <h3 style={{ color: "#000" }}>{eventDetails?.eventName}</h3>
              {boothInvite?.map((item, i) => {
                if (item?.eventId === eventID) {
                  return (
                    <p style={{ fontSize: 12 }} key={i}>
                      <i
                        className="fas fa-calendar"
                        style={{ marginLeft: 4, marginRight: 8 }}
                      ></i>
                      {item?.email}
                    </p>
                  );
                }
              })}
            </div>
          </div>
        </NavbarBrand>

        <Nav className="ml-auto px-4" navbar>
          <div className="d-flex justify-content-center">
            <NavItem className="px-2">
              <NavLink href="#">
                <Link
                  to={`/${myEventName?.replace(
                    / /g,
                    "-"
                  )}/event-landing/${eventID}`}
                  className="px-2"
                >
                  <button
                    className="btn btn-primary waves-effect waves-light Publish__btn__event"
                    // onClick={handlePreview}
                    onClick={() => setPreviewBooth(!previewBooth)}
                  >
                    View Event Page
                  </button>
                </Link>
              </NavLink>
            </NavItem>
            <NavItem className="px-2">
              <NavLink href="#">
                {boothID && (
                  <button
                    className="btn btn-success waves-effect waves-light Publish__btn__event"
                    // onClick={handlePreview}
                    onClick={() => setPreviewBooth(!previewBooth)}
                  >
                    Preview Booth
                  </button>
                )}
              </NavLink>
            </NavItem>

            {previewBooth ? (
              <CustomModal
                isOpen={previewBooth}
                toggle={toggleSlideInPreview}
                title="Booth"
                body={
                  <BoothBody
                    eventID={eventID}
                    token={token}
                    boothDetails={boothDetails}
                    boothID={boothID}
                  />
                }
              />
            ) : null}
          </div>
        </Nav>
      </Navbar>
    </div>
  );
}

export default NavBar;
