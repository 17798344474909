import React from "react";
import { Col, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import useCustomQuery from "../../../Hooks/useCustomQuery";
import defaultImg from "../../../assets/images/event/DefaultBanner.jpg";
function AllInviteEvents({ search, userEmail, token, t }) {
  const Navigate = useNavigate();

  // events/explore/eventID;

  const {
    data: getEvents,
    error: errorGetEvents,
    isLoading: isLoadingGetEvents,
  } = useCustomQuery(
    ["MyExploreEvents", userEmail],
    `${process.env.REACT_APP_API}events/explore`,
    token
  );

  if (isLoadingGetEvents) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // isLoadingGetEvents;
  // console.log("me...");

  // console.log("getEvents...get......Event.....", getEvents);

  const filterEventsByName = (events, searchQuery) => {
    if (!searchQuery) return events;
    return events.filter((event) =>
      event?.eventName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };
  const filteredEvents = filterEventsByName(getEvents, search);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const handleEnterEvent = (eventName, evID) => {
    Navigate(`/${eventName.replace(/ /g, "-")}/Invite/${evID}`);
  };
  return (
    <>
      {filteredEvents?.map((item, index) => {
        // const date = item.StartDateTime.split(" ");
        // console.log("Get all events....", item?.state);

        // Event date configuration
        let dateValue = new Date(item.startDate);

        let day = dayNames[dateValue.getUTCDay()];
        let month = monthNames[dateValue.getUTCMonth()];
        let year = dateValue.getUTCFullYear();
        let date = dateValue.getUTCDate();

        let formattedDate = `${day} ${date} ${month} ${year}`;

        return (
          <div key={index}>
            <Col xs={12} sm={12} md={12} lg={12} key={index}>
              <div className="d-flex align-items-center p-3 mb-4 shadow-sm bg-white card-brd">
                <div
                  className="MyEvent_left_container-div d-flex align-items-center"
                  style={{ flex: 0.4 }}
                >
                  <div className="MyEvent_Parent_div">
                    <div className="MyEvent_image_div">
                      <img
                        src={item?.bannerImage ? item?.bannerImage : defaultImg}
                        width="100%"
                        height="100%"
                        className="Image_Fit card-brd"
                        alt="event"
                      />
                    </div>
                    <div className="MyEvent_banner_div text-light">
                      <div
                        className={
                          item?.eventType?.includes("Social")
                            ? "ribbon-4 px-4 MyEvent__ribbon__text"
                            : item?.eventType?.includes("Exhibition") &&
                              item?.eventType?.includes("Conference")
                            ? "ribbon-5 px-4 MyEvent__ribbon__text" // New class for both "Exhibition" and "Conference"
                            : item?.eventType?.includes("Exhibition")
                            ? "ribbon-3 px-4 MyEvent__ribbon__text"
                            : "ribbon-2 px-4 MyEvent__ribbon__text"
                        }
                      >
                        {item?.eventType?.includes("Social")
                          ? "Social"
                          : item?.eventType?.includes("Exhibition") &&
                            item?.eventType?.includes("Conference")
                          ? "Expo"
                          : "Conference"}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-column justify-content-around p-4 mx-3">
                    <h5 className="MyEvent__text" style={{ marginBottom: -8 }}>
                      {item.eventName}
                    </h5>
                    <div
                      className="mt-3 justify-content-end"
                      style={{ color: "#000000", fontSize: 12 }}
                    >
                      <div className="MyEvent__date">{formattedDate}</div>
                      <div className="MyEvent__date">
                        {`${item.startTime} - ${item.endTime} SAST`}
                      </div>
                    </div>
                    <div className="mt-3 justify-content-end">
                      <div className="MyEvent__date">
                        <h5
                          className="MyEvent__text text-success"
                          style={{ fontSize: 12 }}
                        >
                          Published
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="d-flex justify-content-evenly align-items-center"
                  style={{ flex: 0.6 }}
                >
                  <div>
                    <h5
                      className={
                        item?.eventStatus === "Upcoming"
                          ? `text-info font-weight-bold MyEvent__status`
                          : item?.eventStatus === "Ongoing"
                          ? `text-success font-weight-bold MyEvent__status`
                          : `text-danger font-weight-bold MyEvent__status`
                      }
                    >
                      <span style={{ visibility: "hidden" }}>data</span>
                      {t(`${item?.eventStatus}`)}
                    </h5>
                  </div>

                  {/* -------------------------------------------------------------- Actions ------------------------------------------------------------------ */}
                  <div
                    style={{
                      marginLeft: 25,
                    }}
                  >
                    <div>
                      <button
                        type="button"
                        className="btn px-3 btn-md shadow-sm text-light btnMyEventEdit"
                        onClick={() =>
                          handleEnterEvent(item?.eventName, item._id)
                        }
                      >
                        Enter Event
                        <span style={{ visibility: "hidden" }}>ii.</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        );
      })}
    </>
  );
}

export default AllInviteEvents;
