import React, { useState } from "react";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../../Hooks/useCustomMutation";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";
import "../css/Summary.scss";

import MainSponsors from "./components/MainSponsors";
import MainSidebar from "./components/MainSidebar";

import "../css/sessions.scss";

const TabExhibition = ({ userId, token, eventID }) => {
  const [activeLink, setActiveLink] = useState(0);

  // Function to handle link clicks
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  return (
    <React.Fragment>
      <div className="sessions-container">
        <div className="column sessions-menu">
          <ul className="sessions-menu__list">
            <li className="sessions-menu__item">
              <a
                href="#"
                className={`sessions-menu__link ${
                  activeLink === 0 ? "sessions-menu__list--active" : ""
                }`}
                onClick={() => handleLinkClick(0)}
              >
                Exhibition Main Sponsors
              </a>
            </li>
            <li className="sessions-menu__item">
              <a
                href="#"
                className={`sessions-menu__link ${
                  activeLink === 2 ? "sessions-menu__list--active" : ""
                }`}
                onClick={() => handleLinkClick(2)}
              >
                Exhibition Sidebar Sponsors
              </a>
            </li>
          </ul>
        </div>
        {activeLink === 0 && (
          <div className="column sessions-content--summary">
            <MainSponsors userId={userId} token={token} eventID={eventID} />
          </div>
        )}
        {activeLink === 2 && (
          <div className="column sessions-content--summary">
            <MainSidebar userId={userId} token={token} eventID={eventID} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default TabExhibition;
