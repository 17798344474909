import React from "react";
import { Modal } from "reactstrap";

function CustomModal({ isOpen, toggleEvent, title, body }) {
  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggleEvent}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
          {title}
        </h5>
        <button
          onClick={toggleEvent}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">{body}</div>
    </Modal>
  );
}

export default CustomModal;
