import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { IoCloseCircleOutline } from "react-icons/io5";
import "./SlideBigBar.scss"; // Create a CSS file for styling

const SlideBigBar = ({ isOpen, toggle, title, body }) => {
  // const [activeLink, setActiveLink] = useState(0);

  const handleClickInside = (e) => {
    e.stopPropagation(); // Prevent the click event from propagating to the overlay
  };

  return (
    <div className={`booths-slide-in-modal-light ${isOpen ? "show" : ""}`}>
      <div className="booths-slide-in-overlay-light" onClick={toggle}></div>
      <div
        className="booths-slide-in-content-light"
        onClick={handleClickInside}
      >
        <div className="booths-slide-in-header-light">
          <div>
            <h3 className="modal-title-light mt-0">{title}</h3>
          </div>
          <button
            className="close"
            onClick={toggle}
            aria-label="Close"
            style={{
              paddingTop: "48px",
              paddingRight: "56px",
              fontSize: 14,
              color: "#e4e5e6",
            }}
          >
            {/* <IoCloseCircleOutline size={28} style={{ cursor: "pointer" }} /> */}
          </button>
        </div>
        <div className="booths-slide-in-body-light">{body}</div>
      </div>
    </div>
  );
};

export default SlideBigBar;
