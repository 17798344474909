import React from "react";
import Main from "./components/Main";

const EventSettings = ({ eventID, token, boothDetails, boothID }) => {
  return (
    <Main
      eventID={eventID}
      token={token}
      boothDetails={boothDetails}
      boothID={boothID}
    />
  );
};

export default EventSettings;
