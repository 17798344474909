import React, { useState } from "react";
import {
  warningMessage,
  dangerMessage,
  successMessage,
} from "../../../components/Toast/Toast";
import { Spinner, Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import excelBulkAttendeeFile from "../../../assets/ticketBulk/bulkTicket.xlsx";

// import "./css/Summary.scss";
import "./css/AttachBulkTicket.scss";

const AttachBulkDoc = ({ handleNext, eventDetails, token }) => {
  const [isAttachmentDocument, setIsAttachmentDocument] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [btnLoad, setBtnLoad] = useState(false);

  const handleSubmitForm = (event) => {
    event.preventDefault();
    setBtnLoad(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    const formdata = new FormData();
    if (isAttachmentDocument) {
      isAttachmentDocument?.map((item) => {
        formdata.append("document", item);
      });
    }
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    return fetch(
      `${process.env.REACT_APP_API}bulk-attendees/add/tickets/${eventDetails?._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res?.status === "success") {
          setBtnLoad(false);
          successMessage(`User Successfully Registered`);
          handleNext();
        } else {
          setBtnLoad(false);
          warningMessage(`${res?.message}`);
        }
      })
      .catch((error) => {
        setBtnLoad(false);
        dangerMessage(`${error.message}`);
      });
  };

  //attach document function
  const handleAttachment = (event) => {
    const selectedFiles = event.target.files;
    const fileArray = Array.from(selectedFiles);
    setIsAttachmentDocument(fileArray);

    const previews = fileArray.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  //define file icon
  const getFileIcon = (fileName) => {
    if (fileName.endsWith(".pdf")) {
      return "far fa-file-pdf";
    } else if (fileName.endsWith(".xls") || fileName.endsWith(".xlsx")) {
      return "far fa-file-excel";
    } else if (fileName.endsWith(".doc") || fileName.endsWith(".docx")) {
      return "far fa-file-word";
    } else if (fileName.endsWith(".JPG") || fileName.endsWith(".jpg")) {
      return "fas fa-file-image";
    } else if (fileName.endsWith(".PNG") || fileName.endsWith(".png")) {
      return "fas fa-file-image";
    } else if (fileName.endsWith(".JPEG") || fileName.endsWith(".jpeg")) {
      return "fas fa-file-image";
    }
    // Add more conditions for other file types if needed
    return "far fa-file"; // Default icon
  };

  // if (mutation.isSuccess) {
  //   setSlideInOpenDocument(false);
  // }

  return (
    <React.Fragment>
      <div className="bulkTicket__body">
        <label className="mt-4">
          Registration Instructions:
          <span style={{ fontSize: 13, fontWeight: "lighter" }}>
            Please first download the registration template below, complete it,
            and then upload the filled-out template using the upload button
            below.
          </span>
        </label>
        <br></br>
        <a href={excelBulkAttendeeFile} download="BulkAttendee.xlsx">
          <Button className="btn bg-success" style={{ border: "none" }}>
            Download Template
          </Button>
        </a>
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="booth__body--form"
          onSubmit={handleSubmitForm}
        >
          <div className="mt-0">
            {/* ------------------ Form Fields ---------------------- */}

            <div className="booth__body--form-input-container">
              {/* <div className="">
                <p className="h4">Upload Bulk Attendee(s)* </p>
              </div> */}

              <div className="file-previews-doc">
                {isAttachmentDocument?.map((document, index) => {
                  // console.log("file...", document);
                  const isImage =
                    getFileIcon(document.name) === "fas fa-file-image";
                  const imageUrl = isImage
                    ? URL.createObjectURL(document)
                    : null;
                  return (
                    <>
                      {isImage ? (
                        <div key={index} className="file-preview-doc-img">
                          <img
                            src={imageUrl}
                            alt={document.name}
                            style={{
                              width: "70px",
                              height: "60px",
                              borderRadius: "5px",
                            }}
                          />
                        </div>
                      ) : (
                        <div key={index} className="file-preview-doc">
                          <i
                            className={`${getFileIcon(
                              document.name
                            )} file-icon-doc`}
                          ></i>
                          <div className="file-details-doc">
                            <div className="file-name-doc">{document.name}</div>
                            <div className="file-size-doc">
                              {(document.size / 1024).toFixed(2)} KB
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>

              <div className="mb-3 drop-zone">
                <label htmlFor="attachment" className="drop-zone-label">
                  <i className="fas fa-cloud-upload-alt"></i>
                  <div className="drop-zone-text">Attach file(s)</div>
                </label>
                <input
                  type="file"
                  id="attachment"
                  className="form-control drop-zone-input"
                  // multiple={false}
                  accept=".xls,.xlsx, .xlsx/*"
                  onChange={handleAttachment}
                />
              </div>
              <div
                className="sponsor-form-body__sponsor-logo"
                style={{ marginRight: "12px", marginBottom: "-16px" }}
              >
                <p
                  color="ambience.6"
                  // font-size="1"
                  className="sponsor-form-body--input-description"
                >
                  <span className="h6">Recommended format: </span>
                  EXCEL File| Up to 11 MB
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center" style={{}}>
            <button className="btn btn-primary" type="submit">
              <div className="d-flex justify-content-center align-items-center text-light">
                {!btnLoad ? <span>Upload</span> : null}
                {!btnLoad ? null : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                )}
              </div>
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default AttachBulkDoc;
