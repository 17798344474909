import React, { useState, useRef, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useCustomMutation from "../../../../../Hooks/useCustomMutation";
import { Spinner, Button, Row, Col } from "reactstrap";

import { countryList } from "../../../../../components/Countries/countries";
import { BiEdit } from "react-icons/bi";

function Event({ userId, token, eventID, eventDetails, setShowEditEvent }) {
  const queryClient = useQueryClient();
  const [isPicture, setIsPicture] = useState(false);
  const [Banner, setBanner] = useState(false);
  const [BannerServer, setBannerServer] = useState();
  const [eventName, setEventName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [eventFormat, setEventFormat] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [visibility, setVisibility] = useState("");

  const [inputs, setInputs] = useState({});

  // connnect to api
  const key = ["myEvents", userId];

  const mutation = useCustomMutation(key);

  // function to set event
  const hybridFunc = (e) => {
    setEventFormat("Hybrid");
  };
  const inPersonFunc = (e) => {
    setEventFormat("In Person");
  };
  const virtualFunc = (e) => {
    setEventFormat("Virtual");
  };

  const EventStatePublic = (e) => {
    setVisibility("Public");
  };
  const EventStateFunc2 = (e) => {
    setVisibility("Private");
  };

  // image upload

  let iconStyles = {
    color: "#1a87a3",
    filter: "drop-shadow(0px 0px 10px white)",
    backgroundColor: "white",
    borderRadius: 5,
  };

  const refFileUpload = useRef("");

  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setBannerServer(event.target.files[0]);
      setIsPicture(false);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setBanner(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  // // handleValidSubmit

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const onChangeEventDescription = (event) => {
    setEventDescription(event.target.value);
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "PATCH",
      url = `events/${eventID}`,
      isJSON = true;
    var formdata = new FormData();
    if (BannerServer) {
      formdata.append("bannerImage", BannerServer);
    }
    if (eventName) {
      formdata.append("eventName", eventName);
    }
    if (eventDescription) {
      formdata.append("eventDescription", eventDescription);
    }

    if (startDate) {
      formdata.append("startDate", startDate);
    }
    if (endDate) {
      formdata.append("endDate", endDate);
    }
    if (startTime) {
      formdata.append("startTime", startTime);
    }
    if (endTime) {
      formdata.append("endTime", endTime);
    }

    if (eventFormat) {
      formdata.append("eventFormat", eventFormat);
    }
    //  if (eventFormat) {
    //    formdata.append("eventType", "Conference");
    //  }
    if (visibility) {
      formdata.append("visibility", visibility);
    }

    // formdata.append("state", "Not Published");

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Event Successfully Updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      queryClient.invalidateQueries(["myEventDetail", eventID]);
      setShowEditEvent(false);
    }
    if (eventDetails) {
      if (eventDetails?.bannerImage) {
        setBannerServer(eventDetails?.bannerImage);
        setIsPicture(true);
      }

      setEventName(eventDetails?.eventName);
      setEventDescription(eventDetails?.eventDescription);
      if (eventDetails?.startDate) {
        const date = new Date(eventDetails?.startDate);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        setStartDate(`${year}-${month}-${day}`);
      }
      if (eventDetails?.endDate) {
        const date = new Date(eventDetails?.endDate);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        setEndDate(`${year}-${month}-${day}`);
      }

      setStartTime(eventDetails?.startTime);
      setEndTime(eventDetails?.endTime);
      setEventFormat(eventDetails?.eventFormat);
      setVisibility(eventDetails?.visibility);
    }
  }, [mutation.isSuccess, eventDetails]);

  return (
    <div className="d-flex justify-content-center">
      <div className="w-100 justify-content-center">
        <div className="w-100 d-flex justify-content-center">
          <div className="event__form__banner_container">
            <img
              // src={isPicture ? BannerServer : ConferenceBanner}
              src={isPicture ? BannerServer : Banner}
              alt="eventBanner"
              className="Image_Fit event__form__banner_img "
            />
            <Button
              size="md"
              variant="separator-light"
              className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
              onClick={onThumbChangeClick}
            >
              <BiEdit size={40} style={iconStyles} />
            </Button>
            <input
              type="file"
              ref={refFileUpload}
              className="file-upload d-none"
              accept="image/*"
              onChange={changeThumb}
            />
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center mt-4">
          <form className="form-create-event" onSubmit={handleSubmitForm}>
            <Row>
              <Col md={12}>
                <label className="event__input__form__Creation">
                  Event Name
                  <input
                    type="text"
                    name="eventName"
                    className="mb-3 form-control fromInput w-100"
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                    // onChange={handleChange}
                  />
                </label>
              </Col>
              <Col md={12}>
                <label className="event__input__form__Creation">
                  Event Type
                  <input
                    type="text"
                    name="eventType"
                    placeholder="Conference"
                    className="mb-3 form-control fromInput w-100"
                    value={
                      eventDetails?.eventType &&
                      eventDetails?.eventType.length > 1
                        ? "Expo"
                        : eventDetails?.eventType
                    }
                    disabled
                  />
                </label>
              </Col>
              <Col md={12}>
                <label className="event__input__form__Creation">
                  Event Description
                  <textarea
                    value={eventDescription}
                    onChange={onChangeEventDescription}
                    placeholder="Write a short summary about the event..."
                    className="mb-3 form-control fromInput"
                    rows={5}
                    required
                  ></textarea>
                </label>
              </Col>
              <Col sm={12} md={4} lg={3}>
                <label className="event__form__Creation">
                  Start Date
                  <input
                    type="date"
                    name="startDate__event"
                    placeholder="dd-mm-yyyy"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    // onChange={handleChange}
                    className="form-control fromInput"
                    required
                  />
                </label>
              </Col>
              <Col sm={12} md={4} lg={3}>
                <label className="event__form__Creation">
                  End Date
                  <input
                    type="date"
                    name="endDate__event"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    // onChange={handleChange}
                    className="mb-3 form-control fromInput"
                    required
                  />
                </label>
              </Col>

              <Col sm={12} md={4} lg={3}>
                <label>
                  Start Time
                  <input
                    type="time"
                    name="startTime__event"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    // onChange={handleChange}
                    className="mb-3 form-control fromInput"
                    required
                  />
                </label>
              </Col>
              <Col sm={12} md={4} lg={3}>
                <label>
                  End Time
                  <input
                    type="time"
                    name="endTime__event"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    // onChange={handleChange}
                    className="mb-3 form-control fromInput"
                    required
                  />
                </label>
              </Col>
            </Row>

            <Row>
              <Col md={12} lg={12}>
                <div>
                  <label>Format</label>
                  <div className="d-flex">
                    <div className="form-check mb-2">
                      <input
                        key={eventFormat}
                        className="form-check-input"
                        type="radio"
                        name="example1"
                        id="example1"
                        value="Hybrid"
                        onChange={(e) => hybridFunc(e.target.value)}
                        defaultChecked={eventFormat === "Hybrid" ? true : false}
                      />
                      <label className="form-check-label" htmlFor="example1">
                        Hybrid
                      </label>
                    </div>
                    <div className="px-3">
                      <div className="form-check">
                        <input
                          key={eventFormat}
                          className="form-check-input"
                          type="radio"
                          name="example2"
                          id="example2"
                          value="In Person"
                          onChange={(e) => inPersonFunc(e.target.value)}
                          defaultChecked={
                            eventFormat === "In Person" ? true : false
                          }
                        />
                        <label className="form-check-label" htmlFor="example2">
                          In Person
                        </label>
                      </div>
                    </div>
                    <div className="px-3">
                      <div className="form-check">
                        <input
                          key={eventFormat}
                          className="form-check-input"
                          type="radio"
                          name="example3"
                          id="example3"
                          value="Virtual"
                          onChange={(e) => virtualFunc(e.target.value)}
                          defaultChecked={
                            eventFormat === "Virtual" ? true : false
                          }
                        />
                        <label className="form-check-label" htmlFor="example3">
                          Virtual
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            {eventFormat === "In Person" ? (
              <div>
                <Row>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      Location
                      <input
                        type="text"
                        name="location__venue"
                        placeholder="Venue Location"
                        value={inputs.location__venue}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      Physical Address
                      <input
                        type="text"
                        name="address__venue"
                        placeholder="Event Address"
                        value={inputs.address__venue}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      City
                      <input
                        type="text"
                        name="city"
                        placeholder="City"
                        value={inputs.city}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      State
                      <input
                        type="text"
                        name="state"
                        value={inputs.state}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      Zip
                      <input
                        type="text"
                        name="zip"
                        value={inputs.zip}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      Country
                      <select
                        name="country"
                        value={inputs.country}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      >
                        {countryList.map((country, i) => (
                          <option key={i}> {country} </option>
                        ))}
                      </select>
                    </label>
                  </Col>
                </Row>
              </div>
            ) : null}
            {eventFormat === "Hybrid" ? (
              <div>
                <Row>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      Location
                      <input
                        type="text"
                        name="location__venue"
                        placeholder="Venue Location"
                        value={inputs.location__venue}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      Physical Address
                      <input
                        type="text"
                        name="address__venue"
                        placeholder="Event Address"
                        value={inputs.address__venue}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      City
                      <input
                        type="text"
                        name="city"
                        placeholder="City"
                        value={inputs.city}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      State
                      <input
                        type="text"
                        name="state"
                        value={inputs.state}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      Zip
                      <input
                        type="text"
                        name="zip"
                        value={inputs.zip}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      />
                    </label>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                    <label className="event__form__Creation">
                      Country
                      <select
                        name="country"
                        value={inputs.country}
                        onChange={handleChange}
                        className="mb-3 form-control fromInput"
                        required
                      >
                        {countryList.map((country, i) => (
                          <option key={i}> {country} </option>
                        ))}
                      </select>
                    </label>
                  </Col>
                </Row>
              </div>
            ) : null}
            <Row>
              <Col md={12} lg={12}>
                <div className="mt-3">
                  <label>Set Event</label>
                  <div className="d-flex">
                    <div className="form-check mb-2">
                      <input
                        key={visibility}
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        value="Public"
                        onChange={(e) => EventStatePublic(e.target.value)}
                        defaultChecked={visibility === "Public" ? true : false}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios1"
                      >
                        Public
                      </label>
                    </div>
                    <div className="px-3">
                      <div className="form-check">
                        <input
                          key={visibility}
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          value={"Private"}
                          onChange={(e) => EventStateFunc2(e.target.value)}
                          defaultChecked={
                            visibility === "Private" ? true : false
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios2"
                        >
                          Private
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-center align-item-center">
              <div className="d-flex justify-content-center align-item-center">
                <button className="btn btn-primary" type="submit">
                  <div className="d-flex justify-content-center align-items-center">
                    {!mutation.isLoading ? <span>Submit</span> : null}
                    {!mutation.isLoading ? null : (
                      <span>
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        Loading
                      </span>
                    )}
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Event;
