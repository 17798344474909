import React from "react";
import { Stepper, Step, StepLabel } from "@mui/material";

const MUIStepper = ({ activeStep }) => {
  const steps = [
    "Select Ticket",
    "Select Quantity",
    "Registration",
    "Confirmation",
  ];

  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default MUIStepper;
