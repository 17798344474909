import React, { useState } from "react";
import useCustomQuery from "../../../../../../../Hooks/useCustomQuery";
import { MdGroups } from "react-icons/md";
import "../../../css/AllRequestWithdrawalTable.scss";

function AllRequest() {
  return (
    <div className=" w-100">
      <table className="withdrawal-table">
        <thead className="withdrawal-table__head">
          <tr className="withdrawal-table__row">
            <th className="withdrawal-table__head--cell">Name</th>
            <th className="withdrawal-table__head--cell">Amount</th>
            <th className="withdrawal-table__head--cell">Status</th>
            <th className="withdrawal-table__head--cell">Method</th>
            <th className="withdrawal-table__head--cell">Details</th>
            <th className="withdrawal-table__head--cell">Note</th>
            <th className="withdrawal-table__head--cell">Date</th>
          </tr>
        </thead>
        <tbody className="withdrawal-table__body">
          {/* {getAttendees.map((attendee) => (
            <tr className="withdrawal-table__row">
              <td className="withdrawal-table--data">{`${attendee.firstName} ${attendee.lastName}`}</td>
              <td className="withdrawal-table--data">{`${attendee.email}`}</td>
              <td className="withdrawal-table--data participant-type">
                <MdGroups size={16} className="mx-1" /> Attendee
              </td>
              <td className="withdrawal-table--data">Invitation</td>
              <td className="withdrawal-table--data">Registered</td>
            </tr>
          ))} */}
        </tbody>
      </table>
    </div>
  );
}

export default AllRequest;
