import React, { useState, useEffect } from "react";
import usePost from "../../../../Hooks/usePost";
import { Spinner, Button, Row, Col } from "reactstrap";

function ParticipantProfile({ userData, token }) {
  const { execute, pending, data, status } = usePost();

  //   const userId = userDetails?.id;

  const [firstName, setFirstName] = useState(userData?.firstName);
  const [lastName, setLastName] = useState(userData?.lastName);
  const [phoneNumber, setPhoneNumber] = useState(userData?.phoneNumber);
  const [organizationName, setOrganizationName] = useState(
    userData?.organizationName
  );
  const [organizationType, setOrganizationType] = useState(
    userData?.organizationType
  );
  const [sector, setSector] = useState(userData?.sector);
  const [email, setEmail] = useState(userData?.email);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `users/updateMe`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("firstName", firstName);
    formdata.append("lastName", lastName);
    formdata.append("phoneNumber", phoneNumber);
    formdata.append("email", email);
    execute(
      url,
      formdata,
      Method,
      "Profile Successfully Updated",
      token,
      isJSON
    );
  };

  useEffect(() => {
    if (status === "success") {
      const user = data;
      if (user) {
        localStorage.clear();
        localStorage.setItem("authShowbayUser", JSON.stringify(user));
      }
    }
  }, [status]);

  return (
    <div className="w-100 d-flex justify-content-center mt-3">
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        <Row>
          <Col md={6}>
            <label className="event__input__form__Creation">
              First name
              <input
                type="text"
                name="firstName"
                className="mb-3 form-control fromInput w-100"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </label>
          </Col>
          <Col md={6}>
            <label className="event__input__form__Creation">
              Last name
              <input
                type="text"
                name="lastName"
                className="mb-3 form-control fromInput w-100"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </label>
          </Col>
          <Col md={6}>
            <label className="event__input__form__Creation">
              Email
              <input
                type="email"
                name="email"
                className="mb-3 form-control fromInput w-100"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled
              />
            </label>
          </Col>
          <Col md={6}>
            <label className="event__input__form__Creation">
              Phone Number
              <input
                type="text"
                name="phoneNumber"
                className="mb-3 form-control fromInput w-100"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </label>
          </Col>

          <div className="d-flex justify-content-center align-item-center">
            <div className="d-flex justify-content-center align-item-center">
              <button className="btn btn-primary" type="submit">
                <div className="d-flex justify-content-center align-items-center">
                  {!pending ? <span>Update</span> : null}
                  {!pending ? null : (
                    <span>
                      <Spinner as="span" animation="border" size="sm" /> Loading
                    </span>
                  )}
                </div>
              </button>
            </div>
          </div>
        </Row>
      </form>
    </div>
  );
}

export default ParticipantProfile;
