import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import "../allBooth.scss";

function Booths({ search, eventID, evName, token, setAllCompanies }) {
  const Navigate = useNavigate();
  const {
    data: getAllBooth,
    error: errorGetAllBooth,
    isFetching: isFetchingGetAllBooth,
  } = useCustomQuery(
    ["allBooths", eventID],
    `${process.env.REACT_APP_API}booths/event/${eventID}`,
    token
  );
  useEffect(() => {
    if (getAllBooth) {
      setAllCompanies(getAllBooth);
    }
  }, [getAllBooth, setAllCompanies]);
  const filterBoothsByName = (booth, searchQuery) => {
    //
    if (!searchQuery) return booth;
    return booth?.filter(
      (item) =>
        item?.boothName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.companyProfiles[0]?.companyName
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
  };
  const filteredBooths = filterBoothsByName(getAllBooth, search);
  const toggleSlideInBoothDetails = (booth) => {
    // setSlideInOpenBoothDetails(!slideInOpenBoothDetails);
    //
    let eventName = evName;
    Navigate(
      `/Exhibition/${eventName?.replace(/ /g, "-")}/${booth?.boothName.replace(
        / /g,
        "-"
      )}/${eventID}/${booth?.id}`
    );
  };

  // console.log("getAllBooth...", getAllBooth);

  return (
    <div className="allBooths mb-5">
      {filteredBooths?.map((item, i) => {
        return (
          <div
            className="allBooths-box"
            key={i}
            onClick={() => toggleSlideInBoothDetails(item)}
          >
            <div className="allBooths-box-img">
              <img alt="booth-logo" src={item?.boothLogo} />
            </div>
            <div className="mt-2">
              <div className="allBooths-box-text-container">
                {item?.companyProfiles?.map((item, i) => {
                  return (
                    <div className="allBooths-box-company-logo" key={i}>
                      <img alt="booth-logo" src={item?.companyLogo} />
                    </div>
                  );
                })}

                <div>
                  <div className="allBooths-box-text-title mt-1">
                    {item?.boothName}
                  </div>
                  {item?.boothTags?.map((tag, i) => {
                    return (
                      <div key={i}>
                        <div className="allBooths-box-text-tag">{tag}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Booths;
