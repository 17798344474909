import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useCustomQuery from "../../../Hooks/useCustomQuery";
import exhibitionLobby from "../../../assets/images/exhibitonLobby/lobby.png";
import placeHolderSponsor from "../../../assets/images/exhibitonLobby/Showbay.png";

import "./boothLobby.scss";
function BoothLobby({ userId, token, eventID, eventDetail }) {
  const Navigate = useNavigate();
  const [sponsors, setSponsors] = useState([]);
  const [replacement, setReplacement] = useState([]);
  const {
    data: getMySponsorsInstitutional,
    error: errorGetMySponsorsInstitutional,
    isLoading: isLoadingGetSponsorsInstitutional,
  } = useCustomQuery(
    ["mySponsorsInstitutional", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=institutional`,
    token
  );
  const {
    data: getMyExhibitionLobby,
    error: errorGetMyExhibitionLobby,
    isLoading: isLoadingGetMyExhibitionLobby,
  } = useCustomQuery(
    ["MyExhibitionLobby", eventID],
    `${process.env.REACT_APP_API}lobby-banners/${eventID}`,
    token
  );
  // console.log(
  //   "@@@@@...getMyExhibitionLobby...@@@@@@@",
  //   getMySponsorsInstitutional
  // );

  useEffect(() => {
    if (getMyExhibitionLobby) {
      setSponsors(getMyExhibitionLobby);
    }
    if (getMySponsorsInstitutional) {
      setSponsors(getMyExhibitionLobby);
    }
  }, [getMyExhibitionLobby, getMySponsorsInstitutional]);
  // console.log("getMySponsorsInstitutional.....", getMySponsorsInstitutional);

  const handleEnterExhibition = () => {
    Navigate(
      `/${eventDetail?.eventName?.replace(/ /g, "-")}/booths/${
        eventDetail?._id
      }`
    );
  };

  return (
    <div className="booth-lobby--booth">
      <img
        src={exhibitionLobby}
        alt="exhibition-lobby"
        className="w-100 h-100"
      />
      <div className="booth-lobby--sponsor1">
        {sponsors?.map((item, i) => {
          return (
            <img
              alt="sponsor-main"
              src={item?.sideBannerOne}
              className="w-100 h-100"
              key={i}
            />
          );
        })}
      </div>

      <div className="booth-lobby--sponsor2">
        {sponsors?.map((item, i) => {
          return (
            <img
              alt="sponsor-main"
              src={item?.sideBannerTwo}
              className="w-100 h-100"
              key={i}
            />
          );
        })}
        {/* <img
          alt="sponsor-main"
          src={sponsors[0]?.sideBannerTwo}
          className="w-100 h-100"
        /> */}
      </div>
      <div className="booth-lobby--sponsor3">
        {sponsors?.map((item, i) => {
          return (
            <img
              alt="sponsor-main"
              src={item?.sideBannerThree}
              className="w-100 h-100"
              key={i}
            />
          );
        })}
      </div>
      <div className="booth-lobby--sponsor4">
        {sponsors?.map((item, i) => {
          return (
            <img
              alt="sponsor-main"
              src={
                item?.sideBannerFour
                  ? item?.sideBannerFour
                  : item?.sideBannerTwo
              }
              className="w-100 h-100"
              key={i}
            />
          );
        })}
      </div>
      <div className="booth-lobby--sponsor5">
        {sponsors?.map((item, i) => {
          return (
            <img
              alt="sponsor-main"
              src={
                item?.sideBannerFive
                  ? item?.sideBannerFive
                  : item?.sideBannerOne
              }
              className="w-100 h-100"
              key={i}
            />
          );
        })}
      </div>
      <div className="booth-lobby--sideBar1">
        {sponsors?.map((item, i) => {
          return (
            <img
              alt="sponsor-main"
              src={
                item?.sideBannerTwo ? item?.sideBannerTwo : placeHolderSponsor
              }
              className="w-100 h-100"
              key={i}
            />
          );
        })}
      </div>
      <div className="booth-lobby--sideBar2">
        {sponsors?.map((item, i) => {
          return (
            <img
              alt="sponsor-main"
              src={
                item?.sideBannerThree
                  ? item?.sideBannerThree
                  : placeHolderSponsor
              }
              className="w-100 h-100"
              key={i}
            />
          );
        })}
      </div>
      <div className="booth-lobby--sideBar3">
        {sponsors?.map((item, i) => {
          return (
            <img
              alt="sponsor-main"
              src={
                item?.sideBannerThree
                  ? item?.sideBannerThree
                  : placeHolderSponsor
              }
              className="w-100 h-100"
              key={i}
            />
          );
        })}
      </div>
      <div className="booth-lobby--sideBar4">
        {sponsors?.map((item, i) => {
          return (
            <img
              alt="sponsor-main"
              src={
                item?.sideBannerTwo ? item?.sideBannerTwo : placeHolderSponsor
              }
              className="w-100 h-100"
              key={i}
            />
          );
        })}
      </div>
      <div
        className="booth-lobby--reception-video"
        onClick={() => handleEnterExhibition()}
      >
        <div className="w-100 d-flex justify-content-center align-items-center h5 text-light">
          Enter Exhibition
        </div>
      </div>
    </div>
  );
}

export default BoothLobby;
