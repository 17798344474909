import React from "react";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import "./attendeeCard.scss";
import { FaPersonBooth } from "react-icons/fa";

function LiveBooths({ token, eventID }) {
  const {
    data: getMyEventBooths,
    error: errorgetMyEventBooths,
    isLoading: isLoadinggetMyEventBooths,
  } = useCustomQuery(
    ["myEventBooths", eventID],
    `${process.env.REACT_APP_API}booths/event/${eventID}`,
    token
  );

  return (
    <div className="registered-user-card">
      <div className="d-flex">
        <div className="attendee-analistics-icons-container mt-1">
          <FaPersonBooth size={30} color="white" />
        </div>
        <div className="d-flex align-items-center">
          <div>
            <div style={{ color: "black", fontSize: 16, fontWeight: "900" }}>
              Live Booth
            </div>
            <div style={{ color: "#1a87a3", fontSize: 28, fontWeight: "900" }}>
              {Array.isArray(getMyEventBooths) ? getMyEventBooths.length : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveBooths;
