import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { countryList } from "../../../../../components/Countries/countries";

function OrganizationForm({ updateFormInputs, formInputs }) {
  const [selectedEventPerYear, setSelectedEventPerYear] = useState("");
  const [selectedAttendeePerEvent, setSelectedAttendeePerEvent] = useState("");
  const handleEventPerYear = (option) => {
    setSelectedEventPerYear(option);
    updateFormInputs({ eventsPerYear: option });
  };
  const getEventPerYearOptionStyle = (option) => ({
    flex: "1 1 0%",
    WebkitBoxPack: "center",
    WebkitBoxAlign: "center",
    height: 30,
    cursor: "pointer",
    transition: "all 250ms ease 0s",

    backgroundColor:
      selectedEventPerYear === option ? "blue" : "rgb(225, 225, 225)",
    border:
      selectedEventPerYear === option
        ? "2px solid blue"
        : "1px solid rgb(244, 244, 244)",
    borderRadius: "8px",
    color: selectedEventPerYear === option ? "white" : "black",
    // borderTopLeftRadius: "8px",
    // borderBottomLeftRadius: "8px",
    userSelect: "none",
  });

  const handleAttendeesSelect = (option) => {
    setSelectedAttendeePerEvent(option);
    updateFormInputs({ attendeesPerEvent: option });
  };
  const getAttendeePerEventStyle = (option) => ({
    flex: "1 1 0%",
    WebkitBoxPack: "center",
    WebkitBoxAlign: "center",
    cursor: "pointer",
    transition: "all 250ms ease 0s",
    height: 30,
    backgroundColor:
      selectedAttendeePerEvent === option ? "blue" : "rgb(225, 225, 225)",
    border:
      selectedAttendeePerEvent === option
        ? "2px solid blue"
        : "1px solid rgb(244, 244, 244)",
    borderRadius: "8px",
    color: selectedAttendeePerEvent === option ? "white" : "black",
    userSelect: "none",
  });
  return (
    <>
      <h5>Create your organization</h5>
      <span>To get started, let us know more about your organization</span>
      <AvForm>
        <div className="mb-3 fromInputContaoiner">
          <AvField
            className="fromInput"
            name="organizationName"
            type="text"
            placeholder="Organization Name"
            label="Organization Name"
            required
            value={formInputs.organizationName || ""} // Populate with formInputs
            onChange={(e) =>
              updateFormInputs({ organizationName: e.target.value })
            }
          />
        </div>
        <div className="d-flex mb-4">
          <Row>
            <Col md={6}>
              <div className="fromInputContaoiner">
                <AvField
                  className="fromInput"
                  type="select"
                  name="organizationType"
                  label="Organization Type"
                  value={formInputs.organizationType || ""} // Populate with formInputs
                  onChange={(e) =>
                    updateFormInputs({ organizationType: e.target.value })
                  }
                >
                  <option>Select...</option>
                  <option value="Association">Association</option>
                  <option value="Corporate">Corporate</option>
                  <option value="Media Company">Media Company</option>
                  <option value="Non-profit Organization">
                    Non-profit Organization
                  </option>
                  <option value="Others">Others</option>
                  <option value="Trade shows/Exhibitions">
                    Trade shows/Exhibitions
                  </option>
                </AvField>
              </div>
            </Col>
            <Col md={6}>
              <div className="fromInputContaoiner">
                <AvField
                  className="fromInput"
                  type="select"
                  name="organizationCountry"
                  label="Organization Country"
                  value={formInputs.organizationCountry || ""} // Populate with formInputs
                  onChange={(e) =>
                    updateFormInputs({
                      organizationCountry: e.target.value,
                    })
                  }
                >
                  <option>Select a country</option>
                  {countryList?.map((country, i) => {
                    return (
                      <option value={country} key={i}>
                        {country}
                      </option>
                    );
                  })}
                </AvField>
              </div>
            </Col>
          </Row>
        </div>
        <div className="organization-step">
          <label className="mb-4">
            <span>* </span>How many events do you plan on organizing per year?
          </label>
          <div className="d-flex option-select-wrapper">
            <div
              className="d-flex justify-content-center align-items-center option-select"
              style={getEventPerYearOptionStyle("1-5")}
              onClick={() => handleEventPerYear("1-5")}
            >
              1-5
            </div>

            <div
              className="d-flex justify-content-center align-items-center option-select"
              style={getEventPerYearOptionStyle("6-15")}
              onClick={() => handleEventPerYear("6-15")}
            >
              6-15
            </div>
            <div
              className="d-flex justify-content-center align-items-center option-select"
              style={getEventPerYearOptionStyle("16-25")}
              onClick={() => handleEventPerYear("16-25")}
            >
              16-25
            </div>
            <div
              className="d-flex justify-content-center align-items-center option-select"
              style={getEventPerYearOptionStyle("26-40")}
              onClick={() => handleEventPerYear("26-40")}
            >
              26-40
            </div>
            <div
              className="d-flex justify-content-center align-items-center option-select"
              style={getEventPerYearOptionStyle("40+")}
              onClick={() => handleEventPerYear("40+")}
            >
              40+
            </div>
          </div>
        </div>
        <div className="organization-step">
          <label className="mb-4 mt-3">
            <span>* </span>How many attendees do you expect on average per
            event?
          </label>
          <div className="d-flex option-select-wrapper">
            <div
              className="d-flex justify-content-center align-items-center option-select"
              style={getAttendeePerEventStyle("0-100")}
              onClick={() => handleAttendeesSelect("0-100")}
            >
              0-100
            </div>
            <div
              className="d-flex justify-content-center align-items-center option-select"
              style={getAttendeePerEventStyle("100-500")}
              onClick={() => handleAttendeesSelect("100-500")}
            >
              100-500
            </div>
            <div
              className="d-flex justify-content-center align-items-center option-select"
              style={getAttendeePerEventStyle("500-1k")}
              onClick={() => handleAttendeesSelect("500-1k")}
            >
              500-1k
            </div>
            <div
              className="d-flex justify-content-center align-items-center option-select"
              style={getAttendeePerEventStyle("1k-5k")}
              onClick={() => handleAttendeesSelect("1k-5k")}
            >
              1k-5k
            </div>
            <div
              className="d-flex justify-content-center align-items-center option-select"
              style={getAttendeePerEventStyle("5k+")}
              onClick={() => handleAttendeesSelect("5k+")}
            >
              5k+
            </div>
          </div>
        </div>
      </AvForm>
    </>
  );
}

export default OrganizationForm;
