import React, { useState } from "react";
import usePost from "../../../../Hooks/usePost";
// import useCustomMutation from "../../../../Hooks/useCustomMutation";
import { Row, Col, Spinner } from "reactstrap";

function Enquiry({ token, boothID }) {
  const { execute, pending, data } = usePost();
  // const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  // const userId = obj?.data?.data?.id;

  // const key = ["sendEnquiry", userId];
  // const mutation = useCustomMutation(key);

  const [inputs, setInputs] = useState({});
  const [note, setNote] = useState("");
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const onChangeNote = (event) => {
    setNote(event.target.value);
  };
  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "POST",
      endPoint = `booth-leads/${boothID}`;
    const raw = JSON.stringify({
      name: inputs.name,
      email: inputs.email,
      phone: inputs.number,
      note: note,
    });
    execute(endPoint, raw, Method, "Enquiry Successfully Sent", token);
  };
  return (
    <div className="w-100 d-flex justify-content-center">
      <form
        className="form-create-event"
        onSubmit={handleSubmitForm}
        style={{
          backgroundColor: "#F4F4F4",
          maxWidth: 600,
          padding: 10,
          borderRadius: 8,
        }}
      >
        <Row>
          <Col md={12}>
            <label className="event__input__form__Creation">
              Name
              <input
                type="text"
                name="name"
                className="mb-3 form-control fromInput w-100"
                value={inputs.name}
                onChange={handleChange}
                required
              />
            </label>
          </Col>
          <Col md={12}>
            <label className="event__input__form__Creation">
              email
              <input
                type="email"
                name="email"
                className="mb-3 form-control fromInput w-100"
                value={inputs.email}
                onChange={handleChange}
                required
              />
            </label>
          </Col>
          <Col md={12}>
            <label className="event__input__form__Creation">
              Phone Number
              <input
                type="text"
                name="number"
                className="mb-3 form-control fromInput w-100"
                value={inputs.number}
                onChange={handleChange}
                required
              />
            </label>
          </Col>
          <Col md={12}>
            <label className="event__input__form__Creation">
              Note
              <textarea
                value={note}
                onChange={onChangeNote}
                placeholder="Write here..."
                className="mb-3 form-control fromInput"
                rows={5}
                required
              ></textarea>
            </label>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-center align-item-center">
              <div className="d-flex justify-content-center align-item-center">
                <button className="btn btn-primary" type="submit">
                  <div className="d-flex justify-content-center align-items-center">
                    {!pending ? <span>Send</span> : null}
                    {!pending ? null : (
                      <span>
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        Loading
                      </span>
                    )}
                  </div>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default Enquiry;
