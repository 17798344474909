import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  Modal,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import { BsFillShareFill } from "react-icons/bs";
import { IoArrowForwardOutline } from "react-icons/io5";
import classnames from "classnames";

const MenuNavigation = ({ getMyEventDetail }) => {
  const Navigate = useNavigate();
  const [openShare, setOpenShare] = useState(false);
  const [activeEventMenu, setactiveEventMenu] = useState("5");

  // link
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);
  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess("Copied!");
  }

  // event date settings
  const getStartDate = getMyEventDetail?.startDate;
  // Convert event start date
  const date = new Date(getStartDate);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const startDate = date.toLocaleDateString("en-US", options);
  const getEndDate = getMyEventDetail?.endDate;
  const endDate = new Date(getEndDate);
  const optionEndDate = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const endDateEvent = endDate.toLocaleDateString("en-US", optionEndDate);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function toggleShareModal() {
    setOpenShare(!openShare);
    removeBodyCss();
  }

  const toggleActiveMenu = (tab) => {
    if (activeEventMenu !== tab) {
      setactiveEventMenu(tab);
    }
  };

  return (
    <div>
      <Navbar style={{ backgroundColor: "#fff" }}>
        <NavbarBrand href="/dashboard">
          <div className="d-flex">
            <div style={{ paddingLeft: 36, paddingRight: 18 }}>
              {/* <IoArrowBack size={30} /> */}
            </div>
            <div>
              <h3 style={{ color: "#000" }}>
                {getMyEventDetail?.eventName}

                <sup
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    color:
                      getMyEventDetail?.state === "Published"
                        ? "#45cb85"
                        : "red",
                    marginLeft: 8,
                  }}
                >
                  &#8226;
                  {getMyEventDetail?.state}
                </sup>
              </h3>
              <p style={{ fontSize: 12 }}>
                <i
                  className="fas fa-calendar"
                  style={{ marginLeft: 4, marginRight: 8 }}
                ></i>
                {startDate}, {getMyEventDetail?.startTime} - {endDateEvent},{" "}
                {getMyEventDetail?.endTime}
                SAST
              </p>
            </div>
          </div>
        </NavbarBrand>
        <Nav className="ml-auto px-4" navbar>
          <div className="d-flex justify-content-center">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeEventMenu === "6",
                })}
                onClick={() => {
                  // toggle2("6");
                }}
              >
                <button
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    Navigate(
                      `/${getMyEventDetail?.eventName?.replace(
                        / /g,
                        "-"
                      )}/Ticket/${getMyEventDetail?._id}`
                    );
                  }}
                >
                  <div className="d-flex justify-content-center align-center ">
                    <span className="px-2">Buy Ticket(s)</span>
                    {/* <span>
                      <IoArrowForwardOutline size={20} />
                    </span> */}
                  </div>
                </button>
              </NavLink>
            </NavItem>
            {/* <NavItem className="px-2">
              <NavLink href="#">
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    toggleShareModal();
                  }}
                  data-toggle="modal"
                  data-target=".bs-example-modal-center"
                >
                  <div className="d-flex justify-content-center align-center ">
                    <span>
                      <BsFillShareFill size={20} />
                    </span>
                    <span className="px-2">Share Event</span>
                  </div>
                </button>
                <Modal
                  isOpen={openShare}
                  toggle={() => {
                    toggleShareModal();
                  }}
                  centered={true}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">Copy the link</h5>

                    <button
                      type="button"
                      onClick={() => {
                        setOpenShare(false);
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="d-flex justify-content-center align-items-center">
                      <form>
                        <input
                          className="form-control fromInput"
                          ref={textAreaRef}
                          value={`https://www.event.showbay24com/`}
                        />
                      </form>
                      {
                       
                        document.queryCommandSupported("copy") && (
                          <div className="px-3">
                            <button
                              className=" btn btn-primary btn-md"
                              onClick={copyToClipboard}
                            >
                              Copy
                            </button>
                            <span className="px-2 text-success">
                              {copySuccess}
                            </span>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </Modal>
              </NavLink>
            </NavItem> */}
          </div>
        </Nav>
      </Navbar>
      {/* <Card>
        <Row>
          <Col md={12}>
            <div className="mt-2 mb-2 d-flex justify-content-center">
              <Nav
                pills
                className="navtab-bg nav-justified"
                style={{ width: "50%", marginLeft: "60px" }}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeEventMenu === "5",
                    })}
                    onClick={() => {
                      toggleActiveMenu("5");
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <span className="d-none d-sm-block">Reguitration</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeEventMenu === "6",
                    })}
                    onClick={() => {
                      toggleActiveMenu("6");
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <span className="d-none d-sm-block">Attende(e)</span>
                    </div>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
        </Row>
      </Card> */}
    </div>
  );
};

export default MenuNavigation;
