import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Row,
  Col,
  Spinner,
} from "reactstrap";

//Rextangle.png

import Profile from "./Profile";
import Products from "./Products";
import Enquiry from "./Enquiry";
import Live from "./Live";
import Sponsors from "./Sponsors";

import classnames from "classnames";

const Main = ({ eventID, token }) => {
  const [activeTabJustify, setactiveTabJustify] = useState("5");

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myBoothDetails", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  function toggleCustomJustified(tab) {
    if (activeTabJustify !== tab) {
      setactiveTabJustify(tab);
    }
  }

  //   if (!ViewEvent) {
  //     return (
  //       <div className="mt-5 d-flex justify-content-center align-items-center">
  //         <Spinner animation="border" role="status">
  //           <span className="visually-hidden">Loading...</span>
  //         </Spinner>
  //       </div>
  //     );
  //   }

  const StartTime = "here";
  const EndTime = "end";
  const NewStartTime = StartTime.toString();
  const NewEndTime = EndTime.toString();

  return (
    <React.Fragment>
      <div className="d-flex px-4 justify-content-center align-items-center">
        <div className="w-100">
          <Row className="mt-1  w-100">
            <Col md={12}>
              <div className="shadow-sm mt-2 card-brd  w-100">
                <img
                  //   src={`https://app.showbay24.com/EventCoverImages/${ViewEvent.EventCoverImage}`}
                  width="100%"
                  height="100%"
                  className="card-brd"
                />
              </div>
              <div className="bg-white shadow-sm">
                <Nav pills className="navtab-bg nav-justified mt-2 mb-2">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "5",
                      })}
                      onClick={() => {
                        toggleCustomJustified("5");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">Profile</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "6",
                      })}
                      onClick={() => {
                        toggleCustomJustified("6");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-calendar"></i>
                      </span>
                      <span className="d-none d-sm-block">Products</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "7",
                      })}
                      onClick={() => {
                        toggleCustomJustified("7");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-star"></i>
                      </span>
                      <span className="d-none d-sm-block">Enquiry</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "8",
                      })}
                      onClick={() => {
                        toggleCustomJustified("8");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-message"></i>
                      </span>
                      <span className="d-none d-sm-block">Live</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTabJustify}>
                  <TabPane tabId="5" className="p-3">
                    <Profile />
                  </TabPane>
                  <TabPane tabId="6" className="p-3 ">
                    <Products />
                  </TabPane>
                  <TabPane tabId="7" className="p-3">
                    <Enquiry />
                  </TabPane>
                  <TabPane tabId="8" className="p-3">
                    <Live />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Main;
