export const data = [
  {
    id: 1,
    senderId: 1,
    img: require("../../../assets/images/users/avatar-1.jpg"),
    text: "good day!",
  },
  {
    id: 2,
    senderId: 2,
    img: require("../../../assets/images/users/avatar-1.jpg"),
    text: "hello !",
  },
];

export const chats = [
  {
    chatId: 1,
    msg: [
      {
        senderId: 1,
        img: require("../../../assets/images/users/avatar-1.jpg"),
      },
    ],
  },
  {
    chatId: 2,
    msg: [
      {
        senderId: 1,
        img: require("../../../assets/images/users/avatar-2.jpg"),
      },
    ],
  },
];
