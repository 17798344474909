import React, { useState, useEffect } from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBTypography,
  MDBInputGroup,
  // MDBScrollbar,
} from "mdb-react-ui-kit";
import SimpleBar from "simplebar-react";
import "./privateChats.scss";
import { useParams } from "react-router-dom";

import Contacts from "./components/Contancts";
import ChatArea from "./components/ChatArea";
import { ContactProvider } from "../../../../contexts/contact-context/contact.context";
import { socket } from "../../../../utils/socketConfig";

const ChatApp = ({ token, eventDetail }) => {
  document.title = "Showbay 24 | Community Chat";

  return (
    <div
      style={{
        background: "#131216",
      }}
    >
      <div
        className="page-content  community---container"
        style={{ background: "#131216" }}
      >
        <MDBContainer fluid className="py-0  chat---general---container">
          <MDBRow>
            <MDBCol md="12">
              <MDBCard id="chat3" style={{ borderRadius: "15px" }}>
                <MDBCardBody>
                  <ContactProvider>
                    <MDBRow>
                      <MDBCol md="6" lg="5" xl="4" className="mb-4 mb-md-0">
                        <Contacts eventDetail={eventDetail} />
                      </MDBCol>

                      <MDBCol md="6" lg="7" xl="8">
                        {/* here is the chatare view where the user can type and view the differents messages */}
                        <ChatArea />
                      </MDBCol>
                    </MDBRow>
                  </ContactProvider>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  );
};

export default ChatApp;
