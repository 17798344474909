import React from "react";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
import { Spinner } from "reactstrap";

function BoothCompanyProfile({ token, boothId }) {
  const {
    data: boothCompanyProfile,
    error: errorBoothCompanyProfile,
    isFetching: isFetchingBoothCompanyProfile,
  } = useCustomQuery(
    ["myboothCompanyProfile", boothId],
    `${process.env.REACT_APP_API}company-profile/booth/${boothId}`,
    token
  );

  return (
    <div>
      {isFetchingBoothCompanyProfile ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <img
          src={boothCompanyProfile?.companyLogo}
          alt="compsany-profile"
          style={{ borderRadius: "10px", width: "50px", height: "50px" }}
        />
      )}
    </div>
  );
}

export default BoothCompanyProfile;
