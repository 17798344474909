import React, { useState } from "react";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AddHost from "../../hosts/SlideInHost";
import EditHost from "../../hosts/EditSlideInHost";
function Host({ eventID, token }) {
  const [slideInOpenHost, setSlideInOpenHost] = useState(false);
  const [editslideInOpenHost, setEditSlideInOpenHost] = useState(false);
  const toggleSlideInOpenHost = () => {
    setSlideInOpenHost(!slideInOpenHost);
  };
  const toggleEditSlideInOpenHost = () => {
    setEditSlideInOpenHost(!editslideInOpenHost);
  };
  const {
    data: getMyHosts,
    error: errorGetMyHosts,
    isFetching: isFetchingGetMyHosts,
  } = useCustomQuery(
    ["my-Hosts", eventID],
    `${process.env.REACT_APP_API}users/event-host/${eventID}`,
    token
  );

  // console.log("getMyHosts...", getMyHosts?.organizationName);
  //   const getLastTwoHosts = (myHosts) => {
  //     if (myHosts.length >= 2) {
  //       return myHosts.slice(-4);
  //     }

  //     if (myHosts.length === 1) {
  //       return [myHosts[0]];
  //     }
  //     return [];
  //   };
  //   let lastTwoHosts = [];
  //   if (getMyHosts) {
  //     lastTwoHosts = getLastTwoHosts(getMyHosts);
  //   }
  return (
    <div className="summary-setup__affiliates--sponsors">
      <div className="summary-setup__affiliates--sponsors__heading">
        <h5>Host(s)</h5>
        {getMyHosts?.roles?.includes("host") ? (
          <>
            <p
              type="button"
              onClick={toggleEditSlideInOpenHost}
              style={{ color: "#3b5de7" }}
            >
              Edit Host
            </p>
            <EditHost
              isOpen={editslideInOpenHost}
              toggle={toggleEditSlideInOpenHost}
              title="Edit Host Profile"
              eventID={eventID}
              hostDetails={getMyHosts}
              hostType={getMyHosts?.organizationName}
            />
          </>
        ) : null}
      </div>
      <div className="summary-setup__affiliates--sponsors__body">
        {getMyHosts?.roles?.includes("host") ? null : (
          <div className="btn-add-speaker d-flex justify-content-center align-items-center">
            <AiOutlinePlusCircle
              size={24}
              type="button"
              onClick={toggleSlideInOpenHost}
            />
            <AddHost
              isOpen={slideInOpenHost}
              toggle={toggleSlideInOpenHost}
              title="Create Host Profile"
              eventID={eventID}
            />
          </div>
        )}
        {getMyHosts?.roles?.includes("host") ? (
          <div>
            <img
              src={getMyHosts?.photo}
              alt="host-pic"
              style={{ width: "70px", height: "70px", borderRadius: "50%" }}
            />
            <div className="d-flex">
              {getMyHosts?.organizationName ? (
                <p className="mx-2">{getMyHosts?.organizationName}</p>
              ) : (
                <>
                  <p>{getMyHosts?.firstName}</p> <p>{getMyHosts?.lastName}</p>
                </>
              )}
            </div>
          </div>
        ) : null}

        {/* {lastTwoHosts.map((host, i) => {
          return (
            <div>
              <img src={host?.sponsorLogo} width="70px" alt="" key={i} />
              <div className="d-flex">
                <p>hello</p>
              </div>
            </div>
          );
        })} */}
      </div>
    </div>
  );
}

export default Host;
