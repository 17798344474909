import React, { useState } from "react";
import "./SlideInComponent.scss"; // Create a CSS file for styling
import BulkAttendee from "./components/bulk-attendee/bulk-attendee.component";
import SingleAttendee from "./components/single-attendee/single-attendee.component";

const CustomModal = ({ isOpen, toggle, title }) => {
  const [activeLink, setActiveLink] = useState(0);

  const handleClickInside = (e) => {
    e.stopPropagation(); // Prevent the click event from propagating to the overlay
  };

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  return (
    <div className={`slide-in-modal ${isOpen ? "show" : ""}`}>
      <div className="slide-in-overlay" onClick={toggle}></div>
      <div className="slide-in-content" onClick={handleClickInside}>
        <div className="slide-in-header">
          <div>
            <h3 className="modal-title mt-0">{title}</h3>
            <p>
              Add attendees to the attendee list. For a seamless one-click
              registration/entry for your attendees, please fill all mandatory
              form fields.
            </p>
          </div>
          <button
            className="close"
            onClick={toggle}
            aria-label="Close"
            style={{ paddingTop: "48px", paddingRight: "56px", fontSize: 14 }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="slide-in-body-attendee">
          <div className="slide-in-body-attendee__links">
            <a
              href="#"
              className={`registration-menu__link ${
                activeLink === 0 ? "registration-menu__link--active" : ""
              }`}
              onClick={() => handleLinkClick(0)}
            >
              Bulk upload attendees
            </a>
            <a
              href="#"
              className={`registration-menu__link ${
                activeLink === 2 ? "registration-menu__link--active" : ""
              }`}
              onClick={() => handleLinkClick(2)}
            >
              Add a single attendee
            </a>
          </div>
          <div className="slide-in-body-attendee__content">
            {activeLink === 0 && <BulkAttendee toggle={toggle} />}
            {activeLink === 2 && <SingleAttendee toggle={toggle} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
