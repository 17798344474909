import React from "react";
import { Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
// import "../talkToUs.scss";

function ContactDetailsForm({ updateFormInputs, formInputs }) {
  return (
    <>
      <h5>Contact details</h5>
      <span>Your organization's contact details</span>
      <AvForm>
        <div className="mb-4 w-100">
          <Row>
            <Col md={6}>
              <div className="w-100 mb-3 fromInputContaoiner bg-sucess ">
                <AvField
                  className="fromInput w-100"
                  name="organizationEmail"
                  type="text"
                  placeholder="email"
                  label="Organization email"
                  required
                  value={formInputs.organizationEmail || ""}
                  onChange={(e) =>
                    updateFormInputs({ organizationEmail: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3 fromInputContaoiner w-100">
                <AvField
                  className="fromInput"
                  name="organizationPhoneNumber"
                  type="text"
                  placeholder="phone number"
                  label="Organization phone number"
                  required
                  value={formInputs.organizationPhoneNumber || ""} // Populate with formInputs
                  onChange={(e) =>
                    updateFormInputs({
                      organizationPhoneNumber: e.target.value,
                    })
                  }
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="fromInputContaoiner">
                <AvField
                  className="fromInput"
                  type="select"
                  name="defaultLanguage"
                  label="Default Language"
                  value={formInputs.defaultLanguage || ""} // Populate with formInputs
                  onChange={(e) =>
                    updateFormInputs({ defaultLanguage: e.target.value })
                  }
                >
                  <option>Default Language</option>
                  <option value="en">English</option>
                  <option value="fr">French</option>
                </AvField>
              </div>
            </Col>
          </Row>
        </div>
        <div style={{ width: "100%", padding: 2 }}>
          <div className="w-100">
            {/* <p>
              All your community and event-related URLs will contain{" "}
              <span className="">shadowops-community</span>
            </p>
            <div
              style={{
                borderRadius: "8px",
                background: "#1a87a3",
                padding: "12px 8px",
                boxSizing: "border-box",
                color: "#fff",
              }}
            >
              <ul
                style={{
                  // width: "100%",

                  textAlign: "left",
                  // color: "red",
                }}
              >
                <li
                  style={{
                    // color: "rgb(140, 140, 140)",
                    lineHeight: "18px",
                  }}
                >
                  The chosen name will appear in all of your community and event
                  URLs.
                </li>
                <li
                  style={{
                    // color: "rgb(140, 140, 140)",
                    lineHeight: "18px",
                  }}
                >
                  Community name can be updated afterward, but not the URL.{" "}
                </li>
                <li
                  style={{
                    // color: "rgb(140, 140, 140)",
                    lineHeight: "18px",
                  }}
                >
                  We recommend including your brand name in your community name.{" "}
                </li>
                <li
                  style={{
                    // color: "rgb(140, 140, 140)",
                    lineHeight: "18px",
                  }}
                >
                  Avoid including a year as it will show in all your future
                  event URLs.
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </AvForm>
    </>
  );
}

export default ContactDetailsForm;
