import React from "react";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
function Sponsors({ token, eventID }) {
  const {
    data: getMySponsors,
    error: errorGetMySponsors,
    isLoading: isLoadingGetMySponsors,
  } = useCustomQuery(
    ["mySponsors", eventID],
    `${process.env.REACT_APP_API}sponsors/event/${eventID}`,
    token
  );

  const {
    data: getMySponsorsInstitutional,
    error: errorGetMySponsorsInstitutional,
    isLoading: isLoadingGetSponsorsInstitutional,
  } = useCustomQuery(
    ["mySponsorsInstitutional", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=institutional`,
    token
  );

  const {
    data: getMySponsorsPlatinum,
    error: errorGetMySponsorsPlatinum,
    isLoading: isLoadingGetMySponsorsPlatinum,
  } = useCustomQuery(
    ["mySponsorsPlatinum", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=platinum`,
    token
  );

  const {
    data: getMySponsorsGold,
    error: errorGetMySponsorsGold,
    isLoading: isLoadingGetMySponsorsGold,
  } = useCustomQuery(
    ["mySponsorsGold", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=gold`,
    token
  );

  const {
    data: getMySponsorsSilver,
    error: errorGetMySponsorsSilver,
    isLoading: isLoadingGetSponsorsSilver,
  } = useCustomQuery(
    ["mySponsorsSilver", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=silver`,
    token
  );
  const isSponsors = getMySponsors && getMySponsors?.length > 0;
  const isInstitutionalSponsors =
    getMySponsorsInstitutional && getMySponsorsInstitutional?.length > 0;
  const isPlatinumSponsors =
    getMySponsorsPlatinum && getMySponsorsPlatinum?.length > 0;
  const isGoldSponsors = getMySponsorsGold && getMySponsorsGold?.length > 0;
  const isSilverSponsors =
    getMySponsorsSilver && getMySponsorsSilver?.length > 0;

  console.log("getMySponsors....", getMySponsors);
  return (
    <>
      {isSponsors ? (
        <div className="p-5 text-light">
          {isInstitutionalSponsors ? (
            <>
              <h5 className="text-light">INSTITUTIONAL</h5>
              <div className="d-flex">
                {getMySponsorsInstitutional?.map((sponsor, i) => {
                  return (
                    <div key={i}>
                      <img
                        src={sponsor?.sponsorLogo}
                        alt="Showbay sponsor banner"
                        style={{
                          width: "90px",
                          height: "90px",
                          borderRadius: "50%",
                          marginRight: 4,
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
          {isPlatinumSponsors ? (
            <>
              <h5 className="mt-5 text-light">PLATINUM</h5>
              <div className="d-flex">
                {getMySponsorsPlatinum?.map((sponsor, i) => {
                  return (
                    <div key={i}>
                      <img
                        src={sponsor?.sponsorLogo}
                        alt="Showbay sponsor banner"
                        style={{
                          width: "90px",
                          height: "90px",
                          borderRadius: "50%",
                          marginRight: 4,
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
          {isGoldSponsors ? (
            <>
              <h5 className="mt-5 text-light">GOLD</h5>
              <div className="d-flex">
                {getMySponsorsGold?.map((sponsor, i) => {
                  return (
                    <div key={i}>
                      <img
                        src={sponsor?.sponsorLogo}
                        alt="Showbay sponsor banner"
                        style={{
                          width: "90px",
                          height: "90px",
                          borderRadius: "50%",
                          marginRight: 4,
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
          {isSilverSponsors ? (
            <>
              <h5 className="mt-5 text-light">SILVER</h5>
              <div className="d-flex">
                {getMySponsorsSilver?.map((sponsor, i) => {
                  return (
                    <div className="event-sponsors__item" key={i}>
                      <img
                        src={sponsor?.sponsorLogo}
                        alt="Showbay sponsor banner"
                        style={{
                          width: "90px",
                          height: "90px",
                          borderRadius: "50%",
                          marginRight: 4,
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </>
  );
}

export default Sponsors;
