import React, { useState, useRef, useEffect } from "react";
import { Spinner, Button } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiEdit } from "react-icons/bi";
import "bootstrap/dist/css/bootstrap.min.css";

import useCustomMutation from "../../../../Hooks/useCustomMutation";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import { sessionCategories } from "../../../../components/sessionCategories/sessionCategories";
import ImgSession from "../../../../assets/images/banners/sessions/bannerSession.png";

import "./css/AddSession.scss";

const EditSession = ({
  token,
  eventID,
  eventFormat,
  setSlideInOpenSession,
  sessionDeatails,
}) => {
  const key = ["mySessions", eventID];
  const mutation = useCustomMutation(key);

  const [inputs, setInputs] = useState({});
  const [isPicture, setIsPicture] = useState(false);
  const [BannerServer, setBannerServer] = useState();
  const [profile, setProfile] = useState();
  const [sessionName, setSessionName] = useState("");
  const [sessionLocation, setSessionLocation] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [duration, setDuration] = useState("");
  const [host, setHost] = useState(null);
  const [sessionTags, setSessionTags] = useState("");
  const [sessionDescription, setSessionDescription] = useState(null);
  const [access, setAccess] = useState("");
  console.log("sessionDetails...", sessionDeatails);
  // time picker
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedTime, setSelectedTime] = useState("");

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const {
    data: getMyTeam,
    error: errorGetMyMyTeam,
    isLoading: isLoadingGetMyTeam,
  } = useCustomQuery(
    ["myTeams", eventID],
    `${process.env.REACT_APP_API}auth/team-member/${eventID}`,
    token
  );

  // console.log("myTeams......", getMyTeam);
  // console.log("eventID......", eventID);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const refFileUpload = useRef("");

  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setBannerServer(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // console.log("session...inputs", inputs);
  // console.log("eventFormat...", eventFormat);

  const handleSubmitForm = (event) => {
    event.preventDefault();
    // console.log("session...inputs", inputs);
    const Method = "PATCH",
      url = `sessions/${sessionDeatails?._id}`,
      isJSON = true;
    var formdata = new FormData();

    if (BannerServer) {
      formdata.append("sessionLogo", BannerServer);
    }
    if (sessionName) {
      formdata.append("sessionName", sessionName);
    }
    if (sessionLocation) {
      formdata.append("sessionLocation", sessionLocation);
    }
    if (selectedDate) {
      formdata.append("sessionDate", selectedDate);
    }
    if (startTime) {
      formdata.append("startTime", startTime);
    }
    if (duration) {
      formdata.append("duration", duration);
    }
    if (host) {
      formdata.append("host", host);
    }
    if (sessionTags) {
      formdata.append("tags", sessionTags);
    }
    if (sessionDescription) {
      formdata.append("sessionDescription", sessionDescription);
    }
    if (access) {
      formdata.append("access", access);
    }

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Session Successfully Updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (sessionDeatails?.sessionLogo) {
      setBannerServer(sessionDeatails?.sessionLogo);
      setIsPicture(true);
    }
    setSessionName(sessionDeatails?.sessionName);
    setSessionLocation(sessionDeatails?.setSessionLocation);

    // setSelectedDate(sessionDeatails?.selectedDate);
    if (sessionDeatails?.selectedDate) {
      const date = new Date(sessionDeatails?.startDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      setSelectedDate(`${year}-${month}-${day}`);
    }
    setStartTime(sessionDeatails?.startTime);
    setDuration(sessionDeatails?.duration);
    setHost(sessionDeatails?.host);
    setSessionTags(sessionDeatails?.sessionTags);
    setSessionDescription(sessionDeatails?.sessionDescription);
    setAccess(sessionDeatails?.access);
    // if (mutation.isSuccess) {
    //   setInputs({});
    //   setSlideInOpenSession(false);
    // }
    // if (getMyEventDetail?.eventFormat) {
    //   setSessionFormat(getMyEventDetail?.eventFormat);
    // }
  }, [mutation.isSuccess]);

  const location = () => {
    return (
      <div className="booth__body--form-input-container">
        <label
          htmlFor="sessionLocation"
          className="booth__body--form-input-container__label"
        >
          <p className="input-heading">Session Location*</p>
          <p className="input-description">
            Make your session to be easily found
          </p>
        </label>
        <div className="single-attendee__body--form-input-container gzIdbH">
          <input
            id="sessionLocation"
            name="sessionLocation"
            type="text"
            onChange={handleChange}
            required
            placeholder="Session Location"
            className="single-attendee__body--form-input-container-field"
            onFocus={(e) => e.target.classList.add("active")}
            onBlur={(e) =>
              e.target.value === "" && e.target.classList.remove("active")
            }
          />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="booth__body">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="booth__body--form"
          onSubmit={handleSubmitForm}
        >
          <div className="mt-0">
            <div className="d-flex justify-content-center">
              <div className="section---banner---container mt-2">
                <img
                  src={isPicture ? BannerServer : profile}
                  alt="session Image"
                  className="w-100 h-100 section---banner---container---img"
                />
                <Button
                  size="md"
                  variant="separator-light"
                  className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "

                  // onClick={onThumbChangeClick}
                >
                  <BiEdit
                    size={40}
                    style={{
                      color: "black",
                      filter: "drop-shadow(0px 0px 10px white)",
                    }}
                  />
                </Button>
                <input
                  type="file"
                  ref={refFileUpload}
                  className="file-upload d-none"
                  accept="image/*"
                  onChange={changeThumb}
                />
              </div>
            </div>
            <div
              className="sponsor-form-body__sponsor-logo"
              style={{ marginRight: "12px", marginBottom: "-16px" }}
            >
              <p
                color="ambience.6"
                font-size="1"
                className="sponsor-form-body--input-description"
              >
                Recommended format: 400x200px | JPG, SVG, PNG | Up to 1 MB
              </p>
            </div>
            {/* ------------------ Form Fields ---------------------- */}
            <div className="booth__body--form-input-container">
              <label
                htmlFor="sessionName"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Name*</p>
                <p className="input-description">
                  Suggested topic by the speaker
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="sessionName"
                  name="sessionName"
                  type="text"
                  value={sessionName}
                  onChange={(e) => setSessionName(e.target.value)}
                  // onChange={handleChange}
                  // required
                  placeholder="Session Name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>

            {eventFormat === "Hybrid" ? (
              <>{location()}</>
            ) : eventFormat === "In Person" ? (
              <>{location()}</>
            ) : null}

            <div className="booth__body--form-input-container">
              <label
                htmlFor="access"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Category*</p>
                <p className="input-description">
                  Specify which Category should attend to this activity
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <select
                  id="access"
                  name="access"
                  value={access}
                  onChange={(e) => setAccess(e.target.value)}
                  className="single-attendee__body--form-input-container-field"
                  required
                >
                  <option value={null}> {access} </option>
                  {sessionCategories.map((category, i) => {
                    if (category !== access) {
                      return (
                        <option key={i} value={category}>
                          {category}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {/* vfhgnjsgnjksdnjksdnfdshdkehfnkdjfbsdkjhcehdgfhdfhshdfhdfcbhvcjdbviuvhsdvkjefhkabsdcbhbcdnxkkmdsjcn */}
              <div className="booth__body--form-input-container session-field-col-half">
                <label
                  htmlFor="startDate"
                  className="booth__body--form-input-container__label"
                >
                  <p className="input-heading">Session date*</p>
                  <p className="input-description">
                    On which day is this session
                  </p>
                </label>
                <div className="single-attendee__body--form-input-container gzIdbH">
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="MM/dd/yyyy"
                    placeholderText=""
                    className="single-attendee__body--form-input-container-field"
                    onFocus={(e) => e.target.classList.add("active")}
                    onBlur={(e) =>
                      e.target.value === "" &&
                      e.target.classList.remove("active")
                    }
                    style={{ position: "absolute", zIndex: 999 }}
                  />
                </div>
              </div>

              <div className="booth__body--form-input-container session-field-col-half">
                <label
                  htmlFor="startTime"
                  className="booth__body--form-input-container__label"
                >
                  <p className="input-heading">Start Time *</p>
                  <p className="input-description">
                    Select this session starting time
                  </p>
                </label>
                <div className="single-attendee__body--form-input-container gzIdbH">
                  <input
                    id="startTime"
                    name="startTime"
                    type="time"
                    onChange={handleChange}
                    required
                    className="single-attendee__body--form-input-container-field"
                    onClick={toggleDropdown}
                    value={inputs?.startTime}
                  />
                </div>
              </div>
              <div className="booth__body--form-input-container session-field-col-half">
                <label
                  htmlFor="startDate"
                  className="booth__body--form-input-container__label"
                >
                  <p className="input-heading">Duration *</p>
                  <p className="input-description">
                    How long is this session in minutes
                  </p>
                </label>
                <div className="single-attendee__body--form-input-container gzIdbH">
                  <input
                    id="duration"
                    name="duration"
                    type="text"
                    onChange={handleChange}
                    required
                    placeholder=""
                    className="single-attendee__body--form-input-container-field"
                    onFocus={(e) => e.target.classList.add("active")}
                    onBlur={(e) =>
                      e.target.value === "" &&
                      e.target.classList.remove("active")
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className="booth__body--form-input-container"
              style={{ zIndex: 1 }}
            >
              <label
                htmlFor="host"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Session Coordinator*</p>
                <p className="input-description">
                  You can add only team members as Session Coordinator. Only one
                  person can be a Session Coordinator.
                </p>
              </label>

              <div className="booth__body--form-input-container">
                <select
                  id="host"
                  name="host"
                  value={inputs.host}
                  onChange={handleChange}
                  className="single-attendee__body--form-input-container-field"
                  required
                >
                  <option value={null}> Select... </option>
                  {getMyTeam?.data?.map((getMyTeam, i) => {
                    // console.log("getMyTeam...", getMyTeam);
                    if (getMyTeam?.roles[0] !== "host") {
                      return (
                        <option key={i} value={getMyTeam?.email}>
                          {getMyTeam?.firstName} {getMyTeam?.lastName}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
              {/* <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="host"
                  name="host"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter host name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div> */}
            </div>

            <div className="booth__body--form-input-container">
              <label
                htmlFor="sessionTags"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Tags</p>
                <p className="input-description">
                  By adding tags, you can help the attendee to find their
                  preferred session quickly.
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="sessionTags"
                  name="sessionTags"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Add tags"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>

            <div className="booth__body--form-input-container">
              <label
                htmlFor="biography"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Session description</p>
                <p className="input-description">
                  Add more details about this item for better clarity to
                  attendees.
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <textarea
                  name="sessionDescription"
                  id="sessionDescription"
                  placeholder="Add session description"
                  onChange={handleChange}
                  style={{
                    minHeight: "120px",
                    width: "100%",
                    marginBottom: "2px",
                    position: "relative",
                    border: "1px solid rgb(154, 151, 170)",
                    borderRadius: "8px",
                    background: "transparent",
                    padding: "12px",
                  }}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="next-container" style={{}}>
            <button className="btn btn-primary" type="submit">
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? <span>Submit</span> : null}
                {!mutation.isLoading ? null : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                )}
              </div>
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default EditSession;
