import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";
import "../css/Summary.scss";

import "../css/ticketingTab.scss";
// import EventEntry from "./EventEntry";
import Ticketing from "./Ticketing/Ticketing";
import Withdrawals from "./Withdrawals/Withdrawal";
// import AddSpeaker from "../AddSpeaker";

const TicketingTab = ({ userId, token, eventID }) => {
  // add speaker

  const [activeLink, setActiveLink] = useState(0);

  // // Function to handle link clicks
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  return (
    <React.Fragment>
      <div className="registration-container">
        <div className="column registration-menu">
          <ul className="registration-menu__list">
            <li className="registration-menu__item">
              <a
                href="#"
                className={`registration-menu__link ${
                  activeLink === 0 ? "registration-menu__list--active" : ""
                }`}
                onClick={() => handleLinkClick(0)}
              >
                Ticketing
              </a>
            </li>
            <li className="registration-menu__item">
              <a
                href="#"
                className={`registration-menu__link ${
                  activeLink === 2 ? "registration-menu__list--active" : ""
                }`}
                onClick={() => handleLinkClick(2)}
              >
                Withdrawals
              </a>
            </li>
            {/* <li className="registration-menu__item">
              <a
                href="#"
                className={`registration-menu__link ${
                  activeLink === 4 ? "registration-menu__list--active" : ""
                }`}
                onClick={() => handleLinkClick(2)}
              >
                Registration Form
              </a>
            </li> */}
          </ul>
        </div>
        <div className="column registration-content">
          {activeLink === 0 && (
            <Ticketing userId={userId} token={token} eventID={eventID} />
          )}
          {activeLink === 2 && (
            <Withdrawals userId={userId} token={token} eventID={eventID} />
          )}
          {/*
         {activeLink === 4 && <RegistrationForm userId={userId} token={token} eventID={eventID}/>}*/}
        </div>
      </div>
    </React.Fragment>
  );
};
export default TicketingTab;
