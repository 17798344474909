import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import CustomLogin from "../Pages/Authentication/CustomLogin";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import Verify from "../Pages/Verify/Index";
import TalkToUs from "../Pages/Authentication/TalkToUs";
import AttendeeReg from "../Pages/Authentication/AttenddeeReg";
import UserProfile from "../Pages/Authentication/user-profile";
import CreateEvent from "../Pages/Organizer/CreateEvent/Index";
import EventSummary from "../Pages/Organizer/EventSummary/Index";
import EventLanding from "../Pages/EventLanding/Index";
import JoinEventAsOrganizer from "../Pages/JoinEvent/Index";

import JoinSessionAsOrganizer from "../Pages/Organizer/JoinSession/Index";
import JoinBoothAsOrganizer from "../components/Common/JoinBooth/Index";
import MobileSession from "../Pages/Mobile/JoinSession";
import JoinSessionAsAttendee from "../components/Common/JoinSession/Index";
// import JoinSessionAsAttendee from "../components/Common/JoinBooth/Index";
// import JoinSessionAsExhibitor from "../Pages/Attendee/JoinSession/Index";
import JoinSessionSocial from "../Pages/Attendee/JoinActivity/Index";

//Speaker
import SpeakerEventReception from "../Pages/Speaker/JoinEvent/Index";
import JoinSessionAsSpeaker from "../Pages/Attendee/JoinSession/Index";

//Attendee
// import AttendeeEventLanding from "../Pages/Attendee/EventLanding/Index";
import MyTickets from "../Pages/MyTictets/MyTickets";
import MyInvoices from "../Pages/Invoice/Invoice";
import AttendeeEventReception from "../Pages/Attendee/JoinEvent/Index";
import BuyEvent from "../Pages/InvitePage/Index";
import BuyTicket from "../Pages/BuyTickets/Index";
import ExploreEvent from "../Pages/Explore/index";

// exhibitor

import ExhibitorSetBooth from "../Pages/Exhibitor/BoothSetup/Index";
import ExhibitorEventReception from "../Pages/Exhibitor/JoinEvent/Index";
import ExhibitorJoinBooth from "../Pages/Exhibitor/JoinBooth/Index";

//all users join exhibition lobby
import AllBooth from "../components/Common/allBooth/AllBooth";

//event officer
import OfficerDasboard from "../Pages/EventOfficer/Index";

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },
  {
    path: "/dashboard/:eventID",
    component: <ExploreEvent />,
  },
  {
    path: "/dashboard/:eventID",
    component: <ExploreEvent />,
  },

  // Profile
  { path: "/userprofile", component: <UserProfile /> },
  { path: "/my-tickets", component: <MyTickets /> },
  { path: "/my-invoices", component: <MyInvoices /> },
  { path: "/create-event", component: <CreateEvent /> },
  //see all booths
  { path: "/:eventName/booths/:eventID", component: <AllBooth /> },

  { path: "/:eventName/event-summary/:eventID", component: <EventSummary /> },
  { path: "/:eventName/event-landing/:eventID", component: <EventLanding /> },
  {
    path: "/:eventName/event-reception/:eventID",
    component: <JoinEventAsOrganizer />,
  },
  {
    path: "/Exhibition/:eventName/:boothName/:eventID/:boothID",
    component: <JoinBoothAsOrganizer />,
  },

  {
    path: "/:eventName/event/reception/:eventID",
    component: <AttendeeEventReception />,
  },

  {
    path: "/:eventName/Invite/:eventID",
    component: <BuyEvent />,
  },
  {
    path: "/:eventName/Ticket/:eventID",
    component: <BuyTicket />,
  },

  {
    path: "/:eventName/exhibitor/event/reception/:eventID",
    component: <ExhibitorEventReception />,
  },

  // JoinSessionAsSpeaker

  //Exhibitor routes

  {
    path: "/:eventName/speaker/event/reception/:eventID",
    component: <SpeakerEventReception />,
  },

  {
    path: "/:eventName/set-booth/:eventID",
    component: <ExhibitorSetBooth />,
  },
  {
    path: "/:boothName/exhibitor/:eventID/:boothID",
    component: <ExhibitorJoinBooth />,
  },

  //
  {
    path: "/explore-events",
    component: <ExploreEvent />,
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/login/:eventID", component: <CustomLogin /> },

  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  {
    path: "/register-as",
    component: <Register />,
  },
  {
    path: "login/verify/:ID/:Token",
    component: <Verify />,
  },
  {
    path: "/talk-to-us",
    component: <TalkToUs />,
  },
  { path: "/register-attendee", component: <AttendeeReg /> },
  {
    path: "/live-session/organizer/:eventName/:sessionName/:eventID/:sessionID",
    component: <JoinSessionAsOrganizer />,
  },

  {
    path: "/live-session/attendee/:eventName/:sessionName/:eventID/:sessionID",
    component: <JoinSessionAsAttendee />,
  },
  {
    path: "/:eventName/:sessionName/:firstName/:lastName/:eventID/:sessionID",
    component: <MobileSession />,
  },

  {
    path: "/live-session/social/attendee/:eventName/:sessionName/:eventID/:sessionID",
    component: <JoinSessionSocial />,
  },

  // live-session/attendee/
];

const customProtectedRoutes = [
  // event Officer
  // {
  //   path: "/portal/:eventID",
  //   component: <OfficerDasboard />,
  // },
  // { path: "/dashboard", component: <Dashboard /> },
];

const protectedRoutes = [
  // event Officer

  {
    path: "/portal/:eventID",
    component: <OfficerDasboard />,
  },
  { path: "/dashboard", component: <Dashboard /> },
];

export {
  authProtectedRoutes,
  publicRoutes,
  customProtectedRoutes,
  protectedRoutes,
};
