import React, { useState } from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";
import "./css/Summary.scss";

// import CreateSession from "../CreateSession";
// import EditSession from "../EditSession";
// import AddSpeaker from "./AddSpeaker";

import "./css/sessions.scss";
import ActivityList from "./activity/activity-list.component";

const ActivityTab = ({ userId, token, eventID }) => {
  const [modal_large, setmodal_large] = useState(false);
  // add speaker
  const [modal_large2, setmodal_large2] = useState(false);

  // edit event
  const [modal_large3, setmodal_large3] = useState(false);

  const [activeLink, setActiveLink] = useState(null);

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/${eventID}`,
    token
  );

  // console.log("getMyEventDetail...", getMyEventDetail);
  // console.log("getSession...Tab..", getMySessions);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }
  function tog_large2() {
    setmodal_large2(!modal_large2);
    removeBodyCss();
  }
  function tog_large3() {
    setmodal_large3(!modal_large3);
    removeBodyCss();
  }

  const DeleteEvent = (e, values) => {
    e.preventDefault();
    const Method = "DELETE",
      endPoint = `events/${eventID}`,
      isJSON = null;
    var raw = null;

    // execute(endPoint, raw, Method, RegisterMsg, token, isJSON);
  };

  if (isLoadingGetMySessions) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }

  // Function to handle link clicks
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  return (
    <React.Fragment>
      <div className="sessions-container">
        <div className="column sessions-menu">
          <ul className="sessions-menu__list">
            <li className="sessions-menu__item">
              <a
                href="#"
                className={`sessions-menu__link ${
                  activeLink === 0 ? "sessions-menu__list--active" : ""
                }`}
                onClick={() => handleLinkClick(0)}
              >
                Activities List
              </a>
            </li>
          </ul>
        </div>
        <div className="column sessions-content--summary ">
          <ActivityList userId={userId} token={token} eventID={eventID} />
        </div>
      </div>
    </React.Fragment>
  );
};
export default ActivityTab;
