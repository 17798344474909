import React from "react";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import "./attendeeCard.scss";
import Rating from "react-rating";
import { FaStar } from "react-icons/fa";

function EventRatings({ token, eventID }) {
  const {
    data: getMyEventRating,
    error: errorgetMyEventRating,
    isLoading: isLoadinggetMyEventRating,
  } = useCustomQuery(
    ["myEventRatings", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  const ratings =
    Array.isArray(getMyEventRating?.ratings) &&
    getMyEventRating?.ratings.length > 0
      ? getMyEventRating.ratings.reduce(
          (accumulator, currentValue) => accumulator + currentValue
        ) / getMyEventRating.ratings.length
      : 0;

  return (
    <div className="registered-user-card">
      <div className="d-flex">
        <div className="attendee-analistics-icons-container mt-1">
          <FaStar size={30} color="white" />
        </div>
        <div className="d-flex align-items-center">
          <div>
            <div style={{ color: "black", fontSize: 20, fontWeight: "900" }}>
              Event Ratings
            </div>
            <div style={{ color: "#1a87a3", fontSize: 18, fontWeight: "900" }}>
              <div className="rating-star">
                <Rating
                  emptySymbol="mdi mdi-star-outline text-warning fa-1x"
                  fullSymbol="mdi mdi-star text-warning fa-1x"
                  fractions={2}
                  initialRating={ratings}
                  readonly={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventRatings;
