import React from "react";

function HostProfile({ hostDetails }) {
  console.log("hostDetails....", hostDetails);
  return (
    <div className="w-100">
      <div className="d-flex">
        <div style={{ width: 120, height: 120 }}>
          <img
            src={hostDetails?.photo}
            className="w-100 h-100"
            style={{ borderRadius: 10 }}
          />
        </div>
        <div className="d-flex justify-content-center">
          <div className="mt-1 mx-2">
            <div className="" style={{ color: "black" }}>
              {hostDetails?.organizationName
                ? hostDetails?.organizationName
                : null}
            </div>
            <div className="" style={{ fontSize: 22, color: "black" }}>
              {!hostDetails?.organizationName
                ? `${hostDetails?.firstName} ${hostDetails?.lastName}`
                : null}
            </div>
            <div className="" style={{ color: "black" }}>
              {hostDetails?.email}
            </div>
            <div className="" style={{ color: "black" }}>
              {hostDetails?.phoneNumber}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2" style={{ color: "black" }}></div>
    </div>
  );
}

export default HostProfile;
