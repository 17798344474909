async function fetcher(url, token) {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const result = await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((res) => {
      if (res?.status === "success") {
        // return { data: res?.data, error: null };
        return res?.data;
      }
      if (res?.status === "fail") {
        const data = [];
        return data;
        // return { data: [], error: res?.message };
      }
    })
    .catch((error) => {
      // console.log("res.....error....", error);
      return { data: [], error: error };
      // const data = [];
      // return data;
    });

  return result;
}
export default fetcher;
