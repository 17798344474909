import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import SimpleBar from "simplebar-react";
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Row,
  Col,
  Spinner,
} from "reactstrap";

//Rextangle.png
import "./Main.scss";
import Profile from "./Profile";
import Products from "./Products";
// import Enquiry from "./Enquiry";
import Live from "./Live";
import Sponsors from "./Sponsors";

import "./liveBtn.scss";
import classnames from "classnames";

const Main = ({ eventID, token, boothDetails, boothID }) => {
  const [activeTabJustify, setactiveTabJustify] = useState("5");

  function toggleCustomJustified(tab) {
    if (activeTabJustify !== tab) {
      setactiveTabJustify(tab);
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ height: "600px" }}>
        <div className="d-flex px-4 justify-content-center align-items-center">
          <div className="w-100">
            <Row className="mt-1  w-100">
              <Col md={12}>
                <div
                  className="shadow-sm mt-2 card-brd  w-100 preview-booth-container"
                  style={{ position: "relative" }}
                >
                  <img src={boothDetails?.boothLogo} className="card-brd" />
                  {boothDetails?.chat ? (
                    <div className="live-component">
                      <button
                        className="btn btn-primary btn-wave"
                        style={{
                          width: 120,
                          height: 40,
                          position: "absolute",
                          top: "50%",
                          // right: "50%",
                          left: "40%",
                          bottom: "50%",
                          transform: "translateY(-50%)", // Adjust vertically
                        }}
                      >
                        Go Live
                      </button>
                    </div>
                  ) : null}
                </div>

                <div className="tab--booth-body-container shadow-sm">
                  <Nav pills className="navtab-bg nav-justified mt-2 mb-2">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "5",
                        })}
                        onClick={() => {
                          toggleCustomJustified("5");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block btn--text">
                          Profile
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "6",
                        })}
                        onClick={() => {
                          toggleCustomJustified("6");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-calendar"></i>
                        </span>
                        <span className="d-none d-sm-block btn--text">
                          Products
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "7",
                        })}
                        onClick={() => {
                          toggleCustomJustified("7");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-star"></i>
                        </span>
                        <span className="d-none d-sm-block btn--text">
                          Sponsors
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "8",
                        })}
                        onClick={() => {
                          toggleCustomJustified("8");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-message"></i>
                        </span>
                        <span className="d-none d-sm-block btn--text">
                          Chats
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTabJustify}>
                    <TabPane tabId="5" className="p-3">
                      <Profile
                        eventID={eventID}
                        token={token}
                        boothID={boothID}
                      />
                    </TabPane>
                    <TabPane tabId="6" className="p-3 ">
                      <Products
                        eventID={eventID}
                        token={token}
                        boothID={boothID}
                      />
                    </TabPane>
                    <TabPane tabId="7" className="p-3">
                      <Sponsors
                        eventID={eventID}
                        token={token}
                        boothID={boothID}
                      />
                    </TabPane>
                    <TabPane tabId="8" className="p-3">
                      <Live eventID={eventID} token={token} boothID={boothID} />
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};
export default Main;
