import React from "react";
import { Button } from "@mui/material";
import "./css/ticketSelection.scss";

function VipTicket({
  type,
  price,
  earlyBird,
  earlyBirdDate,
  setSelectedTicket,
  handleContinue,
}) {
  const handleSelect = () => {
    let ticket = { type: type, price: price };
    setSelectedTicket(ticket);
    handleContinue();
  };
  return (
    <div className="ticket-card-container">
      <div className="ticket-card-divider">
        <div className="ticket-card-divider-left"></div>
        <div className="ticket-card-divider-right"></div>
      </div>
      <div className="ticket-card mb-2">
        <div className="ticket-card-top">
          <div className="ticket-card-top-title">
            <p className="ticket-card-top-title-type text-warning">{type}</p>
            {/* <p className="ticketing-card-top-title-price">R200</p> */}
          </div>
          <div className="ticket-card-top-title justify-content-center">
            <div className="d-flex">
              <span
                className="ticket-title-price"
                style={{
                  textDecoration: earlyBird ? "line-through" : "",
                  textDecorationColor: earlyBird ? "#fcb92c" : "",
                }}
              >
                R{price}
              </span>
              {earlyBird && (
                <p className="mx-1 ticket-discount-price text-warning">
                  R{earlyBird}
                </p>
              )}
              <br></br>
            </div>
          </div>
          {earlyBirdDate && (
            <div className="ticket-status-container">
              <span className="ticket-card-days">
                Early Bird valid until{" "}
                <span style={{ fontWeight: "700", color: "#fcb92c" }}>
                  {earlyBirdDate}
                </span>
              </span>
            </div>
          )}
        </div>
        <div className="ticket-horizontal-diver-main"></div>
        <div
          className="ticket-card-bottom 
            "
        >
          <div className="ticket-card-bottom-container p-2">
            <div className="ticket-card-bottom-container-btn">
              <Button
                variant="contained"
                onClick={handleSelect}
                style={{
                  width: "150px",
                  height: "40px",
                  marginTop: "20px",
                  backgroundColor: "#fcb92c",
                }}
              >
                Select
              </Button>
            </div>
          </div>
          <div className="ticket-card-bottom-circle-main bg-warning"></div>
        </div>
      </div>
    </div>
  );
}

export default VipTicket;
