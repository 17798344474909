import React, { useState } from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import { Modal } from "reactstrap";
import ProductDetails from "./components/ProductDetails";
import { isNull } from "lodash";

function Products({ eventID, token, boothID, boothDetails }) {
  const [viewProducts, setViewProducts] = useState(false);
  const [productDetails, setPoductDetails] = useState(isNull);
  const {
    data: getProducts,
    error: errorGetProducts,
    isLoading: isLoadingGetProducts,
  } = useCustomQuery(
    ["exhibition-Products-Booth", eventID],
    `${process.env.REACT_APP_API}products/booth/${boothID}`,
    token
  );
  let handleViewProduct = (product) => {
    setViewProducts(!viewProducts);
    setPoductDetails(product);
  };

  function tog_viewProduct() {
    setViewProducts(!viewProducts);
  }
  return (
    <>
      <div className="w-100 d-flex mb-5">
        {getProducts?.map((product, i) => {
          return (
            <div key={i} className="mx-2">
              <div
                style={{ width: 200, height: 200, borderRadius: 10 }}
                onClick={() => handleViewProduct(product)}
              >
                <img
                  src={product?.productImage}
                  className="w-100 h-100"
                  style={{ borderRadius: 10 }}
                />
                <div className="w-100 mt-1  d-flex justify-content-center">
                  {product?.productName}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        size="md"
        isOpen={viewProducts}
        toggle={() => {
          tog_viewProduct();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Product Details
          </h5>
          <button
            onClick={() => {
              setViewProducts(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <ProductDetails details={productDetails} />
        </div>
        {/* <div className="modal-footer d-flex justify-content-between">
          <button
            onClick={() => {
              setDeleteEventModal(false);
            }}
            type="button"
            className="btn btn-dark"
          >
            Cancel
          </button>
         
        </div> */}
      </Modal>
    </>
  );
}

export default Products;
