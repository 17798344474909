import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../../Hooks/useCustomMutation";
import { Modal, Spinner } from "reactstrap";
import LoaderSM from "../../../../../components/Loader/LoaderSM";
// import SessionPreviewSlider from "../../../../../components/Modal/SessionSlide";
import SlideInRightBar from "../../../../../components/Common/SlideInComponent/SlideInComponent";

import { AiOutlineSearch } from "react-icons/ai";

import { AiFillEye } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";

import { AiOutlinePlusCircle } from "react-icons/ai";
import AddBooth from "../Addbooth";

function Exhibitor({ userId, token, eventID }) {
  const [dropdownOpenStates, setDropdownOpenStates] = useState({});
  const [slideInOpen, setSlideInOpen] = useState(false);
  const [attendeeID, setAttendeeID] = useState(false);
  const [attendeeEmail, setAttendeeEmail] = useState(false);
  const [search, setSearch] = useState("");
  const [slideInOpenBooth, setSlideInOpenBooth] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const key = ["myExhibitor", eventID];
  const mutationDelete = useCustomMutation(key);

  const {
    data: getMyExhitors,
    error: errorGetMyExhitors,
    isLoading: isLoadingGetMyExhitors,
  } = useCustomQuery(
    ["myBooths", eventID],
    `${process.env.REACT_APP_API}exhibitors/${eventID}`,
    token
  );
  const tog_deleteParticipant = (id, email) => {
    setAttendeeID(id);
    setAttendeeEmail(email);
    setIsToggleDelete(!isToggleDelete);
  };

  const DeleteParticitant = () => {
    const Method = "DELETE",
      url = `exhibitors/${attendeeID}/?event=${eventID}&exhibitor=${attendeeEmail}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Exhibitor Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  if (isLoadingGetMyExhitors) {
    <LoaderSM />;
  }
  const toggleSlideInBooth = () => {
    setSlideInOpenBooth(!slideInOpenBooth);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Full Name</span>,
      selector: (row) => `${row?.firstName} ${" "} ${row?.lastName}`,
    },
    {
      name: <span className="font-weight-bold fs-13">Organization Name</span>,
      selector: (row) => row?.organization,
    },

    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email,
    },
    {
      name: <span className="font-weight-bold fs-13">Booth Ref</span>,
      selector: (row) => row?.boothNumber,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div>
            <span>
              <AiFillEye
                size={20}
                className="mx-1"
                // onClick={() => tog_viewSponsor(row)}
              />
            </span>
            <span>
              <BiEdit size={16} className="mx-1" />
            </span>
            <span>
              <AiFillDelete
                size={20}
                className="mx-1"
                onClick={() => tog_deleteParticipant(row?._id, row?.email)}
              />
            </span>
          </div>
        );
      },
    },
  ];

  // console.log("getMyBooths...", getMyExhitors);

  useEffect(() => {
    if (mutationDelete.isSuccess) {
      setIsToggleDelete(false);
    }
  }, [mutationDelete.isSuccess]);

  const data = getMyExhitors;

  return (
    <React.Fragment>
      <div className="people-attendees__heading">
        <h3>Exhibitor</h3>
        <span className="text">Manage your exhibitor allocated here</span>
      </div>
      <div className="people-attendees__body">
        <div className="mb-5 d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div
              style={{
                background: "#ffffff",
                paddingTop: ".58rem",
                paddingBottom: ".5rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                marginRight: 32,
                border: "solid",
                borderWidth: 0.1,
                borderRadius: 8,
              }}
            >
              <AiOutlineSearch size={24} />
              <input
                type="text"
                placeholder={"Search Exhibitor"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  border: "none",
                  marginLeft: 8,
                  paddingRight: 24,
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div
              className="d-flex"
              style={{
                border: "1px solid #21929c",
                borderRadius: "8px",
                fontSize: 14,
                background: "#21929c",
                color: "#fefefe",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: "14px",
                height: 50,
              }}
              onClick={toggleSlideInBooth}
              pointer="cursor"
            >
              <p>
                <AiOutlinePlusCircle
                  size={22}
                  // style={{ marginRight: 8 }}
                />
                <span pointer="cursor">Add Booth</span>
                <SlideInRightBar
                  isOpen={slideInOpenBooth}
                  title="Add Booth"
                  toggle={toggleSlideInBooth}
                  body={
                    <AddBooth
                      userId={userId}
                      token={token}
                      eventID={eventID}
                      setSlideInOpenBooth={setSlideInOpenBooth}
                    />
                  }
                />
              </p>
            </div>
          </div>
        </div>
        <div className="analystic-table-card">
          <div className="w-100">
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="300px"
              columns={columns}
              responsive
              data={data}
              pagination
            />
          </div>
        </div>
      </div>
      <Modal
        size="md"
        isOpen={isToggleDelete}
        toggle={() => {
          tog_deleteParticipant();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Delete Event
          </h5>
          <button
            onClick={() => {
              setIsToggleDelete(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5 className="text-danger">
            Are you sure you want to delete this Exhibitor ?
          </h5>
        </div>
        <div className="modal-footer d-flex justify-content-between">
          <button
            onClick={() => {
              setIsToggleDelete(false);
            }}
            type="button"
            className="btn btn-dark"
          >
            Cancel
          </button>
          <button
            onClick={() => DeleteParticitant()}
            type="button"
            className="btn btn-danger"
          >
            {!mutationDelete?.isLoading ? (
              <span className="px-2">Sure</span>
            ) : (
              <span>
                <Spinner as="span" animation="border" size="sm" />
                Loading
              </span>
            )}
          </button>
        </div>
      </Modal>
      <SlideInRightBar
        isOpen={slideInOpenBooth}
        title="Add Booth"
        toggle={toggleSlideInBooth}
        body={
          <AddBooth
            userId={userId}
            token={token}
            eventID={eventID}
            setSlideInOpenBooth={setSlideInOpenBooth}
          />
        }
      />
    </React.Fragment>
  );
}

export default Exhibitor;
