import React, { useState } from "react";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../../Hooks/useCustomMutation";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";
//icons

// import { useNavigate, useParams } from "react-router-dom";

import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineSend } from "react-icons/ai";

import BoothDefault from "../../../../../assets/images/golfie.jpg";
import UserDefault from "../../../../../assets/images/users/avatar-1.jpg";

import CustomModal from "../../../../../components/Common/boothModal/slide-in-modal.component";

import BoothBody from "./boothBody";
import "./booths.styles.scss";

const Booths = ({ userId, token, eventID, eventDetail }) => {
  const [modal_large, setmodal_large] = useState(false);
  const [isChatBoxVisible, setChatBoxVisible] = useState(false);
  // add speaker
  const [modal_large2, setmodal_large2] = useState(false);
  const [inputs, setInputs] = useState({});

  // edit event
  const [modal_large3, setmodal_large3] = useState(false);

  const [Dedirect, setDedirect] = useState(false);

  // add sponsor

  const [slideInOpenBoothDetails, setSlideInOpenBoothDetails] = useState(false);

  const toggleSlideInBoothDetails = () => {
    setSlideInOpenBoothDetails(!slideInOpenBoothDetails);
  };

  const handleChatClick = () => {
    setChatBoxVisible(true);
  };

  const handleCloseClick = () => {
    setChatBoxVisible(false);
  };

  const {
    data: getAllBooth,
    error: errorGetAllBooth,
    isLoading: isLoadingGetAllBooth,
  } = useCustomQuery(
    ["allBooths", eventID],
    `${process.env.REACT_APP_API}booths/event/${eventID}`,
    token
  );

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomQuery(
    ["mySession", eventID],
    `https://orca-app-65xn9.ondigitalocean.app/api/v1/sessions/by-event/standard/${eventID}`,
    token
  );

  // console.log("eventDetail...", eventDetail);
  //   console.log("getSession...", getMySessions);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }
  function tog_large2() {
    setmodal_large2(!modal_large2);
    removeBodyCss();
  }
  function tog_large3() {
    setmodal_large3(!modal_large3);
    removeBodyCss();
  }

  //   const DeleteEvent = (e, values) => {
  //     e.preventDefault();
  //     const Method = "DELETE",
  //       endPoint = `events/${eventID}`,
  //       isJSON = null,
  //       token = userDetails?.token;
  //     var raw = null;

  //     // execute(endPoint, raw, Method, RegisterMsg, token, isJSON);
  //   };

  if (isLoadingGetAllBooth) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  console.log("getAllBooth...", getAllBooth);

  // if (isLoadingGetMySessions) {
  //   <div className="mt-5 d-flex justify-content-center align-items-center">
  //     <Spinner animation="border" role="status">
  //       <span className="visually-hidden">Loading...</span>
  //     </Spinner>
  //   </div>;
  // }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  return (
    <div className="booths-content">
      <div className="booths-content__header">
        <h2 style={{ color: "white" }}>Booths</h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "24px",
            marginLeft: "24px",
          }}
        >
          <div>
            <div
              className="search-bar"
              style={{
                backgroundColor: "#131216",
                border: "1px solid white",
                color: "white",
              }}
            >
              <AiOutlineSearch size={18} />
              <input
                className="search-bar__input"
                type="text"
                placeholder={`Search booths`}
                style={{ backgroundColor: "#131216", color: "white" }}
              />
            </div>
          </div>
          <div
            className="with-exhibitors"
            style={{
              background: "#5d5d5f",
              borderRadius: "8px",
              padding: "4px 8px",
              fontSize: "10px",
              marginBottom: "-24px",
            }}
          >
            <span style={{ color: "white" }}>With exhibitors (0)</span>
          </div>
        </div>
      </div>
      {getAllBooth?.map((booth, i) => {
        console.log("booth...", booth);
        return (
          <div className="booths-content__body" key={i}>
            <div
              className="booths-content__body--booth__container"
              style={{ position: "relative", cursor: "pointer" }}
              onClick={toggleSlideInBoothDetails}
            >
              <div
                className="booths-content__body--booth"
                style={{
                  padding: "18px",
                  width: "48%",
                  background: "#5d5d5f",
                  borderRadius: "8px",
                }}
              >
                <img
                  src={BoothDefault}
                  width="100%"
                  height="40%"
                  alt=""
                  style={{ borderRadius: "12px" }}
                />
                <div
                  style={{
                    display: "flex",
                    gap: "16px",
                    marginTop: "16px",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={UserDefault}
                    width="8%"
                    alt=""
                    style={{ borderRadius: "8px" }}
                  />
                  <span style={{ color: "white" }}>|</span>
                  <p style={{ color: "white", marginBottom: "-8px" }}>
                    {booth?.boothName}
                  </p>
                  <div
                    className="with-exhibitors"
                    style={{
                      background: "#131216",
                      borderRadius: "8px",
                      padding: "4px 8px",
                      fontSize: "10px",
                      marginBottom: "-12px",
                      marginLeft: "240px",
                    }}
                  >
                    {booth?.boothTags?.map((tag, i) => {
                      return (
                        <span style={{ color: "white" }} key={i}>
                          # {tag}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div
                className="booth-chat"
                style={{
                  margin: "24px 0",
                  color: "#a3a1af",
                  visibility: "hidden",
                }}
              >
                <p style={{ fontSize: "17px", fontWeight: "700" }}>
                  Chat with Us
                </p>
                <div
                  style={{
                    width: "max-content",
                    padding: "8px 12px",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    border: "1px solid #a3a1af",
                    borderRadius: "8px",
                    background: "rgba(255, 255, 255, 0.1)",
                    cursor: "pointer",
                  }}
                  onClick={handleChatClick}
                >
                  <span style={{ fontSize: "12px" }}>
                    Send a message or question
                  </span>
                  <AiOutlineSend />
                </div>
              </div>

              <CustomModal
                isOpen={slideInOpenBoothDetails}
                toggle={toggleSlideInBoothDetails}
                title="Booth"
                body={<BoothBody boothDetails={booth} />}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Booths;
