import React, { useState, useRef, useEffect } from "react";
import { Spinner, Button } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiEdit } from "react-icons/bi";
import "bootstrap/dist/css/bootstrap.min.css";

import useCustomMutation from "../../../../Hooks/useCustomMutation";
import ImgSession from "../../../../assets/images/banners/sessions/bannerSession.png";
import { sessionCategories } from "../../../../components/sessionCategories/sessionCategories";

// import "./css/Summary.scss";
import "./css/AddSession.scss";

const AddActivityLineup = ({
  token,
  eventID,
  setSlideInOpenSession,
  activity,
}) => {
  // const refFileUpload = useRef(null);
  const key = ["mySessions", eventID];
  const mutation = useCustomMutation(key);

  // console.log("activity...", activity?._id);

  const [inputs, setInputs] = useState({});
  const [logoServer, setLogoServer] = useState();
  const [profile, setProfile] = useState();
  // const [sessionName, setSessionName] = useState({});
  //   const [startTime, setSessionName] = useState({});
  const [activeLink, setActiveLink] = useState(null);

  // add attendee
  const [slideInOpen, setSlideInOpen] = useState(false);

  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };

  // time picker
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedTime, setSelectedTime] = useState("");

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleTimeSelection = (time) => {
    setSelectedTime(time);
    setShowDropdown(false);
  };

  // dating
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  // Function to handle link clicks
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  const refFileUpload = useRef("");

  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLogoServer(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // console.log("session...inputs", inputs);
  // console.log("selectedDate...", selectedDate);

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "POST",
      url = `acts/${eventID}/${activity?._id}`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("profileImage", logoServer);
    formdata.append("actName", inputs?.actName);
    formdata.append("email", inputs?.email);
    formdata.append("city", inputs?.city);
    formdata.append("country", inputs?.country);
    formdata.append("actDetails", inputs?.actDetails);
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Lineup Successfully Added",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      setLogoServer();
      setInputs({});
      setSlideInOpenSession(false);
    }
  }, [mutation.isSuccess]);

  return (
    <React.Fragment>
      <div className="booth__body">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="booth__body--form"
          onSubmit={handleSubmitForm}
        >
          <div className="mt-0 w-100">
            <div className="d-flex justify-content-center">
              <div className="section---banner---container mt-2">
                <img
                  src={!logoServer ? ImgSession : profile}
                  alt="session Image"
                  className="w-100 h-100 section---banner---container---img"
                />
                <Button
                  size="md"
                  variant="separator-light"
                  className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
                  onClick={onThumbChangeClick}
                >
                  <BiEdit
                    size={40}
                    style={{
                      color: "black",
                      filter: "drop-shadow(0px 0px 10px white)",
                    }}
                  />
                </Button>
                <input
                  type="file"
                  ref={refFileUpload}
                  className="file-upload d-none"
                  accept="image/*"
                  onChange={changeThumb}
                />
              </div>
            </div>

            {/* ------------------ Form Fields ---------------------- */}
            <div
              className="sponsor-form-body__sponsor-logo"
              style={{ marginRight: "12px", marginBottom: "-16px" }}
            >
              <p
                color="ambience.6"
                font-size="1"
                className="sponsor-form-body--input-description"
              >
                Recommended format: 400x200px | JPG, SVG, PNG | Up to 1 MB
              </p>
            </div>

            <div className="booth__body--form-input-container">
              <label
                htmlFor="actName"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Name*</p>
                <p className="input-description">Name of your guest</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="actName"
                  name="actName"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="email"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Email*</p>
                <p className="input-description">Email of your guest</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={handleChange}
                  required
                  placeholder="Email"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="city"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">city</p>
                <p className="input-description">City of your guest</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="city"
                  name="city"
                  type="text"
                  onChange={handleChange}
                  placeholder=""
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="country"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">country</p>
                <p className="input-description">country of your guest</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="country"
                  name="country"
                  type="text"
                  onChange={handleChange}
                  placeholder=""
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>

            <div className="booth__body--form-input-container">
              <label
                htmlFor="actDetails"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Lineup description</p>
                <p className="input-description">
                  Add more details about this item for better clarity to
                  attendees.
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <textarea
                  name="actDetails"
                  id="actDetails"
                  placeholder="Add session description"
                  onChange={handleChange}
                  style={{
                    minHeight: "120px",
                    width: "100%",
                    marginBottom: "16px",
                    position: "relative",
                    border: "1px solid rgb(154, 151, 170)",
                    borderRadius: "8px",
                    background: "transparent",
                    padding: "12px",
                  }}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="next-container" style={{}}>
            <button className="btn btn-primary" type="submit">
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? <span>Add</span> : null}
                {!mutation.isLoading ? null : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                )}
              </div>
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default AddActivityLineup;
