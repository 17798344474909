import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  Modal,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Main from "./components/Main";
import { IoArrowBack } from "react-icons/io5";
import { AddToCalendarButton } from "add-to-calendar-button-react";

const JoinBooth = () => {
  const navigate = useNavigate();
  document.title = "Showbay 24 | Join Booth";
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userId = obj?.data?.data?.id;
  const token = obj?.token;
  const params = useParams();

  const eventID = params.eventID;
  const boothID = params.boothID;
  const boothName = params.boothName;
  const myBoothName = boothName.replace(/-/g, " ");

  return (
    <div className="page-content">
      <Navbar
        style={{
          position: "fixed",
          width: "98%",
          background: "#fff",
          zIndex: 1000,
          borderRadius: 5,
        }}
      >
        <NavbarBrand className="w-100 d-flex">
          <div className="w-100 d-flex">
            <div
              className="w-100 d-flex"
              onClick={() => {
                navigate(-1);
              }}
            >
              <div style={{ paddingLeft: 36, paddingRight: 18 }}>
                <IoArrowBack size={30} />
              </div>
              <div>
                <h3 style={{ color: "#000" }}>{myBoothName}</h3>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-end">
              <div className="w-100 d-flex align-items-center h4 text-primary">
                Booth going live soon
              </div>
              <div
                style={{
                  // position: "absolute",
                  // zIndex: "1001",
                  // top: "64%",
                  // right: "2%",
                  color: "white",
                  fontWeight: "600",
                  letterSpacing: "0.8px",
                  border: "none",
                }}
              >
                <AddToCalendarButton
                  name="Calendar"
                  options={[
                    "Apple",
                    "Google",
                    "Outlook.com",
                    "iCal",
                    "Microsoft365",
                  ]}
                  location="jkffl"
                  startDate="2021-12-20"
                  // endDate="2021/12/20"
                  // startTime="20:00"
                  // endTime="11:00"
                  // style={{ background: "blue" }}
                  // location={session?.sessionLocation}
                  // tartDate={date}
                  // endDate={date}
                  // startTime={session?.startTime}
                  // endTime={session?.endTime}
                  // timeZone={timeZone}
                  lightMode="dark"
                  hideBackground
                  label="Add A Reminder"
                >
                  Add a Reminder
                </AddToCalendarButton>
              </div>
            </div>
          </div>
        </NavbarBrand>
      </Navbar>
      <div style={{ marginTop: "4%" }}>
        <Main
          userId={userId}
          eventID={eventID}
          boothName={boothName}
          boothID={boothID}
          token={token}
        />
      </div>
    </div>
  );
};

export default JoinBooth;
