import React, { useState } from "react";
import { Spinner } from "reactstrap";
import {
  warningMessage,
  dangerMessage,
  successMessage,
} from "../../../components/Toast/Toast";
import { Button, TextField, Typography } from "@mui/material";
const RegistrationForm = ({
  selectedTicket,
  ticketQuantity,
  handleNext,
  eventDetails,
  token,
}) => {
  const [submissionsLeft, setSubmissionsLeft] = useState(ticketQuantity);
  const [btnLoad, setBtnLoad] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    organization: "",
    city: "",
    country: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnLoad(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      jobTitle: formData.jobTitle,
      organization: formData.organization,
      city: formData.city,
      country: formData.country,
      eventName: eventDetails?.eventName,
      date: eventDetails?.startDate,
      startTime: eventDetails?.startTime,
      venue: eventDetails?.eventFormat === "Virtual" ? "Venue" : "N/A",
      ticketType: selectedTicket.type,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    return fetch(
      `${process.env.REACT_APP_API}tickets/${eventDetails?._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res?.status === "success") {
          console.log("res.....", res);
          setBtnLoad(false);
          successMessage(`User Successfully Registered`);
          handleNext();
          // if (submissionsLeft > 1) {
          //   setSubmissionsLeft(submissionsLeft - 1);
          // } else {
          //   handleNext();
          // }
        } else {
          setBtnLoad(false);
          warningMessage(`${res?.message}`);
        }
      })
      .catch((error) => {
        setBtnLoad(false);
        dangerMessage(`${error.message}`);
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div>
          <div className="d-flex justify-content-center">
            {/* <Typography variant="h6">
              Registrations Left: {submissionsLeft}
            </Typography> */}
          </div>

          <form onSubmit={handleSubmit}>
            <div className="mt-3">
              <TextField
                label="First Name"
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
                variant="outlined"
                style={{ marginRight: "10px" }}
                required
              />
              <TextField
                label="Last Name"
                value={formData.lastName}
                onChange={(e) =>
                  setFormData({ ...formData, lastName: e.target.value })
                }
                variant="outlined"
                style={{ marginRight: "10px" }}
                required
              />
            </div>
            <div className="mt-3">
              <TextField
                label="Email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                variant="outlined"
                style={{ marginRight: "10px" }}
                required
              />
              <TextField
                label="Job Title"
                value={formData.jobTitle}
                onChange={(e) =>
                  setFormData({ ...formData, jobTitle: e.target.value })
                }
                variant="outlined"
                style={{ marginRight: "10px" }}
              />
            </div>{" "}
            <div className="mt-3">
              <TextField
                label="Organization"
                value={formData.organization}
                onChange={(e) =>
                  setFormData({ ...formData, organization: e.target.value })
                }
                variant="outlined"
                style={{ marginRight: "10px" }}
              />
              <TextField
                label="City"
                value={formData.city}
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
                variant="outlined"
                style={{ marginRight: "10px" }}
              />
            </div>
            <div className="mt-3">
              <TextField
                label="Country"
                value={formData.country}
                onChange={(e) =>
                  setFormData({ ...formData, country: e.target.value })
                }
                variant="outlined"
                style={{ marginRight: "10px", width: "100%" }}
              />
            </div>
            <div className="mt-3 d-flex justify-content-center">
              <Button type="submit" variant="contained">
                {btnLoad ? (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
