import React from "react";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import "./attendeeCard.scss";
import { FaUser } from "react-icons/fa";

function RegisterUsers({ token, eventID }) {

  const {
    data: getRegisteredAttendees,
    error: errorGetRegisteredAttendees,
    isLoading: isLoadingGetRegisteredAttendees,
  } = useCustomQuery(
    ["myRegisteredAttendees", eventID],
    `${process.env.REACT_APP_API}attendees/${eventID}/event`,
    token
  );

  return (
    <div className="registered-user-card">
      <div className="d-flex">
        <div className="attendee-analistics-icons-container mt-1">
          <FaUser size={30} color="white" />
        </div>
        <div className="d-flex align-items-center">
          <div>
            <div style={{ color: "black", fontSize: 16, fontWeight: "900" }}>
              Attendees Registered
            </div>
            <div style={{ color: "#1a87a3", fontSize: 28, fontWeight: "900" }}>
            {Array.isArray(getRegisteredAttendees) ? getRegisteredAttendees.length : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterUsers;
