import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "./css/Summary.scss";

// import CreateSession from "../CreateSession";
// import EditSession from "../EditSession";
// import AddSpeaker from "./AddSpeaker";

import "./css/sessions.scss";
import SessionList from "./sessions/sessions-list/session-list.component";

const SessionsTab = ({ userId, token, eventID }) => {
  const [activeLink, setActiveLink] = useState(null);

  // Function to handle link clicks
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  return (
    <React.Fragment>
      <div className="sessions-container">
        <div className="column sessions-menu">
          <ul className="sessions-menu__list">
            <li className="sessions-menu__item">
              <a
                href="#"
                className={`sessions-menu__link ${
                  activeLink === 0 ? "sessions-menu__list--active" : ""
                }`}
                onClick={() => handleLinkClick(0)}
              >
                Session List
              </a>
            </li>
          </ul>
        </div>
        <div className="column sessions-content--summary">
          <SessionList userId={userId} token={token} eventID={eventID} />
        </div>
      </div>
    </React.Fragment>
  );
};
export default SessionsTab;
