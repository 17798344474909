import React, { useState } from "react";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";
//icons

import { useNavigate } from "react-router-dom";

import SlideInComponent from "../../../../../components/Common/SlideInComponent/SlideInComponent";

const Reception = ({ token, userId, eventID, eventDetail }) => {
  const Navigate = useNavigate();
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userName = obj?.data?.data?.firstName;
  const userEmail = obj?.data?.data?.email;

  const [slideInOpenSession, setSlideInOpenSession] = useState(false);
  const [slideInOpenSpeaker, setSlideInOpenSpeaker] = useState(false);
  const [slideInOpenSponsor, setSlideInOpenSponsor] = useState(false);
  const [slideInOpenBooth, setSlideInOpenBooth] = useState(false);

  const toggleSlideInSession = () => {
    setSlideInOpenSession(!slideInOpenSession);
  };

  const toggleSlideInSpeaker = () => {
    setSlideInOpenSpeaker(!slideInOpenSpeaker);
  };

  const toggleSlideInSponsor = () => {
    setSlideInOpenSponsor(!slideInOpenSponsor);
  };

  const toggleSlideInBooth = () => {
    setSlideInOpenBooth(!slideInOpenBooth);
  };

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/${eventID}`,
    token
  );

  const {
    data: getMySponsors,
    error: errorGetMySponsors,
    isLoading: isLoadingGetMySponsors,
  } = useCustomQuery(
    ["mySponsors", eventID],
    `${process.env.REACT_APP_API}sponsors/event/${eventID}`,
    token
  );

  const {
    data: getMySponsorsInstitutional,
    error: errorGetMySponsorsInstitutional,
    isLoading: isLoadingGetSponsorsInstitutional,
  } = useCustomQuery(
    ["mySponsorsInstitutional", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=institutional`,
    token
  );

  const {
    data: getMySponsorsPlatinum,
    error: errorGetMySponsorsPlatinum,
    isLoading: isLoadingGetMySponsorsPlatinum,
  } = useCustomQuery(
    ["mySponsorsPlatinum", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=platinum`,
    token
  );

  const {
    data: getMySponsorsGold,
    error: errorGetMySponsorsGold,
    isLoading: isLoadingGetMySponsorsGold,
  } = useCustomQuery(
    ["mySponsorsGold", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=gold`,
    token
  );

  const {
    data: getMySponsorsSilver,
    error: errorGetMySponsorsSilver,
    isLoading: isLoadingGetSponsorsSilver,
  } = useCustomQuery(
    ["mySponsorsSilver", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=silver`,
    token
  );

  const isSponsors = getMySponsors && getMySponsors?.length > 0;
  const isInstitutionalSponsors =
    getMySponsorsInstitutional && getMySponsorsInstitutional?.length > 0;
  const isPlatinumSponsors =
    getMySponsorsPlatinum && getMySponsorsPlatinum?.length > 0;
  const isGoldSponsors = getMySponsorsGold && getMySponsorsGold?.length > 0;
  const isSilverSponsors =
    getMySponsorsSilver && getMySponsorsSilver?.length > 0;

  if (isLoadingGetMySessions) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }

  const handleJoinSession = (sessionName, sessionID) => {
    const sanitizedEventName = eventDetail?.eventName.replace(
      /[^a-zA-Z0-9]/g,
      "-"
    );
    const sanitizedSessionName = sessionName.replace(/[^a-zA-Z0-9]/g, "-");
    Navigate(
      `/live-session/organizer/${sanitizedEventName}/${sanitizedSessionName}/${eventID}/${sessionID}`
    );
  };

  // Render sessions based on the count
  const renderSessions = () => {
    const filteredSessions = getMySessions?.filter((session) =>
      session?.speakers?.some((speaker) => speaker?.email === userEmail)
    );

    return filteredSessions?.slice(0, 2).map((session, i) => {
      return (
        <div
          key={i}
          className="event-details__upcoming__sessions--session"
          style={{
            border: "1px solid #828283",
            borderRadius: "12px",
            width: "100%",
            position: "relative",
            color: "#c2c2c3",
            padding: "16px",
            background: "rgba(255, 255, 255, 0.1)",
            marginBottom: "16px",
          }}
        >
          <p style={{ color: "white", fontWeight: 600, fontSize: "13px" }}>
            {session?.startTime} - {session?.endTime}
          </p>
          <h4
            style={{
              color: "white",
              fontWeight: 600,
              margin: "5px 0 10px",
            }}
          >
            {session?.sessionName}
          </h4>
          <div className="d-flex">
            {session?.speakers?.map((speaker, i) => {
              return (
                <div
                  className="d-flex justify-content-center align-items-center h5"
                  key={i}
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    backgroundColor: "#1a87a3",
                    color: "white",
                    marginLeft: "-7px",
                    zIndex: i,
                    border: "2px solid white",
                  }}
                >
                  {speaker?.profileImage ? (
                    <img
                      src={speaker?.profileImage}
                      alt="speaker-picture"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <>{speaker?.firstName}</>
                  )}
                </div>
              );
            })}
          </div>

          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <span
              style={{
                backgroundColor: "#2d2c32",
                border: "0.8px solid #5c5c5c",
                borderRadius: "8px",
                padding: "4px 8px",
                fontSize: "12px",
              }}
            >
              Session
            </span>
            <span> &#8226;</span>
            {session?.tags?.map((tag, i) => {
              return (
                <div key={i} className="d-flex">
                  <span style={{ fontSize: "13px", marginRight: "5px" }}>
                    #{tag}
                  </span>
                </div>
              );
            })}
          </div>

          <button
            style={{
              position: "absolute",
              zIndex: "999",
              top: "5%",
              right: "5%",
              backgroundColor: "#2dd173",
              color: "white",
              fontWeight: "600",
              letterSpacing: "0.8px",
              padding: "8px 16px",
              border: "none",
              borderRadius: "7px",
            }}
            onClick={() =>
              handleJoinSession(session?.sessionName, session?._id)
            }
          >
            Join now
          </button>
        </div>
      );
    });
  };

  return (
    <div className="reception-content">
      <div className="community">
        <div
          className="community-banner"
          style={{ backgroundImage: `url(${eventDetail?.bannerImage})` }}
        >
          <div className="community-content">
            <div className="community-content__wrapper">
              <h6>{eventDetail?.eventName}</h6>
            </div>
          </div>
        </div>
      </div>

      <div className="event-details">
        <div className="event-details__welcome">
          <h2 style={{ color: "white", margin: "24px 0" }}>
            Welcome, {userName}
          </h2>
          <p style={{ color: "#c2c2c3", marginTop: "-12px", fontSize: "13px" }}>
            {eventDetail?.eventDescription}
          </p>
          {eventDetail?.videoLink ? (
            <iframe
              width="560"
              height="387"
              src={eventDetail?.videoLink}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              style={{ border: "0.4px solid #8f8f90", borderRadius: "12px" }}
            ></iframe>
          ) : null}
        </div>
        <div className="event-details__upcoming">
          <h2 style={{ color: "white", margin: "24px 0" }}>My Session</h2>
          <p style={{ color: "#c2c2c3", marginTop: "-12px", fontSize: "13px" }}>
            Explore what's about to come next from the session summary below.
          </p>
          <div className="event-details__upcoming__sessions">
            {renderSessions()}
          </div>
        </div>
      </div>
      {isSponsors ? (
        <div id="sponsors" className="event-sponsors text-dark">
          <h3>Sponsors</h3>
          {isInstitutionalSponsors ? (
            <>
              <h5 className="mt-5">Institutional</h5>
              <div className="">
                {getMySponsorsInstitutional?.map((sponsor, i) => {
                  return (
                    <div className="event-sponsors__item" key={i}>
                      <img
                        src={sponsor?.sponsorLogo}
                        alt="Showbay sponsor banner"
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
          {isPlatinumSponsors ? (
            <>
              <h5 className="mt-5">Platinum</h5>
              <div className="d-flex">
                {getMySponsorsPlatinum?.map((sponsor, i) => {
                  return (
                    <div className="event-sponsors__item" key={i}>
                      <img
                        src={sponsor?.sponsorLogo}
                        alt="Showbay sponsor banner"
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
          {isGoldSponsors ? (
            <>
              <h5 className="mt-5">Gold</h5>
              <div className="d-flex">
                {getMySponsorsGold?.map((sponsor, i) => {
                  return (
                    <div className="event-sponsors__item" key={i}>
                      <img
                        src={sponsor?.sponsorLogo}
                        alt="Showbay sponsor banner"
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
          {isSilverSponsors ? (
            <>
              <h5 className="mt-5">Silver</h5>
              <div className="d-flex">
                {getMySponsorsSilver?.map((sponsor, i) => {
                  return (
                    <div className="event-sponsors__item" key={i}>
                      <img
                        src={sponsor?.sponsorLogo}
                        alt="Showbay sponsor banner"
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
export default Reception;
