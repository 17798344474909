import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useCustomQuery from "../../../../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../../../../Hooks/useCustomMutation";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner, Button } from "reactstrap";
import "../../../css/Summary.scss";

// import excelFile from "../../../../../../../assets/participants/Participants.xlsx";

// import csvFile from "../../../../../../../assets/participants/attendees.csv";
import excelFile from "../../../../../../../assets/participants/Attendee.xlsx";

import "./bulk-attendee.styles.scss";

const BulkAttendee = ({ toggle }) => {
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  // const userId = obj?.data?.data?.id;
  const token = obj?.token;
  const params = useParams();
  const eventID = params.eventID;

  const key = ["myAttendees", eventID];

  const mutation = useCustomMutation(key);
  const [isAttachmentDocument, setIsAttachmentDocument] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  // Function to handle link clicks

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "POST",
      url = `bulk-attendees/add/${eventID}`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("document", isAttachmentDocument[0]);

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Attendees Successfully Added",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsAttachmentDocument([]);
      toggle();
    }
  }, [mutation?.isSuccess]);

  if (isLoadingGetMyEventDetail) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  //attach document function
  const handleAttachment = (event) => {
    const selectedFiles = event.target.files;
    const fileArray = Array.from(selectedFiles);
    setIsAttachmentDocument(fileArray);

    const previews = fileArray.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  //define file icon
  const getFileIcon = (fileName) => {
    if (fileName.endsWith(".csv")) {
      return "fas fa-file-csv";
    } else if (fileName.endsWith(".xls") || fileName.endsWith(".xlsx")) {
      return "far fa-file-excel";
    }
    // Add more conditions for other file types if needed
    return "far fa-file"; // Default icon
  };
  return (
    <React.Fragment>
      <div className="bulk-attendee__heading">
        <p className="bulk-attendee__heading--header">
          Upload the list of attendees
        </p>
        <p className="bulk-attendee__heading--description">
          You can use the attached template as a guide. Please fill out all
          required fields to avoid issues after submitting attendee information.
          Keep in mind that csv uploads are limited to 5000 rows and 1MB in
          size.
        </p>
        <div className="mb-3">
          <a href={excelFile} download="Attendee.xlsx">
            <Button className="btn bg-primary">Download This Template</Button>
          </a>
        </div>
      </div>

      <form
        noValidate
        data-testid="form"
        // className="single-attendee__body--form"
        onSubmit={handleSubmitForm}
      >
        <div className="mt-0">
          {/* ------------------ Form Fields ---------------------- */}

          <div className="booth__body--form-input-container">
            <p className="input-heading">Upload file* </p>
            <div className="file-previews-doc">
              {isAttachmentDocument?.map((document, index) => {
                console.log("imagePreviews...", imagePreviews);
                return (
                  <div key={index} className="file-preview-doc">
                    <i
                      className={`${getFileIcon(document.name)} file-icon-doc`}
                    ></i>
                    <div className="file-details-doc">
                      <div className="file-name-doc">{document.name}</div>
                      <div className="file-size-doc">
                        {(document.size / 1024).toFixed(2)} KB
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mb-1 drop-zone">
              <label htmlFor="attachment" className="drop-zone-label">
                <i className="fas fa-cloud-upload-alt"></i>
                <div className="drop-zone-text">Attach file(s)</div>
              </label>
              <input
                type="file"
                id="attachment"
                className="form-control drop-zone-input"
                // accept=".pdf, .xls, .xlsx, .doc, .docx,image/*"
                accept=".xlsx,.xls,"
                onChange={handleAttachment}
              />
            </div>
            <div style={{ marginRight: "12px" }}>
              <p color="ambience.6">
                <span className="h6">Recommended format: </span>
                Excel | Up to 5 MB
              </p>
            </div>
          </div>
        </div>

        <div className="next-container" style={{ marginTop: "16px" }}>
          <button className="" type="submit">
            <div className="d-flex justify-content-center align-items-center text-light">
              {!mutation.isLoading ? <span>Submit</span> : null}
              {!mutation.isLoading ? null : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading
                </span>
              )}
            </div>
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};
export default BulkAttendee;
