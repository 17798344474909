import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Live.scss";
import SimpleBar from "simplebar-react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import ChatComponent from "./chat.component";

function Live({ boothID }) {
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userId = obj?.data?.data?._id;
  const userImg = obj?.data?.data?.photo;
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const uniqueUserIds = new Set();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleContactClick = (contact) => {
    setSelectedContact(contact);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if selected contact id is available before making the API call
        if (boothID) {
          const response = await axios.post(
            "https://orca-app-65xn9.ondigitalocean.app/api/v1/messages/booth/getmsg",
            {
              to: boothID,
            }
          );
          setMessages(response);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchData();
  }, [boothID, userId]);
  console.log("messagesssssssssss", messages);

  const filteredMessages = messages.filter((msg) => {
    const userId = msg.from.userId;
    if (!uniqueUserIds.has(userId)) {
      uniqueUserIds.add(userId);
      return true;
    }
    return false;
  });

  const renderedMessages = filteredMessages.map((msg, _idx) => (
    <a
      href="#"
      style={{ color: "#e4e5e6" }}
      key={_idx}
      onClick={() => handleContactClick(msg.from)}
    >
      <div className="tooltiping__content--attendee">
        <img
          className="tooltiping__content--attendee--img"
          src={msg?.from?.photo}
          alt="showbay attendee list attendee"
        />
        <div className="tooltiping__content--attendee--info">
          <p className="tooltiping__content--attendee--info-name">
            {msg?.from?.firstName} {msg?.from?.lastName}
          </p>
          <div className="tooltiping__content--attendee--info-status">
            <div
              style={{
                width: "4px",
                height: "4px",
                backgroundColor: "#2dd173",
                borderRadius: "50%",
              }}
            ></div>
            {msg?.message.slice(0, 10)}
          </div>
        </div>
      </div>
    </a>
  ));

  return (
    <>
      <MDBContainer style={{ backgroundColor: "transparent" }}>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="12" lg="12" xl="12">
            <MDBCard id="chat2" style={{ borderRadius: "15px" }}>
              <SimpleBar
                // suppressScrollX
                style={{ position: "relative", height: "400px" }}
              >
                {selectedContact ? (
                  <ChatComponent
                    boothId={boothID}
                    selectedContact={selectedContact}
                    setSelectedContact={setSelectedContact}
                  />
                ) : (
                  <MDBCardBody>
                    {!messages.length === 0 ? (
                      <>
                        <div className="w-full flex items-center justify-center">
                          <h1>Loading...</h1>
                        </div>
                      </>
                    ) : (
                      <>{renderedMessages}</>
                    )}
                  </MDBCardBody>
                )}
              </SimpleBar>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}

export default Live;
