import React from "react";

function ProductDetails({ details }) {
  console.log("details...", details);
  return (
    <div className="w-100">
      <div className="d-flex justify-content-center">
        <div style={{ width: 200, height: 200, borderRadius: 10 }}>
          <img
            src={details?.productImage}
            className="w-100 h-100"
            // style={{ width: 200, height: 200, borderRadius: 10 }}
            style={{ borderRadius: 10 }}
          />
        </div>
      </div>

      <div className="w-100 d-flex justify-content-start">
        <div className="mx-3">
          <div
            className="d-flex justify-content-center"
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "black",
            }}
          >
            {details?.productName}
          </div>
          <div
            className="w-100 d-flex justify-content-center"
            style={{
              fontSize: 12,
              color: "black",
            }}
          >
            {details?.productDescription}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
