import AvatarOne from "../../../../../../assets/images/users/avatar-1.jpg";
import AvatarTwo from "../../../../../../assets/images/users/avatar-5.jpg";
import AvatarThree from "../../../../../../assets/images/users/avatar-6.jpg";
import AvatarFour from "../../../../../../assets/images/users/avatar-7.jpg";
import AvatarFive from "../../../../../../assets/images/users/avatar-8.jpg";

export const attendees = [
  {
    id: 1,
    firstName: "Macy",
    lastName: "Stevens",
    profileImg: AvatarOne,
    status: "Online",
  },
  {
    id: 2,
    firstName: "Angie",
    lastName: "Brock",
    profileImg: AvatarTwo,
    status: "Online",
  },
  {
    id: 3,
    firstName: "Brian",
    lastName: "Dawn",
    profileImg: AvatarThree,
    status: "Online",
  },
  {
    id: 4,
    firstName: "Mark",
    lastName: "Manson",
    profileImg: AvatarFour,
    status: "Online",
  },
  {
    id: 5,
    firstName: "Dawn",
    lastName: "Roddy",
    profileImg: AvatarFive,
    status: "Online",
  },
];

export const messages = [
  {
    id: 1,
    firstName: "Macy",
    lastName: "Stevens",
    profileImg: AvatarOne,
    msg: "Hey! Love your profile.",
  },
  {
    id: 2,
    firstName: "Angie",
    lastName: "Brock",
    profileImg: AvatarTwo,
    msg: "Meeting on the 21st.",
  },
  {
    id: 3,
    firstName: "Brian",
    lastName: "Dawn",
    profileImg: AvatarThree,
    msg: "You: Sure, sounds great!",
  },
  {
    id: 4,
    firstName: "Mark",
    lastName: "Manson",
    profileImg: AvatarFour,
    msg: "How are you?",
  },
  {
    id: 5,
    firstName: "Dawn",
    lastName: "Roddy",
    profileImg: AvatarFive,
    msg: "The pleasure's all mine!",
  },
];

export const alerts = [
  {
    id: 1,
    title: "Announcement!",
    alert: "Announcement: TheGlory on stage",
  },
  {
    id: 2,
    title: "Reminder...",
    alert: "Join our second s...",
  },
  {
    id: 3,
    title: "Been here for a while?",
    alert: "Announcement: For those parting",
  },
  {
    id: 4,
    title: "Group announcements",
    alert: "Group talks open at",
  },
  {
    id: 5,
    title: "Reminder!",
    alert: "Be sure not to f",
  },
];
