import React, { useState, useEffect } from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import { useNavigate } from "react-router-dom";
import { BsCalendar4 } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import bgEvent from "../../../../assets/images/golfie.jpg";
import "../css/Social.scss";
import { Spinner } from "reactstrap";
import { IoArrowForwardOutline } from "react-icons/io5";

// import Schedule from "../Conference/Schedule";

function SocialLanding({ token, eventID, eventDetails }) {
  const Navigate = useNavigate();
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userID = obj?.data?.data?._id;
  const userEmail = obj?.data?.data?.email;
  const [slideInOpen, setSlideInOpen] = useState(false);
  const [groupedSessions, setGroupedSessions] = useState({});
  const toggleSlideIn = () => {
    setSlideInOpen(!slideInOpen);
  };
  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isFetching: isFetchingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  const {
    data: getMyHosts,
    error: errorGetMyHosts,
    isFetching: isFetchingGetMyHosts,
  } = useCustomQuery(
    ["myHosts", eventID],
    `${process.env.REACT_APP_API}users/event-host/${eventID}`,
    token
  );

  // console.log("eventDetails... ", eventDetails);

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/${eventID}`,
    token
  );

  const {
    data: getEventBooths,
    error: errorGetEventBooths,
    isLoading: isLoadingGetEventBooths,
  } = useCustomQuery(
    ["eventsBooths", eventID],
    `${process.env.REACT_APP_API}booths/event/${eventID}`,
    token
  );

  // group sessions by date

  const groupSessionsByDate = (getMySessions) => {
    const groups = {};

    getMySessions.forEach((session) => {
      const sessionDate = new Date(session.sessionDate);
      const dateKey = sessionDate.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'

      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }

      groups[dateKey].push(session);
    });

    setGroupedSessions(groups);
  };

  useEffect(() => {
    if (getMySessions) {
      groupSessionsByDate(getMySessions);
    }
  }, [getMySessions]);

  const {
    data: getMySponsors,
    error: errorGetMySponsors,
    isLoading: isLoadingGetMySponsors,
  } = useCustomQuery(
    ["mySponsors", eventID],
    `${process.env.REACT_APP_API}sponsors/event/${eventID}`,
    token
  );

  const {
    data: getMySponsorsInstitutional,
    error: errorGetMySponsorsInstitutional,
    isLoading: isLoadingGetSponsorsInstitutional,
  } = useCustomQuery(
    ["mySponsorsInstitutional", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=institutional`,
    token
  );

  const {
    data: getMySponsorsPlatinum,
    error: errorGetMySponsorsPlatinum,
    isLoading: isLoadingGetMySponsorsPlatinum,
  } = useCustomQuery(
    ["mySponsorsPlatinum", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=platinum`,
    token
  );

  const {
    data: getMySponsorsGold,
    error: errorGetMySponsorsGold,
    isLoading: isLoadingGetMySponsorsGold,
  } = useCustomQuery(
    ["mySponsorsGold", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=gold`,
    token
  );

  const {
    data: getMySponsorsSilver,
    error: errorGetMySponsorsSilver,
    isLoading: isLoadingGetSponsorsSilver,
  } = useCustomQuery(
    ["mySponsorsSilver", eventID],
    `${process.env.REACT_APP_API}sponsors/category/${eventID}/?tier=silver`,
    token
  );

  const isInstitutionalSponsors =
    getMySponsorsInstitutional && getMySponsorsInstitutional?.length > 0;
  const isPlatinumSponsors =
    getMySponsorsPlatinum && getMySponsorsPlatinum?.length > 0;
  const isGoldSponsors = getMySponsorsGold && getMySponsorsGold?.length > 0;
  const isSilverSponsors =
    getMySponsorsSilver && getMySponsorsSilver?.length > 0;

  // console.log("getMySponsors...", getMySponsors);
  // console.log("eventID..", eventID);
  // console.log("getMySponsors..", getMySponsors);

  if (isFetchingGetMyEventDetail) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  if (isLoadingGetMySessions) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  const handleBuyTicket = () => {
    Navigate(
      `/${getMyEventDetail?.eventName.replace(/ /g, "-")}/Ticket/${eventID}`
    );
  };
  //Enter As
  const buyTicketEventBtn = () => {
    return (
      <div className="">
        <button className="btn  btn--social--enter" onClick={handleBuyTicket}>
          Buy Ticket
        </button>
      </div>
    );
  };

  //enter Event
  const handleJoinAsAttendee = () => {
    Navigate(
      `/${getMyEventDetail?.eventName.replace(
        / /g,
        "-"
      )}/event/reception/${eventID}`
    );
  };
  const enterEnterEventBtn = () => {
    return (
      <div className="">
        <button
          className="btn  btn--social--enter"
          onClick={handleJoinAsAttendee}
        >
          Enter Event
        </button>
      </div>
    );
  };

  const isSponsors = getMySponsors && getMySponsors?.length > 0;

  function formatStartDate(dateString) {
    const date = new Date(dateString);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let day = date.getDate();
    let month = months[date.getMonth()];

    return `${day} ${month}`;
  }

  function formatEndDate(dateString) {
    const date = new Date(dateString);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let day = date.getDate();
    let month = months[date.getMonth()];
    let year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  // Usage
  const startDate = formatStartDate(getMyEventDetail?.startDate);
  const endtDate = formatEndDate(getMyEventDetail?.endDate);

  // const handleEnterEvent = () => {
  //   Navigate(
  //     `/${eventDetails?.eventName.replace(/ /g, "-")}/event/reception/${
  //       eventDetails?._id
  //     }`
  //   );
  // };

  return (
    <div className="page-content">
      <div className="main">
        <img src={getMyEventDetail?.bannerImage} alt="event" />
        <div className="wrapper">
          <div className="wrapper-left">
            <div className="wrapper-left-Title">
              <div className="">
                <p>{getMyEventDetail?.eventName}</p>
              </div>

              <div className="d-flex align-items-center">
                <div className="wrapper-left-btn">Event</div>
                <div className="wrapper-left-subTitle mx-4">
                  <hr className="wrapper-left-line"></hr>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper-right">
            <img src={getMyEventDetail?.bannerImage} alt="event" />
          </div>
        </div>
      </div>
      <div className="w-100">
        <div className="w-100 d-flex justify-content-between social--event--Location">
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <BsCalendar4 size={40} color="black" />
              </div>

              <div className="mx-3 d-flex align-items-center">
                <div className="">
                  <div>
                    {startDate} - {endtDate}
                  </div>
                  <div>
                    {getMyEventDetail?.startTime} - {getMyEventDetail?.endTime}
                  </div>
                </div>
              </div>
            </div>
            <div className="date--separator"></div>
            <div className="">
              <GrLocation size={40} color="black" />
              {getMyEventDetail?.eventFormat === "Virtual" ? (
                <>{getMyEventDetail?.eventFormat}</>
              ) : null}
            </div>
          </div>
          {/* <div className="">
            <button className="btn  btn--social--buy">Buy Ticket</button>
          </div> */}

          {getMyEventDetail?.attendees?.includes(userEmail) ? (
            <>{enterEnterEventBtn()}</>
          ) : (
            <>{buyTicketEventBtn()}</>
          )}
        </div>
      </div>
      <div className="w-100">
        <div className="w-100 d-flex social--event--Details">
          <div className="w-50">
            <div>
              <p className="social--section--title">DETAILS</p>
              <hr className="underline---social"></hr>
            </div>
          </div>
          <div className="w-50 text-justify">
            <p>{getMyEventDetail?.eventDescription}</p>
          </div>
        </div>
      </div>
      <div className="w-100">
        <div className="w-100 d-flex social--event--Information">
          <div className="w-50">
            <div>
              <p className="social--section--title">Sponsor(s)</p>
              <hr className="underline---social"></hr>
            </div>
          </div>
          <div className="w-50 text-justify">
            {isSponsors ? (
              <div id="sponsors" className="event-sponsors text-dark">
                {/* <h3>Sponsors</h3> */}
                {isInstitutionalSponsors ? (
                  <>
                    <h5 className="">INSTITUTIONAL</h5>
                    <div className="">
                      {getMySponsorsInstitutional?.map((sponsor, i) => {
                        return (
                          <div className="event-sponsors__item" key={i}>
                            <img
                              src={sponsor?.sponsorLogo}
                              alt="Showbay sponsor banner"
                              style={{
                                width: "70px",
                                height: "70px",
                                marginRight: 4,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : null}
                {isPlatinumSponsors ? (
                  <>
                    <h5 className="mt-5">PLATINUM</h5>
                    <div className="d-flex">
                      {getMySponsorsPlatinum?.map((sponsor, i) => {
                        return (
                          <div className="event-sponsors__item" key={i}>
                            <img
                              src={sponsor?.sponsorLogo}
                              alt="Showbay sponsor banner"
                              style={{
                                width: "70px",
                                height: "70px",
                                marginRight: 4,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : null}
                {isGoldSponsors ? (
                  <>
                    <h5 className="mt-5">GOLD</h5>
                    <div className="d-flex">
                      {getMySponsorsGold?.map((sponsor, i) => {
                        return (
                          <div className="event-sponsors__item" key={i}>
                            <img
                              src={sponsor?.sponsorLogo}
                              alt="Showbay sponsor banner"
                              style={{
                                width: "70px",
                                height: "70px",
                                marginRight: 4,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : null}
                {isSilverSponsors ? (
                  <>
                    <h5 className="mt-5">SILVER</h5>
                    <div className="d-flex">
                      {getMySponsorsSilver?.map((sponsor, i) => {
                        return (
                          <div className="event-sponsors__item" key={i}>
                            <img
                              src={sponsor?.sponsorLogo}
                              alt="Showbay sponsor banner"
                              style={{
                                width: "70px",
                                height: "70px",
                                marginRight: 4,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
            {/* <Schedule mySchedule={getMySessions} /> */}
            {/* <p>{getMyEventDetail?.eventDescription}</p> */}
          </div>
        </div>
      </div>
      {getMyHosts && (
        <div className="w-100">
          <div className="w-100 d-flex social--event--Organizer">
            <div className="w-50">
              <div>
                <p className="social--section--title">Organizer</p>
                <hr className="underline---social"></hr>
              </div>
            </div>
            <div className="w-50 text-justify">
              <div id="hosts" className="hosts text-dark">
                <h3>Hosts</h3>
                <div className="host">
                  <img
                    src={getMyHosts?.photo}
                    alt="host-pic"
                    style={{
                      width: "120px",
                      height: "120px",
                      marginRight: 4,
                    }}
                  />
                  <p className="host--profile-img">
                    <p className="host--name">
                      {getMyHosts?.organizationName
                        ? `${getMyHosts?.organizationName}`
                        : `${getMyHosts?.firstName} ${getMyHosts?.lastName}`}
                    </p>
                  </p>
                  {/* <p className="host--name">
                    {`${getMyEventDetail?.eventOrganizerId?.firstName} ${getMyEventDetail?.eventOrganizerId?.lastName}`}
                  </p>
                   <p className="host--profile-slider" onClick={toggleSlideIn}>
                  View Profile {"  "}
                  <IoArrowForwardOutline size={16} />
                  <CustomModal
                  isOpen={slideInOpen}
                  toggle={toggleSlideIn}
                  title="Shadow Sketch"
                  body={<p className="host--profile-img">S</p>}
                />
                </p>  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {eventDetails?.eventFormat === "Virtual" ? null : (
        <div className="w-100 social--event--map">
          <div>
            <iframe
              width="100%"
              height="600"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.maps.ie/population/">
                Population Estimator map
              </a>
            </iframe>
          </div>
        </div>
      )}
    </div>
  );
}

export default SocialLanding;
