import react from "react";
import { Button } from "reactstrap";
import { MdMarkEmailRead } from "react-icons/md";

const SuccessMessage = ({ handleMessage }) => {
  return (
    <div className="verify---container">
      <div className="verify---card p-4">
        <div>
          <p className="verify---title h1">Success</p>
          <div className="d-flex justify-content-center">
            <MdMarkEmailRead size={70} color="#0ac074" />
          </div>
          <div className="p-4">
            <p
              style={{ fontSize: 14, fontWeight: "500", textAlign: "justify" }}
            >
              We are thrilled to inform you that your email verification process
              is now complete! By verifying your email, you've established a
              strong foundation for a secure and personalized experience within
              our community.
            </p>
          </div>

          <div className="d-flex justify-content-center verify---btn">
            <Button onClick={handleMessage}>Ok</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;
