import React, { useState, useRef, useEffect } from "react";
import { Spinner, Button } from "reactstrap";

import { BiEdit } from "react-icons/bi";
import "bootstrap/dist/css/bootstrap.min.css";

import useCustomMutation from "../../../../../../../Hooks/useCustomMutation";
import ImgSession from "../../../../../../../assets/images/banners/sessions/bannerSession.png";
import "../../../css/Summary.scss";
import "./individual.scss";

const Individual = ({ eventID, toggle }) => {
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const token = obj?.token;
  const key = ["my-Hosts", eventID];
  const mutation = useCustomMutation(key);
  const [inputs, setInputs] = useState({});

  const [logoServer, setLogoServer] = useState();
  const [profile, setProfile] = useState();

  // console.log("getMyEventDetail...", getMyEventDetail);

  // Function to handle link clicks

  const refFileUpload = useRef("");

  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLogoServer(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "POST",
      url = `auth/host-org/${eventID}`,
      isJSON = true;
    const formdata = new FormData();
    formdata.append("photo", logoServer);
    formdata.append("firstName", inputs.firstName);
    formdata.append("lastName", inputs.lastName);
    formdata.append("email", inputs.email);
    // formdata.append("description", inputs.jobTitle);
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Host Successfully Created",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  // if (isLoadingGetMySessions) {
  //   <div className="mt-5 d-flex justify-content-center align-items-center">
  //     <Spinner animation="border" role="status">
  //       <span className="visually-hidden">Loading...</span>
  //     </Spinner>
  //   </div>;
  // }
  useEffect(() => {
    if (mutation.isSuccess) {
      toggle(false);
    }
  }, [mutation.isSuccess]);

  return (
    <React.Fragment>
      <div className="booth__body">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="booth__body--form"
          onSubmit={handleSubmitForm}
        >
          <div className="mt-0">
            <div>Upload Host Picture</div>
            <div className="d-flex justify-content-center">
              <div className="section---banner---container mt-2">
                <img
                  src={!logoServer ? ImgSession : profile}
                  alt="session Image"
                  className="w-100 h-100 section---banner---container---img"
                />
                <Button
                  size="md"
                  variant="separator-light"
                  className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
                  onClick={onThumbChangeClick}
                >
                  <BiEdit
                    size={40}
                    style={{
                      color: "black",
                      filter: "drop-shadow(0px 0px 10px white)",
                    }}
                  />
                </Button>
                <input
                  type="file"
                  ref={refFileUpload}
                  className="file-upload d-none"
                  accept="image/*"
                  onChange={changeThumb}
                />
              </div>
            </div>
            <div
              className="sponsor-form-body__sponsor-logo"
              style={{ marginRight: "12px", marginBottom: "-16px" }}
            >
              <p
                color="ambience.6"
                font-size="1"
                className="sponsor-form-body--input-description"
              >
                Recommended format: 400x200px | JPG, SVG, PNG | Up to 1 MB
              </p>
            </div>
            {/* ------------------ Form Fields ---------------------- */}

            <div className="booth__body--form-input-container">
              <label
                htmlFor="firstName"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">First Name*</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="First Name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="lastName"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Last Name</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Last Name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>
            <div className="booth__body--form-input-container">
              <label
                htmlFor="email"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Email*</p>
                {/* <p className="input-description">
                  Where attendee will receive further event communication
                </p> */}
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="email"
                  name="email"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter email "
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>

            {/* <div className="booth__body--form-input-container">
              <label
                htmlFor="description"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Host Biography</p>
                <p className="input-description">
                  Bio of the host and their achievements.
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="description"
                  name="description"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder=""
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                  style={{ minHeight: 100 }}
                />
              </div>
            </div> */}
          </div>
          <div className="next-container" style={{}}>
            <button className="btn btn-primary" type="submit">
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? <span>Submit</span> : null}
                {!mutation.isLoading ? null : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                )}
              </div>
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default Individual;
