import React from "react";

import logosm from "../../assets/images/logo-sm.png";
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { Link } from "react-router-dom";

import LanguageDropdown from "./TopbarDropdown/LanguageDropdown";

const CustomTopBar = () => {
  return (
    <React.Fragment>
      <header
        id="page-topbar"
        className="customize---topBar"
        style={{ userSelect: "none" }}
      >
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box text-center">
              <Link to="/register-as" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logosm} alt="logo-sm-dark" height="27" />
                </span>
                <span className="logo-lg">
                  <img src={logodark} alt="logo-dark" height="30" />
                </span>
              </Link>

              <Link to="/register-as" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logosm} alt="logo-sm-light" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logolight} alt="logo-light" height="30" />
                </span>
              </Link>
            </div>
          </div>

          <div className="d-flex">
            <LanguageDropdown />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default CustomTopBar;
