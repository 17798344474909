import React, { useState } from "react";
import { Card } from "reactstrap";
import SponsorsSlideBar from "../../../../../../../components/Common/SlideInComponent/SlideInComponent";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AddBoothSponsor from "../../../AddBoothSponsor";

function Sponsors({ eventID, token, boothID, sponsors }) {
  const [slideInOpenSponsor, setSlideInOpenSponsor] = useState(false);

  const toggleSlideInSponsors = () => {
    setSlideInOpenSponsor(!slideInOpenSponsor);
  };

  const getLastTwoSponsors = (mySponsors) => {
    if (mySponsors?.length >= 2) {
      return mySponsors?.slice(-2);
    }

    if (mySponsors?.length === 1) {
      return [mySponsors[0]];
    }
    return [];
  };
  let lastTwoSponsors = [];
  if (sponsors) {
    lastTwoSponsors = getLastTwoSponsors(sponsors);
  }

  return (
    <div className="mt-1">
      <Card>
        <div className="summary-details__content">
          <div className="summary-details__content--heading">
            <div className="w-100">
              <div className="d-flex justify-content-between">
                <h4>Sponsors</h4>
              </div>

              {sponsors && boothID && (
                <div className="d-flex">
                  <div
                    className="btn-add-speaker d-flex justify-content-center align-items-center"
                    style={{
                      width: 100,
                      borderStyle: "dashed",
                      borderRadius: 5,
                      borderWidth: 1,
                      padding: 10,
                      marginRight: 3,
                    }}
                  >
                    <AiOutlinePlusCircle
                      size={24}
                      type="button"
                      onClick={toggleSlideInSponsors}
                    />
                  </div>
                  {lastTwoSponsors?.map((sponsor, i) => {
                    return (
                      <div
                        className="btn-add-speaker d-flex"
                        style={{
                          width: 100,
                          borderRadius: 5,
                          marginRight: 3,
                        }}
                        key={i}
                      >
                        <img
                          src={sponsor?.sponsorLogo}
                          alt="booth-sponsor"
                          className="w-100 h-100"
                          style={{
                            borderRadius: 5,
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
      {slideInOpenSponsor ? (
        <SponsorsSlideBar
          isOpen={true}
          title="Add Booth Sponsors"
          toggle={toggleSlideInSponsors}
          body={
            <AddBoothSponsor
              token={token}
              boothID={boothID}
              eventID={eventID}
              setSlideInOpenSponsor={setSlideInOpenSponsor}
            />
          }
        />
      ) : null}
    </div>
  );
}

export default Sponsors;
