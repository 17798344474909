import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Spinner } from "reactstrap";

// import { useStore1Selector } from "../../../..";
// import { userdetails } from "../../../../Redux/Slices/userSlice";

import { Link, useParams } from "react-router-dom";

const Profile = () => {
  const { eventID } = useParams();
  const [ViewShedule, setViewShedule] = useState();
  // const user = useStore1Selector(userdetails);
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://app.showbay24.com/api/session/ListSessionsByEventID/${eventID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("all details", result);
        setViewShedule(result);
      })
      .catch((error) => console.log("error", error));
  }, []);
  if (!ViewShedule) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div>
        <Container>
          <div className="">
            <h3>Schedule</h3>
          </div>
          {ViewShedule.map((item, index) => {
            return (
              <div className="d-flex mt-5 justify-content-start" key={index}>
                <div className="">
                  <h5>{item.StartTime.substring(0, 5)}</h5>
                  <h6 className="d-flex justify-content-end">
                    {item.EndTime.substring(0, 5)}
                  </h6>
                </div>
                <div className="bg-white shadow-lg p-5 card-brd mx-4 schedule_card">
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <h5>{item.SessionTitle}</h5>
                    </div>
                    <div className="">{item.StartDate}</div>
                  </div>
                  <div className="">
                    <p>Speaker</p>
                    <div className="d-flex justify-content-start">
                      <div className="d-flex justify-content-start">
                        <div className="d-flex justify-content-start schedule-pic-speaker">
                          <img
                            src={"https://picsum.photos/130/130?image=856"}
                            width="100%"
                            height="100%"
                            className="d-flex justify-content-start rounded-circle"
                          />
                        </div>
                        <div className="d-flex justify-content-start align-items-center px-3">
                          <h5 className="text-dark">Steven Paul</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="mt-5">
            <h3>Host</h3>
            <div className="d-flex">
              <div className="schedule_host_picture">
                <div className="d-flex justify-content-start schedule-pic-speaker">
                  <img
                    // src={user.ProfilePicture}
                    width="100%"
                    height="100%"
                    className="d-flex justify-content-start rounded-circle ImageFit"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <h6 className="text-dark">{`jed kazadi`}</h6>
                  {/* <h6 className="text-dark">{`${user.Name} ${user.Surname}`}</h6> */}
                </div>
              </div>
              {/* <div className="schedule_host_picture">
                <div className="d-flex justify-content-start schedule-pic-speaker">
                  <img
                    src={Speaker_1}
                    width="100%"
                    height="100%"
                    className="d-flex justify-content-start rounded-circle ImageFit"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <h6 className="text-dark">william</h6>
                </div>
              </div> */}
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Profile;
