import React from "react";
import useCustomQuery from "../../../../../../Hooks/useCustomQuery";
import { IoDiamondSharp } from "react-icons/io5";
import { RiVipCrownFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import "./attendeeCard.scss";

function TicketsSales({ token, eventID }) {
  const {
    data: getMyEventTickets,
    error: errorgetMyEventTickets,
    isLoading: isLoadinggetMyEventTickets,
  } = useCustomQuery(
    ["myEventTicker", eventID],
    `${process.env.REACT_APP_API}tickets/type/65a137332f67c5a469161867/?type=standard`,
    token
  );
  console.log("ticccccc", getMyEventTickets);

  return (
    <div className="Tickets-analystic-card">
      <div>
        <div className="text-center pt-3">
          <p style={{ color: "black", fontSize: 25, fontWeight: "900" }}>
            Attendees Per Categories
          </p>
        </div>
        <div className="d-flex Tickets-analystic-card-content">
          <div className="attendee-analistics-icons-container ">
            <IoDiamondSharp size={30} color="white" />
          </div>
          <div className="d-flex align-items-center">
            <div>
              <div style={{ color: "black", fontSize: 16, fontWeight: "900" }}>
                VVIP
              </div>
              <div
                style={{ color: "#1a87a3", fontSize: 28, fontWeight: "900" }}
              >
                800
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex mt-2 mb-2 Tickets-analystic-card-content">
          <div className="attendee-analistics-icons-container">
            <RiVipCrownFill size={30} color="white" />
          </div>
          <div className="d-flex align-items-center">
            <div>
              <div style={{ color: "black", fontSize: 18, fontWeight: "900" }}>
                VIP
              </div>
              <div
                style={{ color: "#1a87a3", fontSize: 20, fontWeight: "900" }}
              >
                800
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex Tickets-analystic-card-content">
          <div className="attendee-analistics-icons-container">
            <FaUser size={30} color="white" />
          </div>

          <div className="d-flex align-items-center">
            <div>
              <div style={{ color: "black", fontSize: 18, fontWeight: "900" }}>
                STANDARDS
              </div>
              <div
                style={{ color: "#1a87a3", fontSize: 20, fontWeight: "900" }}
              >
                1300
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketsSales;
