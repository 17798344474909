import React from "react";
import "./attendeeCard.scss";
import Rating from "react-rating";
import { FaStar } from "react-icons/fa";

function BoothssRatings() {
  return (
    <div className="registered-user-card">
      <div className="d-flex">
        <div className="attendee-analistics-icons-container mt-1">
          <FaStar size={30} color="white" />
        </div>
        <div className="d-flex align-items-center">
          <div>
            <div style={{ color: "black", fontSize: 20, fontWeight: "900" }}>
              Booths Ratings
            </div>
            <div style={{ color: "#1a87a3", fontSize: 18, fontWeight: "900" }}>
              <div className="rating-star">
                <Rating
                  emptySymbol="mdi mdi-star-outline text-warning fa-1x"
                  fullSymbol="mdi mdi-star text-warning fa-1x"
                  fractions={2}
                  initialRating={3}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoothssRatings;
