import React from "react";
import useCustomQuery from "../../../../Hooks/useCustomQuery";

function Products({ eventID, token, boothID }) {
  const {
    data: MyBoothProducts,
    error: errorGetMyBoothProducts,
    isLoading: isLoadingGetMyBoothProducts,
  } = useCustomQuery(
    ["exhibtitor-ProductsBooth", eventID],
    `${process.env.REACT_APP_API}products/booth/${boothID}`,
    token
  );
  return (
    <div className="w-100">
      <div className="w-100">
        {MyBoothProducts?.map((product, i) => {
          return (
            <img
              src={product?.productImage}
              alt="product"
              key={i}
              style={{ width: 120, height: 120, borderRadius: 10, margin: 8 }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Products;
