import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "./SlideInComponent.scss"; // Create a CSS file for styling

const CustomModal = ({ isOpen, toggle, title, body }) => {
  const handleClickInside = (e) => {
    e.stopPropagation(); // Prevent the click event from propagating to the overlay
  };

  return (
    <div className={`slide-in-modal ${isOpen ? "show" : ""}`}>
      <div className="slide-in-overlay" onClick={toggle}></div>
      <div className="slide-in-content" onClick={handleClickInside}>
        <div className="slide-in-header">
          <div>
            <h3 className="modal-title mt-0">{title}</h3>
          </div>
          <button
            className="close"
            onClick={toggle}
            aria-label="Close"
            style={{ paddingTop: "48px", paddingRight: "56px", fontSize: 14 }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="slide-in-body">{body}</div>
      </div>
    </div>
  );
};

export default CustomModal;
