import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { Spinner } from "reactstrap";

import { makeStyles } from "@material-ui/core/styles";

import usePost from "../../../../Hooks/usePost";

import OrganizationForm from "./components/OrganizationForm";
import ContactDetailsForm from "./components/ContactDetailsForm";
// import CommunityForm from "./components/CommunityForm";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Organization",
    "Contact Details",
    // "Community",
    // "Payment",
  ];
}

function getStepContent(step, updateFormInputs, formInputs) {
  switch (step) {
    case 0:
      return (
        <>
          <OrganizationForm
            updateFormInputs={updateFormInputs}
            formInputs={formInputs}
          />
        </>
      );
    case 1:
      return (
        <>
          <ContactDetailsForm
            updateFormInputs={updateFormInputs}
            formInputs={formInputs}
          />
        </>
      );
    // case 2:
    //   return (
    //     <>
    //       <CommunityForm
    //         updateFormInputs={updateFormInputs}
    //         formInputs={formInputs}
    //       />
    //     </>
    //   );
    default:
      return "unknown step";
  }
}

const LinaerStepper = ({ setIsFormSubmitted }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();
  const [formInputs, setFormInputs] = useState({});
  const { execute, pending, status } = usePost();

  // status ? <RenderThanks /> : null

  const isStepOptional = (step) => {
    // return step === 1 || step === 2;
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
  };

  const handleFinish = (e, values) => {
    e.preventDefault();
    const Method = "POST",
      endPoint = "leads",
      isJSON = null,
      token = null;

    var raw = JSON.stringify({
      organizationName: formInputs.organizationName,
      organizationType: formInputs.organizationType,
      organizationCountry: formInputs.organizationCountry,
      eventsPerYear: formInputs.eventsPerYear,
      attendeesPerYear: formInputs.attendeesPerEvent,
      organizationEmail: formInputs.organizationEmail,
      organizationPhoneNumber: formInputs.organizationPhoneNumber,
      communityName: formInputs.communityName,
      language: formInputs.defaultLanguage,
      terms: true,
    });

    execute(endPoint, raw, Method, "Successful register", token, isJSON);
    console.log(formInputs);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };

  const updateFormInputs = (values) => {
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      ...values,
    }));
  };
  useEffect(() => {
    if (status) {
      setIsFormSubmitted(true);
    }
  }, [status]);

  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
          Thank You
        </Typography>
      ) : (
        <>
          <form>
            {getStepContent(activeStep, updateFormInputs, formInputs)}
            <input type="checkbox" id="myCheckbox" className="mt-2"></input>
            <label htmlFor="myCheckbox">
              {" "}
              <span className="required">*</span>
              {/* I have read and consent to the
              Organizer's terms and conditions */}
              <a
                href="https://showbay24.com/terms-of-use/"
                target="_blank"
                rel="noopener noreferrer"
              >
                I have read and consent to the Organizer's terms and conditions
              </a>
            </label>
          </form>
          <Button
            className={classes.button}
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleFinish}
            >
              {!pending ? (
                "Finish"
              ) : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading
                </span>
              )}
            </Button>
          ) : (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              Next
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default LinaerStepper;
