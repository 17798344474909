import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Spinner, Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useQueryClient } from "@tanstack/react-query";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import ImgSession from "../../../../assets/images/banners/sessions/bannerSession.png";
import { BiEdit } from "react-icons/bi";

import "./css/Summary.scss";
import "./css/AddSponsor.scss";

const EditCompanyProfile = ({
  token,
  eventID,
  companyDetails,
  setSlideInOpenCompany,
}) => {
  const queryClient = useQueryClient();
  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  // const userEmail = obj?.data?.data?.email;
  const key = ["myBooth-Company", eventID];
  const mutation = useCustomMutation(key);
  const [isPicture, setIsPicture] = useState(false);
  const [profile, setProfile] = useState(null);
  const [logoServer, setLogoServer] = useState();
  const [companyName, setCompanyName] = useState(null);
  const [companyDescription, setCompanyDescription] = useState(null);
  const [companyAddress, setCompanyAddress] = useState(null);
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState(null);
  const [companyFax, setCompanyFax] = useState(null);
  const [companyWebsite, setCompanyWebsite] = useState(null);
  const [companyfacebook, setCompanyfacebook] = useState(null);
  const [companyinstagram, setCompanyinstagram] = useState(null);
  const [companyLinkdIn, setCompanyLinkdIn] = useState(null);

  const refFileUpload = useRef("");

  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLogoServer(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };
      setIsPicture(false);
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   const value = event.target.value;
  //   setInputs((values) => ({ ...values, [name]: value }));
  // };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "PATCH",
      url = `company-profile/${companyDetails?._id}`,
      isJSON = true;
    const formdata = new FormData();
    if (logoServer) {
      console.log("logoServer....", logoServer);
      formdata.append("companyLogo", logoServer);
    }
    if (companyName) {
      formdata.append("companyName", companyName);
    }
    if (companyDescription) {
      formdata.append("companyDescription", companyDescription);
    }
    if (companyAddress) {
      formdata.append("companyAddress", companyAddress);
    }
    if (companyPhoneNumber) {
      formdata.append("companyPhone", companyPhoneNumber);
    }
    if (companyFax) {
      formdata.append("companyFax", companyFax);
    }
    if (companyWebsite) {
      formdata.append("companyWebsite", companyWebsite);
    }
    if (companyfacebook) {
      formdata.append("companyFacebook", companyfacebook);
    }
    if (companyinstagram) {
      formdata.append("companyInstagram", companyinstagram);
    }

    //     if () {

    // }
    // formdata.append("companylinkedIn", inputs.companylinkedIn);

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Company Profile updated successfully",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      queryClient.invalidateQueries(["exhibtitor-CompanyBooth", eventID]);
      setSlideInOpenCompany(false);
    }
    if (companyDetails) {
      if (companyDetails?.companyLogo) {
        setLogoServer(companyDetails?.companyLogo);
        setIsPicture(true);
      }
      setCompanyName(companyDetails?.companyName);
      setCompanyDescription(companyDetails?.companyDescription);
      setCompanyAddress(companyDetails?.companyAddress);
      setCompanyPhoneNumber(companyDetails?.companyPhone);
      setCompanyFax(companyDetails?.companyFax);
      setCompanyWebsite(companyDetails?.companyWebsite);
      setCompanyfacebook(companyDetails?.companyFacebook);
      setCompanyinstagram(companyDetails?.companyInstagram);
    }
  }, [mutation.isSuccess, companyDetails?.id]);

  return (
    <React.Fragment>
      <div className="sponsor-form-container">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="sponsor-form-body"
          onSubmit={handleSubmitForm}
        >
          <div className="sponsor-form-body__sponsor-logo">
            <div className="d-flex justify-content-center">
              <div className="section---banner---container mt-2">
                <img
                  src={isPicture ? logoServer : profile}
                  alt="session Image"
                  className="w-100 h-100 section---banner---container---img"
                />
                <Button
                  size="md"
                  variant="separator-light"
                  className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
                  onClick={onThumbChangeClick}
                >
                  <BiEdit
                    size={40}
                    style={{
                      color: "black",
                      filter: "drop-shadow(0px 0px 10px white)",
                    }}
                  />
                </Button>
                <input
                  type="file"
                  ref={refFileUpload}
                  className="file-upload d-none"
                  accept="image/*"
                  onChange={changeThumb}
                />
              </div>
            </div>
            <div
              className="sponsor-form-body__sponsor-logo"
              style={{ marginRight: "12px", marginBottom: "-55px" }}
            >
              <p
                color="ambience.6"
                font-size="1"
                className="sponsor-form-body--input-description"
              >
                <div className="text-dark"></div>
                Recommended company logo size and format: 600x400px | JPG, PNG |
                Up to 5 MB
              </p>
            </div>
          </div>
          <Row>
            <Col md={12}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyName">
                  <p className="sponsor-form-body--input-heading">
                    Company Name*
                  </p>
                </label>
                <input
                  type="text"
                  id="companyName"
                  value={companyName}
                  placeholder=""
                  className="sponsor-form-body__input--field"
                  name="companyName"
                  aria-label="companyName"
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyDescription">
                  <p className="sponsor-form-body--input-heading">
                    Company Description
                  </p>
                </label>
                <input
                  type="text"
                  id="companyDescription"
                  value={companyDescription}
                  placeholder=""
                  className="sponsor-form-body__input--field"
                  name="companyDescription"
                  aria-label="companyDescription"
                  onChange={(e) => setCompanyDescription(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyAddress">
                  <p className="sponsor-form-body--input-heading">
                    Company Address
                  </p>
                </label>
                <input
                  type="text"
                  id="companyAddress"
                  value={companyAddress}
                  placeholder=""
                  className="sponsor-form-body__input--field"
                  name="companyAddress"
                  aria-label="companyDescription"
                  onChange={(e) => setCompanyAddress(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyPhoneNumber">
                  <p className="sponsor-form-body--input-heading">
                    Company Phone number
                  </p>
                </label>
                <input
                  type="text"
                  id="companyPhoneNumber"
                  value={companyPhoneNumber}
                  placeholder=""
                  className="sponsor-form-body__input--field"
                  name="companyPhoneNumber"
                  aria-label="companyPhoneNumber"
                  onChange={(e) => setCompanyPhoneNumber(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyFax">
                  <p className="sponsor-form-body--input-heading">
                    Company Fax number
                  </p>
                </label>
                <input
                  type="text"
                  id="companyFax"
                  value={companyFax}
                  placeholder=""
                  className="sponsor-form-body__input--field"
                  name="companyFax"
                  aria-label="companyDescription"
                  onChange={(e) => setCompanyFax(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyWebsite">
                  <p className="sponsor-form-body--input-heading">
                    Company Website
                  </p>
                </label>
                <input
                  type="text"
                  id="companyWebsite"
                  value={companyWebsite}
                  placeholder="https://www.yourCompany.com/"
                  className="sponsor-form-body__input--field"
                  name="companyWebsite"
                  aria-label="companyDescription"
                  onChange={(e) => setCompanyWebsite(e.target.value)}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyfacebook">
                  <p className="sponsor-form-body--input-heading">
                    Company facebook Link
                  </p>
                </label>
                <input
                  type="text"
                  id="companyfacebook"
                  value={companyfacebook}
                  placeholder="https://www.fascebook.com/"
                  className="sponsor-form-body__input--field"
                  name="companyfacebook"
                  aria-label="companyDescription"
                  onChange={(e) => setCompanyfacebook(e.target.value)}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyinstagram">
                  <p className="sponsor-form-body--input-heading">
                    Company Instagram Link
                  </p>
                </label>
                <input
                  type="text"
                  id="companyinstagram"
                  value={companyinstagram}
                  placeholder="https://www.instragram.com/"
                  className="sponsor-form-body__input--field"
                  name="companyinstagram"
                  aria-label="companyinstagram"
                  onChange={(e) => setCompanyinstagram(e.target.value)}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companylinkedIn">
                  <p className="sponsor-form-body--input-heading">
                    Company LinkdIn Link
                  </p>
                </label>
                <input
                  type="text"
                  id="companylinkedIn"
                  value={companyLinkdIn}
                  placeholder="https://www.linkedIn.com/"
                  className="sponsor-form-body__input--field"
                  name="companylinkedIn"
                  aria-label="companylinkedIn"
                  onChange={(e) => setCompanyLinkdIn(e.target.value)}
                />
              </div>
            </Col>
          </Row>

          <button className="btn-add-sponsor" type="submit">
            <div className="d-flex justify-content-center align-items-center">
              {!mutation.isLoading ? <span>Update Company</span> : null}
              {!mutation.isLoading ? null : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading
                </span>
              )}
            </div>
          </button>
        </form>
      </div>
    </React.Fragment>
  );
};
export default EditCompanyProfile;
