import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useCustomFetchQuery from "../../Hooks/useCustomFecthQuery";
import Separator from "../../components/Common/Separator/Separator";
import MenuNavigation from "./OfficerMenu/MenuNavigation";
import Attendees from "../Organizer/EventSummary/components/attendees/attendees.component";

function Index() {
  document.title = "Showbay 24| Event Officer";

  const obj = JSON.parse(localStorage.getItem("authShowbayUser"));
  const userId = obj?.data?.data?.id;
  const token = obj?.token;
  const params = useParams();
  const eventID = params.eventID;

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomFetchQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  //   console.log("getMyEventDetail.....", getMyEventDetail);

  return (
    <div className="w-100">
      <Separator />
      <div className="w-100 mt-3" style={{ position: "relative", zIndex: 99 }}>
        <div>
          <div className="w-100" style={{ position: "fixed" }}>
            <MenuNavigation getMyEventDetail={getMyEventDetail} />
          </div>
        </div>
      </div>
      <div
        className="w-100 d-flex justify-content-center"
        style={{ position: "relative", marginTop: "8%" }}
      >
        <div className="w-75">
          <Attendees token={token} eventID={eventID} />
        </div>
      </div>
    </div>
  );
}

export default Index;
