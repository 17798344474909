import React from "react";
import { FaPlay } from "react-icons/fa";
import "./liveSesion.scss";

function Live({ boothDetails }) {
  return (
    <div className="live-component d-flex w-100 justify-content-center align-items-center">
      <div
        className="d-flex justify-content-center shadow-sm mt-2 card-brd"
        style={{ position: "relative" }}
      >
        <img
          src={boothDetails?.boothLogo}
          alt="Booth Logo"
          className="card-brd"
        />

        <button
          className="btn btn-primary btn-wave"
          style={{
            width: 120,
            height: 40,
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)", // Adjust vertically
          }}
        >
          Join Session
        </button>
      </div>
    </div>
  );
}

export default Live;
